<template>
  <!--下载窗口-->
  <div class="dowload mr0At" style="width: 1200px;">
    <!--联系电话-->
   <!-- <div class="d_tit dif alc">
      <img src="@/assets/image/yizhibang/20telephone.png" style="width: 20px;height: 18px;">
      <h3 class="fs18 col333 bold mrLft10">联系店铺方 0538-000000 </h3>
      <h3 class="fs18 col333 bold" style="margin-left: 44px;">王经理 154-00000000 </h3>
    </div>-->
    <!--应用列表-->
    <div class="app_list pdBtm30" v-if="download.length>0">
      <ul>
        <li class="dif alc mrTop20 app_item posr" v-for="(item,index) in download" :key="index" @click="goAppDetail(item.id)">
          <!--软件图标-->
          <div class="app_img bra10" style="width: 100px;height: 100px;overflow: hidden;border-radius: 20px">
            <img :src="item.appimage" class="w100 h100">
          </div>
          <!--软件信息-->
          <div class="app_info" style="margin-left: 41px;">
            <ul class="fs12 col666 difb difw" style="width: 410px;">
              <li class="one_overflow">软件名称：{{item.title}}</li>
              <li class="one_overflow">软件性质：{{item.nature}}</li>
              <Li class="one_overflow">软件语言：{{item.language}}</Li>
              <Li class="one_overflow">软件授权：{{item.accredit}}</Li>
              <li class="one_overflow">软件大小：{{item.size}}</li>
              <li class="one_overflow">软件版本：{{item.versions}}</li>
              <li class="one_overflow">使用平台：{{item.platform}}</li>
            </ul>
          </div>
          <!--下载地址-->
          <div class="d_code dif alc" v-if="$store.state.token">
            <div v-if="item.androidimage">
              <img style="width: 74px;height: 74px;" class="bgccc pointer" :src="item.androidimage" @click.stop="$all.showImg(item.androidimage)">
              <p class="fs12 col333 tct" style="margin-top: 15px;">安卓版本下载</p>
            </div>
            <div v-else style="opacity: 0;">
              <img style="width: 74px;height: 74px;" class="bgccc" :src="item.androidimage" >
              <p class="fs12 col333 tct" style="margin-top: 15px;">安卓版本下载</p>
            </div>
            <div class="mrLft30" v-if="item.iosimage">
              <img style="width: 74px;height: 74px;" class="bgccc pointer" :src="item.iosimage" @click.stop="$all.showImg(item.iosimage)">
              <p class="fs12 col333 tct" style="margin-top: 15px;">苹果版本下载</p>
            </div>
            <div v-else style="opacity: 0;" class="mrLft30">
              <img style="width: 74px;height: 74px;" class="bgccc" :src="item.androidimage" >
              <p class="fs12 col333 tct" style="margin-top: 15px;">安卓版本下载</p>
            </div>
          </div>
          <div v-else style="width: 178px;">
            <div class="fs14">登录后即可查看下载二维码</div>
          </div>
          <!--下载-->
          <div class="d_d difcb h100" style="margin-left: 134px;">
            <div style="margin-bottom: 16px;" class="pointer">软件介绍</div>
            <div class="pointer" @click.stop="goAppDetail(item.id,2)">pc端下载</div>
          </div>
          <!--操作-->
          <div class="dif alc fs14 colFDAE38 share">
            <p class="pointer" v-if="item.is_collect==0" @click.stop="collect(item)">收藏</p>
            <p class="pointer" v-else @click.stop="collect(item)">取消收藏</p>
            <!--<p class="pointer" style="margin-left: 16px;" >分享</p>-->
          </div>
        </li>
      </ul>
    </div>
    <div v-else style="padding: 100px 0; ">
      <el-result icon="info" title="暂无内容"></el-result>
    </div>
  </div>
</template>

<script>
export default {
  name: "download",
  props: {
    download: [Array]
  },
  data() {
    return {

    }
  },
  methods: {
    collect(item) { //收藏软件
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      this.$axios("POST","/api/member/collect",{
        id: item.id,
        type: 7
      }).then(res => {
        if(res.code===1) {
          this.$message.success('操作成功')
          if(item.is_collect==1) {
            item.is_collect= 0
          }else {
            item.is_collect= 1
          }
          this.$forceUpdate()
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    goAppDetail(id,type) { //跳转到软件详情
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      let data = {
        id: id
      }
      if(type) data.type = type
      let routeUrl = this.$router.resolve({
        path: '/search/app/detail',
        query: data
      })
      window.open(routeUrl .href, '_blank');
    },
  },
  created() {

  }
}
</script>

<style scoped>
  .share{
    position: absolute;
    bottom: 16px;
    right: 22px;
  }
  .dowload{
    border-top: 1px solid #F0F0F0;
    padding-top: 26px;
  }
  .app_info li{
    text-align: left;
    width: 50%;
    margin-bottom: 10px;
  }
  .app_item{
    width: 1200px;
    box-sizing: border-box;
    background: #FAFAFA;
    border: 1px solid #F5F5F5;
    padding: 23px 36px;
  }
  .d_d div{
    width: 120px;
    height: 40px;
    background: #3A54FF;
    border-radius: 20px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
</style>