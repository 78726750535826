<template>
  <div class="tct total_container">
    <div class="mrBtm10">
      <img :src="userinfo.avatar" style="width:86px;height: 86px;margin-top: 42px;" class="bra bg3765FF">
      <div class="fs14 col333">{{userinfo.username}}</div>
      <!--vip等级-->
      <div class="difcac">
        <div class="difcac mrTop10" style="height: 15px;">
          <div class="user_vip " v-if="userinfo2.is_pay==1 ">
            <img src="@/assets/image/yizhibang/45vip_bg.png" style="width: 33px;height: 15px;">
            <span class="fs12 colfff posr" style="font-style: italic;">VIP</span>
          </div>
          <!--企业会员-->
          <div class="difa alc mrLft10 user_vip" style="width: 40px;" v-if="userinfo2.vip_type==1">
            <img src="@/assets/image/yizhibang/45card_bg.png" style="width: 100%;height: 15px;">
            <img src="@/assets/image/yizhibang/45authentication.png" style="width: 15px;height: 15px;margin-left: 3px;">
            <div style="color: #FFFFFF;margin-left: 12px;position:relative;font-size: 12px;">企</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="rowsc fs15 col666">
        <div @click="LeftType('1')" :class="[leftSelect=='1'?'left_header':'','pointer','total_header']">个人中心</div>
        <div @click="LeftType('2')" :class="[leftSelect=='2'?'right_header':'','pointer','total_header']">企业中心</div>
      </div>
      <div class="w100 bgff fs15 col666 total_content">
        <div v-if="leftSelect=='1'">
          <div @click="contentSelect('1')" class="totla_cont_item" :class="[contentType=='1'?'col468':'']">
            <img src="@/assets/image/yizhibang/45_zhuye.png" v-if="contentType=='1'" >
            <img src="@/assets/image/yizhibang/45_zhuye_gray.png" v-else >
            <span class="mrLft10">我的主页</span>
          </div>
          <div @click="contentSelect('2')" class="totla_cont_item" :class="[contentType=='2'?'col468':'']">
            <img src="@/assets/image/yizhibang/45vip.png" v-if="contentType=='2'" >
            <img src="@/assets/image/yizhibang/45vip_gray.png" v-else >
            <span class="mrLft10">会员中心</span>
          </div>
          <div @click="contentSelect('3')" class="totla_cont_item" :class="[contentType=='3'?'col468':'']">
            <img src="@/assets/image/yizhibang/45news.png" v-if="contentType=='3'" >
            <img src="@/assets/image/yizhibang/45news_gray.png" v-else >
            <span class="mrLft10">我的消息</span>
          </div>
          <div @click="contentSelect('4')" class="totla_cont_item" :class="[contentType=='4'?'col468':'']">
            <img src="@/assets/image/yizhibang/45collection.png" v-if="contentType=='4'" >
            <img src="@/assets/image/yizhibang/45collection_gray.png" v-else >
            <span class="mrLft10">我的收藏</span>
          </div>
          <div @click="contentSelect('5')" class="totla_cont_item" :class="[contentType=='5'?'col468':'']">
            <img src="@/assets/image/yizhibang/45ziliao.png" v-if="contentType=='5'" >
            <img src="@/assets/image/yizhibang/45ziliao_gray.png" v-else >
            <span class="mrLft10">我的资料</span>
          </div>
          <div @click="contentSelect('6')" class="totla_cont_item" :class="[contentType=='6'?'col468':'']">
            <img src="@/assets/image/yizhibang/45identity.png" v-if="contentType=='6'" >
            <img src="@/assets/image/yizhibang/45identity_gray.png" v-else >
            <span class="mrLft10">身份认证</span>
          </div>
          <div @click="contentSelect('7')" class="totla_cont_item" :class="[contentType=='7'?'col468':'']">
            <img src="@/assets/image/yizhibang/45security.png" v-if="contentType=='7'" >
            <img src="@/assets/image/yizhibang/45security_gray.png" v-else >
            <span class="mrLft10">账号安全</span>
          </div>
          <div @click="contentSelect('8')" class="totla_cont_item" :class="[contentType=='8'?'col468':'']">
            <img src="@/assets/image/yizhibang/45jilu.png" v-if="contentType=='8'" >
            <img src="@/assets/image/yizhibang/45jilu_gray.png" v-else >
            <span class="mrLft10">登录记录</span>
          </div>
        </div>
        <div v-else>
          <div @click="contentSelect('1')"  class="totla_cont_item"
               :class="[contentType=='1'?'col468':'']" >
            <img src="@/assets/image/yizhibang/45_zhuye.png" v-if="contentType=='1'" >
            <img src="@/assets/image/yizhibang/45_zhuye_gray.png" v-else >
            <span class="mrLft10">企业认证</span>
          </div>
          <div @click="contentSelect('2')"  class="totla_cont_item"
               :class="[contentType=='2'?'col468':'']">
            <img src="@/assets/image/yizhibang/63blue_img2.png" v-if="contentType=='2'" >
            <img src="@/assets/image/yizhibang/63gray_img2.png" v-else >
            <span class="mrLft10">会员中心</span>
          </div>
          <div @click="contentSelect('3')"  class="totla_cont_item"
               :class="[contentType=='3'?'col468':'']">
            <img src="@/assets/image/yizhibang/63blue_img3.png" v-if="contentType=='3'" >
            <img src="@/assets/image/yizhibang/63gray_img3.png" v-else >
            <span class="mrLft10">官网发布</span>
          </div>
          <div @click="contentSelect('4')"  class="totla_cont_item"
               :class="[contentType=='4'?'col468':'']">
            <img src="@/assets/image/yizhibang/63blue_img4.png" v-if="contentType=='4'" >
            <img src="@/assets/image/yizhibang/63gray_img4.png" v-else >
            <span class="mrLft10">信息发布</span>
          </div>
          <div @click="contentSelect('5')"  class="totla_cont_item"
               :class="[contentType=='5'?'col468':'']">
            <img src="@/assets/image/yizhibang/63blue_img5.png" v-if="contentType=='5'" >
            <img src="@/assets/image/yizhibang/63gray_img5.png" v-else >
            <span class="mrLft10">资讯发布</span>
          </div>
          <div @click="contentSelect('6')"  class="totla_cont_item"
               :class="[contentType=='6'?'col468':'']">
            <img src="@/assets/image/yizhibang/63blue_img6.png" v-if="contentType=='6'" >
            <img src="@/assets/image/yizhibang/63gray_img6.png" v-else >
            <span class="mrLft10">需求发布</span>
          </div>
          <div @click="contentSelect('7')"  class="totla_cont_item"
               :class="[contentType=='7'?'col468':'']">
            <img src="@/assets/image/yizhibang/45security.png" v-if="contentType=='7'" >
            <img src="@/assets/image/yizhibang/45security_gray.png" v-else >
            <span class="mrLft10">店铺发布</span>
          </div>
          <div @click="contentSelect('8')"  class="totla_cont_item"
               :class="[contentType=='8'?'col468':'']" v-if="type==1">
            <img src="@/assets/image/yizhibang/jiameng_fill.png" v-if="contentType=='8'" >
            <img src="@/assets/image/yizhibang/jiameng.png" v-else >
            <span class="mrLft10">加盟发布</span>
          </div>
          <div @click="contentSelect('9')"  class="totla_cont_item"
               :class="[contentType=='9'?'col468':'']">
            <img src="@/assets/image/yizhibang/zhao_fill.png" v-if="contentType=='9'" >
            <img src="@/assets/image/yizhibang/zhao.png" v-else >
            <span class="mrLft10">招聘发布</span>
          </div>
          <div @click="contentSelect('10')"  class="totla_cont_item"
               :class="[contentType=='10'?'col468':'']" v-if="type==1">
            <img src="@/assets/image/yizhibang/ruanjianfa_fill.png" v-if="contentType=='10'" >
            <img src="@/assets/image/yizhibang/ruanjianfa.png" v-else >
            <span class="mrLft10">软件发布</span>
          </div>
          <!--<div @click="contentSelect('11')"  class="totla_cont_item"-->
          <!--     :class="[contentType=='11'?'col468':'']">-->
          <!--  <img src="@/assets/image/yizhibang/63blue_img6.png" v-if="contentType=='11'" >-->
          <!--  <img src="@/assets/image/yizhibang/63gray_img6.png" v-else >-->
          <!--  <span class="mrLft10">我的定制</span>-->
          <!--</div>-->
          <div @click="contentSelect('12')"  class="totla_cont_item"
               :class="[contentType=='12'?'col468':'']">
            <img src="@/assets/image/yizhibang/fangwen_fill.png" v-if="contentType=='12'" >
            <img src="@/assets/image/yizhibang/fangwen.png" v-else >
            <span class="mrLft10">访问数据</span>
          </div>
          <div @click="contentSelect('13')"  class="totla_cont_item"
               :class="[contentType=='13'?'col468':'']">
            <img src="@/assets/image/yizhibang/shengcheng_fill.png" v-if="contentType=='13'" >
            <img src="@/assets/image/yizhibang/shengcheng.png" v-else >
            <span class="mrLft10">生成官网</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        fullHeight:document.documentElement.clientHeight,//屏幕高度 默认值
        type: '0',//企业入驻类型
        leftSelect:'1',//顶部的点击事件
        status: '0',//0未审核 1已通过 2未通过
        leftList:[
          {icon: '45_zhuye_gray.png',selectIcon:'45_zhuye.png',name:'我的主页',id:'1'},
          {icon: '45vip_gray.png',selectIcon:'45vip.png',name:'会员中心',id:'2'},
          {icon: '45news_gray.png',selectIcon:'45news.png',name:'我的消息',id:'3'},
          {icon: '45collection_gray.png',selectIcon:'45collection.png',name:'我的收藏',id:'4'},
          {icon: '45ziliao_gray.png',selectIcon:'45ziliao.png',name:'我的资料',id:'5'},
          {icon: '45identity_gray.png',selectIcon:'45identity.png',name:'身份认证',id:'6'},
          {icon: '45security_gray.png',selectIcon:'45security.png',name:'账号安全',id:'7'},
          {icon: '45jilu_gray.png',selectIcon:'45jilu.png',name:'登录记录',id:'8'},
        ],//左侧的数组
        rightList:[
          {icon: '',selectIcon:'',name:'企业认证',id:'1'},
          {icon: '',selectIcon:'',name:'会员中心',id:'2'},
          {icon: '',selectIcon:'',name:'官网发布',id:'3'},
          {icon: '',selectIcon:'',name:'信息发布',id:'4'},
          {icon: '',selectIcon:'',name:'资讯发布',id:'5'},
          {icon: '',selectIcon:'',name:'需求发布',id:'6'},
          {icon: '',selectIcon:'',name:'店铺发布',id:'7'},
          {icon: '',selectIcon:'',name:'加盟发布',id:'8'},
          {icon: '',selectIcon:'',name:'招聘发布',id:'9'},
          {icon: '',selectIcon:'',name:'软件发布',id:'10'},
          {icon: '',selectIcon:'',name:'我的定制',id:'11'},
          {icon: '',selectIcon:'',name:'访问数据',id:'12'},
          {icon: '',selectIcon:'',name:'生成官网',id:'13'},
        ],//右侧的数组
        contentType:'1',//全部内容的选择
        userinfo: [],
        userinfo2: {
          is_pay: 0,//1 会员
          end_time: '',//到期时间
          vip_type: 0,
          vip_time: '',
        }
      }
    },
    created() {
      let query = this.$route.query //获取点击头像跳转传来的数据
      this.contentType = query.contentType
      this.leftSelect = query.leftSelect
      this.getEnterpriseCenter();//获取企业入驻信息
      //获取头像和名称
      this.$axios("POST","/api/member/personalCenter",{})
      .then(res => {
        this.userinfo = res.data
      })
      this.getUserInfo()
    },
    watch: {
      //监听路由参数
      "$route": function () {
        let query = this.$route.query
        this.contentType = query.contentType
        this.leftSelect = query.leftSelect
        this.contentSelect(this.contentType)
      }
    },
    methods:{
      getUserInfo() {
        this.$axios("POST","/api/user/getUser",{})
            .then(res => {
              this.userinfo2 = res.data
            })
      },
      getEnterpriseCenter() { //获取企业入驻信息
        this.$axios("POST","/api/User/getUserCompany",{})
        .then(res => {
          if(res.code===1) {
            this.status = res.data.status
            this.type = res.data.type
          }
        })
      },
      LeftType(event) {//个人中心 企业中心的点击事件
        if(this.leftSelect == event) return false;
        this.leftSelect = event;//赋值顶部
        this.contentType = '1';//赋值下面
        this.$emit('LeftHeaderType',{'top':event,'bottom':'1'});//这是传给父页面
      },
      contentSelect(id) {//底部全部内容的点击事件
        if(this.leftSelect==2) {
          if(id>=2 && id<=13 && this.status==0) {
            return this.$alert('请先完成企业认证',{type: 'info'})
          }
          if(id>2 && id<=13 && this.userinfo2.vip_type==0) {
            return this.$alert('开通企业会员即可享受更多权限',{type: 'info'})
            .then(res => {
              this.$router.replace({
                path: '/meCenter',
                query:{
                  leftSelect:2,
                  contentType: 2,
                  time: new Date().getTime()
                }
              }).catch(err => err)
            })
          }
        }
        this.contentType = id;//赋值下面内容
        // this.$emit('LeftHeaderType',{'top':this.leftSelect,'bottom': id});//这是传给父页面
        this.$router.push({
          path: '/meCenter',
          query:{
            leftSelect:this.leftSelect,
            contentType: id,
            time: new Date().getTime()
          }
        }).catch(err => err)
      }
    }
  }
</script>

<style scoped>
  .user_vip{
    width: 33px;
    height: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7px;
  }
  .user_vip img{
    position: absolute;
    left: 0;
    top: 0;
  }
  .total_container {
    width: 224px;
    box-sizing: border-box;
    background: #f3f2f2;
    border-radius: 10px 0 0 10px;
  }
  .total_header {
    font-size: 14px;
    color: #333333;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
  }
  .left_header {
    background-color: #ffffff;
    border-radius: 10px 10px 0 0;
  }
  .right_header {
    background-color: #ffffff;
    border-radius: 10px 10px 0 0;
  }
  .total_content {
    width: 208px;
    margin: 0 auto;
    background: #FFFFFF;
    padding-top: 25px;
    padding-bottom: 1px;
  }
  .totla_cont_item{
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
  }
  .total_content>div>div:hover {
    cursor: pointer;
    color: #3765FF;
  }
  .col468{
    color: #3765FF;
  }
</style>
