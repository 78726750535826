<template>
  <!--右侧推荐 分类-->
  <div class="res_right box" style="width: 421px;">
    <!--排行榜分类-->
    <!--<div class="tag mrBtm20">
      <ul class="dif difw">
        <li v-for="(item,index) in tags" :key="index" @click="imformation(item.id,item)" class="pointer fs14 col999"
            style="margin-bottom: 5px;"
            :class="isActive==item.id?'active':'norml'">{{item.name}}</li>
      </ul>
    </div>-->
    <!--标题-->
    <div class="posr mrBtm20">
     <h2 class="bold col333 fs20 tlt">{{currentName}}</h2>
      <div class="title_bold"></div>
    </div>
    <!--排名第一的-->
    <div style="width: 368px;height: 232px;" class="posr" >
      <el-carousel height="232px">
        <el-carousel-item v-for="item in swiper" :key="item.id">
          <img :src="item.image" class="w100 h100"  alt="">
        </el-carousel-item>
      </el-carousel>
      <!--蒙版-->
      <!--<div class="mb"></div>-->
      <!--热门标签-->
      <!--<div class="hot">
        <img src="@/assets/image/yizhibang/icon_hot.png" style="width: 12px;height: 14px;">
        <div style="font-size: 12px;" class="colfff mrLft10">NO.1</div>
      </div>-->
      <!--来源 标题-->
      <!--<div class="hot_title">
        <div class="two_overflow fs22 colfff">{{res[0].title}}</div>
        <div class="colfff" style="font-size: 12px;margin-top: 8px;">{{res[0].createtime}}  来源：{{res[0].siteName}}</div>
      </div>-->
    </div>
    <!--排名不是第一的-->
    <div class="box dif alc" style="padding: 16px 0;width: 368px;border-bottom: 1px solid #E6E6E6;"
         @click="goDetail(item.id)"
         v-if="index<4"
         v-for="(item,index) in res" :key="index">
      <div class="posr">
        <div class="hot2">
          <img src="@/assets/image/yizhibang/icon_hot.png" style="width: 12px;height: 14px;">
          <div style="font-size: 12px;margin-left: 5px;" class="colfff">NO.{{index+1}}</div>
        </div>
        <img class="bgcb" style="width: 114px;height: 78px;min-width: 114px;min-height: 78px" :src="item.image[1]">
      </div>
      <div class="mrLft20 difcb tlt" style="height: 74px;">
        <div class="fs16 two_overflow pointer hover" style="color:#1D1E20;">{{item.title}}</div>
        <div class="col999" style="font-size: 12px;">{{item.siteName}}  {{item.createtime}}</div>
      </div>
    </div>

    <!--<div class="hot dife mrTop30">
      <ul style="width: 470px;" class="difb difw">
        <li style="width: 230px;" class="box mrBtm10" v-for="(item,index) in res" @click="goAdvisory(item.id)">
          <img :src="'https://yzb.yzbse.com'+item.image">
          <p class="two_overflow fs13 tlt">{{item.title}}</p>
        </li>
      </ul>
    </div>-->
  </div>
</template>

<script>
export default {
  name: "hot",
  props: {
    sid: {
      type: [Number,String]
    }
  },
  data() {
    return {
      tags: [
        {id:1,name:'综合榜'},
        {id:3,name:'热度榜'},
        {id:6,name:'最新榜'},
        {id:4,name:'宣传榜'},
        {id:2,name:'点赞榜'},
        {id:8,name:'评价榜'},
        {id:5,name:'分享榜'},
        {id:7,name:'收藏榜'},
      ],
      isActive: 1,
      res: [],//资讯榜单数据
      swiper: [],
      currentName: '综合榜',
    }
  },
  created() {
    this.imformation(1,{name: '热度推荐'})
    this.getSwiper()
  },
  methods: {
    getSwiper() { //获取轮播图
      this.$axios("POST","/api/index/getAd",{})
          .then(res => {
            if(res.code==1) this.swiper = res.data
          })
    },
    goDetail(id) { //跳转到资讯详情
      this.$router.push({
        path: '/search/advisory/detail',
        query: {
          id: id
        }
      }).catch(err => err)
    },
    imformation(id,item) { //获取资讯榜单
      this.currentName = item.name
      this.isActive = id
      let data = {
        type: id
      }
      if(this.sid) data.sid = this.sid
      this.$axios("POST","/api/site_title/imformation",data).then(res => {
        this.res = res.data.slice(0,6)
      })
    },
    goAdvisory(id){ //跳转到资讯详情
      this.$router.push({
        path: '/search/advisory/detail',
        query: {
          id: id
        }
      }).catch(err => err)
    }
  }
}
</script>

<style scoped>
.hot2{
  width: 50px;
  background: #EB6100;
  position: absolute;
  display: flex;
  top: 3px;
  left: 3px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.hot_title{
  padding: 0 22px;
  padding-bottom: 15px;
  box-sizing: border-box;
  position: absolute;
  text-align: left;
  bottom: 0;
  left: 0;
  width: 100%;
}
.hot{
  width: 60px;
  height: 20px;
  background: #EB6100;
  position: absolute;
  display: flex;
  top: 11px;
  left: 11px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.mb{
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
}

.title_bold{
  width: 256px;
  height: 10px;
  background: #F6F6F6;
  position: absolute;
  top: 50%;
  right: 53px;
  transform: translateY(-50%);
}
h4.tlt{
  position: absolute;
  top: 0;
  right: 405px;
}
.f1{flex: 1}
.tag li.active{
  border: 1px solid #3A54FF;
  color: #3A54FF;
  padding: 0 11px;
  border-radius: 12px;
}
.tag li.norml{
  border: 1px solid rgba(0,0,0,0);
  padding: 0 11px;
  border-radius: 12px;
}
.hot li img{
  width: 230px;
  height: 120px;
  background: #666;
}
</style>