<template>
  <!--分类软件-->
  <div class="app_center">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "appCenter"
}
</script>

<style scoped>

</style>