<template>
  <div class="padding" v-loading="showLoading">
    <!-- 加盟发布 -->
    <div class="dif alc mrBtm30">
      <h1 class="fs16 col333 bold tlt">加盟发布</h1>
      <!--<div class="dif alc pointer mrLft30" @click="isShow3=!isShow3">-->
      <!--  <img src="@/assets/image/yizhibang/03no_check.png" style="width: 16px;height: 16px;" v-if="!isShow3">-->
      <!--  <img src="@/assets/image/yizhibang/03yes_check.png" style="width: 16px;height: 16px;" v-else>-->
      <!--  <p class="fs12 col666 mrLft10">是否关闭此功能<span class="col999">(在您首页官网搜索将不显示此服务)</span></p>-->
      <!--</div>-->
    </div>
    <!--分类-->
    <div class="dif alc">
      <div class="fs14 col333 dif alc">
        <p style="color: #f00">*</p>
        <p class="mrLft10">分类</p>
        <div style="width: 360px;margin-left: 15px;">
          <el-input placeholder="加盟" disabled></el-input>
        </div>
      </div>
    </div>

    <!--上传商标选择地-->
    <div class="dif alc" style="margin-top: 50px;">
      <div class="posr">
        <el-upload
            class="avatar-uploader"
            action="https://yzb.yzbse.com/api/common/upload"
            :show-file-list="false"
            :on-success="onsuccess"
            :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl2" :src="imageUrl2" style="width: 186px;height: 126px;">
          <div class="posr" v-else>
            <img src="@/assets/image/yizhibang/69upload_logo.png" style="width: 186px;height: 126px;">
            <p class="fs12 posa" style="color: #B3B3B3;left: 0;right: 0;top: 82px;">请上传商标图片</p>
          </div>
        </el-upload>

      </div>
      <div class="mrLft30 difcb" style="height: 126px;">
        <!--品牌名称-->
        <div class="dif alc">
          <p class="mrRit10">品牌名称</p>
          <div style="width: 360px;">
            <el-input placeholder="请输入品牌名称" v-model="brandname"></el-input>
          </div>
        </div>
        <!--企业名称-->
        <div class="dif alc">
          <p class="mrRit10">企业名称</p>
          <div style="width: 360px;">
            <el-input placeholder="请输入企业名称" v-model="enterprisename"></el-input>
          </div>
        </div>
        <!--所在地区-->
        <div class="dif alc">
          <p class="mrRit10">所在地区</p>
          <div style="width: 360px;">
            <el-cascader
                style="width: 360px;"
                :options="options"
                v-model="selectedOptions"
                @change="handleChange"
            >
            </el-cascader>
          </div>
        </div>
      </div>
    </div>
    <!--标题和标题属性-->
    <div class="dif alc" style="margin-top: 50px;">
      <p class="fs14 col333 mrRit10">标题</p>
      <div style="width: 400px;">
        <el-input placeholder="请输入标题" v-model="title"></el-input>
      </div>
      <!--<p class="fs14 col333 mrLft30 mrRit20">标题属性</p>
      <div class="dif alc">
        &lt;!&ndash;默认&ndash;&gt;
        <div class="dif alc pointer mrRit20" @click="titleType=1">
          <img src="@/assets/image/yizhibang/51close.png" style="width: 16px;height: 16px;" v-if="titleType===1">
          <img src="@/assets/image/yizhibang/51noclose.png" style="width: 16px;height: 16px;" v-else>
          <p class="mrLft10 fs14 col999">默认</p>
        </div>
        &lt;!&ndash;加粗&ndash;&gt;
        <div class="dif alc pointer mrRit20" @click="titleType=2">
          <img src="@/assets/image/yizhibang/51close.png" style="width: 16px;height: 16px;" v-if="titleType===2">
          <img src="@/assets/image/yizhibang/51noclose.png" style="width: 16px;height: 16px;" v-else>
          <p class="mrLft10 fs14 col999">加粗</p>
        </div>
        &lt;!&ndash;斜体&ndash;&gt;
        <div class="dif alc pointer" @click="titleType=3">
          <img src="@/assets/image/yizhibang/51close.png" style="width: 16px;height: 16px;" v-if="titleType===3">
          <img src="@/assets/image/yizhibang/51noclose.png" style="width: 16px;height: 16px;" v-else>
          <p class="mrLft10 fs14 col999">斜体</p>
        </div>
      </div>-->
    </div>
    <!--加盟介绍-->
    <div class="mrBtm20" style="margin-top: 50px;">
      <p class="fs14 col333 mrRit10 tlt mrBtm10">加盟发布介绍</p>
      <div style="width: 768px;">
        <el-input placeholder="请填写加盟发布介绍关健词，并用“/”号分开 [用于发布官网的首页搜索,请如实谨慎填写]" type="textarea" rows="5" v-model="keyword"></el-input>
      </div>
    </div>
    <!--加盟相关-->
    <div class="dif difw mr0At">
      <div class="w30 dif alc mrBtm10">
        <span class="text_red mrRit10">*</span>
        <span class="mrRit10">投资金额</span>
        <div style="width: 200px;"><el-input type="text" placeholder="请输入金额区间" v-model="investmentNUm"></el-input></div>
      </div>
      <div class="w30 dif alc mrBtm10">
        <span class="text_red mrRit10">*</span>
        <span class="mrRit10">加盟区域</span>
        <div style="width: 200px;"><el-input type="text" placeholder="请输入加盟区域" v-model="leagueArea"></el-input></div>
      </div>
      <div class="w30 dif alc mrBtm10">
        <span class="text_red mrRit10">*</span>
        <span class="mrRit10">门店总数</span>
        <div style="width: 200px;"><el-input type="number" placeholder="请输入门店总数" v-model="storeNum"></el-input></div>
      </div>
      <div class="w30 dif alc mrBtm10">
        <span class="text_red mrRit10">*</span>
        <span class="mrRit10">经营模式</span>
        <div style="width: 200px;"><el-input type="text" placeholder="请输入经营模式" v-model="manage_pattern"></el-input></div>
      </div>
      <div class="w30 dif alc mrBtm10">
        <span class="text_red mrRit10">*</span>
        <span class="mrRit10">品牌成立</span>
        <div style="width: 200px;"><el-input type="text" placeholder="请输入品牌成立时间" v-model="brand_authorizing"></el-input></div>
      </div>
      <div class="w30 dif alc mrBtm10">
        <span class="text_red mrRit10">*</span>
        <span class="mrRit10">适合人群</span>
        <div style="width: 200px;"><el-input type="text" placeholder="请输入适合人群" v-model="brandCrowd"></el-input></div>
      </div>
      <div class="w30 dif alc mrBtm10">
        <span class="text_red mrRit10">*</span>
        <span class="mrRit10">所属行业</span>
        <div style="width: 200px;"><el-input type="text" placeholder="请输入所属行业" v-model="industryInvolved"></el-input></div>
      </div>
    </div>
    <!--联系方式-->
    <div class="dif alc mrTop20">
      <div class="dif alc">
        <span class="text_red mrRit10">*</span>
        <span class="mrRit10 fs">联系电话</span>
        <el-input v-model="user_name" style="width: 70%;"></el-input>
      </div>
      <div class="dif alc mrLft30">
        <span class="text_red mrRit10">*</span>
        <span class="mrRit10">联系人</span>
        <el-input v-model="user_phone" style="width: 60%;"></el-input>
      </div>
      <!--<div class="dif alc">
        <span class="mrRit10">手机</span>
        <el-input v-model="phone" style="width: 70%;"></el-input>
      </div>-->
      <!--<div class="dif alc pointer" @click="isdefault=!isdefault">-->
      <!--  <span class="mrRit10">设为默认</span>-->
      <!--  <img src="@/assets/image/yizhibang/03no_check.png" style="width: 16px;height: 16px;" v-if="!isdefault">-->
      <!--  <img src="@/assets/image/yizhibang/03yes_check.png" style="width: 16px;height: 16px;" v-else>-->
      <!--</div>-->
    </div>
    <!--品牌展示-->
    <div class="mrTop10 tlt mrTop10">
      <p class="tlt fs16 mrBtm20">品牌展示(最多上传5张图片)</p>
      <!--照片墙-->
      <el-upload
          action="https://yzb.yzbse.com/api/common/upload"
          list-type="picture-card"
          :on-success="onsuccess2"
          :on-remove="handleRemove"
          :limit="limit"
          :file-list="fileList"
      >
        <i slot="default" class="el-icon-plus"></i>
      </el-upload>
    </div>
    <!--目录编辑-->
    <!--<p class="col333 fs14 tlt mrTop30 mrBtm20 bold">招商详情</p>-->
    <!--目录列表-->
    <!--<div class="list dif alc" >
      <div class="difcac fs16 col333 list_1">目录</div>
      <div class="list2">
        <div class="dif alc" style="height: 238px;">
          <div class="list2_item" >
            <p class="fs14 col3A53FF mrTop10">1.加盟优势
              <span class="text-red pointer fs12 mrLft10">删除</span>
            </p>
            <p class="fs14 col3A53FF mrTop10">2.加盟条件
              <span class="text-red pointer fs12 mrLft10">删除</span>
            </p>
            <p class="fs14 col3A53FF mrTop10">3.加盟流程
              <span class="text-red pointer fs12 mrLft10">删除</span>
            </p>
          </div>
        </div>
      </div>
    </div>-->
    <!--招商详情-->
    <div class="mrBtm30 mrTop30">
      <div class="dif alc mrBtm20">
        <div class="message_icon mrRit10"></div>
        <!--<p class="fs14 col333 bold">招商详情</p>-->
        <div style="width: 250px;">
          <el-input v-model="label1" class="fs14 col333 bold" placeholder="请输入标题"></el-input>
        </div>
      </div>
      <quill-editor1 v-show="showRichtText"
          @getEditor="getEditor4"
          ref="quillEditor4"
          :index="0"
      ></quill-editor1>
    </div>
    <!--加盟优势-->
    <div class="mrBtm30">
      <div class="dif alc mrBtm20">
        <div class="message_icon mrRit10"></div>
        <!--<p class="fs14 col333 bold">加盟优势</p>-->
        <div style="width: 250px;">
          <el-input v-model="label2" class="fs14 col333 bold" placeholder="请输入标题"></el-input>
        </div>
      </div>
      <quill-editor1 v-show="showRichtText"
          @getEditor="getEditor1"
          ref="quillEditor1"
          :quillref="'q1'"
          :index="1"
      ></quill-editor1>
    </div>
    <!--加盟条件-->
    <div class="mrBtm30">
      <div class="dif alc mrBtm20">
        <div class="message_icon mrRit10"></div>
        <!--<p class="fs14 col333 bold">加盟条件</p>-->
        <div style="width: 250px;">
          <el-input v-model="label3" class="fs14 col333 bold" placeholder="请输入标题"></el-input>
        </div>
      </div>
      <quill-editor1 v-show="showRichtText"
          @getEditor="getEditor2"
          ref="quillEditor2"
          :index="2"
      ></quill-editor1>
    </div>
    <!--加盟流程-->
    <div class="mrBtm30">
      <div class="dif alc mrBtm20">
        <div class="message_icon mrRit10"></div>
        <!--<p class="fs14 col333 bold">加盟流程</p>-->
        <div style="width: 250px;">
          <el-input v-model="label4" class="fs14 col333 bold" placeholder="请输入标题"></el-input>
        </div>
      </div>
      <quill-editor1 v-show="showRichtText"
          @getEditor="getEditor3"
          ref="quillEditor3"
          :index="3"
      ></quill-editor1>
    </div>
    <!--其他-->
    <div class="mrBtm30">
      <div class="dif alc mrBtm20">
        <div class="message_icon mrRit10"></div>
        <p class="fs14 col333 bold">其他</p>
      </div>
      <quill-editor1 v-show="showRichtText"
          @getEditor="getEditor5"
          ref="quillEditor5"
          :index="5"
      ></quill-editor1>
    </div>
    <div style="height: 50px;"></div>
    <!--取消和发布-->
    <div class="difcac">
      <div class="difcac msg_button1 colfff pointer">取消</div>
      <div class="difcac msg_button2 colfff pointer" @click="submit">发布</div>
    </div>
  </div>
</template>

<script>
//富文本
import quillEditor1 from "@/components/quillEditor1";
import {CodeToText, regionData, TextToCode} from "element-china-area-data";

export default {
  props: {
    LeftHeaderType: [String, Number],
  },
  components: {
    quillEditor1
  },
  data() {
    return {
      label1: '招商详情',
      label2: '加盟优势',
      label3: '加盟条件',
      label4: '加盟流程',
      showRichtText: false,//显示富文本
      user_name: '',
      user_phone: '',
      isdefault: false,//设为默认
      isShow3: false,//是否显示此功能
      pageHeight:document.documentElement.clientHeight,//获取页面高度
      landline:'',//座机
      phone: '',//手机号
      broker: '',//经纪人
      limit: 5,
      fileList: [],
      imageUrl: '',//商标图片
      imageUrl2: '',//商标图片完整路径
      brandname: '',//品牌名称
      enterprisename: '',//企业名称
      options: regionData,//二级联动 省  市
      selectedOptions: [],//选择的内容
      province_id: '',//省
      city_id: '',//市
      area_id: '',//区
      title: '',//标题
      keyword: '',//关键字
      investmentNUm: '',//投资金额
      leagueArea: '',//加盟区域
      storeNum: '',//门店总数
      manage_pattern: '',//经营模式
      brand_authorizing: '',//品牌成立
      brandCrowd:'',//适合人群
      industryInvolved: '',//所属行业
      attractcontent: '',//招商详情
      advantagecontent: '',//加盟优势
      conditioncontent: '',//加盟条件
      processcontent: '',//加盟流程
      other: '',//其他
      showImg: [],//品牌展示
      showLoading: false,
    }
  },
  methods: {
    getMySubmit() { //获取我的提交
      this.showLoading = true
      this.$axios("POST","/api/site/siteInfodetail",{
        type: 4,
        infoType: 4,
      }).then(res => {
        this.showLoading = false
        if(res.code===1 && res.data.length>0) {
          let data = res.data.pop()
          if(data) {
            this.title = data.title
            this.keyword = data.keyword
            this.investmentNUm = data.investmentNUm
            this.leagueArea = data.leagueArea
            this.storeNum = data.storeNum
            this.brandCrowd = data.brandCrowd
            this.industryInvolved = data.industryInvolved
            this.attractcontent = data.attractcontent
            this.advantagecontent = data.advantagecontent
            this.conditioncontent = data.conditioncontent
            this.processcontent = data.processcontent
            this.$refs.quillEditor1.editorContent = unescape(data.advantagecontent)
            this.$refs.quillEditor2.editorContent = unescape(data.conditioncontent)
            this.$refs.quillEditor3.editorContent = unescape(data.processcontent)
            this.$refs.quillEditor4.editorContent = unescape(data.attractcontent)
            this.$refs.quillEditor5.editorContent = unescape(data.other)
            let arr = data.images.split(',')
            this.showImg = arr
            this.fileList = arr.map(item => {
              return {
                url: this.$store.state.url + item,
                response: {data: {url: item}}
              }
            })
            this.other = data.other
            this.imageUrl = data.brandimage
            this.imageUrl2 = this.$store.state.url + data.brandimage
            this.enterprisename = data.enterprisename
            this.province_id = data.province_id
            this.city_id = data.city_id
            this.area_id = data.area_id
            this.manage_pattern = data.manage_pattern
            this.brand_authorizing = data.brand_authorizing
            this.user_name = data.user_name
            this.user_phone = data.user_phone
            data.label1?this.label1 = data.label1:''
            data.label2?this.label2 = data.label2:''
            data.label3?this.label3 = data.label3:''
            data.label4?this.label4 = data.label4:''

          }
        }
        setTimeout(() => {
          this.showRichtText = true
        },1200)
      })
    },
    handleRemove(file, fileList) { //删除品牌展示回调
      console.log(fileList)
      let arr = []
      for(let i in fileList) {
          arr.push(fileList[i].response.data.url)
      }
      this.showImg = arr
      console.log(arr)
    },
    onsuccess(file) { //上传商标成功
      this.imageUrl = file.data.url
      this.imageUrl2 = file.data.fullurl
      this.$forceUpdate()
    },
    onsuccess2(file,t,fileList) { //上传品牌展示
      console.log(fileList);
      this.showImg.push(file.data.url)
    },
    beforeAvatarUpload(file,fileList) {//限制图片大小和格式
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传封面图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传封面图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    getEditor1(cont) {//加盟优势
      this.advantagecontent = escape(cont)
    },
    getEditor2(cont) {//加盟条件
      this.conditioncontent = escape(cont)
    },
    getEditor3(cont) {//加盟流程
      this.processcontent = escape(cont)
    },
    getEditor4(cont) { //招商详情
      this.attractcontent = escape(cont)
    },
    getEditor5(cont) { //其他
      this.other = escape(cont)
    },
    handleChange() { //选择省市区
      /*let province_name = CodeToText[this.selectedOptions[0]]; //省名
      let city_name = CodeToText[this.selectedOptions[1]]; //市名
      let area_name = CodeToText[this.selectedOptions[2]]; //区名*/
      let province_name = this.selectedOptions[0]; //省名
      let city_name = this.selectedOptions[1]; //市名
      let area_name = this.selectedOptions[2]; //区名
      this.province_id = province_name
      this.city_id = city_name
      this.area_id = area_name
    },
    submit() { //发布加盟
      let imgarr = this.showImg.join(',')
      if(this.title==='') {
        return this.$alert('请输入标题',{type: 'error'})
      }else if(this.imageUrl==='') {
        return this.$alert('请上传商标图片',{type: 'error'})
      }else if(this.keyword==='') {
        return this.$alert('请输入关键字',{type: 'error'})
      }else if(this.investmentNUm==='') {
        return this.$alert('请输入投资金额',{type: 'error'})
      }else if(this.leagueArea==='') {
        return this.$alert('请选择加盟区域',{type: 'error'})
      }else if(this.storeNum==='') {
        return this.$alert('请输入门店总数',{type: 'error'})
      }else if(this.brandCrowd==='') {
        return this.$alert('请输入适合人群',{type: 'error'})
      }else if(this.industryInvolved==='') {
        return this.$alert('请输入所属行业',{type: 'error'})
      }else if(this.industryInvolved==='') {
        return this.$alert('请输入所属行业',{type: 'error'})
      }else if(this.attractcontent==='') {
        return this.$alert('请输入招商详情',{type: 'error'})
      }else if(this.advantagecontent==='') {
        return this.$alert('请输入加盟优势',{type: 'error'})
      }else if(this.conditioncontent==='') {
        return this.$alert('请输入加盟条件',{type: 'error'})
      }else if(this.processcontent==='') {
        return this.$alert('请输入加盟流程',{type: 'error'})
      }else if(this.brandname==='') {
        return this.$alert('请输入品牌名称',{type: 'error'})
      }else if(this.enterprisename==='') {
        return this.$alert('请输入企业全称',{type: 'error'})
      }else if(this.manage_pattern==='') {
        return this.$alert('请输入经营模式',{type: 'error'})
      }else if(this.brand_authorizing==='') {
        return this.$alert('请输入品牌成立',{type: 'error'})
      }
      let address = `${CodeToText[this.province_id]}${CodeToText[this.city_id]}${CodeToText[this.area_id]}`
      this.$axios("POST","/api/Release/releaseLeague",{
        user_name: this.user_name,
        user_phone: this.user_phone,
        title: this.title,
        keyword: this.keyword,
        investmentNUm: this.investmentNUm,
        leagueArea: this.leagueArea,
        storeNum: this.storeNum,
        brandCrowd: this.brandCrowd,
        industryInvolved: this.industryInvolved,
        images: imgarr,
        attractcontent: this.attractcontent,
        advantagecontent: this.advantagecontent,
        conditioncontent: this.conditioncontent,
        processcontent: this.processcontent,
        brandimage: this.imageUrl,
        brandname: this.brandname,
        enterprisename: this.enterprisename,
        province_id:this.province_id,
        city_id: this.city_id,
        area_id: this.area_id,
        manage_pattern: this.manage_pattern,
        brand_authorizing: this.brand_authorizing,
        other: this.other,
        label1: this.label1,
        label2: this.label2,
        label3: this.label3,
        label4: this.label4,
        address: address
      }).then(res => {
        if(res.code===1) {
          this.$alert('添加成功',{type: 'success'})
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    getDetail() { //获取我的官网发布
      this.$axios("POST","/api/Release/getReleaseSite",{})
          .then(res => {
            this.isLoading = false
            if(res.code===1) {
              if(res.data.address) {
                this.address = res.data.address
                let addressArr = res.data.address.split(',')
                this.selectedOptions = [
                  TextToCode[addressArr[0]].code,
                  TextToCode[addressArr[0]][addressArr[1]].code,
                  TextToCode[addressArr[0]][addressArr[1]][addressArr[2]].code,
                ]
                this.province_id = TextToCode[addressArr[0]].code
                this.city_id = TextToCode[addressArr[0]][addressArr[1]].code
                this.area_id = TextToCode[addressArr[0]][addressArr[1]][addressArr[2]].code
              }
              this.brandname = res.data.brand_title

              this.enterprisename = res.data.enterprise

            }else {
              this.$message.error(res.msg)
            }
          })
    }
  },
  created() {
    this.getMySubmit()
    //获取默认联系方式
    this.getDetail()
    if(this.$store.state.contactDetails.id) {
      this.landline = this.$store.state.contactDetails.landline_phone
      this.broker = this.$store.state.contactDetails.broker_name
      this.phone = this.$store.state.contactDetails.tel
      this.isdefault = true
    }
  }
};
</script>

<style scoped>
.container {
  overflow: hidden;
  overflow-y: auto;
}
.padding {
   padding: 30px;
 }

input {
  padding: 10px;
  border: 0.5px solid #c2c2c2;
  border-radius: 0.5rem;
  width: 80%;
}


/*上传图片*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

/* 目录 */
.list{
  width: 1200px;
  border: 1px solid #EBF1F5;
  height: 238px;
  margin-bottom: 30px;
}
.list_1{
  width: 100px;
  border: 1px solid #EBF1F5;
  height: 238px;
  background: #F5FBFF;
}
.list2{
  width: 1100px;
  height: 238px;
  overflow-x: auto;
}
.list2_item{
  box-sizing: border-box;
  flex: none;
  width: 160px;
  height: 238px;
  border: 1px solid #EBF1F5;
  overflow: hidden;
}


.message_icon{
  width: 10px;
  height: 10px;
  background: #345FFD;
}

.msg_button1{
  width: 226px;
  height: 50px;
  background: #CCCCCC;
  border-radius: 6px;
}
.msg_button2{
  width: 226px;
  height: 50px;
  background: #3A54FF;
  border-radius: 6px;
  margin-left: 40px;
}
</style>