<template>
  <div class="padding consult">
    <div class="tlt">
      <span class="pointer fs16 col666" @click="isShow=true" :class="isShow===true?'active':''"> 已经发布 </span>
      <span class="pointer fs16 col666 mrLft30" @click="isShow=false" :class="isShow===false?'active':''"> 资讯发布 </span>
    </div>
    <!--已经发布-->
    <div v-if="isShow" v-loading="getLoading">
      <!--资讯列表-->
      <div style="margin-top: 14px;">
        <ul>
          <li class="zixun_item" v-for="(item,index) in res" :key="index" @click="goDetail(item)">
            <img class="bgccc" style="width: 114px;height: 78px;margin-right: 21px;" :src="getCover(item.images)">
            <div style="width: 400px;height: 76px;" class="difcb tlt">
              <h3 class="fs16 col1D1E20 two_overflow" style="font-weight:normal;">{{item.title}}</h3>
              <p class="fs12 col999">{{item.source}} {{getTime(item.createtime)}}</p>
            </div>
            <div class="zixun_share dif alc">
              <!--<p>链接分享</p>
              <img src="@/assets/image/yizhibang/45qq.png" style="width: 20px;height: 20px;margin-left: 7px;"
                   class="pointer">
              <img src="@/assets/image/yizhibang/45qzone.png" style="width: 20px;height: 20px;margin: 0 7px;"
                   class="pointer">
              <img src="@/assets/image/yizhibang/45sina.png" style="width: 20px;height: 20px;" class="pointer">-->
              <div class="fs14 text-red difcac mrLft30 pointer" style="background: #EEEEEE;width: 42px;height: 20px;" @click.stop="delAdvice(item)">删除</div>
            </div>
          </li>
        </ul>
        <!--分页-->
        <!--<div class="difcac">
          <el-pagination
              :current-page="pages"
              @current-change="curChange"
              background
              layout="jumper, prev, pager  ,next   "
              :total="totla">
          </el-pagination>
        </div>-->
      </div>
    </div>
    <!--资讯发布-->
    <div v-else class="mrTop30">
      <!--分类-->
      <div class="dif alc">
        <div class="fs14 col333 dif alc">
          <p style="color: #f00">*</p>
          <p class="mrLft10">分类</p>
          <div style="width: 360px;margin-left: 15px;">
            <el-select disabled placeholder="资讯" class="w100"></el-select>
          </div>
        </div>
      </div>
      <!--标题-->
      <div class="dif alc mrTop20 mrBtm20">
        <div class="fs14 col333 dif alc">
          <p style="color: #f00">*</p>
          <p class="mrLft10">标题</p>
          <div style="width: 768px;margin-left: 15px;">
            <el-input placeholder="请输入标题" v-model="title"></el-input>
          </div>
        </div>
      </div>
      <!--资讯发布介绍-->
      <div class="" style="margin-top: 50px;">
        <p class="fs14 col333 mrRit10 tlt mrBtm10">资讯发布介绍</p>
        <div style="width: 768px;">
          <el-input placeholder="请填写资讯发布的搜索关键词，并用“/”号分开 [用于发布官网的首页搜索，请谨慎填写]" type="textarea" rows="5" v-model="keywords"></el-input>
        </div>
      </div>
      <!--发布时间-->
      <div class="dif alc" style="margin-top: 50px;">
        <!--来源名称-->
        <div class="dif alc mrRit30">
          <p style="color: #f00">*</p>
          <p class="mrRit10">输入来源名称</p>
          <div style="width: 180px;">
            <el-input placeholder="请输入来源名称" v-model="source"></el-input>
          </div>
        </div>
        <!--发布时间-->
        <!--<div class="dif alc mrRit30">
          <p style="color: #f00">*</p>
          <p class="mrRit10">选择发布时间</p>
          <div style="width: 180px;">
            <el-date-picker
                style="width: 180px;"
                v-model="date"
                type="datetime"
                value-format="timestamp"
                placeholder="选择日期时间">
            </el-date-picker>
          </div>
        </div>-->
      </div>
      <!--上传封面图片-->
      <div class="dif alc mrTop30 mrBtm30"
           v-loading="upLoding"
           element-loading-text="上传中">
        <div class="dif alc">
          <div class="posr" v-if="imageUrl.length>0" v-for="(item,index) in imageUrl" :key="imageUrl">
            <div class="posa pointer" style="top: 0;right: 30px;" @click="deleteImg(index)">
              <el-icon class="el-icon-delete-solid"></el-icon>
            </div>
            <img :src="$store.state.url+item" class="avatar mrRit20"  @click="current=index">
          </div>
          <el-upload
              class="avatar-uploader dif alc"
              action="https://yzb.yzbse.com/api/common/upload"
              accept="image/jpg,image/png,image/jpeg"
              :show-file-list="false"
              :on-success="onsuccess"
              :on-progress="onprogress"
              :before-upload="beforeAvatarUpload">
            <div class="avatar-uploader-icon" v-if="imageUrl.length<3">
              <img src="@/assets/image/yizhibang/69upload_logo.png" class="w100 h100">
              <p class="posa fs12 colb3b3b3" style="top: 105px;left: 0;right: 0;">请上传封面图片</p>
            </div>
          </el-upload>
        </div>
      </div>
      <!--富文本2-->
      <quill-editor1
          @getEditor="getEditor"
          ref="quillEditor"
          :index="0"
      ></quill-editor1>
      <!--取消和发布-->
      <div class="difcac">
        <div class="difcac msg_button1 colfff pointer" @click="isShow=true">取消</div>
        <div class="difcac msg_button2 colfff pointer" @click="submit">发布</div>
      </div>
    </div>
  </div>
</template>

<script>
import quillEditor1 from "@/components/quillEditor1";

export default {
  props: {
    LeftHeaderType: [String, Number],
  },
  components: {
    quillEditor1
  },
  data() {
    return {
      totla: 20,//总条数
      pages: 1, //当前页数
      upLoding: false,//上传加载
      getLoading: false,//获取资讯加载
      pageHeight:document.documentElement.clientHeight,//获取页面高度
      isShow: true, //true 已经发布  false  资讯发布
      title: '',//标题
      keywords: '',//关键词
      date: 0,//时间
      imageUrl: [],//上传封面图片,
      content: '',//资讯内容
      source: '',//来源
      res: [],//我的发布列表
    }
  },
  methods: {
    goDetail(item) { //跳转到详情
      this.$router.push({
        path: '/search/advisory/detail',
        query: {
          id: item.id
        }
      })
    },
    delAdvice(item) { //删除
      this.$confirm('确认要删除该资讯吗？',{type: 'warning'})
      .then(con => {
        this.$axios("POST","/api/member/deleteUserInfo",{
          type: 1,
          id: item.id
        }).then(res => {
          if(res.code===1) {
            this.$message.success('删除成功')
            this.getConsult()
          }else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    curChange(e) { //切换页数
      this.pages = e
      this.getConsult(this.pages)
    },
    deleteImg(index) { //删除所选图片
      this.imageUrl.splice(index,1)
    },
    getEditor(cont) {
      cont = escape(cont)
      this.content = cont
      console.log(unescape(this.content));
    },
    onEditorChange(i,{ editor, html, text }) { //富文本输入
      console.log(i);
    },
    onprogress() {
      this.upLoding=true
    },
    onsuccess(file){ //上传成功
      this.imageUrl.push(file.data.url)
      this.upLoding = false
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error('上传封面图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传封面图片大小不能超过 2MB!');
      }
      // return isJPG && isLt2M;
      return  isLt2M;
    },
    submit() { //发布资讯
      if(this.title === '') {
        this.$alert('请填写标题','提示')
        return
      }else if(this.keywords === '') {
        this.$alert('请填写发布介绍','提示')
        return
        return
      }else if(this.source==='') {
        this.$alert('请输入来源','提示')
        return
      }
      // else if(this.date===0) {
      //   this.$alert('请选择时间','提示')
      // }
      let images = ''
      if(this.imageUrl.length>0){
        images = this.imageUrl.join(',')
      }else {
        this.$alert('请上传封面图片','提示')
        return
      }
      this.$axios("POST","/api/Release/releaseInformation",{
        title: this.title,
        // add_time: this.date/1000,
        keyword: this.keywords,
        content: this.content,
        images: images,
        source: this.source
      }).then(res => {
        if(res.code===1) {
          this.$alert(res.msg,'提示')
          this.isShow = true
          this.getConsult()
        }else {
          this.$alert(res.msg,'提示')
        }
      })
    },
    getConsult(pages) { //获取我的发布资讯
      this.getLoading = true
      this.$axios("POST","/api/Release/myReleaseInformation",{}).then(res => {
        this.getLoading = false
        document.documentElement.scrollTop=0
        if(res.code===1) {
          this.res = res.data
        }else {
          this.$message.error(res.msg)
        }
      })
    }
  },
  created() {
    //获取发布的资讯
    this.getConsult()
  },
  computed: {
    getTime() { //时间戳转换
      return function (time) {
        var date = new Date(time*1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        return M + D + h + m + s;
      }
    },
    getCover() { //获取第一张图片
      return function (images) {
        if(images) {
          let cover = images.split(',')
          return this.$store.state.url + cover[0]
        }
      }
    }
  }
};
</script>

<style scoped>
.consult ::v-deep .el-input__inner{
  height: 40px;
  border-radius: 10px;
}
.consult ::v-deep .el-upload{
  display: flex; align-items: center;
}
/*选中状态*/
.active{
  color: #3765FF
}
/*已发布*/
.zixun_item {
  margin-bottom: 10px;
  position: relative;
  width: 1090px;
  height: 120px;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  padding: 21px;
  display: flex;
  align-items: center;
}

.zixun_share {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}
/*发布按钮*/
.fabu{
  text-align: center;
  margin: 0 auto;
  width: 10%;
  padding: 10px;
  border-radius: 10px;
  color: #FFFFFF;
  background: #004F8A;
}
/*资讯发布*/
input {
  padding: 10px;
  border: 0.5px solid #c2c2c2;
  border-radius: 0.5rem;
  width: 80%;
}
.officialWebsite_classfiy_body {
  display: flex;
  text-align: left;
  /* align-content: center; */
  margin-top: 20px;
  align-items: center;
}
.keywords{
  margin-top: 10px;
  resize: none;
  width: 100%;
  border-radius: 15px;
  padding: 20px;
}
/*上传图片*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  position: relative;
  border-radius: 20px;
  font-size: 28px;
  color: #8c939d;
  width: 250px;
  height: 178px;
  text-align: center;
}
.avatar {
  width: 250px;
  height: 178px;
  display: block;
}
/* 列表 */
/*分享*/
.a_share div{
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
}
.a_share div img{
  width: 20px;
  height: 20px;
}

.msg_button1{
  width: 226px;
  height: 50px;
  background: #CCCCCC;
  border-radius: 6px;
}
.msg_button2{
  width: 226px;
  height: 50px;
  background: #3A54FF;
  border-radius: 6px;
  margin-left: 40px;
}
</style>
