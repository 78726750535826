<template>
  <!--店铺-->
  <div class="shop_class" @click="showShare=false">
    <div class=" h100 difb res_box">
      <!--店铺搜索左侧列表-->
      <div class="res_left" style="width: 900px;">
        <!--导航-->
        <div class="dif alc tlt fs12 col999 mrTop20 mrBtm20">
          <router-link to="/" class="fs12 col999">首页</router-link>
          <p>>店铺搜索</p>
        </div>
        <!--搜索结果-->
        <ul class="shop_res dif difw" v-if="res.length>0">
          <li v-for="(item,index) in res" :key="index" class="mrBtm30" @click="goShopDetail(item.url)">
            <div class="good_img">
              <!--图片轮播-->
              <el-carousel indicator-position="none" height="248px"  :autoplay="true" style="z-index: 20">
                <el-carousel-item v-for="(item2,index2) in getSwiper(item.images)" :key="index2">
                  <div class="posr w100 h100">
                    <img :src="$store.state.url+item2" style="width: 248px;height: 248px;">
                    <div class="mb1"></div>
                    <div class="mb2"></div>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <!--序号-->
              <div class="noindex">NO.{{index+1}}</div>
              <!--品牌-->
              <div class="pinpai">
                <marquee scrollamount=3 v-if="item.brand && item.brand.length>4">品牌：{{item.brand || '暂无'}}</marquee>
                <span v-else>品牌：{{item.brand || '暂无'}}</span>
              </div>
              <!--公司-->
              <p class="compoany one_overflow" style="word-break: break-all;z-index: 23;max-width: 200px">公司：{{item.compoany || '暂无'}}</p>
            </div>
            <div class="good_bottom">
              <!--店铺类型-->
              <div class="difb alc">
                <div class="dif alc">
                  <!--<img src=""  class="bgccc" style="width: 52px;height: 24px;">-->
                  <p class="bold fs12 one_overflow" style="color:#000033;max-width: 150px">{{item.title}}</p>
                  <!--<p class="bold fs12" style="color:#000033;margin-left: 3px;" v-if="item.type==1">直营店</p>-->
                  <!--<p class="bold fs12" style="color:#000033;margin-left: 3px;" v-if="item.type==2">旗舰店</p>-->
                  <!--<p class="bold fs12" style="color:#000033;margin-left: 3px;" v-if="item.type==3">专卖店</p>-->
                  <!--<p class="bold fs12" style="color:#000033;margin-left: 3px;" v-if="item.type==5">专营店</p>-->
                </div>
                <div class="difb alc box">
                  <router-link to="/search/shopdetail" target="_blank" class="col3765FF fs12">进入店铺</router-link>
                </div>
              </div>
              <!--操作-->
              <div class="difb alc" style="margin-top: 15px;">
                <div class="dif alc fs12 colF39800">
                  <p class="pointer" @click.stop="setShare(item,index)">分享</p>
                  <p style="margin-left: 13px;" class="pointer" @click.stop="collect(item)" v-if="item.is_collect==0 || !item.is_collect">收藏</p>
                  <p style="margin-left: 13px;" class="pointer" @click.stop="collect(item)" v-else>已收藏</p>
                </div>
                <p class="fs12 one_overflow" style="color:#1C1C1C;max-width: 100px">
                  <span v-if="item.type==1">直营店</span>
                  <span v-if="item.type==2">旗舰店</span>
                  <span v-if="item.type==3">专卖店</span>
                  <span v-if="item.type==4">专营店</span>
                </p>
              </div>
            </div>
          </li>
        </ul>
        <!--无搜索结果-->
       <div v-if="res.length<=0" style="padding-top: 100px;">
         <el-result icon="info" title="未搜索到结果"></el-result>
        </div>
      </div>
      <!--右侧推荐 分类-->
      <hot class="mrTop30"></hot>
      <!--分享-->
      <div class="share_icon shadow_bottom difcc" v-if="showShare" style="z-index: 9999" @click.stop>
        <i class="el-icon-close close pointer fs24" @click.stop="showShare=false"></i>
        <h3>点击分享</h3>
        <share :config="config"></share>
      </div>
    </div>
  </div>
</template>

<script>
import Hot from "@/components/search/hot";
export default {
  name: "shopCenter",
  components: {Hot},
  data() {
    return {
      tags: [
        {id:0,name:'综合榜'},
        {id:1,name:'点赞榜'},
        {id:2,name:'热度榜'},
        {id:3,name:'好评榜'},
        {id:4,name:'宣传榜'},
        {id:5,name:'点赞年榜'},
      ],
      isActive: 0,
      res: [],//店铺搜索结果
      config:{
        sites: ['qq','wechat','qzone'],
        image: '',
        title: '',
        description: '',
        url: '', // 网址，默认使用 window.location.href
        source: '', // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
        wechatQrcodeTitle: '微信扫一扫：分享', // 微信二维码提示文字
        wechatQrcodeHelper: '<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>'
      },
      showShare: false,
    }
  },
  created() {
    //获取从首页跳转来的搜索关键字 并 搜索
    let keyword = this.$route.query.keyword
    this.keyword = keyword
    let type = this.$route.query.type
    this.type = type
    this.search(keyword,type)
  },
  watch:{
    //监听路由参数  搜索
    "$route.params":function (route){
      this.keyword = this.$route.query.keyword
      this.type = this.$route.query.type
      this.search(this.keyword,this.type)
    }
  },
  methods: {
    //收藏
    collect(item) {
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      this.$axios("POST","/api/member/collect",{
        id: item.id,
        type: 4
      }).then(res => {
        if(res.code===1) {
          this.$message.success('操作成功')
          setTimeout(() => {
            this.search(this.keyword,this.type)
          },1500)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    setShare(item,index) {
      this.showShare = true
      this.config.title = item.title
      let host = window.location.protocol+"//"+window.location.host+ '/search/shopdetail?id='+item.id
      this.config.url = host //不能分享localhost
      // this.config.url = item.url
      this.config.description = item.keyword
    },
    search(keyword,type){ //搜索
      this.$message.info('努力加载中')
      this.$axios("POST",'/api/search/search',{
        keyword,
        type
      }).then(res => {
        if(res.code ===1) {
          this.res = res.data
        }else{
          this.res = []
        }
      })
    },
    goShopDetail(url) {
      sessionStorage.goodsUrl = url
      let routeUrl = this.$router.resolve({
        path: '/search/shopdetail'
      })
      window.open(routeUrl .href, '_blank');
    }
  },
  computed: {
    getSwiper() { //获取轮播图
      return function (item) {
        if(item) {
          return item.split(',')
        }
      }
    }
  }
}
</script>

<style scoped>
  .shop_class{
    width: 100%;
    box-sizing: border-box;
    padding: 0 70px;
  }

  .shop_res li:nth-child(3n-1) {
    margin-left: 30px;
    margin-right: 30px;
  }
  .good_bottom{
    padding: 10px 10px;
    width: 248px;
    height: 70px;
    background: #F7F6FA;
    box-sizing: border-box;

  }
  .good_img{
    background: #3765FF;
    width: 248px;
    height: 248px;
    position: relative;
  }
  .mb1{
    width: 100%;
    height: 129px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
  }
  .mb2{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 130px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.5;
  }
  .noindex {
    font-weight: bold;
    font-size: 16px;
    color: #F39800;
    position: absolute;
    top: 23px;
    left: 12px;
    z-index: 90;
  }
  .pinpai{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    width: 100px;
    font-size: 12px;
    height: 24px;
    background: #F39800;
    border-radius: 11px;
    position: absolute;
    top: 20px;
    right: 10px;
    z-index:33;
  }
  .compoany{
    font-size: 12px;
    color: #FFFFFF;
    position: absolute;
    bottom: 12px;
    left: 10px;
    z-index: 3;
  }
  .share_icon{
    width: 200px;
    height: 100px;
    border-radius: 20px;
    background: #FFFFFF;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 99;
  }
  .close{
    position: fixed;
    top: 10px;
    right: 10px;
  }
</style>