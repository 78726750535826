<template>
  <div :style="{'height':pageHeight-110+'px'}" class="container pdLft30 pdTop30 pdRit30 pdBtm30 box">
    <div class="difcac mrTop30 w100 but posr">
      <p class="top_title fs16 col333 bold" v-if="isActive===1">密码修改</p>
      <p class="top_title fs16 col333 bold" v-else>密保修改</p>
      <div class="top_button pointer difcac fs16 bold" @click="isActive=1" :class="isActive===1?'active1':''">密码修改</div>
      <div class="top_button pointer difcac fs16 bold" @click="isActive=2" :class="isActive===2?'active1':''">验证码修改</div>
    </div>
    <!--密码修改-->
    <div v-if="isActive===1">
      <div class="form mrTop30">
        <div style="width: 500px;" class="mr0At mrTop20">
          <div class="dif alc mrBtm20">
            <div class="col333 fs16" style="width: 80px;">用户账号</div>
            <div style="width: 360px;">
              <el-input v-model="mobile" placeholder="请输入手机号"  maxlength="11"></el-input>
            </div>
          </div>
          <div class="dif alc mrBtm20">
            <div class="col333 fs16" style="width: 80px;">原始密码</div>
            <div style="width: 360px;">
              <el-input v-model.trim="oldPwd" type="password" show-password placeholder="请输入原始密码"></el-input>
            </div>
          </div>
          <div class="dif alc mrBtm20">
            <div class="col333 fs16" style="width: 80px;">新设密码</div>
            <div style="width: 360px;">
              <el-input v-model.trim="newPwd" type="password" show-password placeholder="请输入新密码"></el-input>
            </div>
          </div>
          <div class="dif alc mrBtm20">
            <div class="col333 fs16" style="width: 80px;">确认密码</div>
            <div style="width: 360px;">
              <el-input v-model.trim="newPwd2" type="password" show-password placeholder="请确认密码"></el-input>
            </div>
          </div>
          <!--<div class="dif alc mrBtm20">
            <div class="col333 fs16" style="width: 80px;">验证码</div>
            <div style="width: 200px;">
              <el-input v-model.trim="captcha" placeholder="请输入验证码"></el-input>
            </div>
            <el-button style="background-color: #3765FF;color: #fff;margin-left: 50px;" @click="getCode" v-if="time===0">发送验证码</el-button>
            <el-button style="background-color: #3765FF;color: #fff;margin-left: 25px;" disabled v-else>重新发送({{time}}秒)</el-button>
          </div>-->
          <div @click="updatePwd" class="center_button fs24 colfff pointer">点击确认</div>
        </div>
      </div>

    </div>
    <!--验证码修改-->
    <div v-else>
      <div class="form mrTop30">
        <div style="width: 500px;" class="mr0At mrTop20">
          <div class="dif alc mrBtm20">
            <div class="col333 fs16" style="width: 80px;">用户账号</div>
            <div style="width: 360px;">
              <el-input v-model="mobile" placeholder="请输入手机号" maxlength="11"></el-input>
            </div>
          </div>
          <div class="dif alc mrBtm20">
            <div class="col333 fs16" style="width: 80px;">新设密码</div>
            <div style="width: 360px;">
              <el-input v-model.trim="newPwd" type="password" show-password placeholder="请输入新密码"></el-input>
            </div>
          </div>
          <div class="dif alc mrBtm20">
            <div class="col333 fs16" style="width: 80px;">确认密码</div>
            <div style="width: 360px;">
              <el-input v-model.trim="newPwd2" type="password" show-password placeholder="请确认密码"></el-input>
            </div>
          </div>
          <div class="dif alc mrBtm20">
            <div class="col333 fs16" style="width: 80px;">验证码</div>
            <div style="width: 200px;">
              <el-input v-model.trim="captcha" placeholder="请输入验证码"></el-input>
            </div>
            <el-button style="background-color: #3765FF;color: #fff;margin-left: 50px;" @click="getCode(1)" v-if="time===31">发送验证码</el-button>
            <el-button style="background-color: #3765FF;color: #fff;margin-left: 25px;" disabled v-else>重新发送({{time}}秒)</el-button>
          </div>
          <div @click="codePwd" class="center_button fs24 colfff pointer">点击确认</div>
        </div>
      </div>
    </div>
    <!--修改密保-->
    <div class="form mrTop30">
      <p class="fs16 col333 bold tlt mrLft30 mrTop20">安全密保</p>
      <!--修改选项-->
      <div class="up_select">
        <div class="difcc pointer" @click="isActive2=1">
          <img src="@/assets/image/yizhibang/60iphone.png" v-if="isActive2===1" style="width: 41px;height: 41px;">
          <img src="@/assets/image/yizhibang/60iphone_gray.png" v-else style="width: 41px;height: 41px;">
          <p class="fs16 mrTop10" :class="isActive2===1?'col333':'colccc'">换绑手机</p>
        </div>
        <!--<div class="difcc pointer" @click="isActive2=2">
          <img src="@/assets/image/yizhibang/60email.png" v-if="isActive2===2" style="width: 41px;height: 41px;">
          <img src="@/assets/image/yizhibang/60email_gray.png" v-else style="width: 41px;height: 41px;">
          <p class="fs16 mrTop10" :class="isActive2===2?'col333':'colccc'">换绑邮箱</p>
        </div>-->
        <!--<div class="difcc pointer" @click="isActive2=3">
          <img src="@/assets/image/yizhibang/60problem.png" v-if="isActive2===3" style="width: 41px;height: 41px;">
          <img src="@/assets/image/yizhibang/60problem_gray.png" v-else style="width: 41px;height: 41px;">
          <p class="fs16 mrTop10" :class="isActive2===3?'col333':'colccc'">问题密保</p>
        </div>-->
      </div>
      <!--修改手机-->
      <div class="  mrTop30" v-if="isActive2===1">
        <div class="difcac">
          <div class="dif">
            <p class="fs16 col333 mrTop10">第一步</p>
            <div class="mrLft20">
              <div style="width: 360px;">
                <el-input placeholder="请输入绑定手机号码" v-model="obj.mobile" maxlength="11"></el-input>
              </div>
              <div class="difb alc mrTop20" style="width: 360px;">
                <div style="width: 220px;">
                  <el-input placeholder="请输入验证码" v-model="obj.code" maxlength="11"></el-input>
                </div>
                <div class="get_code fs14 colfff pointer" @click="getCode(2)">
                  <span v-if="time2==31">发送验证码</span>
                  <span v-else>重新发送({{time2}}秒)</span>
                </div>
              </div>
            </div>
          </div>
          <div class="dif" style="margin-left: 50px;">
            <p class="fs16 col333 mrTop10">第二步</p>
            <div class="mrLft20">
              <div style="width: 360px;">
                <el-input placeholder="请输入新绑手机" v-model="obj.newMobile" maxlength="11"></el-input>
              </div>
              <div class="difb alc mrTop20" style="width: 360px;">
                <div style="width: 220px;">
                  <el-input placeholder="请输入验证码" v-model="obj.code2"></el-input>
                </div>
                <div class="get_code fs14 colfff pointer" @click="getCode(3)">
                  <span v-if="time3==31">发送验证码</span>
                  <span v-else>重新发送({{time3}}秒)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--<p class="fs14 col3765FF tlt mrTop20 mrBtm20" style="margin-left: 200px ;">丢失手机用问题密保修改</p>-->
        <div class="difcac mrTop30">

          <div class="bottom_button fs24 colfff pointer mrTop10" @click="changemobile()">提交</div>
        </div>
      </div>
      <!--修改邮箱-->
      <div class="difcac difw mrTop30" v-if="isActive2===2">
        <div class="dif">
          <p class="fs16 col333 mrTop10">第一步</p>
          <div class="mrLft20">
            <div style="width: 360px;">
              <el-input placeholder="请输入绑定邮箱"></el-input>
            </div>
            <div class="difb alc mrTop20" style="width: 360px;">
              <div style="width: 220px;">
                <el-input placeholder="请输入验证码"></el-input>
              </div>
              <div class="get_code fs14 colfff pointer">
                发送验证码
              </div>
            </div>
          </div>
        </div>
        <div class="dif" style="margin-left: 50px;">
          <p class="fs16 col333 mrTop10">第二步</p>
          <div class="mrLft20">
            <div style="width: 360px;">
              <el-input placeholder="请输入新绑邮箱"></el-input>
            </div>
            <div class="difb alc mrTop20" style="width: 360px;">
              <div style="width: 220px;">
                <el-input placeholder="请输入验证码"></el-input>
              </div>
              <div class="get_code fs14 colfff pointer">
                发送验证码
              </div>
            </div>
          </div>
        </div>
        <div class="difcac mrTop30">
          <div class="bottom_button fs24 pointer colfff">提交</div>
        </div>
      </div>
      <!--密保问题修改手机-->
      <div class="mrTop30" v-if="isActive2===3">
        <div class="dif" style="margin-left: 123px;">
          <p class="fs16 col333 mrTop10">手机号</p>
          <div class="mrLft20">
            <div style="width: 360px;">
              <el-input placeholder="请输入新绑手机"></el-input>
            </div>
            <div class="difb alc mrTop20" style="width: 360px;">
              <div style="width: 220px;">
                <el-input placeholder="请输入验证码"></el-input>
              </div>
              <div class="get_code fs14 pointer colfff">
                发送验证码
              </div>
            </div>
          </div>
        </div>
        <div class="difcac mrBtm30" style="margin-top: 50px;">
          <div class="dif alc">
            <p class="fs16 col333">问题一</p>
            <div style="width: 360px;" class="mrLft20">
              <el-select v-model="value" placeholder="请选择" class="w100">
                <el-option
                    v-for="item in 3"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="dif alc" style="margin-left: 60px;">
            <p class="fs16 col333">答案</p>
            <div style="width: 360px;" class="mrLft20">
              <el-input placeholder="请输入答案"></el-input>
            </div>
          </div>
        </div>
        <div class="difcac mrBtm30" >
          <div class="dif alc">
            <p class="fs16 col333">问题二</p>
            <div style="width: 360px;" class="mrLft20">
              <el-select v-model="value" placeholder="请选择" class="w100">
                <el-option
                    v-for="item in 3"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="dif alc" style="margin-left: 60px;">
            <p class="fs16 col333">答案</p>
            <div style="width: 360px;" class="mrLft20">
              <el-input placeholder="请输入答案"></el-input>
            </div>
          </div>
        </div>
        <div class="difcac mrBtm30" >
          <div class="dif alc">
            <p class="fs16 col333">问题三</p>
            <div style="width: 360px;" class="mrLft20">
              <el-select v-model="value" placeholder="请选择" class="w100">
                <el-option
                    v-for="item in 3"
                    :key="item"
                    :label="item"
                    :value="item">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="dif alc" style="margin-left: 60px;">
            <p class="fs16 col333">答案</p>
            <div style="width: 360px;" class="mrLft20">
              <el-input placeholder="请输入答案"></el-input>
            </div>
          </div>
        </div>
        <div class="difcac">
          <div class="bottom_button pointer fs24 colfff">提交</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
      data() {
        return {
          pageHeight:document.documentElement.clientHeight,//获取页面高度
          mobile: '',//输入的电话或者邮箱
          oldPwd: '',//原始密码
          email: '',//当前账号邮箱
          mobile2: '',//当前账号电话
          newPwd: '',//新密码
          newPwd2: '',//确认密码,
          captcha: '',//验证码,
          isActive: 1,
          isActive2: 1,
          setInterval: '',//重新发送验证码
          time: 31,
          timer: null,
          time2: 31,
          timer2: null,
          time3: 31,
          timer3: null,
          obj: {
            mobile: '',
            code: '',
            newMobile: '',
            code2: ''
          },
        }
      },
      methods: {
        changemobile() { //修改手机号
          this.$axios("POST","/api/User/changemobile",this.obj)
          .then(res => {
            if(res.code===1) {
              this.$alert(res.msg,{type: 'success'})
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
        },
        updatePwd() { //通过原始密码修改密码
          if(!this.newPwd) {
            return this.$alert('请输入密码',{type: 'error'})
          }
          let reg  = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{6,20}');
          if(!reg.test(this.newPwd)) {
            return this.$alert('请输入至少6位密码,包括大小写字母、数字',{type: 'error'})
          }
          this.$axios("POST","/api/User/editpwd",{
            mobile: this.mobile,
            password: this.oldPwd,
            newpassword: this.newPwd,
            repeatpassword: this.newPwd2,
          })
          .then(res => {
            if(res.code===1) {
              this.$alert(res.msg,{type: 'success'})
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
        },
        codePwd() { //通过短信修改密码
          if(!this.newPwd) {
            return this.$alert('请输入密码',{type: 'error'})
          }
          let reg  = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{6,20}');
          if(!reg.test(this.newPwd)) {
            return this.$alert('请输入至少6位密码,包括大小写字母、数字',{type: 'error'})
          }
          this.$axios("POST","/api/User/codepwd",{
            mobile: this.mobile,
            password: this.newPwd,
            newpassword: this.newPwd2,
            code: this.captcha,
          }).then(res => {
            if(res.code===1) {
              this.$alert(res.msg,{type: 'success'})
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
        },
        getCode(type) {
          let mobile = this.mobile
          let event = 'codepwd'
          if(type==1) {
            clearInterval(this.timer)
          }else if(type==2) {
            clearInterval(this.timer2)
            mobile = this.obj.mobile
            event = 'mobile1'
          }else if(type==3) {
            clearInterval(this.timer3)
            mobile = this.obj.newMobile
            event = 'mobile2'
          }
          this.$message('发送中')
          this.$axios("POST","/api/Index/sendSms",{
            mobile: mobile,
            event: event
          }).then(res => {
            if(res.code===1) {
              this.$message.success('发送成功,验证码有效时间120秒')
              if(type==1) {
                clearInterval(this.timer)
              }else if(type==2) {
                clearInterval(this.timer2)
              }else if(type==3) {
                clearInterval(this.timer3)
              }
              let timer = null
              timer = setInterval(()=>{
                let tiem = 0
                if(type==1) {
                  this.time --
                  tiem = this.time
                }else if(type==2) {
                  this.time2 --
                  tiem = this.time2
                }else if(type==3) {
                  this.time3 --
                  tiem = this.time3
                }
                if(tiem<=0) {
                  clearInterval(timer)
                  if(type==1) {
                    this.time = 31
                    clearInterval(this.timer)
                  }else if(type==2) {
                    this.time2 = 31
                    clearInterval(this.timer2)
                  }else if(type==3) {
                    this.time3 = 31
                    clearInterval(this.timer3)
                  }
                }
              },1000)
            }else {
              this.$message.error(res.msg)

            }
          })
        }
      },
      created() {
        this.$axios("POST","/api/member/personalCenter",{})
        .then(res => {
          this.mobile2 = res.data.mobile
          this.email = res.data.email
        })
      }
    }
</script>

<style scoped>
.container {
  overflow: hidden;
  overflow-y: auto;
}
.top_title{
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}
/*按钮默认样式*/
.but .top_button{
  width: 430px;
  height: 40px;
  border-radius: 6px 0 0 6px;
  background: #E6E6E6;
  color: #3765FF;
}
.but .top_button:last-child{
  border-radius: 0 6px 6px 0;
}
/*选中按钮*/
.but .active1{
  color: #fff;
  background: #3765FF;
}
/*修改密码按钮样式*/
.center_button{
  width: 440px;
  height: 56px;
  background: #3765FF;
  border-radius: 6px;
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
}
.get_code{
  width: 130px;
  height: 40px;
  background: #3765FF;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom_button{
  width: 360px;
  height: 56px;
  background: #3A54FF;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*密保修改选项*/
.up_select{
  width: 567px;
  height: 113px;
  background: #F5F5F5;
  border-radius: 10px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>