<template>
  <!--服务窗口 招聘-->
  <div class="zhaopin">
    <!--联系电话-->
    <!--<div class="d_tit dif alc mrBtm30">-->
    <!--  <img src="@/assets/image/yizhibang/20telephone.png" style="width: 20px;height: 18px;">-->
    <!--  <h3 class="fs18 col333 bold mrLft10">联系招聘方 0538-000000 </h3>-->
    <!--  <h3 class="fs18 col333 bold" style="margin-left: 44px;">王经理 154-00000000 </h3>-->
    <!--</div>-->
    <!--招聘内容-->
    <div class="z_main">
      <ul v-if="res.length>0">
        <li class="zhaopin_item posr" v-for="(item,index) in res" :key="index" @click="goZhaopinDetail(item.id)">
          <div class="dif alc">
            <p class="fs18 bold col3765FF one_overflow tlt" style="margin-right: 16px;width: 320px;">{{item.position}}</p>
            <p class="fs16 col333 tlt one_overflow" style="width: 250px;">{{item.company}}</p>
            <div class="dif alc mrLft20">
              <p>{{item.createtime}}</p>
              <div class="zhaopin_type">{{item.type}}</div>
            </div>
          </div>
          <div class="dif alc mrTop20">
            <!--<p class="fs18" style="color:#ff0000;">{{item.money_title}}</p>-->
            <p class="fs18" style="color:#ff0000;">{{formatNumber(item.min_money)}}-{{formatNumber(item.max_money)}}</p>
            <p class="fs16 col999 mrLft30 mrRit30">{{item.experience_title}}</p>
            <p class="fs16 col999">{{item.education_title}}</p>
          </div>
          <!--职工福利-->
          <div class="dif alc mrTop20">
            <p class="fs14 col333" style="margin-right: 13px;">职位福利</p>
            <ul class="dif alc fuli one_overflow" style="max-width: 900px">
              <li v-for="(item2,index2) in item.welfare">{{item2}}</li>
            </ul>
          </div>
          <div class="caozuo dif alc">
            <p class="fs14 colFDAE38 mrRit20 pointer" @click.stop="collect(item)" v-if="item.is_collect==0 || !item.is_collect">收藏</p>
            <p class="fs14 colFDAE38 mrRit20 pointer" @click.stop="collect(item)" v-else>已收藏</p>
            <p class="fs14 colFDAE38 pointer" @click.stop="setShare(item,index)">分享</p>
          </div>
        </li>
        <!--<li class="dif alc mrTop30 mrBtm30 posr" v-for="(item,index) in res">
          <div class="col004F8A fs16 difcb bold">
            <p>{{item.position}}</p>
            <p>{{item.money_title}}</p>
          </div>
          <div class="mrLft30">
            &lt;!&ndash;招聘条件&ndash;&gt;
            <ul class="difb fs14 col7b bold alc mrBtm10 tiaojian" style="width: 710px;">
              <li>招{{item.num}}人</li>
              <li>{{item.education_title}}</li>
              <li>{{item.experience_title}}</li>
              <li>{{item.city_name}}/{{item.area_name}}</li>
              <li class="one_overflow">{{item.company}}</li>
              <li>{{item.createtime}}</li>
              <li class="z_type">{{item.type}}</li>
            </ul>
            &lt;!&ndash;职工福利&ndash;&gt;
            <ul class="welfare dif alc fs12">
              <p class="mrRit20">职工福利</p>
              <li v-for="(item,index) in item.welfare" :key="index">{{item}}</li>
            </ul>
          </div>
          <div class="caozuo col004F8A dif ">
            <p>收藏</p>
            <p class="mrLft20">分享</p>
          </div>
        </li>-->
      </ul>
      <div v-else style="padding: 100px 0;">
        <el-result icon="info" title="暂无内容"></el-result>
      </div>
    </div>

    <!--分享-->
    <div class="share_icon shadow_bottom difcc" v-if="showShare" @click.stop>
      <i class="el-icon-close close pointer fs24" @click.stop="showShare=false"></i>
      <h3>点击分享</h3>
      <share :config="config"></share>
    </div>
  </div>
</template>

<script>
export default {
  name: "zhaopin",
  props: {
    sid: [String,Number]
  },
  data() {
    return {
      res: [],//招聘结果
      config:{
        sites: ['qq','wechat','qzone'],
        image: '',
        title: '',
        description: '',
        url: '', // 网址，默认使用 window.location.href
        source: '', // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
        wechatQrcodeTitle: '微信扫一扫：分享', // 微信二维码提示文字
        wechatQrcodeHelper: '<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>'
      },
      showShare: false,
    }
  },
  computed: {
    formatNumber() { //数字格式化
      return function (num) {
        return num >= 1e3 && num < 1e4 ? (num / 1e3).toFixed(1) + 'k' : num >= 1e4 ? (num / 1e4).toFixed(1) + 'w' : num
      }
    },
  },
  methods: {
    setShare(item,index) {
      this.showShare = true
      this.config.title = item.position
      let host = window.location.protocol+"//"+window.location.host+ '/search/zhaopin/detail?id='+item.id
      this.config.url = host
      this.config.description = item.keyword
    },
    collect(item) {
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      this.$axios("POST","/api/member/collect",{
        id: item.id,
        type: 6
      }).then(res => {
        if(res.code===1) {
          this.$message.success('操作成功')
          setTimeout(() => {
            this.getZhaopin(this.sid)
          },1500)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    goZhaopinDetail(id) { //跳转到招聘详情页
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      let routeUrl = this.$router.resolve({
        path: '/search/zhaopin/detail?id='+id
      })
      window.open(routeUrl .href, '_blank');
    },
    getZhaopin(sid) {
      this.$axios("POST","/api/site/siteInfodetail",{
        sid,
        type: 4,
        infoType: 5
      }).then(res => {
        console.log(res);
        this.res = res.data || []
      })
    }
  },
  created() {
    this.getZhaopin(this.sid)
  }
}
</script>

<style scoped>
  .zhaopin{
    padding-bottom: 200px;
  }
  .zhaopin_item{
    width: 1200px;
    background: #FAFAFA;
    border: 1px solid #F5F5F5;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 22px 32px;
  }
  .zhaopin_type{
    width: 42px;
    height: 20px;
    background: #3765FF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #FFFFFF;
    margin-left: 8px;
  }
  .fuli li{
    font-size: 14px;
    color: #999999;
    border: 1px solid #EEEEEE;
    padding: 8px 11px;
    margin-right: 10px;
  }
  .caozuo{
    position: absolute;
    bottom: 16px;
    right: 22px;
  }
  .share_icon{
    width: 200px;
    height: 100px;
    border-radius: 20px;
    background: #FFFFFF;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 99;
  }
  .close{
    position: fixed;
    top: 10px;
    right: 10px;
  }
</style>