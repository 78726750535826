<template>
   <div class="head difb" style="padding-right: 80px">
      <!-- 头部左部分类区域 -->
      <div class="head_left">
        <div class="dif alc ">
          <span class="fs14 colfff mrRit10 pointer" @click="showGroup=!showGroup">行业榜单</span>
          <i class="el-icon-arrow-up pointer" style="color:#fff;" v-if="showGroup" @click="showGroup=!showGroup"></i>
          <i class="el-icon-arrow-down pointer" style="color:#fff;" v-else @click="showGroup=!showGroup"></i>
          <!--行业分类内容-->
          <div class="group" v-if="showGroup" @mouseleave="showGroup=false">
            <div class="dif posr" style="border-bottom: 1px dashed #E6E6E6;" v-if="item.sonInfo.length>0" v-for="(item,index) in list" :key="index">
              <div class="fs14 bold col333">{{item.name}}</div>
              <div class="dif difw alc" style="width: 600px;">
                <div class="fs14 col999 pointer" style="width: 120px;"
                     v-if="showMore===index" @click="searchCate(item2)"
                     v-for="(item2,index2) in item.sonInfo">{{item2.name}}</div>
                <div class="fs14 col999 pointer" style="width: 120px;"
                     v-if="index2<5 && showMore!==index" @click="searchCate(item2)"
                     v-for="(item2,index2) in item.sonInfo">{{item2.name}}</div>
              </div>
              <div class="fs12 col999 more1 dif alc pointer" @click="showMore=index" v-if="showMore!==index && item.sonInfo.length>5">
                <div>更多</div>
                <i class="el-icon-arrow-down" style="color:#999;"></i>
              </div>
              <div class="fs12 col999 more1 dif alc pointer" @click="showMore=-1" v-if="showMore===index && item.sonInfo.length>5">
                <div>收起</div>
                <i class="el-icon-arrow-up" style="color:#999;"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
       <!--头部右侧分类区域-->
      <div class="head_right">
        <div class="pwdBox posr">
          <div @click="isShow(1)" class="pointer fs14 colfff">密码箱</div>
          <pass-box v-if="isShow1==1" @closeWarp="closeWarp()"></pass-box>
        </div>
        <div class="posr" style="margin-right: 15px;">
          <div @click="isShow(2)" class="pointer fs14 colfff" >官网收藏</div>
          <website-collection v-if="isShow1==2" @closeWeb="closeWeb"></website-collection>
        </div>
        <p  class="pointer fs14 colfff" @click="showLianxi()" style="margin-right: 20px;">联系反馈/推荐网址</p>
        <!--<div class="pointer"><p class="pointer fs14 colfff" @click="showCon=true">反馈</p></div>-->

        <div class="user" style="margin-top: 20px;" v-if="$store.state.isLogin">
          <div class="username one_overflow" style="max-width: 150px">{{$store.state.userInfo.username}}</div>
          <div style="margin-top: -6px;" class="posr">
            <div class="noread" v-if="noread>0"></div>
            <img class="userIcon" :src="$store.state.userInfo.avatar" />
          </div>
          <!--点击头像之后的选项-->
          <div class="header_info tct fs12">
            <!--<div class="pointer posr info_item" v-for="(item,index) in userList" @click="goPage(item)">{{item.name}}</div>-->
            <div class="pointer posr info_item" v-for="(item,index) in userList" @click="goPage(item)">
              {{item.name}}
              <div class="noread2" v-if="noread>0 && item.cid==3" ></div>
            </div>
            <div class="pointer posr info_item" @click="logout">退出账号</div>
          </div>
        </div>
        <div class="user pointer" v-if="!$store.state.isLogin"><span @click="showLogin" class="fs14 colfff">登录/注册</span></div>
      </div>

       <!--注册登录-->
       <register-center v-if="!$store.state.isLogin && $store.state.isShowLogin" @closeLogin="closeLogin"></register-center>
       <!--联系合作反馈-->
       <contact-cooperation v-if="showCon" @closecon="closecon"></contact-cooperation>
    </div>
</template>
<script>
import passBox from "@/components/home/head/passBox"; //点击密码箱
import websiteCollection from "@/components/home/head/websiteCollection"; //点击官网收藏
import registerCenter from "@/components/home/register/registerCenter";
import ContactCooperation from "@/components/home/head/contactCooperation"; //注册登录界面
export  default  {
  components: {ContactCooperation, passBox,websiteCollection,registerCenter},
  data(){
    return{
      showCon: false,//是否显示联系合作反馈
      userList: [ //点击头像之后的选项
        {id: 1,cid:2,name: '会员中心'},
        {id: 1,cid:1,name: '个人中心'},
        {id: 2,cid:1,name: '企业中心'},
        {id: 1,cid:4,name: '我的收藏'},
        {id: 1,cid:3,name: '我的消息'}
      ],
      change: 12, //11 行业分类 12 百度 搜索历史,
      isShow1: 0, //密码箱 官网收藏点击显示
      list: [],//行业分类
      showMore: -1,//行业分类显示更多
      showGroup: false,//显示行业更多
      noread: 0,
      timer: null,
    }
  },
  methods:{
    getNoRead() { //获取未读数
      this.$axios("POST","/api/comment/getUnRead",{})
          .then(res => {
            this.noread = res.data
          })
      this.timer = setInterval(() => {
        this.$axios("POST","/api/comment/getUnRead",{})
            .then(res => {
              this.noread = res.data
            })
      },10000)
    },
    getUserInfo() { //获取用户信息
      this.$axios("POST","/api/user/getUser",{})
          .then(res => {
            this.$store.commit('setUserInfo',res.data)
          })
    },
    showLianxi() { //显示联系反馈
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(token) {
        this.showCon=true
      }else {
        this.$alert('请登录后操作',{type: 'error'})
            .then(() => {
              this.showLogin()
            })
      }
    },
    closeWeb() {
      this.isShow1 = 0
    },
    closeWarp() { //关闭密码箱
      this.isShow1 = 0
    },
    searchCate(item) { //搜索分类
      console.log(item);
      this.$router.push({
        path: '/search',
        query:{
          keyword: '',
          type: 1,
          cateid: item.id
        }
      })
    },
    closecon() { //关闭合作联系反馈窗口
      this.showCon = false
    },
    goPage(event) { //跳转到个人中心 企业中心相关页面
      this.$router.push({
        path: '/meCenter',
        query:{
          leftSelect: event.id,
          contentType: event.cid
        }
      }).catch(err => err)
    },
    goIndustry() { //传值给首页 显示行业分类
      this.$emit('goIndustry',this.change)
    },
    isShow(id) { //显示密码箱或者官网收藏
      let ida =''
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      let userinfo = localStorage.getItem('userinfo')

      if(token && userinfo){
        ida = this.isShow1
        ida==id?ida=0:ida=id //再次点击切换显示
        this.isShow1=ida
      }else{
        this.$alert('请登录后操作',{type: 'error'})
            .then(() => {
              this.showLogin()
            })
      }
    },
    showLogin() {
      //打开登录页面
      this.$store.commit('showLogin',true)
      //阻止页面滚动
        let m = function(e){e.preventDefault();};
        document.body.style.overflow='hidden';
        document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
    },
    closeLogin() { //关闭登录页面
      this.$store.commit('showLogin',false)
    },
    logout() {
      this.$store.commit('del_token')
    }
  },
  created() {
    let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
    if(token) {
      this.getUserInfo() //获取用户信息
      this.getNoRead() //获取未读数
    }
    //获取首页行业分类
    this.$axios("POST","/api/classity/readClassity",{})
    .then(res => {
      if(res.code == '1') {
        this.list = res.data
      }else{
        alert(res.msg)
      }
    })
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },

}

</script>
<style>
  .noread2{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right:10px;
    border-radius: 50%;
    background-color: #fa3534;
    width: 7px;
    height: 7px;
  }
  .noread{
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    background-color: #fa3534;
    width: 10px;
    height: 10px;
  }
  .more1{
    position: absolute;
    right: 20px;
    top: 0;
  }
  .group{
    z-index: 111;
    border-radius: 6px;
    background-color: #FFFFFF;
    padding: 40px 20px;
    padding-top: 20px;
    position: absolute;
    top: 60px;
    left: 0;
    width: 750px;
    max-height: 500px;
    overflow-y: auto;
    box-sizing: border-box;
  }
.head {
  width: 100%;
  height: 60px;
  line-height: 60px;
  display: flex;
}
.head_left {
  margin-left: 6%;
  position: relative;
}
.head_left select {
  border: 0 !important;
  background-color: transparent;
  font-size: 16px;
}
.head_right {
  /*margin-left: 50%;*/
  /*width: 30%;*/
  display: flex;
  justify-content: space-around;
}
.pwdBox {
  width: 60px;
}
.user {
  min-width: 100px;
  display: flex;
  position: relative;
}
.username {
  line-height: 20px;
  padding-left: 20px;
  height: 20px;
  color: #fff;
  border-left: 1px solid #ccc;
}
.userIcon {
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-left: 10px;
  font-size: 14px;
  color: #FFFFFF;
}
a{
    text-decoration: none;
    color: #000;
}

.user:hover>.header_info {
  display: block;
}
.header_info{
  position: absolute;
  top: 40px;left: 50px;
  width: 100px;height: 190px;
  padding-top: 5px;
  line-height: 30px;
  z-index: 2;
  display: none;
  background: #FFFFFF;
  border-radius: 6px;
}
.header_info::before{
  content: '';
  display: block;
  border-bottom: 21px solid #FFFFFF;
  border-left: 14px solid rgba(0,0,0,0);
  border-right: 14px solid rgba(0,0,0,0);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -5px;
}
.info_item{
  color: #999999;
  font-size: 14px;

}
.info_item:hover{
  color: #366EFF;
}















</style>
