import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isShowKaiping: true, //是否显示开屏承诺
    isLogin: false, //登陆状态
    isShowLogin: false,//是否显示登录框
    token: '',//token
    userInfo: {},//用户信息
    account: '',//上次登录账号
    password: '',//上次登录密码
    url: 'https://yzb.yzbse.com',//图片路径前缀
    companyInfo: {},//自动显示的所属
    contactDetails: {},//默认的联系方式
    goodsUrl: '',//选择的商品url
    routerInclude: ['webDetail'],
  },
  mutations: {
    showKaiping(state){ //是否显示开屏提示
      state.isShowKaiping = false
    },
    showLogin(state,bool) { //是否显示登录框
      state.isShowLogin = bool
    },
    setUserInfo(state,data){ //存入用户信息
      state.userInfo = data
      localStorage.userinfo = JSON.stringify(data)
    },
    setAccount(state,account){//存入账号
      state.account = account
      localStorage.yizhibangac = account
    },
    setPwd(state,pwd){ //存入密码
      state.password = pwd
      localStorage.setItem('yizhibangp',pwd)
    },
    delPwd(state){ //删除密码
      localStorage.removeItem('yizhibangp')
    },
    set_token(state, token) { //存入token
      state.token = token
      state.isLogin = true
      state.isShowLogin = false
      localStorage.yizhibang = token
    },
    del_token(state) { //删除token 并且推出登录
      state.token = ''
      state.userInfo = {}
      state.isLogin = false
      state.password = ''
      localStorage.removeItem('yizhibang')
      localStorage.removeItem('userinfo')
    },
    setCompanyInfo(state,info) {
      state.companyInfo = info
    },
    setContactDetails(state,info) {
      state.contactDetails = info
    },
    setGoodsUrl(state,url) { //进入店铺时传的url
      state.goodsUrl = url
    },
  },
  actions: {
  },
  modules: {
  }
})
