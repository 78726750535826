<template>
  <!--评论-->
  <div class="comment mr0At" id="comment_top" style="width: 1200px;"  >
    <!--输入框-->
    <div class="comment_input">
      <textarea rows="5" v-model="comment"></textarea>
    </div>
    <!--发布评论-->
    <div class="difb alc mrTop20">
      <div class="dif alc">
        <el-radio-group v-model="star" @change="changeStar">
          <el-radio v-for="(item,index) in starList" :label="item.id"
                              :key="item.id">{{item.title}}</el-radio>
        </el-radio-group>
      </div>
      <div class="submit pointer" @click="postComment(0,1)">发布评论</div>
    </div>
    <!--选项-->
    <div class="c_top">
      <ul class="dif fs16 col333">
        <!--<li>发送私信</li>-->
        <!--<li>总论({{comments.length}})</li>-->
        <!--<li>好评(2500)</li>-->
        <!--<li>中评(2500)</li>-->
        <!--<li>差评(2500)</li>-->
      </ul>
    </div>
    <!--评论区-->
    <div class="comment_area">
      <ul>
        <!--一级评论-->
        <li v-for="(item,index) in comments" class="mrTop10 pdBtm10 pdTop20" style="border-bottom: 1px solid #EEEEEE;">
          <div class="area_top posr dif">
            <!--头像-->
            <div class="ava mrRit10 bra bgccc" style="width: 60px;height: 60px;overflow:hidden;">
              <img :src="item.userinfo.avatar" class="w100 h100 bra">
            </div>
            <div>
              <!--发布时间-->
              <div class="dif alc">
                <p class="area_name fs18 bold col333 mrRit30">{{item.userinfo.username}}</p>
                <p class="area_time fs12 col999 mrRit30" style="color:#7b7b7b;">发表于 {{item.createtime}}</p>
                <div class="fs12 col3A53FF"><span @click="showSixin(item)">私信</span> <span @click="jubao(item)">举报</span></div>
              </div>
              <div class="comment_main box tlt  posr mrTop20" style="width: 1079px;">
                <!--一级评论内容-->
                <p class="fs14 col999 posr mrBtm20">
                  {{item.content}}
                  <img src="@/assets/image/yizhibang/25comment.png" style="width: 17px;height: 16px;margin-right: 40px;"  class="reply pointer" @click="showComment(item.id,2)">
                  <!--<img src="@/assets/image/yizhibang/25thumbs.png" style="width: 17px;height: 16px;"  class="reply pointer" @click="showComment(item.id,2)">-->
                  <!--<span class="reply fs14 pointer" style="color:#7b7b7b;" >回复</span>-->
                </p>
                <!--二级评论-->
                <ul class="two_comment" v-if="item.sonInfo && item.sonInfo.length>0">
                  <li class="pdTop20 mrBtm20 w100" v-for="(item2,index2) in item.sonInfo" v-if="index2<2">
                    <div class="area_top posr dif w100">
                      <!--头像-->
                      <div class="ava mrRit10 bra bgccc" style="width: 60px;height: 60px; overflow: hidden">
                        <img :src="item2.userinfo.avatar" class="w100 h100 bra">
                      </div>
                      <div>
                        <div class="dif alc">
                          <p class="area_name fs18 col333 bold mrRit30">{{item2.userinfo.username}}</p>
                          <p class="area_time fs12 col999 mrRit30">发表于 {{item2.createtime}}</p>
                          <div class="fs12 col3A53FF"><span @click="showSixin(item2)">私信</span> <span @click="jubao(item2)">举报</span></div>
                        </div>
                        <div class="comment_main box tlt  posr mrTop10 mrBtm10 w100">
                          <p class="fs14 col999 posr" style="width: 970px;">
                            <span class="area_time fs14 col999 mrRit10">回复</span>
                            <span class="area_name fs14 col333">{{item.userinfo.username}}：</span>
                            {{item2.content}}
                            <img src="@/assets/image/yizhibang/25comment.png" style="width: 17px;height: 16px;margin-right: 40px;"  class="reply pointer" @click="showComment(item.id,2,item2.userinfo.username)">
                            <!--<img src="@/assets/image/yizhibang/25thumbs.png" style="width: 17px;height: 16px;"  class="reply pointer">-->
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="pdTop20 mrBtm20" v-for="(item2,index2) in item.sonInfo"  v-if="index2>=2 && index===i1 && index===i0">
                    <div class="area_top posr dif alc">
                      <!--头像-->
                      <div class="ava mrRit10 bra bgccc" style="width: 60px;height: 60px;overflow: hidden">
                        <img :src="item2.userinfo.avatar" class="w100 h100 bra">
                      </div>
                      <div>
                        <div class="dif alc">
                          <p class="area_name fs18 col333 bold mrRit30">{{item2.userinfo.username}}</p>
                          <p class="area_time fs12 col999 mrRit30">发表于 {{item2.createtime}}</p>
                          <div class="fs12 col3A53FF"><span @click="showSixin(item2)">私信</span> <span @click="jubao(item2)">举报</span></div>
                        </div>
                        <div class="comment_main box tlt  posr mrTop10 mrBtm10 w100">
                          <p class="fs14 col999 posr" style="width: 970px;">
                            <span class="area_time fs14 col999 mrRit10">回复</span>
                            <span class="area_name fs14 col333">{{item.userinfo.username}}：</span>
                            {{item2.content}}
                            <img src="@/assets/image/yizhibang/25comment.png" style="width: 17px;height: 16px;margin-right: 40px;"  class="reply pointer" @click="showComment(item.id,2)">
                            <!--<img src="@/assets/image/yizhibang/25thumbs.png" style="width: 17px;height: 16px;"  class="reply pointer" @click="showComment(item.id,2)">-->
                          </p>
                        </div>
                      </div>
                    </div>
                    <!--评论的评论的评论-->
                  </li>
                  <span class="pointer fs12 col3A53FF" @click="i1=index;i0=index" v-if="index!==i1 && item.sonInfo.length>2">还有{{item.sonInfo.length - 2}}条评论,点击查看更多</span>
                  <span class="pointer fs12 col3A53FF" @click="i1=index;i0=index" v-else-if="index===i1 && item.sonInfo.length>2 && i0!==index">还有{{item.sonInfo.length - 2}}条评论,点击查看更多</span>
                  <p @click="i1=-1;i0=-1" v-else-if="index===i1 && item.sonInfo.length>2" class="pointer fs12 col3A53FF mrTop10">收起</p>
                </ul>
              </div>
            </div>
            <p class="fs12 fastest" style="color:#F6AA00;" v-if="index===0">沙发</p>
            <p class="fs12 fastest" style="color:#F6AA00;" v-if="index===1">板凳</p>
            <p class="fs12 fastest" style="color:#F6AA00;" v-if="index===2">地板</p>
            <p class="fs12 fastest" style="color:#666666;" v-if="index>2">{{index+1}}F</p>
          </div>
        </li>
      </ul>
    </div>
    <!--评论2-->
    <div class="comment2" v-if="isShow">
      <div class="comment2_cont">
        <span @click="isShow=false" class="fs14 col999">关闭</span>
        <!--输入框-->
        <div class="comment_input2">
          <textarea rows="5" v-model="comment"></textarea>
        </div>
        <!--发布评论-->
        <div class="dife alc mrTop20">
          <div class="submit pointer" v-if="contentType===1" @click="postComment()">回复</div>
          <div class="submit pointer" v-if="contentType===2" @click="sixin()">发送私信</div>
        </div>
      </div>
    </div>

    <el-dialog
        title="举报"
        :visible.sync="showJubao"
        width="30%"
        :before-close="handleClose">
        <el-input
            type="textarea"
            :rows="5"
            placeholder="请输入举报原因"
            v-model="jubaotext">
        </el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="showJubao = false;jubaotext=''">取 消</el-button>
          <el-button type="primary" @click="submitjubao()">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "comment",
  props: {
    sid: [Number,String]
  },
  data() {
    return {
      comments: [],//获取评论
      comment: '',//输入内容
      isShow: false,
      pid: 0,
      type: 0,
      //显示更多
      i0: -1,
      i1: -1,
      i2: -1,
      busername: '',//被回复人名字
      star: 3,//3好评 2中 1差评
      starList: [
        {id: 3,title: '好评'},
        {id: 2,title: '中评'},
        {id: 1,title: '差评'},
      ],
      contentType: 1,//1普通回复 2私信
      showJubao: false,//显示举报
      jubaotext: '',
      setItem: null,
    }
  },
  methods: {
    submitjubao() { //提交举报
      if(!this.jubaotext) {
        return this.$message.error('请输入举报内容')
      }
      this.$axios("POST","/api/user/report",{
        pid: this.setItem.userinfo.uid,
        reason: this.jubaotext
      }).then(res => {
        if(res.code==1) {
          this.$message.success(res.msg)
          this.showJubao = false
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    handleClose() {
      this.jubaotext = ''
      this.showJubao = false
    },
    jubao(item) {
      this.showJubao = true
      this.setItem = item
    },
    sixin() { //发送私信
      if(this.comment==='') {
        this.$alert('请输入内容')
        return
      }
      this.$axios("POST","/api/comment/chat",{
        pid: this.pid,
        comment: this.comment
      }).then(res => {
        if(res.code===1) {
          this.$alert('发送成功')
          this.isShow = false
          this.comment = '' //评论完清空内容
        }else {
          this.$alert(res.msg)
        }
      })
    },
    showSixin(item) { //点击显示私信
      this.pid = item.userinfo.uid
      this.contentType = 2
      this.isShow = true
    },
    changeStar(e) {
      console.log(e);
    },
    postComment(pid,type) { //发布评论
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      if(!pid && !type){ //回复窗口点击
        pid = this.pid
        type = this.type
      }
      if(this.comment === ''){
        this.$alert('评论不允许为空')
        return
      }
      this.$axios("POST","/api/comment/comment",{
        sid: this.sid,
        pid: pid,
        type:type,
        comment: this.comment,
        busername: this.busername,
        star: this.star,
        types: 1
      }).then(res => {
        this.$alert(res.msg)
        this.isShow = false
        this.comment = '' //评论完清空内容
        //获取评论
        this.getComment(this.sid)
      })
    },
    getComment(id,isScroll) {
      //获取评论
      this.$axios("POST","/api/comment/getSiteComment",{
        sid: id,
        types: 1,
      }).then(res => {
        this.comments = res.data
        if(isScroll) {
          setTimeout(() => {
            this.scrollTo()
          },1200)
        }
      })
    },
    showComment(pid,type,bname){ //显示回复时输入框
      this.pid = pid
      this.type = type
      this.isShow = true
      this.busername = bname
      this.contentType = 1
    },
    scrollTo() {
      let el = document.getElementById('comment_top');
      window.scrollTo({
        //滚动到元素位置
        //top: this.$refs[元素].offsetTop,//offsetTop 相对于offsetparent 定位,当有定位时，不准确。
        top: el.getBoundingClientRect().top-50 ,//推荐使用，getBoundingClientRect 相对于当前视口的位置
        //top: 400//滚动到指定距离
        //top: 0,//滚动到顶部
        // top: document.documentElement.scrollHeight-400,//滚动到底部
        behavior: "smooth" // 平滑滚动
      });
    },
  },
  activated() {
   //获取评论
    this.getComment(this.sid,true)
  },
  created() {
    this.getComment(this.sid,true)
  }
}
</script>

<style scoped>
  .two_comment{
    width: 1100px;
    background: #F4F4F4;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    position: relative;
  }
  .two_comment::before{
    content: "";
    display: block;
    border-bottom: 14px solid #F4F4F4;
    border-left: 13px solid rgba(0,0,0,0);
    border-right: 13px solid rgba(0,0,0,0);
    position: absolute;
    top: -10px;
    left: 27px;
  }
  .comment{
    border-top: 1px solid #F0F0F0;
    padding-top: 26px;
  }
  .c_top{
    padding-top: 41px;
    margin-top: 17px;
    padding-bottom: 10px;
    border-top: 1px solid #EEEEEE;
  }
  .c_top li{
    cursor: pointer;
    margin-left: 30px;
  }

  .comment_input2 textarea{
    box-sizing: border-box;
    resize: none;
    margin-top: 20px;
    width: 500px;
    height: 150px;
    background: #F4F4F4;
    border: 1px solid #DEDEDE;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    border: none;
    font-size: 16px;
  }
  .comment_input textarea{
    box-sizing: border-box;
    resize: none;
    width: 1200px;
    height: 120px;
    background: #F4F4F4;
    border: 1px solid #DEDEDE;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    font-size: 16px;
  }
  .submit{
    width: 130px;
    height: 46px;
    background: #3A54FF;
    border-radius: 6px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
  }

  .fastest,.reply{
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    right: 0;
  }
  .reply{
    top: 10px;
    right: 50px;
  }

  /*评论2*/
  .comment2{
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
  }
  .comment2 .comment2_cont{
    box-sizing: border-box;
    padding: 20px 20px;
    border-radius: 20px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .comment2_cont span{
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 15px;
  }
</style>