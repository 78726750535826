let baseUrl = 'https://yzb.yzbse.com'
function request(method,url,data) {
  let token = localStorage.getItem('yizhibang')?localStorage.getItem('yizhibang'):'';//获取token
  return new Promise((resolve,reject)=>{
    //1创建异步对象
    const xhr = new XMLHttpRequest();
    //4创建监听 接收响应
    xhr.onreadystatechange=()=>{
      if(xhr.readyState==4 && xhr.status==200){
        let result = JSON.parse(xhr.responseText);
        resolve(result);//返回出去
      };
    };
    //2创建请求 打开连接
    url = baseUrl + url
    xhr.open(method,url,true);
    xhr.setRequestHeader("Content-Type","application/x-www-form-urlencoded");
    xhr.setRequestHeader("token",token);
    let paramsData = ''
    if(method=='POST') {
      paramsData = '';//创建参数
      if(Object.keys(data) && Object.keys(data).length>0) {
        Object.keys(data).forEach(function(key) {//循环参数
          paramsData += key + '=' + encodeURIComponent(data[key]) + '&'
        })
        paramsData = paramsData.substring(0, paramsData.length - 1);//去除最后一位
      }
    }
    //3发送请求
    xhr.send(paramsData);
  })
}
export default request