let all = {
	// 判断身份证号
	isSfz(idcard) {
		var id =
			/^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/
		if (idcard === '') {

		} else if (!id.test(idcard)) {

			return false
		} else {
			return false
		}
	},
	showImg(url) {
		window.open(url)
	},
	// 判断是否是手机号   
	isPhone(val) {
		var patrn = /^(((1[3456789][0-9]{1})|(15[0-9]{1}))+\d{8})$/
		if (!patrn.test(val) || val === '') {
			// alert('手机号码格式不正确')
			return false
		} else {
			return true
		}
	},
	// 判断邮箱
	isEmail(email) {
		if (email.search(/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/) != -1)
			return true;
		else
			return false;
	},
	// 时间戳转换时间
	timestampToTime(timestamp) {
		var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
		var Y = date.getFullYear() + '-';
		var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
		var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
		var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
		var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
		var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
		return Y + M + D + h + m + s;
	},
	// 判断密码是否符合 至少8位,包括大小写字母、数字
	isPassword(val) {
		// var reg = /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]+$))^.{8,14}$/;
		var reg  = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,14}');
		if (val === '') {
			alert('请输入密码')
			return  false
		} else if (!reg.test(val)) {
			alert('请输入8-14位密码,包括大小写字母、数字')
			return false
		} else {
			return true
		}
	},
}

export default all;