<template>
    <div class="padding message_form">
      <h1 class="fs16 col333 bold tlt mrBtm30">信息发布</h1>
      <!--分类-->
      <div class="dif alc">
        <div class="fs14 col333 dif alc">
          <p style="color: #f00">*</p>
          <p class="mrLft10">分类</p>
          <div style="width: 360px;margin-left: 15px;">
            <el-select  placeholder="信息发布" class="w100" disabled></el-select>
          </div>
        </div>
      </div>
      <!--上传商标选择地-->
      <!--<div class="dif alc" style="margin-top: 50px;">
        <div class="posr">
          <img src="@/assets/image/yizhibang/69upload_logo.png" style="width: 186px;height: 126px;">
          <p class="fs12 posa" style="color: #B3B3B3;left: 0;right: 0;top: 82px;">请上传商标图片</p>
        </div>
        <div class="mrLft30 difcb" style="height: 126px;">
          &lt;!&ndash;品牌名称&ndash;&gt;
          <div class="dif alc">
            <p class="mrRit10">品牌名称</p>
            <div style="width: 360px;">
              <el-input placeholder="请输入品牌名称"></el-input>
            </div>
          </div>
          &lt;!&ndash;企业名称&ndash;&gt;
          <div class="dif alc">
            <p class="mrRit10">企业名称</p>
            <div style="width: 360px;">
              <el-input placeholder="请输入企业名称"></el-input>
            </div>
          </div>
          &lt;!&ndash;所在地区&ndash;&gt;
          <div class="dif alc">
            <p class="mrRit10">所在地区</p>
            <div style="width: 360px;">
              <el-cascader
                  style="width: 360px;"
                  :options="options"
                  v-model="selectedOptions"
                  @change="handleChange"
              >
              </el-cascader>
            </div>
          </div>
        </div>
      </div>-->
      <!--选择分类-->
      <!--<div class="dif alc" style="margin-top: 50px;">
        &lt;!&ndash;选择行业分类&ndash;&gt;
        <div class="dif alc mrRit30">
          <p class="mrRit10">选择行业分类</p>
          <div>
            <el-select v-model="value" placeholder="请选择" class="w100">
              <el-option
                  v-for="item in 3"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </div>
        </div>
        &lt;!&ndash;选择分类菜单&ndash;&gt;
        <div class="dif alc mrRit30">
          <p class="mrRit10">选择分类菜单</p>
          <div>
            <el-select v-model="value" placeholder="请选择" class="w100">
              <el-option
                  v-for="item in 3"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </div>
        </div>
        &lt;!&ndash;选择子菜单&ndash;&gt;
        <div class="dif alc mrRit30">
          <p class="mrRit10">选择子菜单</p>
          <div>
            <el-select v-model="value" placeholder="请选择" class="w100">
              <el-option
                  v-for="item in 3"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </div>
        </div>

      </div>-->
      <!--标题-->
     <!-- <div class="dif alc" style="margin-top: 50px;">
        <p class="fs14 col333 mrRit10">标题</p>
        <div style="width: 400px;">
          <el-input placeholder="请输入标题"></el-input>
        </div>
        <p class="fs14 col333 mrLft30 mrRit20">标题属性</p>
        <div class="dif alc">
          &lt;!&ndash;默认&ndash;&gt;
          <div class="dif alc pointer mrRit20" @click="titleType=1">
            <img src="@/assets/image/yizhibang/51close.png" style="width: 16px;height: 16px;" v-if="titleType===1">
            <img src="@/assets/image/yizhibang/51noclose.png" style="width: 16px;height: 16px;" v-else>
            <p class="mrLft10 fs14 col999">默认</p>
          </div>
          &lt;!&ndash;加粗&ndash;&gt;
          <div class="dif alc pointer mrRit20" @click="titleType=2">
            <img src="@/assets/image/yizhibang/51close.png" style="width: 16px;height: 16px;" v-if="titleType===2">
            <img src="@/assets/image/yizhibang/51noclose.png" style="width: 16px;height: 16px;" v-else>
            <p class="mrLft10 fs14 col999">加粗</p>
          </div>
          &lt;!&ndash;斜体&ndash;&gt;
          <div class="dif alc pointer" @click="titleType=3">
            <img src="@/assets/image/yizhibang/51close.png" style="width: 16px;height: 16px;" v-if="titleType===3">
            <img src="@/assets/image/yizhibang/51noclose.png" style="width: 16px;height: 16px;" v-else>
            <p class="mrLft10 fs14 col999">斜体</p>
          </div>
        </div>
      </div>-->
      <!--信息介绍-->
      <!--<div class="" style="margin-top: 50px;">
        <p class="fs14 col333 mrRit10 tlt mrBtm10">信息发布介绍</p>
        <div style="width: 768px;">
          <el-input placeholder="请填写公司的官网主营关健词[用于发布官网的首页搜索,请如实谨慎填写]" type="textarea" rows="5"></el-input>
        </div>
      </div>-->

      <!--详细内容-->
      <div class="mrTop30 mrBtm30 tlt fs14 col333 bold">
        为了更好宣传您组织或公司展现的形象与实力，请用心编辑（点击眼睛可选择是否对外展示）
      </div>
      <div class="dif difw tlt main">
        <div class="flex align-center" style="width: 45%; margin-top: 10px" v-for="(item,index) in main">
          <div style="margin-right: 20px;width: 64px;" class="fs14 col333">{{item.name}}</div>
          <div class="box1">
            <input type="text" class="box bgf3f3f3" placeholder="代用文字" style="border-radius: 2px;width: 346px;border: none;" v-model="item.cont"/>

            <!--公司类型选择-->
            <div v-if="item===main.company_type" class="shadow_bottom">
              <ul class="tlt bgff bra10" style="padding: 20px;border: none;">
                <li v-for="(item2,index2) in companyType" :key="index2"
                    class="pointer"
                    @click="item.cont = item2">{{item2}}</li>
              </ul>
            </div>
          </div>
          <img src="@/assets/image/enterprise/icon_show.png" v-if="item.status==1" @click="item.status=0"/>
          <img src="@/assets/image/enterprise/icon_hid.png" v-else @click="item.status=1"/>

        </div>
      </div>
      <div class="flex" :style="[{ 'margin-top': '30px' }]">
        <div class="fs14 col333 mrRit20">经营范围</div>
        <textarea
            class="mrRit20 bgf3f3f3 box"
            rows="8"
            placeholder="代用文字"
            v-model="business_scope.cont"
            style="border: none;padding: 20px;"
            :style="[
          { width: '825px' },
          { 'margin-left': '10px' },
        ]"
        ></textarea>
        <img src="@/assets/image/enterprise/icon_show.png" style="width: 30px;height: 25px;" v-if="business_scope.status==1" @click="business_scope.status=0"/>
        <img src="@/assets/image/enterprise/icon_hid.png"  style="width: 30px;height: 25px;" v-else @click="business_scope.status=1"/>
      </div>
      <!--目录编辑-->
      <!--<p class="col333 fs14 tlt mrTop30 mrBtm20 bold">自编辑或点击提示词填入下方目录</p>
      <ul class="dif difw">
        <li v-for="item in 30" class="fs14 col3765FF mrBtm10 pointer" style="margin-right: 50px;">基本简介</li>
      </ul>-->
      <!--目录列表-->
      <!--<div class="list dif alc" >
        <div class="difcac fs16 col333 list_1">目录</div>
        <div class="list2">
          <div class="dif alc" style="height: 238px;">
            <div class="list2_item" >
              <p class="fs14 col3A53FF mrTop10">1.历史沿革
                <span class="text-red pointer fs12 mrLft10">删除</span>
              </p>
            </div>
          </div>
        </div>
      </div>-->
      <!--发布时间 文章模板-->
      <!--<div class="dif alc mrTop20 mrBtm30">
        <div class="dif alc">
          <p class="col333 fs14 mrRit10">发布时间</p>
          <div>
            <el-date-picker
                style="width: 400px;"
                v-model="date"
                type="datetime"
                placeholder="选择日期时间">
            </el-date-picker>
          </div>
        </div>
        <div class="dif alc mrLft30">
          <p class="col333 fs14 mrRit10">文章模板</p>
          <div>
            <el-select v-model="value" placeholder="请选择" style="width: 400px;">
              <el-option
                  v-for="item in 3"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>-->
      <!--历史沿革-->
      <div class="mrBtm30 mrTop20" >
        <div class="dif alc mrBtm20">
          <div class="message_icon mrRit10"></div>
          <div style="width: 250px;">
            <el-input v-model="label1" class="fs14 col333 bold" placeholder="请输入标题"></el-input>
          </div>
        </div>
        <quill-editor1 v-show="showRichtText"
            @getEditor="getEditor1"
            ref="quillEditor1"
            :index="0"
        ></quill-editor1>
      </div>
      <!--主要职能-->
      <div class="mrBtm30" >
        <div class="dif alc mrBtm20">
          <div class="message_icon mrRit10"></div>
          <div style="width: 250px;">
            <el-input v-model="label2" class="fs14 col333 bold" placeholder="请输入标题"></el-input>
          </div>
        </div>
        <quill-editor1 v-show="showRichtText"
            @getEditor="getEditor2"
            ref="quillEditor2"
            :index="1"
        ></quill-editor1>
      </div>
      <!--其他-->
      <div class="mrBtm30" >
        <div class="dif alc mrBtm20">
          <div class="message_icon mrRit10"></div>
          <p class="fs14 col333 bold">其他</p>
        </div>
        <quill-editor1 v-show="showRichtText"
            @getEditor="getEditor3"
            ref="quillEditor3"
            :index="3"
        ></quill-editor1>
      </div>
      <!--添加地图-->
      <div style="margin-top: 60px;margin-bottom: 40px;">
        <p v-if="address_name==''||address_name==null" class="fs22 bold col3765FF pointer" @click="showMap">点击添加所在地图</p>
        <p v-else class="fs22 bold col3765FF pointer" @click="showMap">{{address_name}},更换地址</p>
        <!--<div class="msg_map"></div>-->
        <!--// on-select是选择之后的回调函数  结合下面的地址选择后的回调函数使用-->
        <!--// ref 是为了可以选择当前组件的标识 结合下面的 this.$refs.ms 使用-->
        <!--<mapSelect ref="ms" @on-select="addressSelectHandler"></mapSelect>-->
        <el-drawer
            :visible.sync="drawer"
            direction="rtl"
            size="60%"
        >
          <amap :position="postForm" :formattedAddress="postForm.address" @getPosition="getPosition" />
        </el-drawer>
      </div>
      <!--取消和发布-->
      <div class="difcac">
        <div class="difcac msg_button1 colfff pointer">取消</div>
        <div class="difcac msg_button2 colfff pointer" @click="submit">发布</div>
      </div>
    </div>
</template>

<script>
//地图插件 qqmap
import mapSelect from '@/components/mapSelect'
//地图插件 高德地图
import Amap from '@/components/Amap'
//富文本
import quillEditor1 from "@/components/quillEditor1";
import {CodeToText, provinceAndCityData} from "element-china-area-data";
export default {
  props: {
    LeftHeaderType: [String, Number],
  },
  components: {
    quillEditor1,mapSelect,Amap
  },
  data() {
    return {
      showRichtText: false,//显示富文本
      date: '',//发布时间
      pageHeight:document.documentElement.clientHeight,//获取页面高度
      main: {
        cname: {name: '中文名称',status: 1,cont: ''},
        founder: {name: '创始人',status: 1,cont: ''},
        ename: {name: '外文名称',status: 1,cont: ''},
        initiator: {name: '发起人',status: 1,cont: ''},
        short_name: {name: '简称名称',status: 1,cont: ''},
        leader: {name: '主领导人',status: 1,cont: ''},
        fname: {name: '前称名称',status: 1,cont: ''},
        legal_person: {name: '法定代表',status: 1,cont: ''},
        organization: {name: '组织性质',status: 1,cont: ''},
        trustee: {name: '董事主席',status: 1,cont: ''},
        department: {name: '主管部门',status: 1,cont: ''},
        president: {name: '总裁姓名',status: 1,cont: ''},
        creatTime: {name: '创立时间',status: 1,cont: ''},
        company_type: {name: '公司类型',status: 1,cont: ''},
        registerSite: {name: '注册地点',status: 1,cont: ''},
        patent_num: {name: '专利数量',status: 1,cont: ''},
        registerNum: {name: '注册号码',status: 1,cont: ''},
        publicity: {name: '宣传口号',status: 1,cont: ''},
        registerCapital: {name: '注册资本',status: 1,cont: ''},
        brand_idea: {name: '品牌理念',status: 1,cont: ''},
        turnoverYear: {name: '年营业额',status: 1,cont: ''},
        serve_aim: {name: '服务宗旨',status: 1,cont: ''},
        personnelNum: {name: '职员人数',status: 1,cont: ''},
        vision: {name: '愿景介绍',status: 1,cont: ''},
        registrationAuthority: {name: '登记机关',status: 1,cont: ''},
        mission: {name: '使命介绍',status: 1,cont: ''},
        societyCode: {name: '社会代码',status: 1,cont: ''},
        cost_idea: {name: '价值观点',status: 1,cont: ''},
        headAddress: {name: '总部地址',status: 1,cont: ''},
      },
      business_scope: {cont: '',status: 1}, //经营范围
      companyType: [  //公司类型
        '个体经营',
        '私营企业',
        '有限责任公司',
        '个人独资',
        '中外合资',
        '国有企业',
        '外资企业',
        '股份公司',
        '上市公司',
        '售后网店',
        '分公司-办事处',
        '实体店',
        '网店',
        '组织机构',
        '行业协会',
        '行业网站',
      ],
      imageUrl: '',//图片
      titleType: 1,//当前选中标题样式
      content: '',
      options: provinceAndCityData,//二级联动 省  市
      selectedOptions: [],//选择的内容
      history: '',//历史沿革
      main_function: '',//主要职能
      addressSelect: '', //全部信息
      lat: '', //经纬度
      lng: '',
      address_name: '',//地址名
      other: '',//其他
      label1: '',//历史沿革标题
      label2: '',//主要职能标题
      postForm: { //
        address: '',
        longitude: '',
        latitude: ''
      },
      drawer: false,//显示弹窗
    }
  },
  watch:{
    drawer(){
      if(!drawer){
        this.$fourceUpdate()
      }
    }
  },
  methods: {
    getMessageForm() { //获取上次填写的信息
      this.$axios("POST","/api/site/siteInfodetail",{
        type: 2,
      }).then(res => {
        if(res.code===1 && res.data.length>0) {
          let data = res.data[0]
          this.main.ename.cont = data.ename
          this.main.cname.cont = data.cname
          this.main.cname.status = data.cname_status
          this.main.ename.status = data.ename_status
          this.main.short_name.cont = data.short_name
          this.main.short_name.status = data.shore_status
          this.main.fname.cont = data.fname
          this.main.fname.status = data.fname_status
          this.main.organization.cont = data.organization
          this.main.organization.status = data.organization_status
          this.main.department.cont = data.department
          this.main.department.status = data.department_status
          this.business_scope.cont = data.business_scope
          this.business_scope.status = data.business_status
          this.main.company_type.cont = data.company_type
          this.main.company_type.status = data.company_status
          this.main.patent_num.cont = data.patent_num
          this.main.patent_num.status = data.patent_status
          this.main.publicity.cont = data.publicity
          this.main.publicity.status = data.publicity_status
          this.main.brand_idea.cont = data.brand_idea
          this.main.brand_idea.status = data.brand_status
          this.main.serve_aim.cont = data.serve_aim
          this.main.serve_aim.status = data.serve_status
          this.main.vision.cont = data.vision
          this.main.vision.status = data.vision_status
          this.main.mission.cont = data.mission
          this.main.mission.status = data.mission_status
          this.main.cost_idea.cont = data.cost_idea
          this.main.cost_idea.status = data.cost_status
          this.main.founder.cont = data.founder
          this.main.founder.status = data.founder_status
          this.main.initiator.cont = data.initiator
          this.main.initiator.status = data.initiator_status
          this.main.leader.cont = data.leader
          this.main.leader.status = data.leader_status
          this.main.legal_person.cont  = data.legal_person
          this.main.legal_person.status = data.person_status
          this.main.trustee.cont  = data.trustee
          this.main.trustee.status  = data.trustee_status
          this.main.president.cont  = data.president
          this.main.president.status  = data.president_status
          this.main.creatTime.cont = data.creatTime
          this.main.creatTime.status = data.creatTime_status
          this.main.registerSite.cont = data.registerSite
          this.main.registerSite.status = data.site_status
          this.main.registerNum.cont = data.registerNum
          this.main.registerNum.status = data.num_status
          this.main.registerCapital.cont = data.registerCapital
          this.main.registerCapital.status = data.capital_status
          this.main.turnoverYear.cont = data.turnoverYear
          this.main.turnoverYear.status = data.turnover_status
          this.main.personnelNum.cont = data.personnelNum
          this.main.personnelNum.status = data.personnel_status
          this.main.registrationAuthority.cont = data.registrationAuthority
          this.main.registrationAuthority.status = data.registration_status
          this.main.societyCode.cont = data.societyCode
          this.main.societyCode.status = data.society_status
          this.main.headAddress.cont = data.headAddress
          this.main.headAddress.status = data.head_status
          this.lat = data.lat
          this.lng = data.lng
          this.label1 = data.label1
          this.label2 = data.label2
          this.address_name = data.address_name

          this.postForm.address = data.address_name
          this.postForm.lng = data.lng
          this.postForm.lat = data.lat
          if(data.other) {
            this.$refs.quillEditor3.editorContent = unescape(data.other)
            this.other = unescape(data.other)
          }
          if(data.history) {
            this.$refs.quillEditor1.editorContent = unescape(data.history)
            this.history = unescape(data.history)
          }
          if(data.main_function) {
            this.$refs.quillEditor2.editorContent = unescape(data.main_function)
            this.main_function = unescape(data.main_function)
          }
          // this.showRichtText = true

        }
        setTimeout(() => {
          this.showRichtText = true
        },1200)
      })
    },
    // 获取地址信息
    getPosition({ address, lat, lng, name }) {
      this.postForm.address = address;
      this.postForm.lng = String(lng);
      this.postForm.lat = String(lat);
      this.drawer = false
      this.lat = String(lat);
      this.lng = String(lng);
      this.address_name = String(address);
    },
    addressSelectHandler(rootAddress, address, lat, lng) { //地图选择回调
      this.addressSelect = rootAddress + "," + address + '=====lat:' + lat + ',lng:' + lng
      // 省市区数据  如 ‘福建省、厦门市、思明区’
      console.log(rootAddress)
      // 选择地址 如：‘观音上商务区4号楼’
      console.log(address)
      // 纬度
      console.log(lat)
      this.lat = lat
      // 经度
      console.log(lng)
      this.lng = lng
    },
    getEditor1(cont) { //历史沿革
      cont = escape(cont)
      this.history = cont
    },
    getEditor2(cont) { //主要职能
      cont = escape(cont)
      this.main_function = cont
    },
    getEditor3(cont) { //其他
      cont = escape(cont)
      this.other = cont
    },
    onEditorChange(i,{ editor, html, text }) { //富文本
      console.log(i);
    },
    onsuccess(file){ //上传成功
      this.imageUrl = file.data.fullurl
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传封面图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传封面图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    submit() { //发布信息
      this.$axios("POST","/api/Release/informationRelease",{
        ename: this.main.ename.cont,
        cname: this.main.cname.cont,
        cname_status: this.main.cname.status,
        ename_status: this.main.ename.status,
        short_name: this.main.short_name.cont,
        shore_status: this.main.short_name.status,
        fname: this.main.fname.cont,
        fname_status: this.main.fname.status,
        organization: this.main.organization.cont,
        organization_status: this.main.organization.status,
        department: this.main.department.cont,
        department_status: this.main.department.status,
        business_scope: this.business_scope.cont,
        business_status: this.business_scope.status,
        company_type: this.main.company_type.cont,
        company_status: this.main.company_type.status,
        patent_num: this.main.patent_num.cont,
        patent_status: this.main.patent_num.status,
        publicity: this.main.publicity.cont,
        publicity_status: this.main.publicity.status,
        brand_idea: this.main.brand_idea.cont,
        brand_status: this.main.brand_idea.status,
        serve_aim: this.main.serve_aim.cont,
        serve_status: this.main.serve_aim.status,
        vision: this.main.vision.cont,
        vision_status: this.main.vision.status,
        mission: this.main.mission.cont,
        mission_status: this.main.mission.status,
        cost_idea: this.main.cost_idea.cont,
        cost_status: this.main.cost_idea.status,
        founder: this.main.founder.cont,
        founder_status: this.main.founder.status,
        initiator: this.main.initiator.cont,
        initiator_status: this.main.initiator.status,
        leader: this.main.leader.cont,
        leader_status: this.main.leader.status,
        legal_person: this.main.legal_person.cont,
        person_status: this.main.legal_person.status,
        trustee: this.main.trustee.cont,
        trustee_status: this.main.trustee.status,
        president: this.main.president.cont,
        president_status: this.main.president.status,
        creatTime: this.main.creatTime.cont,
        creatTime_status: this.main.creatTime.status,
        registerSite: this.main.registerSite.cont,
        site_status: this.main.registerSite.status,
        registerNum: this.main.registerNum.cont,
        num_status: this.main.registerNum.status,
        registerCapital: this.main.registerCapital.cont,
        capital_status: this.main.registerCapital.status,
        turnoverYear: this.main.turnoverYear.cont,
        turnover_status: this.main.turnoverYear.status,
        personnelNum: this.main.personnelNum.cont,
        personnel_status: this.main.personnelNum.status,
        registrationAuthority: this.main.registrationAuthority.cont,
        registration_status: this.main.registrationAuthority.status,
        societyCode: this.main.societyCode.cont,
        society_status: this.main.societyCode.status,
        headAddress: this.main.headAddress.cont,
        head_status: this.main.headAddress.status,
        history: this.history,
        main_function: this.main_function,
        lat: this.lat,
        lng: this.lng,
        other: this.other,
        label1: this.label1,
        label2: this.label2,
        address_name: this.address_name
      }).then(res => {
        if(res.code===1) {
          this.$message.success(res.msg)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    showMap() { //显示地图选择
      this.drawer = true
      return
      this.$refs.ms.show()
      //根据省市区设置初始值
    }
  },
  mounted() {
    this.getMessageForm()
    // this.$refs.ms.setLocationByAddress('山东省、济南市、历下区')
    // 根据经纬度设置初始值
    // this.$refs.ms.setLocationByLatLng(39.98174, 116.30631)
  },
  created() {
  }
};
</script>

<style scoped>
.message_form ::v-deep .el-input__inner{
  height: 40px;
  border-radius: 10px;
}

/*上传图片*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

input {
  padding: 10px;
  border: 0.5px solid #c2c2c2;
  border-radius: 0.5rem;
  width: 80%;
}

/*详细内容*/
.main img{
  width: 30px;
  height: 25px;
  margin-left: 10px;
}
.box1{
  position: relative;
}
.box1 div{
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  border-radius: 10px;
  background: #fff;
  transform: translateY(-50%);
}
.box1:hover div{
  display: block;

}
/* 目录 */
.list{
  width: 1200px;
  border: 1px solid #EBF1F5;
  height: 238px;
  margin-bottom: 30px;
}
.list_1{
  width: 100px;
  border: 1px solid #EBF1F5;
  height: 238px;
  background: #F5FBFF;
}
.list2{
  width: 1100px;
  height: 238px;
  overflow-x: auto;
}
.list2_item{
  box-sizing: border-box;
  flex: none;
  width: 160px;
  height: 238px;
  border: 1px solid #EBF1F5;
  overflow: hidden;
}

.message_icon{
  width: 10px;
  height: 10px;
  background: #345FFD;
}
.msg_map{
  width: 974px;
  height: 560px;
  margin: 30px auto;
  background: #CCCCCC;
}
.msg_button1{
  width: 226px;
  height: 50px;
  background: #CCCCCC;
  border-radius: 6px;
}
.msg_button2{
  width: 226px;
  height: 50px;
  background: #3A54FF;
  border-radius: 6px;
  margin-left: 40px;
}
</style>