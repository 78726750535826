import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import meCenter from '../views/meCenter.vue'
import Home from '../views/Home.vue'
import Search from "@/views/Search";
import searchResult from "@/components/search/searchCommon/searchResult";
import webDetail from "@/components/search/searchCommon/webDetail";
import shopCenter from "@/components/search/shopClass/shopCenter";
import shopDetail from "@/components/search/shopClass/shopDetail";
import advisoryCenter from "@/components/search/advisoryClass/advisoryCenter";
import advisoryResult from "@/components/search/advisoryClass/advisoryResult";
import advisoryDetail from "@/components/search/advisoryClass/advisoryDetail";
import demandCenter from "@/components/search/demandClass/demandCenter";
import demandDetail from "@/components/search/demandClass/demandDetail";
import demandResult from "@/components/search/demandClass/demandResult";
import appCenter from "@/components/search/appClass/appCenter";
import appResult from "@/components/search/appClass/appResult";
import appDetail from "@/components/search/appClass/appDetail";
import zhaopinCenter from "@/components/search/zhaopin/zhaopinCenter";
import zhoapinResult from "@/components/search/zhaopin/zhoapinResult";
import zhaopinDetail from "@/components/search/zhaopin/zhaopinDetail";
import createdWeb from "@/views/createdWeb";
import createdIndex from "@/components/createdWeb/index"
import jianjie from "@/components/createdWeb/jianjie";
import news from "@/components/createdWeb/news";
import demand from "@/components/createdWeb/demand";
import shop from "@/components/createdWeb/shop";
import join from "@/components/createdWeb/join";
import zhaopinList from "@/components/createdWeb/zhaopinList";
import download from "@/components/createdWeb/download";
import contactUs from "@/components/createdWeb/contactUs";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/meCenter',
    name: 'meCenter',
    component: meCenter
  },
  {
    path: '/search',
    name: 'search',
    component: Search,

    children: [
      {
        path: '',
        component: searchResult
      },
      {
        path: 'webdetail',
        component: webDetail
      },
      {
        path: 'shopclass',
        component: shopCenter
      },
      {
        path: 'shopdetail',
        component: shopDetail
      },
      {
        path: 'advisory',
        component: advisoryCenter,
        children: [
          {
            path: '',
            component: advisoryResult
          },
          {
            path: 'detail',
            component: advisoryDetail
          }
        ]
      },
      {
        path: 'demand',
        component: demandCenter,
        children: [
          {
            path: '',
            component: demandResult
          },
          {
            path: 'detail',
            component: demandDetail
          }
        ]
      },
      {
        path: 'app',
        component: appCenter,
        children: [
          {
            path: '',
            component: appResult
          },
          {
            path: 'detail',
            component: appDetail
          }
        ]
      },
      {
        path: 'zhaopin',
        component: zhaopinCenter,
        children: [
          {
            path: '',
            component: zhoapinResult
          },
          {
            path: 'detail',
            component: zhaopinDetail
          }
        ]
      }
    ]
  },
  {
    path: '/Web',
    component: createdWeb,
    redirect: '/Web/news',
    children: [
      // {
      //   path: '',
      //   component: createdIndex
      // },
      {
        path: 'jianjie',
        component: jianjie
      },
      {
        path: 'news',
        component: news
      },
      {
        path: 'demand',
        component: demand
      },
      {
        path: 'shop',
        component: shop
      },
      {
        path: 'join',
        component: join
      },
      {
        path: 'zhaopin',
        component: zhaopinList
      },
      {
        path: 'download',
        component: download
      },
      {
        path: 'contactus',
        component: contactUs
      }
    ]
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
