<template>
  <!--软件详情-->
  <div style="width: 1200px;margin: 0 auto;">
    <!--导航-->
    <div class="dif alc tlt fs12 col999 mrTop20 mrBtm20">
      <router-link to="/" class="fs12 col999">首页</router-link>
      <p>>软件搜索</p>
      <p>>软件详情</p>
    </div>
    <div style="width: 1200px;" class="mr0At">
      <!--软件信息-->
      <div class="dif alc mrTop20 app_item posr" >
        <!--软件图标-->
        <div class="app_img bra10" style="width: 100px;height: 100px;overflow: hidden;border-radius: 20px">
          <img  class="w100 h100 bgccc" :src="info.appimage">
        </div>
        <!--软件信息-->
        <div class="app_info" style="margin-left: 41px;">
          <ul class="fs12 col666 difb difw" style="width: 410px;">
            <li class="one_overflow">软件名称：{{info.title || '无'}}</li>
            <li class="one_overflow">软件性质：{{ info.nature || '无' }}</li>
            <Li class="one_overflow">软件语言：{{info.language || '无'}}</Li>
            <Li class="one_overflow">软件授权：{{info.accredit || '无'}}</Li>
            <li class="one_overflow">软件大小：{{ info.size }}<span v-if="info.size_type===1">KB</span><span v-if="info.size_type===2">MB</span></li>
            <li class="one_overflow">软件版本：{{ info.versions || '无' }}</li>
            <li class="one_overflow">使用平台：{{ info.platform || '无' }}</li>
          </ul>
        </div>
        <!--下载地址-->
        <div class="d_code dif alc">
          <div style="margin-right: 33px;" @click.stop="$all.showImg(info.androidimage)">
            <img class="bgccc pointer" style="width: 74px;height: 74px;" :src="info.androidimage">
            <p class="fs12 col333" style="margin-top: 15px;">安卓版本下载</p>
          </div>
          <div>
            <img class="bgccc pointer" style="width: 74px;height: 74px;" :src="info.iosimage" @click.stop="$all.showImg(info.iosimage)">
            <p class="fs12 col333" style="margin-top: 15px;">苹果版本下载</p>
          </div>
        </div>
        <!--下载-->
        <div class="d_d difcb h100" style="margin-left: 134px;">
          <!--<div style="margin-bottom: 16px;">软件介绍</div>-->
          <div class="pointer" @click="showModal()">pc端下载</div>
        </div>
        <!--操作-->
        <div class="dif alc fs14 colFDAE38 share">
          <p class="pointer" @click="collect(info)" v-if="info.is_collect==1">取消收藏</p>
          <p class="pointer" @click="collect(info)" v-else>收藏</p>
          <p class="pointer" style="margin-left: 16px;" @click="setShare()">分享</p>
        </div>
        <!--分享-->
        <div class="share_icon" v-if="showShare" @mouseleave="showShare=false">
          <share :config="config"></share>
        </div>
      </div>
      <div class="d_tit dif alc mrBtm30 mrTop30">
        <img src="@/assets/image/yizhibang/20telephone.png" style="width: 20px;height: 18px;">
        <h3 class="fs18 col333 bold mrLft10">联系方式</h3>
        <h3 class="fs18 col333 bold" style="margin-left: 14px;" v-if="userInfo.is_autonym==1">{{info.user_name}} {{info.user_phone}}</h3>
        <h3 class="fs18 col333 bold" style="margin-left: 14px;" v-else>通过实名认证即可查看联系方式</h3>
      </div>
      <!--软件介绍-->
      <div style="margin-top: 33px;">
        <el-collapse v-model="cat">
          <el-collapse-item class="tlt" name="1">
            <template slot="title">
              <div class="title posr dif alc">
                <div class="title_icon"></div>
                <h3 class="fs18 col333 tlt" style="margin-left: 5px;">软件介绍</h3>
              </div>
            </template>
            <div class="fs14 col333" style="line-height: 40px;text-indent: 2em;word-break: break-all" v-html="content">
            </div>
          </el-collapse-item>

          <el-collapse-item class="tlt" name="2">
            <template slot="title">
              <div class="title posr dif alc">
                <div class="title_icon"></div>
                <h3 class="fs18 col333 tlt" style="margin-left: 5px;">软件截图</h3>
              </div>
            </template>
            <div class="fs14 col333" style="line-height: 40px;text-indent: 2em">
              <el-carousel indicator-position="outside" height="500px">
                <el-carousel-item v-for="(item,index) in getSwiper(info.images)" :key="index">
                  <div class="w100 h100 difcac">
                    <img :src="item" class="h100" style="width: auto">
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <!--评论-->
      <div class="comment mr0At mrTop30" style="width: 1200px;">
        <!--输入框-->
        <div class="comment_input">
          <textarea rows="5" v-model="comment"></textarea>
        </div>
        <!--发布评论-->
        <div class="difb alc mrTop20">
          <div class="dif alc">
            <el-radio-group v-model="star" @change="changeStar">
              <el-radio v-for="(item,index) in starList" :label="item.id"
                :key="item.id">{{item.title}}</el-radio>
            </el-radio-group>
          </div>
          <div class="submit pointer" @click="postComment(0,1)">发布评论</div>
        </div>
        <!--选项-->
        <div class="c_top">
          <ul class="dif fs16 col333">
            <!--<li>发送私信</li>-->
            <li>总论({{comments.length}})</li>
            <!--<li>好评(2500)</li>-->
            <!--<li>中评(2500)</li>-->
            <!--<li>差评(2500)</li>-->
          </ul>
        </div>
        <!--评论区-->
        <div class="comment_area">
          <ul>
            <!--一级评论-->
            <li v-for="(item,index) in comments" class="mrTop10 pdBtm10 pdTop20" style="border-bottom: 1px solid #EEEEEE;">
              <div class="area_top posr dif">
                <!--头像-->
                <div class="ava mrRit10 bra bgccc" style="width: 60px;height: 60px;overflow:hidden;">
                  <img :src="item.userinfo.avatar" class="w100 h100 bra">
                </div>
                <div>
                  <!--发布时间-->
                  <div class="dif alc">
                    <p class="area_name fs18 bold col333 mrRit30">{{item.userinfo.username}}</p>
                    <p class="area_time fs12 col999 mrRit30" style="color:#7b7b7b;">发表于 {{item.createtime}}</p>
                    <div class="fs12 col3A53FF"><span @click="showSixin(item)">私信</span> <span @click="jubao(item)">举报</span></div>
                  </div>

                  <div class="comment_main box tlt  posr mrTop20" style="width: 1079px;">
                    <!--一级评论内容-->
                    <p class="fs14 col999 posr mrBtm20">
                      {{item.content}}
                      <img src="@/assets/image/yizhibang/25comment.png" style="width: 17px;height: 16px;margin-right: 40px;"  class="reply pointer" @click="showComment(item.id,2)">
                      <!--<img src="@/assets/image/yizhibang/25thumbs.png" style="width: 17px;height: 16px;"  class="reply pointer" @click="showComment(item.id,2)">-->
                      <!--<span class="reply fs14 pointer" style="color:#7b7b7b;" >回复</span>-->
                    </p>
                    <!--二级评论-->
                    <ul class="two_comment" v-if="item.sonInfo && item.sonInfo.length>0">
                      <li class="pdTop20 mrBtm20 w100" v-for="(item2,index2) in item.sonInfo" v-if="index2<2">
                        <div class="area_top posr dif w100">
                          <!--头像-->
                          <div class="ava mrRit10 bra bgccc" style="width: 60px;height: 60px; overflow: hidden">
                            <img :src="item2.userinfo.avatar" class="w100 h100 bra">
                          </div>
                          <div>
                            <div class="dif alc">
                              <p class="area_name fs18 col333 bold mrRit30">{{item2.userinfo.username}}</p>
                              <p class="area_time fs12 col999 mrRit30">发表于 {{item2.createtime}}</p>
                              <div class="fs12 col3A53FF"><span @click="showSixin(item2)">私信</span> <span @click="jubao(item2)">举报</span></div>
                            </div>
                            <div class="comment_main box tlt  posr mrTop10 mrBtm10 w100">
                              <p class="fs14 col999 posr" style="width: 970px;">
                                <span class="area_time fs14 col999 mrRit10">回复</span>
                                <span class="area_name fs14 col333">{{item.userinfo.username}}：</span>
                                {{item2.content}}
                                <img src="@/assets/image/yizhibang/25comment.png" style="width: 17px;height: 16px;margin-right: 40px;"  class="reply pointer" @click="showComment(item.id,2,item2.userinfo.username)">
                                <!--<img src="@/assets/image/yizhibang/25thumbs.png" style="width: 17px;heiglht: 16px;"  class="reply pointer">-->
                              </p>
                            </div>
                          </div>

                        </div>

                      </li>
                      <li class="pdTop20 mrBtm20" v-for="(item2,index2) in item.sonInfo"  v-if="index2>=2 && index===i1 && index===i0">
                        <div class="area_top posr dif alc">
                          <!--头像-->
                          <div class="ava mrRit10 bra bgccc" style="width: 60px;height: 60px;overflow: hidden">
                            <img :src="item2.userinfo.avatar" class="w100 h100 bra">
                          </div>
                          <div>
                            <div class="dif alc">
                              <p class="area_name fs18 col333 bold mrRit30">{{item2.userinfo.username}}</p>
                              <p class="area_time fs12 col999 mrRit30">发表于 {{item2.createtime}}</p>
                              <div class="fs12 col3A53FF"><span @click="showSixin(item2)">私信</span> <span @click="jubao(item2)">举报</span></div>
                            </div>
                            <div class="comment_main box tlt  posr mrTop10 mrBtm10 w100">
                              <p class="fs14 col999 posr" style="width: 970px;">
                                <span class="area_time fs14 col999 mrRit10">回复</span>
                                <span class="area_name fs14 col333">{{item.userinfo.username}}：</span>
                                {{item2.content}}
                                <img src="@/assets/image/yizhibang/25comment.png" style="width: 17px;height: 16px;margin-right: 40px;"  class="reply pointer" @click="showComment(item.id,2)">
                                <!--<img src="@/assets/image/yizhibang/25thumbs.png" style="width: 17px;height: 16px;"  class="reply pointer" @click="showComment(item.id,2)">-->
                              </p>
                            </div>
                          </div>
                        </div>
                        <!--评论的评论的评论-->

                      </li>
                      <span class="pointer fs12 col3A53FF" @click="i1=index;i0=index" v-if="index!==i1 && item.sonInfo.length>2">还有{{item.sonInfo.length - 2}}条评论,点击查看更多</span>
                      <span class="pointer fs12 col3A53FF" @click="i1=index;i0=index" v-else-if="index===i1 && item.sonInfo.length>2 && i0!==index">还有{{item.sonInfo.length - 2}}条评论,点击查看更多</span>
                      <p @click="i1=-1;i0=-1" v-else-if="index===i1 && item.sonInfo.length>2" class="pointer fs12 col3A53FF mrTop10">收起</p>
                    </ul>
                  </div>
                </div>
                <p class="fs12 fastest" style="color:#F6AA00;" v-if="index===0">沙发</p>
                <p class="fs12 fastest" style="color:#F6AA00;" v-if="index===1">板凳</p>
                <p class="fs12 fastest" style="color:#F6AA00;" v-if="index===2">地板</p>
              </div>

            </li>
          </ul>
        </div>
        <!--评论2-->
        <div class="comment2" v-if="isShow">
          <div class="comment2_cont">
            <span @click="isShow=false" class="fs14 col999">关闭</span>
            <!--输入框-->
            <div class="comment_input2">
              <textarea rows="5" v-model="comment"></textarea>
            </div>
            <!--发布评论-->
            <div class="dife alc mrTop20">
              <div class="submit pointer" v-if="contentType===1" @click="postComment()">回复</div>
              <div class="submit pointer" v-if="contentType===2" @click="sixin()">发送私信</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "appDetail",
  data() {
    return {
      sid: 0,
      cat: ['1','2'],//默认介绍展开
      comments: [],//获取评论
      comment: '',//输入内容
      isShow: false,
      pid: 0,
      type: 0,
      //显示更多
      i0: -1,
      i1: -1,
      i2: -1,
      busername: '',//被回复人名字
      info: {},//软件详情
      content: '',//软件介绍
      //分享配置
      config:{
        sites: ['qq','wechat','qzone'],
        image: '',
        title: '',
        description: '',
        url: '', // 网址，默认使用 window.location.href
        source: '', // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
        wechatQrcodeTitle: '微信扫一扫：分享', // 微信二维码提示文字
        wechatQrcodeHelper: '<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>'
      },
      showShare: false,
      star: 3,//3好评 2中 1差评
      starList: [
        {id: 3,title: '好评'},
        {id: 2,title: '中评'},
        {id: 1,title: '差评'},
      ],
      downloadInfo: '',//下载提示
      userInfo: {
        is_pay: 0,
        is_autonym: 0
      },
      contentType: 1,//
    }
  },
  methods: {
    getUserInfo() { //获取用户信息
      this.$axios("POST","/api/user/getUser",{})
          .then(res => {
            this.userInfo = res.data
          })
    },
    getConfig(type) { //获取下载提示
      this.$axios("POST","/api/Index/getConfing",{name: 'donwload'})
      .then(res => {
        if(res.code===1) {
          this.downloadInfo = res.data
          if(type) {
            this.showModal()
          }
        }
      })
    },
    showModal() { //显示下载提示  点击下载
      this.$confirm(this.downloadInfo,{type: 'warning'})
      .then(con => {
        window.open(this.info.url)
      })
    },
    //收藏
    collect(item) {
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      this.$axios("POST","/api/member/collect",{
        id: item.id,
        type: 7
      }).then(res => {
        if(res.code===1) {
          this.$message.success('操作成功')
          // this.$router.go(0)
          this.getAppDetail(this.sid)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    setShare() { //显示分享
      this.showShare = !this.showShare
    },
    getAppDetail(id) { //获取app详情
      this.$axios("POST","/api/App/appDetail",{
        id
      }).then(res => {
        //分享相关
        this.config.title = res.data[0].title
        this.config.url = document.location.href
        //设置数据
        this.content = unescape(res.data[0].content)
        this.info = res.data[0]
      })
    },
    postComment(pid,type) { //发布评论
      if(!pid && !type){ //回复窗口点击
        pid = this.pid
        type = this.type
      }
      if(this.comment === ''){
        this.$alert('评论不允许为空',{type: 'error4'})
        return
      }
      this.$axios("POST","/api/comment/comment",{
        sid: this.sid,
        pid: pid,
        type:type,
        comment: this.comment,
        busername: this.busername,
        star: this.star,
        types: 2,
      }).then(res => {
        this.$alert(res.msg,{type: 'error4'})
        this.isShow = false
        this.comment = '' //评论完清空内容
        //获取评论
        this.getComment(this.sid)
      })
    },
    getComment(id) {
      //获取评论
      this.$axios("POST","/api/comment/getSiteComment",{
        sid: id,
        types: 2
      }).then(res => {
        this.comments = res.data
      })
    },
    showComment(pid,type,bname){ //显示回复时输入框
      this.pid = pid
      this.type = type
      this.isShow = true
      this.busername = bname
    },
    sixin() { //发送私信
      if(this.comment==='') {
        this.$alert('请输入内容')
        return
      }
      this.$axios("POST","/api/comment/chat",{
        pid: this.pid,
        comment: this.comment
      }).then(res => {
        if(res.code===1) {
          this.$alert('发送成功')
          this.isShow = false
          this.comment = '' //评论完清空内容
        }else {
          this.$alert(res.msg)
        }
      })
    },
    showSixin(item) { //点击显示私信
      this.pid = item.userinfo.uid
      this.contentType = 2
      this.isShow = true
    },
  },
  created() {
    this.getUserInfo()
    //获取app详情
    let id = this.$route.query.id
    this.sid = this.$route.query.id
    if(this.$route.query.type) {
      this.getConfig(this.$route.query.type)
    }else {
      this.getConfig()
    }
    //获取评论
    this.getComment(this.sid)

    this.getAppDetail(id)
  },
  computed: {
    getSwiper() { //获取轮播图
      return function (str) {
        if(str && str!=='') {
          return str.split(',')
        }
      }
    },

  }
}
</script>

<style scoped>
.share{
  position: absolute;
  bottom: 16px;
  right: 22px;
}
.app_info li{
  text-align: left;
  width: 50%;
  margin-bottom: 10px;
}
.app_item{
  width: 1200px;
  box-sizing: border-box;
  background: #FAFAFA;
  border: 1px solid #F5F5F5;
  padding: 23px 36px;
}
.d_d div{
  width: 120px;
  height: 40px;
  background: #3A54FF;
  border-radius: 20px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

/*评论*/
.two_comment{
  width: 1100px;
  background: #F4F4F4;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
}
.two_comment::before{
  content: "";
  display: block;
  border-bottom: 14px solid #F4F4F4;
  border-left: 13px solid rgba(0,0,0,0);
  border-right: 13px solid rgba(0,0,0,0);
  position: absolute;
  top: -10px;
  left: 27px;
}
.comment{
  border-top: 1px solid #F0F0F0;
  padding-top: 26px;
}
.c_top{
  padding-top: 41px;
  margin-top: 17px;
  padding-bottom: 10px;
  border-top: 1px solid #EEEEEE;
}
.c_top li{
  cursor: pointer;
  margin-left: 30px;
}

.comment_input2 textarea{
  box-sizing: border-box;
  resize: none;
  margin-top: 20px;
  width: 500px;
  height: 150px;
  background: #F4F4F4;
  border-radius: 10px;
  padding: 20px;
  border: none;
  font-size: 16px;
}
.comment_input textarea{
  box-sizing: border-box;
  resize: none;
  width: 1200px;
  height: 120px;
  background: #F4F4F4;
  border: 1px solid #DEDEDE;
  border-radius: 10px;
  padding: 20px;
  font-size: 16px;
}
.submit{
  width: 130px;
  height: 46px;
  background: #3A54FF;
  border-radius: 6px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.fastest,.reply{
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  right: 0;
}
.reply{
  top: 10px;
  right: 50px;
}

/*评论2*/
.comment2{
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}
.comment2 .comment2_cont{
  box-sizing: border-box;
  padding: 20px 20px;
  border-radius: 20px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.comment2_cont span{
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;
}


/*介绍*/
.title_icon{
  width: 10px;
  height: 10px;
  background: #3765FF;
}

/*分享*/
.share_icon{
  position: absolute;
  top: 95px;
  right: -60px;
  transform: translate(-50%,-50%);
  z-index: 99;
}
</style>