<template>
  <!--注册登录-->
  <div class="register difc difw">
    <div class="w60 mrBtm20">
      <el-input v-model="mobile" placeholder="输入手机号" maxlength="11"></el-input>
    </div>
    <div class="posr w60  mrBtm20">
      <el-input v-model="captcha" placeholder="输入验证码"></el-input>
      <p class="fs14 get_code pointer" v-if="time===121" @click="showCode()">获取验证码</p>
      <p class="fs14 get_code pointer" v-else>重新发送({{time}}秒)</p>
    </div>
    <div class="posr w60  mrBtm20">
      <el-input v-model="password" placeholder="输入登录密码" type="password" show-password></el-input>
    </div>
    <div class="posr w60  mrBtm20">
      <el-input v-model="password2" placeholder="确认密码" type="password" show-password></el-input>
    </div>
    <div class="button w60" @click="register">注册</div>
    <div class="difcac mrTop20">
      <img src="@/assets/image/yizhibang/03yes_check.png" class="pointer" style="height: auto;width: 15px;"
           @click="isAgree=!isAgree" v-if="isAgree">
      <img src="@/assets/image/yizhibang/03no_check.png" class="pointer" style="height: auto;width: 15px;"
           @click="isAgree=!isAgree" v-else>
      <p class="col666 fs14 tct mrLft10" style="color:#B3B3B3;">我已阅读并同意
        <span class="col3A53FF pointer" @click="showXy1=true">《服务协议》 </span>
        <span class="col3A53FF pointer" @click="showXy2=true">《隐私协议》 </span>
      </p>
    </div>

    <!--服务协议-->
    <el-dialog
        title="服务协议"
        :visible.sync="showXy1"
        :modal-append-to-body="false"
        top="1vh"
        width="50%">
      <div v-html="fwxy" style="max-height: 500px;overflow-y: auto;text-align: left"></div>
    </el-dialog>
    <!--隐私协议-->
    <el-dialog
        title="隐私协议"
        :visible.sync="showXy2"
        :modal-append-to-body="false"
        top="1vh"
        width="50%">
      <div v-html="yszc" style="max-height: 500px;overflow-y: auto;text-align: left"></div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "register",
  data() {
    return {
      mobile: '',//手机号
      password: '',//密码
      password2: '',//密码
      captcha: '',//密码
      token: '',//token
      userInfo: '',//用户信息
      isAgree:false,
      timer: null,
      time: 121,

      msg: '',
      text: '向右滑动验证',
      accuracy: 5,

      fwxy: '',//服务协议
      yszc: '',//隐私协议
      showXy1: false,//显示协议
      showXy2: false,//显示协议
    }
  },
  created() {
    eventBus.$on('codeSuccess',this.getCode)
    this.getConfig()
  },
  destroyed() {
    eventBus.$off('codeSuccess')
  },
  methods: {
    getConfig() { //获取协议内容
      this.$axios("POST","/api/index/getConfing",{})
          .then(res => {
            if(res.code==1) {
              this.yszc = res.data.yszc
              this.fwxy = res.data.fwxy
            }
          })
    },
    showCode() {
      if(!this.isAgree) {
        this.$alert('请阅读并同意协议内容',{type: 'error'})
        return;
      }
      if(!this.mobile) {
        return this.$message.error('请输入手机号')
      }else if(!this.$all.isPhone(this.mobile)) {
        return this.$message.error('手机号格式不正确')
      }
      this.$emit('showCode',false)
    },
    getCode() { //获取验证码

      clearInterval(this.timer)
      this.$message('发送中')
      this.$axios("POST","/api/Index/sendSms",{
        mobile: this.mobile,
        event: 'register'
      }).then(res => {
        if(res.code===1) {
          this.$message.success('发送成功,验证码有效时间120秒')
          clearInterval(this.timer)
          this.timer = setInterval(()=>{
            this.time--
            if(this.time<=0) {
              this.time = 121
              clearInterval(this.timer)
            }
          },1000)
        }else {
          this.$message.error(res.msg)

        }
      })
    },
    register() { //用户注册
      if(!this.isAgree) {
        this.$alert('请阅读并同意协议内容',{type: 'error'})
        return;
      }
      //开启页面滚动
      let m =function(e){e.preventDefault();};
      document.body.style.overflow='';//出现滚动条
      document.removeEventListener("touchmove",m,{ passive:true });
      //判断是否为手机号
      // this.$all.isPhone(this.mobile)
      //判断两次输入密码是否相同
      let reg  = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{6,20}');
      if(this.password==='') {
        return this.$alert('请输入密码',{type: 'error'})
      }else if(!reg.test(this.password)) {
        return this.$alert('请输入至少6位密码,包括大小写字母、数字',{type: 'error'})
      }
      if(this.password2!==this.password){
        this.$alert('两次输入的密码不一致',{type: 'error'})
        // alert('两次输入的密码不一致')
        return
      }
      //发送注册请求
      this.$axios("POST",'/api/user/register',{
        mobile: this.mobile,
        password: this.password,
        captcha: this.captcha
      }).then(res => {
        if(res.code=='1'){
          // this.token = res.data.userinfo.token //存入token
          // this.userInfo = res.data.userInfo //存入用户信息
          // this.$store.commit('set_token',this.token)
          // this.$store.commit('setUserInfo',this.userInfo)
          this.$emit('changeTab',1)
          this.$message.success('注册成功')
        }else{
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },

    onSuccess(times) {
      console.log('验证通过，耗时 '+ times + '毫秒');
      this.msg = 'login success, 耗时${(times / 1000).toFixed(1)}s'
    },
    onFail(){
      console.log('验证不通过');
      this.msg = ''
    },
    onRefresh(){
      console.log('点击了刷新小图标');
      this.msg = ''
    },
    onFulfilled() {
      console.log('刷新成功啦！');
    },
    onAgain() {
      console.log('检测到非人为操作的哦！');
      this.msg = 'try again';
      // 刷新
      this.$refs.slideblock.reset();
    },
    handleClick() {
      this.$refs.slideblock.reset();
    },
  }
}
</script>

<style scoped>
  .register{
    margin-top: 10px;
  }
  div{line-height: normal}
  input{
    font-size: 16px;
    width: 100%;
    height: 56px;
    border: 1px solid #000;
    border-radius: 10px;
    box-sizing: border-box;
    padding-left: 20px;
  }
  
  .button{
    width: 360px;
    height: 56px;
    background: #3A54FF;
    border-radius: 8px;
    font-size: 24px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .get_code{
    position: absolute;
    color: #3A51FF;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }

  ::v-deep .el-input input.el-input__inner{
    height: 56px;
    border-radius: 10px;
  }
</style>