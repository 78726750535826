<template>
  <!--密码登录-->
  <div class="login difc difw">
    <div class="w60 mrBtm20">
      <el-input v-model="account" placeholder="输入手机号码" type="text" maxlength="11"></el-input>
    </div>
    <div class="posr w60  mrBtm10">
      <el-input @keyup.enter="login" v-model="password" placeholder="输入登录密码" type="password" show-password></el-input>
    </div>

    <div class="fs12 w60 difb alc mrBtm10">
      <p class="pointer mrLft10 fs14 col999" @click="forget">忘记密码</p>
      <div class="dif alc">
        <img src="@/assets/image/yizhibang/03no_check.png" style="width: 16px;height: 16px;" v-if="!remember"
          @click="remember=!remember">
        <img src="@/assets/image/yizhibang/03yes_check.png" style="width: 16px;height: 16px;" v-else
          @click="remember=!remember">
        <p class="mrRit10 mrLft10 fs14 col999" @click="remember=!remember">记住密码</p>
      </div>
    </div>
    <div class="button w60" @click="login">登录</div>
    <div class="difcac mrTop20">
      <img src="@/assets/image/yizhibang/03yes_check.png" class="pointer" style="height: auto;width: 15px;"
        @click="isAgree=!isAgree" v-if="isAgree">
      <img src="@/assets/image/yizhibang/03no_check.png" class="pointer" style="height: auto;width: 15px;"
        @click="isAgree=!isAgree" v-else>
      <p class="col666 fs14 tct mrLft10" style="color:#B3B3B3;">我已阅读并同意
        <span class="col3A53FF pointer" @click="showXy1=true">《服务协议》 </span>
        <span class="col3A53FF pointer" @click="showXy2=true">《隐私协议》 </span>
      </p>
    </div>

    <!--服务协议-->
    <el-dialog title="服务协议" :visible.sync="showXy1" :modal-append-to-body="false" top="1vh" width="50%">
      <div v-html="fwxy" style="max-height: 500px;overflow-y: auto;text-align: left"></div>
    </el-dialog>
    <!--隐私协议-->
    <el-dialog title="隐私协议" :visible.sync="showXy2" :modal-append-to-body="false" top="1vh" width="50%">
      <div v-html="yszc" style="max-height: 500px;overflow-y: auto;text-align: left"></div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "login",
    data() {
      return {
        account: '',//手机号 或者 邮箱  16666666666
        password: '',//密码
        token: '',//token
        userInfo: {}, //用户信息
        remember: false,//记住密码
        isAgree: false,//是否同意协议

        fwxy: '',//服务协议
        yszc: '',//隐私协议
        showXy1: false,//显示协议
        showXy2: false,//显示协议
      }
    },
    mounted() {
      let that = this
      document.onkeyup = function (event) {

        if (event.key === "Enter") {
        console.log(that.showXy1 ,that.showXy2)
          if (that.account && that.password ) {
            that.login()
          }
        }
      };
    },
    methods: {
      forget() { //忘记密码页面
        this.$emit('forgetPwd', true)
      },
      login() { //使用密码登录
        if (!this.isAgree) {
          this.$alert('请阅读并同意协议内容', { type: 'error' })
          return;
        }
        /*this.$all.isPhone()//验证手机号*/
        this.$axios('POST', '/api/user/login', {
          account: this.account,
          password: this.password
        }).then(res => {
          if (res.code == '1') {
            this.token = res.data.userinfo.token //存入token
            this.userInfo = res.data.userinfo //存入信息
            this.$store.commit('set_token', this.token)
            this.$store.commit('setUserInfo', this.userInfo)
            //存入登录的账号和密码(记住密码)
            this.$store.commit('setAccount', this.account)
            console.log(this.remember);
            if (this.remember) {
              this.$store.commit('setPwd', this.password)
            } else {
              this.$store.commit('delPwd')
            }
            //开启页面滚动
            let m = function (e) { e.preventDefault(); };
            document.body.style.overflow = '';//出现滚动条
            document.removeEventListener("touchmove", m, { passive: true });
            this.$message.success('登录成功')
          } else {
            this.$alert(res.msg, { type: 'error' })
          }
        }).catch(err => {
          console.log(err);
        })
      },
      getConfig() { //获取协议内容
        this.$axios("POST", "/api/index/getConfing", {})
          .then(res => {
            if (res.code == 1) {
              this.yszc = res.data.yszc
              this.fwxy = res.data.fwxy
            }
          })
      },
    },
    created() {
      this.getConfig()
      console.log('进入');
      let account = localStorage.getItem('yizhibangac')
      let password = localStorage.getItem('yizhibangp')
      if (account) {
        this.account = account
      }
      if (password) {
        this.password = password
        this.remember = true
      }
    }
  }
</script>

<style scoped>
  .login {
    margin-top: 30px;
  }

  div {
    line-height: normal
  }

  input {
    font-size: 16px;
    width: 100%;
    height: 56px;
    border: 1px solid #000;
    border-radius: 10px;
    box-sizing: border-box;
    padding-left: 20px;
  }

  ::v-deep .el-input input.el-input__inner {
    height: 56px;
    border-radius: 10px;
  }

  .button {
    margin-top: 28px;
    width: 360px;
    height: 56px;
    background: #3A54FF;
    border-radius: 8px;
    font-size: 24px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
</style>