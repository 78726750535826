<template>
<!--  行业分类-->
  <div class="industry">
    <div class="warp">
      <ul v-for="(item,index) in list" :key="index" class="list dif difw">
        <li class="item difcac active colfff">{{item.name}}</li>
        <li v-for="(item2,index2) in item.sonInfo" :key="index2+10" class="item difcac">
          <a href="##">{{item2.name}}</a>
        </li>

        <li v-for="(item3,index3) in empty(item.sonInfo)" :key="index3+20" class="empty">
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
  export default {
    name: "industry",
    data() {
      return {

      }
    },
    computed: {
      empty() { //计算当前行空余位置
        return function (item) {
          let c = Math.ceil((item.length)/15)
          return (c * 14) - item.length
        }
      }
    },
    created() {

    }
  }
</script>

<style scoped>

  .industry{
    width: 1334px;
    margin: 0 auto;
    margin-top: 60px;
  }
  .industry .warp{
    margin: 0 auto;
  }

  .item{
    height: 20px;
    padding: 13px 5px;
    border-radius: 17px;
    line-height: 20px;
    text-align: center;
    border: 1px solid #D1DFE9;
    margin-right: 10px;
    margin-bottom: 20px;
  }
  .empty{
    box-sizing: border-box;
    width: 76px;
    height: 28px;
    border-radius: 17px;
    border: 1px solid #F2F6F9;
    background: #F2F6F9;
    margin-right: 10px;
    margin-bottom: 20px;
  }
  .item a{
    color: #185F95;
    font-size: 16px;
  }
  .item:hover{
    background: #004F8A;
  }
  .item:hover a{
    color: white;
  }

  .item.active{
    background: #004F8A;
  }
  .item.active a{
    color: white;
  }

</style>