<template>
  <!--分类招聘-->
  <div class="zhoapin">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "zhaopinCenter"
}
</script>

<style scoped>

</style>