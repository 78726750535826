import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/oneoneBootstrap.css'
import request from "@/assets/js/axios";
import all from "@/assets/js/all";
import './plugins/element.js'
//滑动验证
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);
//分享
import Share from 'vue-social-share'
import 'vue-social-share/dist/client.css';
import '../node_modules/social-share.js/dist/js/social-share.min.js'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard) //剪贴板
Vue.use(Share)
/*import vshare from 'vshare'
Vue.use(vshare)*/

Vue.prototype.$axios = request;
Vue.prototype.$all = all;
// axios.defaults.withCredentials=true;
window.eventBus = new Vue();
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
