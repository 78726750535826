<template>
  <div class="padding customized">
    <!-- 我的定制 -->
    <div class="dif alc mrBtm30">
      <h1 class="fs16 col333 bold tlt">我的定制</h1>
    </div>
    <!--分类-->
    <div class="dif alc">
      <div class="fs14 col333 dif alc">
        <p style="color: #f00">*</p>
        <p class="mrLft10">分类</p>
        <div style="width: 360px;margin-left: 15px;">
          <el-select v-model="value" placeholder="请选择" class="w100">
            <el-option
                v-for="item in 3"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!--标题-->
    <div class="dif alc" style="margin-top: 30px;">
      <p class="fs14 col333 mrRit20 mrLft10">标题</p>
      <div style="width: 400px;">
        <el-input placeholder="请输入标题"></el-input>
      </div>
      <p class="fs14 col333 mrLft30 mrRit20">标题属性</p>
      <div class="dif alc">
        <!--默认-->
        <div class="dif alc pointer mrRit20" @click="titleType=1">
          <img src="@/assets/image/yizhibang/51close.png" style="width: 16px;height: 16px;" v-if="titleType===1">
          <img src="@/assets/image/yizhibang/51noclose.png" style="width: 16px;height: 16px;" v-else>
          <p class="mrLft10 fs14 col999">默认</p>
        </div>
        <!--加粗-->
        <div class="dif alc pointer mrRit20" @click="titleType=2">
          <img src="@/assets/image/yizhibang/51close.png" style="width: 16px;height: 16px;" v-if="titleType===2">
          <img src="@/assets/image/yizhibang/51noclose.png" style="width: 16px;height: 16px;" v-else>
          <p class="mrLft10 fs14 col999">加粗</p>
        </div>
        <!--斜体-->
        <div class="dif alc pointer" @click="titleType=3">
          <img src="@/assets/image/yizhibang/51close.png" style="width: 16px;height: 16px;" v-if="titleType===3">
          <img src="@/assets/image/yizhibang/51noclose.png" style="width: 16px;height: 16px;" v-else>
          <p class="mrLft10 fs14 col999">斜体</p>
        </div>
      </div>
    </div>
    <!--关键字-->
    <div class="" style="margin-top: 30px;">
      <p class="fs14 col333 mrLft10 mrRit10 tlt mrBtm10">关键字</p>
      <div style="width: 768px;" class="mrLft10">
        <el-input placeholder="请填写关键字" type="textarea" rows="5"></el-input>
      </div>
    </div>
    <!--上传商标选择地-->
    <div class="mrLft10" style="margin-top: 30px;">
      <p class="fs14 col333 mrLft10 mrRit10 tlt mrBtm10">请上传缩略图</p>
      <div class="posr dif">
        <el-upload
            class="avatar-uploader"
            action="https://yzb.yzbse.com/api/common/upload"
            :show-file-list="false"
            :on-success="onsuccess"
            :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl" :src="imageUrl" style="width: 186px;height: 126px;">
          <div class="posr" v-else>
            <img src="@/assets/image/yizhibang/69upload_logo.png" style="width: 186px;height: 126px;">
            <p class="fs12 posa" style="color: #B3B3B3;left: 0;right: 0;top: 82px;">请上传缩略图</p>
          </div>
        </el-upload>

      </div>

    </div>
    <!--发布时间-->
    <div class="dif alc" style="margin-top: 50px;">
      <!--发布时间-->
      <div class="dif alc mrRit30">
        <p style="color: #f00">*</p>
        <p class="mrRit10">选择发布时间</p>
        <div style="width: 400px;">
          <el-date-picker
              style="width: 400px;"
              v-model="date"
              type="datetime"
              value-format="timestamp"
              placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <!--文章模板-->
      <div class="dif alc mrRit30">
        <p style="color: #f00">*</p>
        <p class="mrRit10">选择文章模板</p>
        <div style="width: 400px;">
          <el-select v-model="value" placeholder="请选择文章模板" class="w100">
            <el-option
                v-for="item in 3"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!--富文本-->
    <div style="margin-top: 30px;margin-bottom: 50px;">
      <quill-editor ref="myTextEditor" v-model="content" :options="editorOption" style="height:300px;"></quill-editor>
    </div>
    <!--取消和发布-->
    <div class="difcac">
      <div class="difcac msg_button1 colfff pointer">取消</div>
      <div class="difcac msg_button2 colfff pointer">发布</div>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor';
export default {
  props: {
    LeftHeaderType: [String, Number],
  },
  components: {
    quillEditor
  },
  data() {
    return {
      editorOption: { //富文本配置
        placeholder: '编辑文章内容'
      },
      pageHeight:document.documentElement.clientHeight,//获取页面高度
      titleType: 1,//标题属性 1默认 2加粗 3斜体
      imageUrl: '',//图标
      content: '',//富文本内容
    }
  },
  methods: {
    handleRemove(file, fileList) { //删除文件
      let list = []
      for (let i = 0;i<fileList.length;i++) {
        list.push(fileList[i].response.data.fullurl);
      }
      this.fileList = list
    },
    onsuccess(file) { //上传成功
      this.imageUrl = file.data.fullurl
      this.$forceUpdate()
      console.log(this.imageUrl);
    },
    beforeAvatarUpload(file) {//限制图片大小和格式
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传封面图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传封面图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    onEditorChange(i,{ editor, html, text }) { //富文本
      console.log(i);
    },
  }
};
</script>

<style scoped>
.customized ::v-deep .el-input__inner{
  height: 40px;
  border-radius: 10px;
}

.padding {
  padding: 30px;
}
  input,select {
    padding: 10px;
    border: 0.5px solid #c2c2c2;
    border-radius: 0.5rem;
    width: 80%;
  }
.form_title {
  margin-right: 10px;
}
/*上传图片*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

/*发布*/
.msg_button1{
  width: 226px;
  height: 50px;
  background: #CCCCCC;
  border-radius: 6px;
}
.msg_button2{
  width: 226px;
  height: 50px;
  background: #3A54FF;
  border-radius: 6px;
  margin-left: 40px;
}
</style>