<template>
  <!--个人中心-->
  <div class="container" v-loading="isLoading">
    <div class="user_imgs rows" >
        <div class="tct dif" >
            <img :src="avatar" class="user_image bra" style="width: 81px;height: 81px;margin-right: 17px;">
            <div>
              <!--用户名-->
              <div class="fs14 col333 dif alc mrTop10">
                <!--用户名-->
                <span>{{userName}}</span>
                <!--vip等级-->
                <div class="user_vip" v-if="userInfo.is_pay==1">
                  <img src="@/assets/image/yizhibang/45vip_bg.png" style="width: 33px;height: 15px;">
                  <span class="fs12 colfff posr" style="font-style: italic;">VIP</span>
                </div>
                <!--企业会员-->
                <!--<div class="dif alc mrLft10" v-if="userInfo.vip_type==1">
                  <img src="@/assets/image/yizhibang/45authentication.png" style="width: 15px;height: 15px;">
                  <span style="color: #3535f3;margin-left: 5px;">企</span>
                </div>-->
                <!--企业会员-->
                <div class="difa alc mrLft10 user_vip" style="width: 40px;" v-if="userInfo.vip_type==1">
                  <img src="@/assets/image/yizhibang/45card_bg.png" style="width: 100%;height: 15px;">
                  <img src="@/assets/image/yizhibang/45authentication.png" style="width: 15px;height: 15px;margin-left: 3px;">
                  <div style="color: #FFFFFF;margin-left: 12px;position:relative;font-size: 12px;">企</div>
                </div>
                <!--个人名片-->
                <div class="user_card" >
                  <img src="@/assets/image/yizhibang/45card_bg.png" style="width: 58px;height: 15px;">
                  <span class="fs12 colfff posr pointer" style="font-style: italic;" @click="setShowCard()">个人名片</span>
                  <!--个人名片-->
                  <div class="card2" v-if="showCard" @mouseleave="showCard=false">
                    <div class="card" ref="imageTofile" >
                      <div class="dif alc">
                        <div class="user_share" data-html2canvas-ignore @click="barPhoto()">分享</div>
                        <!--头像-->
                        <img :src="avatar2"  style="width: 81px;height: 81px;" class="bra">
                        <div style="margin-left: 17px;">
                          <!--用户名-->
                          <div class="tlt">
                            <span class="fs24 col333 bold">{{userName}}</span>
                            <span class="fs18 col3765FF bold mrLft10">{{duty}}</span>
                          </div>
                          <!--个人签名-->
                          <div class="col999 fs12 tlt mrTop20" style="max-width: 400px" v-if="is_qianming==1">{{bio}}</div>
                        </div>
                      </div>
                      <div class="card_cont mrTop30">
                        <ul class="tlt fs12 col999 dif difw">
                          <li class="mrBtm10" v-if="is_gender==1">性别: {{gender}}-{{constellation}}</li>
                          <li class="mrBtm10" v-if="is_birthday==1">生日: {{birthday}}</li>
                          <!--<li>公司职务: {{duty}}</li>-->
                          <li class="mrBtm10" v-if="is_landline==1">座机: {{landline}}</li>
                          <li class="mrBtm10" v-if="is_phone==1">手机: {{mobile}}</li>
                          <li class="mrBtm10" v-if="is_qq==1">QQ:{{qqnum}}</li>
                          <li class="mrBtm10" v-if="is_wx==1">微信: {{wechat}}</li>
                          <li class="mrBtm10" v-if="is_school==1">毕业院校:{{academy}}</li>
                          <li class="mrBtm10" v-if="is_email==1">邮箱: {{email}}</li>
                          <li class="mrBtm10">所在地点: {{province_name}}--{{city_name}}</li>
                          <li class="mrBtm10" v-if="is_industry==1">所属行业: {{industry}}</li>
                          <li class="mrBtm10">所属公司/组织: {{company}}</li>
                          <!--<li class="mrBtm10" style="width: 100%;" v-if="is_qianming==1">个人签名: {{bio}}</li>-->
                        </ul>
                        <!--<ul class="tlt fs12 col999">-->

                        <!--</ul>-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col333  fs12 tlt" style="margin-top: 5px;">点击下方眼睛，生成个人的DIY名片</div>
              <!--链接分享-->
              <!--<div class="dif alc mrTop20">
                <span class="fs12 col999">链接分享</span>
                <img src="@/assets/image/yizhibang/45qq.png" style="width: 20px;height: 20px;margin: 0 7px;">
                <img src="@/assets/image/yizhibang/45qzone.png" style="width: 20px;height: 20px;">
                <img src="@/assets/image/yizhibang/45sina.png" style="width: 20px;height: 20px;margin-left: 7px;">
              </div>-->
            </div>
        </div>
    </div>

    <div class="w100 mrAt fs14 col333">
      <div class="borBtmdd dif alc pdBtm20 pdTop20 pdLft10 tlt">
        <img class="hideImg" src="@/assets/image/enterprise/icon_show.png" v-if="is_qianming==1" @click="is_qianming=0;saveInfo()"/>
        <img class="hideImg" src="@/assets/image/enterprise/icon_hid.png" v-else @click="is_qianming=1;saveInfo()"/>
        <div style="max-width: 800px;word-break: break-all" class="mrLft10">
          个人签名：{{bio}}
        </div>
      </div>
      <div class="borBtmdd pdBtm20 pdTop20 rows alc pdLft10">
        <div class="dif alc">
          <img class="hideImg" src="@/assets/image/enterprise/icon_show.png" v-if="is_gender==1" @click="is_gender=0;saveInfo()"/>
          <img class="hideImg" src="@/assets/image/enterprise/icon_hid.png" v-else @click="is_gender=1;saveInfo()"/>
          <div style="margin-right: 100px;" class="mrLft10">性别: {{gender}}</div>
        </div>
        <div class="dif alc">
          <img class="hideImg" src="@/assets/image/enterprise/icon_show.png" v-if="is_birthday==1" @click="is_birthday=0;saveInfo()"/>
          <img class="hideImg" src="@/assets/image/enterprise/icon_hid.png" v-else @click="is_birthday=1;saveInfo()"/>
          <div class="mrLft10">生日星座: {{birthday}} {{constellation}}</div>
        </div>
      </div>
      <div class="borBtmdd pdBtm20 pdTop20 rows pdLft10">
        <div style="margin-right: 100px;" class="tlt">所属公司/组织: {{company}}</div>
        <div class="dif alc">
          <img class="hideImg" src="@/assets/image/enterprise/icon_show.png" v-if="is_school==1" @click="is_school=0;saveInfo()"/>
          <img class="hideImg" src="@/assets/image/enterprise/icon_hid.png" v-else @click="is_school=1;saveInfo()"/>
          <div class="mrLft10">毕业院校: {{academy}}</div>
        </div>
      </div>
      <div class="borBtmdd pdBtm20 pdTop20 rows alc pdLft10">
        <div class="dif alc">
          <img class="hideImg" src="@/assets/image/enterprise/icon_show.png" v-if="is_industry==1" @click="is_industry=0;saveInfo()"/>
          <img class="hideImg" src="@/assets/image/enterprise/icon_hid.png" v-else @click="is_industry=1;saveInfo()"/>
          <div style="width: 200px;" class="tlt one_overflow mrLft10">所属行业: {{industry}}</div>
        </div>
        <div class="pdLft30 mrLft30 tlt one_overflow" style="width: 200px;">公司职务: {{duty}}</div>
        <div class="pdLft30 mrLft30 tlt one_overflow" style="width: 200px;">所在地点: {{province_name}} - {{city_name}}</div>
      </div>
      <div class="borBtmdd pdBtm20 pdTop20 rows alc pdLft10">
        <div class="dif alc">
          <img class="hideImg" src="@/assets/image/enterprise/icon_show.png" v-if="is_landline==1" @click="is_landline=0;saveInfo()"/>
          <img class="hideImg" src="@/assets/image/enterprise/icon_hid.png" v-else @click="is_landline=1;saveInfo()"/>
          <div style="width: 200px;" class="one_overflow tlt mrLft10">座机: {{landline}}</div>
        </div>
        <div class="dif alc">
          <img class="hideImg" src="@/assets/image/enterprise/icon_show.png" v-if="is_phone==1" @click="is_phone=0;saveInfo()"/>
          <img class="hideImg" src="@/assets/image/enterprise/icon_hid.png" v-else @click="is_phone=1;saveInfo()"/>
          <div class=" mrLft10 tlt one_overflow" style="width: 200px;">手机: {{mobile}}</div>
        </div>
        <div class="dif alc">
          <img class="hideImg" src="@/assets/image/enterprise/icon_show.png" v-if="is_email==1" @click="is_email=0;saveInfo()"/>
          <img class="hideImg" src="@/assets/image/enterprise/icon_hid.png" v-else @click="is_email=1;saveInfo()"/>
          <div class=" mrLft10 tlt one_overflow"  style="width: 250px;">邮箱: {{email}}</div>
        </div>
      </div>
      <div class="borBtmdd pdBtm20 pdTop20 rows pdLft10">
        <div class="dif alc">
          <img class="hideImg" src="@/assets/image/enterprise/icon_show.png" v-if="is_qq==1" @click="is_qq=0;saveInfo()"/>
          <img class="hideImg" src="@/assets/image/enterprise/icon_hid.png" v-else @click="is_qq=1;saveInfo()"/>
          <div style="width: 200px;" class="tlt mrLft10">QQ: {{qqnum}}</div>
        </div>
        <div class="dif alc">
          <img class="hideImg" src="@/assets/image/enterprise/icon_show.png" v-if="is_wx==1" @click="is_wx=0;saveInfo()"/>
          <img class="hideImg" src="@/assets/image/enterprise/icon_hid.png" v-else @click="is_wx=1;saveInfo()"/>
          <div class="mrLft10 tlt">微信: {{wechat}}</div>
        </div>
      </div>
      <div class="pdBtm20 pdTop20  pdLft10 tlt">
        主营介绍：{{introduce}}
      </div>
    </div>
    
  </div>
</template>

<script>
  import html2canvas from'html2canvas'
  import {provinceAndCityData,CodeToText,TextToCode } from 'element-china-area-data'
  export default {
    data() {
      return {
        userInfo: {
          is_pay: 0,//0用户 1会员
        },
        showCard: false,
        isLoading: true,
        pageHeight:document.documentElement.clientHeight,//获取页面高度
        userName:'',//用户名
        userinfo: {},//用户信息
        avatar: '',//头像
        avatar2: '',//头像
        gender: 0,//性别 0女 1男
        birthday: '',//生日
        constellation: '',//星座
        duty: '',//职位
        bio: '',//个人签名
        industry: '',//所属行业
        academy: '',//毕业院校
        company: '',//公司租住
        landline: 0,//座机号
        mobile: 0,//手机号
        email: '',//邮箱
        qqnum: 0,//QQ号
        wechat: '',//微信号
        introduce: '',//主营
        city_name: '',//城市名称
        province_name: '',//省市名称
        is_birthday: 1,//是否显示生日
        is_email: 1,//是否显示邮箱
        is_gender: 1,//是否显示性别
        is_industry: 1,//是否显示行业
        is_landline: 1,//是否显示座机
        is_phone: 1,//是否显示电话
        is_qianming: 1,//是否显示签名
        is_qq: 1,//是否显示qq
        is_school: 1,//是否显示学校
        is_wx: 1,//是否显示微信
        imgUrl: '',//截取的图片路径
      }
    },
    created(event) {
      this.getUserInfo()
      this.getAvatar()
      //获取用户个人信息
      this.$axios("POST","/api/member/personalCenter",{})
      .then(res => {
        this.isLoading = false
        let userinfo = res.data
            this.userName = userinfo.username
            this.birthday= userinfo.birthday
            this.avatar= userinfo.avatar
            this.gender = userinfo.gender
            this.birthday= userinfo.birthday
            this.constellation= userinfo.constellation
            this.duty= userinfo.duty
            this.bio= userinfo.bio
            this.industry= userinfo.industry
            this.academy= userinfo.academy
            this.company= userinfo.company
            this.landline= userinfo.landline
            this.mobile= userinfo.mobile
            this.email= userinfo.email
            this.qqnum= userinfo.qqnum
            this.wechat= userinfo.wechat
            this.city_name = userinfo.city_name
            this.province_name = userinfo.province_name
            this.introduce = userinfo.introduce
            this.is_birthday = userinfo.is_birthday
            this.is_email = userinfo.is_email
            this.is_gender = userinfo.is_gender
            this.is_industry = userinfo.is_industry
            this.is_landline = userinfo.is_landline
            this.is_phone = userinfo.is_phone
            this.is_qianming = userinfo.is_qianming
            this.is_qq = userinfo.is_qq
            this.is_school = userinfo.is_school
            this.is_wx = userinfo.is_wx
      })
    },
    methods:{
      getAvatar() { //获取用户头像
        this.$axios("POST","/api/member/getBaseAvatar",{})
        .then(res => {
          this.avatar2 = res.data
        })
      },
      saveInfo() {
        this.$axios("POST","/api/Member/userStatusSave",{
            is_birthday: this.is_birthday,
            is_email: this.is_email,
            is_gender: this.is_gender,
            is_industry: this.is_industry,
            is_landline: this.is_landline,
            is_phone: this.is_phone,
            is_qianming: this.is_qianming,
            is_qq: this.is_qq,
            is_school: this.is_school,
            is_wx: this.is_wx,
          })
        .then(res=> {

        })
      },
      setShowCard() { //设置分享名片
        this.showCard = !this.showCard
        return;
        if(this.userInfo.is_pay==0) {
          this.$alert('开通会员即可生成电子名片',{type: 'info'}).then(res => {
            this.$router.push({
              path: '/meCenter',
              query:{
                leftSelect:1,
                contentType: 2,
                time: new Date().getTime()
              }
            }).catch(err => err)
          })
          return
        }else {

        }
      },
      getUserInfo() { //获取用户信息
        this.$axios("POST","/api/user/getUser",{})
        .then(res => {
          this.userInfo = res.data
        })
      },
      barPhoto() {
        //指定在500毫秒后执行toImage
        this.$message({
          showClose: true,
          message: '正在生成图片...'
        });
        setTimeout(()=>{this.toImage()}, 500);
      },
      toImage() {
        // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
        html2canvas(this.$refs.imageTofile, {
          backgroundColor: '#FFFFFF',
          useCORS: true, // 如果截图的内容里有图片,可能会有跨域的情况,加上这个参数,解决文件跨域问题
          proxy: 'https://yzb.yzbse.com'
        }).then(canvas => {
          let url = canvas.toDataURL("image/png");
          this.imgUrl = url.split(',')[1];
          // window.open(url)
          // window.location.href(this.imgUrl)
          // 把生成的base64位图片上传到服务器,生成在线图片地址
          this.sendUrl();
        }).catch(err => {
          this.$message({
            showClose: true,
            message: '生成失败，请稍后重试',
            type: 'error'
          });
        })
      },
      sendUrl() {
        // // 如果图片需要formData格式,就自己组装一下,主要看后台需要什么参数
        const formData = new FormData();
        formData.append("image", this.imgUrl);
        this.upload("POST","/api/Member/getBaser",formData)
        .then(res => {
          if(res.code===1) {
            window.open(res.data) //打开图片链接
          }else {
            this.$message({
              showClose: true,
              message: '生成失败，请稍后重试',
              type: 'error'
            });
          }
        })
      },
      emailCode() {
        this.$axios("POST","/api/Mailer/Mail",{})
        .then(res => {
        })
      },
      upload(method,url,data) {
        let baseUrl = 'https://yzb.yzbse.com'
        let token = localStorage.getItem('yizhibang')?localStorage.getItem('yizhibang'):'';//获取token
        return new Promise((resolve,reject)=>{
          //1创建异步对象
          const xhr = new XMLHttpRequest();
          //4创建监听 接收响应
          xhr.onreadystatechange=()=>{
            if(xhr.readyState==4 && xhr.status==200){
              let result = JSON.parse(xhr.responseText);
              resolve(result);//返回出去
            };
          };
          //2创建请求 打开连接
          url = baseUrl + url
          xhr.open(method,url,true);
          // xhr.setRequestHeader("Content-Type","application/x-www-form-urlencoded");
          xhr.setRequestHeader("token",token);

          //3发送请求
          xhr.send(data);
        })
      },
      // 转为base64图片
      imageUrlToBase64() {
        //一定要设置为let，不然图片不显示
        let image = new Image();
        //解决跨域问题
        image.setAttribute('crossOrigin', 'anonymous');
        let imageUrl = this.avatar;
        image.src = imageUrl
        let that = this;
        //image.onload为异步加载
        image.onload = () => {
          var canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          var context = canvas.getContext('2d');
          context.drawImage(image, 0, 0, image.width, image.height);
          var quality = 0.8;
          //这里的dataurl就是base64类型
          var dataURL = canvas.toDataURL("image/jpeg", quality);//使用toDataUrl将图片转换成jpeg的格式,不要把图片压缩成png，因为压缩成png后base64的字符串可能比不转换前的长！
          console.log(dataURL);
          this.that = dataURL
        }
      },
    }
  }
</script>

<style scoped>
  .container {
    width: 95%;
    margin-left: 21px;
  }
  .user_imgs {
    margin-top: 36px;
    margin-bottom: 30px;
    margin-left: 26px;
  }
  .user_image {
    width: 110px;
  }
  .hideImg{
    width: 23px;
    height: auto;
  }
  textarea{
    resize: none;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
  }
  /*个人名片*/
  .user_card:hover .card{
    /*display: block;*/
  }
  .card2{
    /*display: none;*/
    width: 560px;
    /*height: 340px;*/
    background: #ffffff;
    box-shadow: 0 0 28px 2px rgba(204, 204, 204, 0.5);
    border-radius: 6px;
    box-sizing: border-box;
    position: absolute;
    left: 70%;
    transform: translateX(-50%);
    top: 26px;
  }
  .card{
    width: 100%;
    height: 100%;
    padding: 29px 35px;
  }
  .card::before{
    display: block;
    content: '';
    border-bottom: 14px solid #FFFFFF;
    border-left: 10px solid rgba(0,0,0,0);
    border-right: 10px solid rgba(0,0,0,0);
    position: absolute;
    left: 48%;
    transform: translateX(-50%);
    top: -12px;
  }
  .user_share{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 45px;
    height: 21px;
    background: #F29424;
    border-radius: 11px;
    font-size: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .card_cont{
    padding: 0 10px;
  }
  .card_cont li{
    font-size: 12px;
    margin-top: 7px;
    width: 50%;
  }

  .user_vip{
    width: 33px;
    height: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7px;
  }
  .user_card{
    width: 58px;
    height: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 13px;
  }
  .user_card>img,.user_vip img{
    position: absolute;
    left: 0;
    top: 0;
  }
</style>
