<template>
  <!--生成官网-->
  <div class="padding container" :style="{'height':pageHeight-110+'px'}">
    <!--顶部选项-->
    <ul class="dif alc col7b top_class">
      <li class="mrRit20 active fs16 col666"><h3>生成官网</h3></li>
      <li class="mrRit20 fs16 col666 pointer" @click="goCheck()">查看已生成官网</li>
    </ul>
    <!--上传-->
    <div class="dif alc" style="margin-top: 50px;">
      <div class="posr">
        <el-upload
            class="avatar-uploader"
            action="https://yzb.yzbse.com/api/common/upload"
            :show-file-list="false"
            :on-success="onsuccess"
            :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl" :src="imageUrl" style="width: 186px;height: 126px;border-radius: 15px">
          <div class="posr" v-else>
            <img src="@/assets/image/yizhibang/69upload_logo.png" style="width: 186px;height: 126px;">
            <p class="fs12 posa" style="color: #B3B3B3;left: 0;right: 0;top: 82px;">请上传商标图片</p>
          </div>
          <div class="fs12">请上传136*50尺寸的图片</div>
        </el-upload>

      </div>
      <div class="mrLft30">
        <!--名称全称-->
        <div class="dif alc tlt mrBtm20">
          <p class="mrRit10 fs14 col333" style="width: 90px;">名称</p>
          <p class="fs14 col999">{{company.sitename}}</p>
        </div>
        <!--关键词-->
        <div class="dif mrTop10 tlt">
          <p class="mrRit10 fs14 col333" style="width: 90px;">关键词</p>
          <p class="col999 fs14" style="max-width: 600px">{{company.keyword}}</p>
        </div>

      </div>
    </div>
    <!--选择-->
    <div class="mrTop30 tlt">
      <div class="dif alc">
        <img src="@/assets/image/yizhibang/16left_logo.png" style="width: 98px;height: 35px;">
        <p class="fs14 col333 mrLft20">请点击下方色块，选择适合您的颜色~</p>
      </div>
      <div style="width: 1090px;height: 36px;" :style="{'background': color}"
           class="mrTop20 difa alc">
        <!--<p class="fs14 colfff nav_active">首页</p>-->
        <p class="fs14 colfff nav_active">公司简介</p>
        <p class="fs14 colfff">实时资讯</p>
        <p class="fs14 colfff">内部需求</p>
        <p class="fs14 colfff">产品店铺</p>
        <p class="fs14 colfff">品牌加盟</p>
        <p class="fs14 colfff">招聘菜单</p>
        <p class="fs14 colfff">软件下载</p>
        <p class="fs14 colfff">联系我们</p>
      </div>
      <div class="dif alc">
        <el-color-picker v-model="color" class="mrTop10"></el-color-picker>
        <p class="fs12 col333 mrLft20">请选择合适的颜色</p>
      </div>
    </div>
    <!--上传图片 -->
    <div class="tlt mrTop30">
      <div style="width: 1090px;">
        <el-carousel indicator-position="outside" height="300">
          <el-carousel-item v-for="(item,index) in swiper" :key="item">
            <img :src="$store.state.url+item" class="w100 h100">
          </el-carousel-item>
          <el-carousel-item v-if="swiper.length<=0">
            <h2 class="difcac w100 h100">
              请上传轮播图
            </h2>
          </el-carousel-item>
        </el-carousel>
      </div>
      <p class="col333 fs14 mrBtm30 mrTop20">通栏广告编辑上传  请上传大小1920*500像素的图片  照片图片格式为JPG、PNG格式，图片大小不超过5MB</p>
      <div class="dif alc">
        <div class="posr mrRit20" v-for="(item,index) in swiper" :key="index">
          <div @click="del(index)" class="posa" style="top: 10px;right: 10px;">
            <i class="fs16 el-icon-delete-solid" style="color: #fa3534"></i>
          </div>
          <img  :src="$store.state.url+item" style="width: 186px;height: 126px;border-radius: 15px">
        </div>
        <div class="posr" v-if="swiper.length<5">
          <el-upload
              class="avatar-uploader"
              action="https://yzb.yzbse.com/api/common/upload"
              :show-file-list="false"
              :on-success="uploadSwiper"
              :before-upload="beforeAvatarUpload">
            <div class="posr">
              <img src="@/assets/image/yizhibang/69upload_logo.png" style="width: 186px;height: 126px;">
              <p class="fs12 posa" style="color: #B3B3B3;left: 0;right: 0;top: 82px;">请上传轮播图</p>
            </div>
          </el-upload>
        </div>
      </div>
    </div>
    <!--具体选项-->
    <div class=" col7b mrTop30">
      <p class="fs14 tlt col333 bold">对应名称</p>
      <ul class="tlt dif difw name">
        <li class="dif alc mrTop20" @click="is_info==1?is_info=0:is_info=1">
          <p class="mrRit30 fs14 col3765FF">信息发布————公司简介 是否官网显示</p>
          <div class="difcc pointer mrRit10 check">
            <img src="@/assets/image/yizhibang/63yes.png" style="width: 10px;height: 8px;" v-if="is_info==1">
          </div>
          <p class="fs14 col333">是否官网显示</p>
        </li>
        <li class="dif alc mrTop20" @click="is_message==1?is_message=0:is_message=1">
          <p class="mrRit30 fs14 col3765FF">资讯发布————实时资讯 是否官网显示</p>
          <div class="difcc pointer mrRit10 check">
            <img src="@/assets/image/yizhibang/63yes.png" style="width: 10px;height: 8px;" v-if="is_message==1">
          </div>
          <p class="fs14 col333">是否官网显示</p>
        </li>
        <li class="dif alc mrTop20" @click="is_demand==1?is_demand=0:is_demand=1">
          <p class="mrRit30 fs14 col3765FF">需求发布————内部需求 是否官网显示</p>
          <div class="difcc pointer mrRit10 check">
            <img src="@/assets/image/yizhibang/63yes.png" style="width: 10px;height: 8px;" v-if="is_demand==1">
          </div>
          <p class="fs14 col333">是否官网显示</p>
        </li>
        <li class="dif alc mrTop20" @click="is_store==1?is_store=0:is_store=1">
          <p class="mrRit30 fs14 col3765FF">店铺发布————产品店铺 是否官网显示</p>
          <div class="difcc pointer mrRit10 check">
            <img src="@/assets/image/yizhibang/63yes.png" style="width: 10px;height: 8px;" v-if="is_store==1">
          </div>
          <p class="fs14 col333">是否官网显示</p>
        </li>
        <li class="dif alc mrTop20" @click="is_league==1?is_league=0:is_league=1">
          <p class="mrRit30 fs14 col3765FF">加盟发布————品牌加盟 是否官网显示</p>
          <div class="difcc pointer mrRit10 check">
            <img src="@/assets/image/yizhibang/63yes.png" style="width: 10px;height: 8px;" v-if="is_league==1">
          </div>
          <p class="fs14 col333">是否官网显示</p>
        </li>
        <li class="dif alc mrTop20" @click="is_recruit==1?is_recruit=0:is_recruit=1">
          <p class="mrRit30 fs14 col3765FF">招聘发布————招聘菜单 是否官网显示</p>
          <div class="difcc pointer mrRit10 check">
            <img src="@/assets/image/yizhibang/63yes.png" style="width: 10px;height: 8px;" v-if="is_recruit==1">
          </div>
          <p class="fs14 col333">是否官网显示</p>
        </li>
        <li class="dif alc mrTop20" @click="is_app==1?is_app=0:is_app=1">
          <p class="mrRit30 fs14 col3765FF">软件发布————软件下载 是否官网显示</p>
          <div class="difcc pointer mrRit10 check">
            <img src="@/assets/image/yizhibang/63yes.png" style="width: 10px;height: 8px;" v-if="is_app==1">
          </div>
          <p class="fs14 col333">是否官网显示</p>
        </li>
      </ul>
      <p class="fs14 tlt col333 bold mrTop30">联系我们</p>
      <ul class="tlt mrTop30">
        <li class="dif alc mrBtm20">
          <p class="f14 col333 mrRit20">手机</p>
          <div style="width: 360px;">
            <el-input placeholder="请输入您的电话" v-model="tel"></el-input>
          </div>
          <div class="difcc pointer mrRit10 check mrLft30" @click="is_tel==1?is_tel=0:is_tel=1">
            <img src="@/assets/image/yizhibang/63yes.png" style="width: 10px;height: 8px;" v-if="is_tel==1">
          </div>
          <p class="fs14 col333">是否官网显示</p>
        </li>
        <li class="dif alc mrBtm20">
          <p class="f14 col333 mrRit20">座机</p>
          <div style="width: 360px;">
            <el-input placeholder="请输入座机号" v-model="landline"></el-input>
          </div>
          <div class="difcc pointer mrRit10 check mrLft30" @click="is_landline==1?is_landline=0:is_landline=1">
            <img src="@/assets/image/yizhibang/63yes.png" style="width: 10px;height: 8px;" v-if="is_landline==1">
          </div>
          <p class="fs14 col333">是否官网显示</p>
        </li>
        <li class="dif alc mrBtm20">
          <p class="f14 col333 mrRit20" style="width: 32px;">QQ</p>
          <div style="width: 360px;">
            <el-input placeholder="请输入您的qq号码" v-model="qq"></el-input>
          </div>
          <div class="difcc pointer mrRit10 check mrLft30" @click="is_qq==1?is_qq=0:is_qq=1">
            <img src="@/assets/image/yizhibang/63yes.png" style="width: 10px;height: 8px;" v-if="is_qq==1">
          </div>
          <p class="fs14 col333">是否官网显示</p>
        </li>
        <li class="dif alc mrBtm20">
          <p class="f14 col333 mrRit20">微信</p>
          <div style="width: 360px;">
            <el-input placeholder="请输入您的微信号" v-model="wx"></el-input>
          </div>
          <div class="difcc pointer mrRit10 check mrLft30" @click="is_wx==1?is_wx=0:is_wx=1">
            <img src="@/assets/image/yizhibang/63yes.png" style="width: 10px;height: 8px;" v-if="is_wx==1">
          </div>
          <p class="fs14 col333">是否官网显示</p>
        </li>
        <li class="dif alc mrBtm20">
          <p class="f14 col333 mrRit20">邮箱</p>
          <div style="width: 360px;">
            <el-input placeholder="请输入您的邮箱" v-model="mail"></el-input>
          </div>
          <div class="difcc pointer mrRit10 check mrLft30" @click="is_mail==1?is_mail=0:is_mail=1">
            <img src="@/assets/image/yizhibang/63yes.png" style="width: 10px;height: 8px;" v-if="is_mail==1">
          </div>
          <p class="fs14 col333">是否官网显示</p>
        </li>
        <li class="dif alc mrBtm20">
          <p class="f14 col333 mrRit30">品牌</p>
          <p class="fs14 col333">{{ company.brand_title }}</p>
        </li>
        <li class="dif alc mrBtm20">
          <p class="f14 col333 mrRit30">公司</p>
          <p class="fs14 col333">{{company.enterprise}}</p>
        </li>
      </ul>
    </div>
    <!--取消和发布-->
    <div class="difcac" style="margin-top: 56px;">
      <div class="difcac msg_button1 colfff pointer" >取消</div>
      <div class="difcac msg_button2 colfff pointer" @click="generateWeb()">发布</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "createWeb",
  data() {
    return {
      topClass: [
        {id:0,name:'生成官网'},
        {id:1,name:'查看已生成官网'},
        {id:2,name:'分享官网'},
      ],
      isActive: 0,
      imageUrl: '',//商标图片
      color: '#3765FF',
      swiper: [],//轮播图
      is_info: 1, //信息介绍是否官网显示
      is_message: 1, //资讯发布是否官网显示
      is_demand: 1, //需求发布是否官网显示
      is_store: 1, //店铺发布是否官网显示
      is_league: 1, //加盟发布是否官网显示
      is_recruit: 1, //招聘发布是否显示
      is_app: 1, //软件发布是否显示
      tel:'',//手机号
      is_tel:1,//手机号是否显示
      landline:'',//座机
      is_landline:1,//座机是否显示
      qq:'',//qq
      is_qq:1,//qq是否显示
      wx:'',//wx号码
      is_wx:1,//是否wx
      mail:'',//邮箱
      is_mail:1,//是否邮箱
      company: {
        address: "",
        brand_title: "",
        enterprise: "",
        id: 0,
        keyword: "",
        sitename: "",
        uid: 0,
      },//自动显示的所属
    }
  },
  methods: {
    del(index) { //删除轮播图
      this.swiper.splice(index,1)
    },
    goCheck() { //查看生成的官网
      if(this.company.sid) {
        let path = this.$router.resolve({
          path: '/Web',
          query: {
            sid: this.company.sid,
            id: this.company.id
          }
        })
        window.open(path.href,'_blank')
      }else {
        this.$alert('暂无生成官网',{type: 'error'})
      }
    },
    getCompanyInfo() {
      this.$axios("POST","/api/Release/getBindInfo",{})
      .then(res => {
        if(res.code==1 && res.data) {
          this.company = res.data
          this.imageUrl = res.data.log_image
          this.color = res.data.top_color || '#3765FF'
          this.swiper = res.data.images.split(',')

          this.is_info = res.data.is_info//是否显示信息发布
          this.is_message = res.data.is_message//是否显示资讯
          this.is_demand = res.data.is_demand//显示需求
          this.is_store = res.data.is_store//显示店铺
          this.is_league = res.data.is_league//显示加盟
          this.is_recruit = res.data.is_recruit
          this.is_app = res.data.is_app
          this.tel = res.data.tel
          this.is_tel = res.data.is_tel
          this.landline = res.data.landline
          this.is_landline = res.data.is_landline
          this.qq = res.data.qq
          this.is_qq = res.data.is_qq
          this.wx = res.data.wx//微信
          this.is_wx = res.data.is_wx//显示微信
          this.mail = res.data.mail//邮箱
          this.is_mail = res.data.is_mail//显示油箱
        }
      })
    },
    generateWeb() { //生成官网
      let img = ''
      if(this.swiper.length>0) {
        img = this.swiper.join(',')
      }
      this.$axios("POST","/api/Release/GenerateSite",{
        log_image: this.imageUrl, //logo图片
        top_color: this.color, //官网顶部的颜色
        images: img,//banner
        is_info: this.is_info,//是否显示信息发布
        is_message: this.is_message,//是否显示资讯
        is_demand: this.is_demand,//显示需求
        is_store: this.is_store,//显示店铺
        is_league: this.is_league,//显示加盟
        is_recruit: this.is_recruit,//显示招聘
        is_app: this.is_app,//显示app
        tel: this.tel,//手机号
        is_tel: this.is_tel,//显示手机号
        landline: this.landline,//座机
        is_landline: this.is_landline,//显示座机
        qq: this.qq,//qq
        is_qq:this.is_qq,//显示qq
        wx: this.wx,//微信
        is_wx: this.is_wx,//显示微信
        mail: this.mail,//邮箱
        is_mail: this.is_mail,//显示油箱
      }).then(res => {
        if(res.code==1) {
          this.$alert('发布成功',{type: 'success'})
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    onsuccess(file){ //上传商标图片成功
      this.imageUrl = file.data.fullurl
    },
    uploadSwiper(file) { //上传轮播图
      this.swiper.push(file.data.url)
    },
    beforeAvatarUpload(file) {//限制图片大小和格式
      const isJPG = file.type == 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传封面图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传封面图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
  },
  created() {
    this.getCompanyInfo()
  }
}
</script>

<style scoped>
  .container {
    overflow: hidden;
    overflow-y: auto;
  }
  .padding{
    padding: 30px;
  }
  input {
    padding: 10px;
    border: 0.5px solid #c2c2c2;
    border-radius: 0.5rem;
    width: 80%;
  }
  /*  顶部选项*/
  .top_class li.active{
    color: #3765FF;
    font-weight: bold;
  }
  .check{
    width: 16px;
    height: 16px;
    border: 1px solid #999899;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .name li {
    margin-right: 50px;
    margin-bottom: 5px;
  }

  .msg_button1{
    width: 226px;
    height: 50px;
    background: #CCCCCC;
    border-radius: 6px;
  }
  .msg_button2{
    width: 226px;
    height: 50px;
    background: #3A54FF;
    border-radius: 6px;
    margin-left: 40px;
  }
</style>