import { render, staticRenderFns } from "./createWeb.vue?vue&type=template&id=7524386f&scoped=true&"
import script from "./createWeb.vue?vue&type=script&lang=js&"
export * from "./createWeb.vue?vue&type=script&lang=js&"
import style0 from "./createWeb.vue?vue&type=style&index=0&id=7524386f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7524386f",
  null
  
)

export default component.exports