<template>
  <!--分类需求 搜索结果-->
  <div class="demand_warp" style="width: 1200px;margin: 0 auto;" @click="showShare=false">
    <!--导航-->
    <div class="dif alc tlt fs12 col999 mrTop20 mrBtm20">
      <router-link to="/" class="fs12 col999">首页</router-link>
      <p>>需求搜索</p>
    </div>
    <!--有搜索结果-->
    <div class="w100 h100 dif res_box" v-if="res.length>0">
      <!--服务窗口 需求-->
      <div class="demand">
        <!--需求内容-->
        <div class="demand_titl bg_a" style="padding: 12px 0;">
          <ul class="fs14 bold col333 dif">
            <li style="padding: 0 20px;">需求名称</li>
            <li style="padding: 0 50px;">需求类型</li>
            <li style="padding: 0 20px;">地点</li>
            <li style="padding: 0 50px;">工作单位</li>
            <li style="padding: 0 40px;" class="tlt">需求标题</li>
            <li style="padding: 0 90px;margin-left: 30px;">起止时间</li>
            <li style="margin-right: 70px;">剩余天数</li>
            <li style="margin-right: 80px;width: 48px;">状态</li>
            <li style="width: 58px;" class="tct">操作</li>
          </ul>
        </div>
        <!--详细信息-->
        <div>
          <ul class="fs12 col333 dif d_info "
              @click="goDemandDetail(item.id)"
              style="padding: 12px 0;"
              :class="index%2===0?'bg_b':'bg_a'"
              v-for="(item,index) in res" :key="index">
            <li style="width: 96px;" class="one_overflow tct">{{item.name}}</li>
            <li style="width: 156px;" class="one_overflow tct">{{item.type}}</li>
            <li style="width: 68px;" class="one_overflow tct">{{item.address}}</li>
            <li style="width: 128px;" class="one_overflow tct">{{item.unit}}</li>
            <li style="width: 196px;" class="tct one_overflow">{{item.title}}</li>
            <li style="width: 236px;" class="one_overflow tct">
              <span style="line-height: normal;">{{getTime(item.start_time)}}</span>至
              <span style="line-height: normal;">{{getTime(item.end_time)}}</span>
            </li>
            <li style="margin-right: 70px;width: 56px;" class="one_overflow tct">{{item.daynum}}天</li>
            <li style="margin-right: 80px;width: 48px;" class="one_overflow tct">
              <span v-if="item.status===1">进行中</span>
              <span v-if="item.status===2">已过期</span>
            </li>
            <li class="dif alc col3A53FF posr">
              <p class="pointer" @click.stop="setShare(item,index)">分享</p>
              <p class="mrLft10 pointer" @click.stop="collect(item)" v-if="item.is_collect==0 || !item.is_collect">收藏</p>
              <p class="mrLft10 pointer" @click.stop="collect(item)" v-else>已收藏</p>
            </li>
          </ul>
        </div>
         <!--<router-link to="/search/demand/detail"></router-link>-->
      </div>
    </div>
    <!--没有搜索结果-->
    <div v-if="res.length<=0" style="padding-top: 100px;padding-bottom: 300px;">
      <el-result icon="info" title="未搜索到结果"></el-result>
    </div>
    <div class="share_icon shadow_bottom difcc" v-if="showShare" @click.stop style="z-index: 9999">
      <i class="el-icon-close close pointer fs24" @click.stop="showShare=false"></i>
      <h3>点击分享</h3>
      <share :config="config"></share>
    </div>
  </div>
</template>

<script>
export default {
  name: "demandResult",
  data() {
    return {
      tags: [
        {id:0,name:'综合榜'},
        {id:1,name:'点赞榜'},
        {id:2,name:'热度榜'},
        {id:3,name:'好评榜'},
        {id:4,name:'宣传榜'},
        {id:5,name:'点赞年榜'},
      ],
      isActive: 0,
      res: [],//需求结果
      keyword: '',
      type: '',
      config:{
        sites: ['qq','wechat','qzone'],
        image: '',
        title: '',
        description: '',
        url: '', // 网址，默认使用 window.location.href
        source: '', // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
        wechatQrcodeTitle: '微信扫一扫：分享', // 微信二维码提示文字
        wechatQrcodeHelper: '<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>'
      },
      showShare: false,
    }
  },
  created() {
    //获取从首页跳转来的搜索关键字 并 搜索
    let keyword = this.$route.query.keyword
    this.keyword = keyword
    let type = this.$route.query.type
    this.type = type
    this.search(keyword,type)
  },
  watch:{
    //监听路由参数  搜索
    "$route.params":function (route){
      this.keyword = this.$route.query.keyword
      this.type = this.$route.query.type

      this.search(this.keyword,this.type)
    }
  },
  methods: {
    //收藏
    collect(item) {
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      this.$axios("POST","/api/member/collect",{
        id: item.id,
        type: 3
      }).then(res => {
        if(res.code===1) {
          this.$message.success('操作成功')
          setTimeout(() => {
            this.search(this.keyword,this.type)
          },1200)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    //分享
    setShare(item,index) {
      this.showShare = true
      this.config.title = item.title
      let host = window.location.protocol+"//"+window.location.host+ '/search/demand/detail?id='+item.id
      this.config.url = host
      this.config.description = item.type+','+item.keyword
    },
    search(keyword,type){ //搜索
      // this.$message.info('努力加载中')
      this.$axios("POST",'/api/search/search',{
        keyword,
        type
      }).then(res => {
        if(res.code == '1') {
          this.res = res.data
          console.log(res);
        }else{
          this.res = []
          console.log(res);
        }
      })
    },
    goDemandDetail(id) { //跳转到需求详情
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      let routeUrl = this.$router.resolve({
        path: '/search/demand/detail',
        query:{
          id
        }
      })
      window.open(routeUrl .href, '_blank');
    },

  },
  computed: {
    getTime() { //获取招工起止时间
      return function (time) {
        var date = new Date(time*1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        return Y + M + D;
      }
    }
  }
}
</script>

<style scoped>
  .bg_a{
    background: #F3F3F3;
  }
  .bg_b{
    background: #FFFFFF;
  }
  .d_info li {
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }
  .gongneng{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -50px;
  }
  .share_icon{
    width: 200px;
    height: 100px;
    border-radius: 20px;
    background: #FFFFFF;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .close{
    position: absolute;
    top: 10px;
    right: 10px;
  }
</style>