<template>
  <!--服务窗口 资讯-->
  <div class="advisory mr0At" style="width: 1200px;margin-top: 40px;">
    <div class="w100 h100 dif res_box">
      <!--搜索结果左侧列表-->
      <div class="res_left f1 pdLft30">
        <ul>
          <!--左侧新闻列表-->
          <li style="margin-bottom: 37px;width: 560px;" v-for="(item,index) in res" :key="index" @click="goAdvisory(item.id)">
            <!--标题-->
            <div class="fs20 col333 tlt one_overflow" style="margin-bottom: 14px;">{{item.title}}</div>
            <!--标题-->
            <div class="dif alc news_img tlt" style="margin-bottom: 13px;">
              <img class="bgcb" v-for="(item2,index2) in item.images" :key="index" :src="item2">
            </div>
            <!--来源 时间-->
            <div class="dif alc">
              <div style="font-size: 12px;color:#999;max-width: 200px" class="one_overflow">{{ item.source }}</div>
              <div style="font-size: 12px;color:#999;" class="mrLft10">{{item.createtime}}</div>
            </div>
          </li>

        </ul>
      </div>
      <!--右侧推荐 分类-->
      <hot></hot>
    </div>
  </div>
</template>

<script>
import Hot from "@/components/search/hot";
export default {
  name: "news",
  props: {
    sid: [String,Number]
  },
  data() {
    return {
      res : [],//资讯获取结果
    }
  },
  components: {Hot},
  methods: {
    goAdvisory(id) { //跳转到资讯详情
      // let path = this.$router.resolve({
      //   path: '/search/advisory/detail',
      //   query: {
      //     id: id
      //   }
      // })
      this.$router.push({
        path: '/search/advisory/detail',
        query: {id: id}
      })
    },
    getInfo() { //获取官网详情---资讯
      this.$axios("POST","/api/site/siteInfodetail",{
        sid: this.$route.query.sid,
        type: 4,
        infoType: 1
      }).then(res => {
        this.res = res.data
      })
    }
  },
  created() {
    this.getInfo()
  }
}
</script>

<style scoped>

.f1{flex: 1}
.news_img img{
  width: 184px;
  height: 122px;
  background: #cbcbcb;
  margin-right: 4px;
}
.news_img img:first-child{
  border-radius: 10px 0 0 10px;
}
.news_img img:last-child{
  border-radius: 0 10px 10px 0;
  margin-right: 0;
}
.renzheng span{
  width: 55px;
  height: 18px;
  text-align: center;
  box-sizing: border-box;
  line-height: 18px;
  font-size: 12px;
  margin-left: 10px;
  display: block;
  background: #034F87;
  border-radius: 10px;
  color: #fff;
}
.img_warp{
  width: 170px;
  height: 100px;
  border: 1px solid #f00;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}
.img_warp img{
  max-width: 170px;
  max-height: 100px;
}

.left_bottom .active{
  color: #295e8d;
}

.hot li img{
  width: 230px;
  height: 120px;
  background: #666;
}

.a_share div{
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
}
.a_share div img{
  width: 20px;
  height: 20px;
}

.a_collect{
  font-size: 16px;
  color: #1E5A87;
  position: absolute;
  top: 0;
  right: 0;
}
</style>