<template>
<!--  忘记密码  -->
  <div class="forget_pass">
    <div style="width: 360px;" class="difc alc mr0At posr">
      <p class="forget_title box">忘记密码</p>
      <div class="forget_back" @click="backLogin">
        <el-icon class="el-icon-arrow-left bold fs24 " style="color:#3A51FF;" ></el-icon>
      </div>
    </div>
    <div class="dif alc" style="margin-top: 39px;">
      <el-input placeholder="请输入手机号" class="f2" v-model="mobile"></el-input>
    </div>
    <div class="difc alc mrTop20">
      <div class="posr" style="width: 360px;height: 56px;">
        <el-input placeholder="请输入验证码" class="f1" v-model="captcha"></el-input>
        <div class="fasong_code pointer fs16" @click="getCode" v-if="time===121">
          获取验证码
        </div>
        <div class="fasong_code fs16" v-else>
          重新发送({{time}}秒)
        </div>
      </div>
    </div>

    <div class="difc alc mrTop20">
      <div style="width: 360px;">
        <el-input type="password" placeholder="至少6位密码,包括大小写字母、数字" class="f2" v-model="newpassword" show-password></el-input>
      </div>
    </div>

    <div class="difc alc mrTop20 mrBtm20">
      <div style="width: 360px;">
        <el-input type="password" placeholder="至少6位密码,包括大小写字母、数字" class="f2" v-model="repeatpassword" show-password></el-input>
      </div>
    </div>

    <div class="button pointer" @click="updatePwd">确认</div>
  </div>
</template>

<script>
export default {
  name: "forgetPass",
  data() {
    return  {
      mobile: '',//手机号或者邮箱
      captcha: '',//验证码
      newpassword: '',//新密码
      repeatpassword: '',//重复密码
      setInterval: '',//重新发送验证码
      timer: null,
      time: 121
    }
  },
  methods: {
    backLogin(){ //返回密码登录
      this.$emit('forgetPwd',false)
    },
    updatePwd() { //修改密码
      //判断密码是否符合
      if(!this.$all.isPassword(this.newpassword)) {
        return;
      }
      //判断两次输入的密码是否一致
      if(this.newpassword!==this.repeatpassword) {
        this.$alert('两次输入的密码不一致',{type: 'error'})
        return
      }
      //发送修改密码请求
      this.$axios("POST","/api/user/resetpwd",{
        mobile: this.mobile,
        captcha: this.captcha,
        newpassword: this.newpassword,
        repeatpassword: this.repeatpassword,
      }).then(res => {
        if(res.code===1) {
          //开启页面滚动
          let m =function(e){e.preventDefault();};
          document.body.style.overflow='';//出现滚动条
          document.removeEventListener("touchmove",m,{ passive:true });
          this.$alert(res.msg,{type: 'success'})
          //返回到密码登录
          this.$emit('forgetPwd',false)
        }else{
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    getCode() { //获取验证码
      clearInterval(this.timer)
      this.$message('发送中')
      this.$axios("POST","/api/Index/sendSms",{
        mobile: this.mobile,
        event: 'changepass'
      }).then(res => {
        if(res.code===1) {
          this.$message.success('发送成功,验证码有效时间120秒')
          clearInterval(this.timer)
          this.timer = setInterval(()=>{
            this.time--
            if(this.time<=0) {
              this.time = 121
              clearInterval(this.timer)
            }
          },1000)
        }else {
          this.$message.error(res.msg)

        }
      })
    },
  }
}
</script>

<style scoped>
  .forget_back{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .f1{flex: 1}
  .f2{flex: 2}
  .forget_title{
    font-size: 20px;
    color: #3A51FF;
    font-weight: bold;
  }
  input{
    font-size: 16px;
    width: 100%;
    height: 50px;
    border: 1px solid #000;
    border-radius: 10px;
    box-sizing: border-box;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .button{
    width: 360px;
    height: 56px;
    background: #3A54FF;
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 41px;
    font-size: 24px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fasong_code{
    font-size: 14px;
    color: #3A51FF;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 19px;
  }

  ::v-deep .el-input input.el-input__inner{
    width: 360px;
    height: 56px;
    border-radius: 10px;
  }
</style>