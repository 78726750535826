<template>
  <div>
    <!-- {{LeftTypeTotal.top}}{{LeftTypeTotal.bottom}} -->
    <!-- 个人中心 -->
    <div v-if="LeftTypeTotal.top=='1'">
      <!-- 我的主页  -->
      <user-home-page v-if="LeftTypeTotal.bottom=='1'"></user-home-page>
      <!-- 会员中心 -->
      <member-center v-if="LeftTypeTotal.bottom=='2'"></member-center>
      <!-- 我的消息 -->
      <me-news v-if="LeftTypeTotal.bottom=='3'"></me-news>
      <!-- 我的收藏 -->
      <me-collection v-if="LeftTypeTotal.bottom=='4'"></me-collection>
      <!-- 我的资料 -->
      <me-update v-if="LeftTypeTotal.bottom=='5'"></me-update>
      <!-- 身份认证 -->
      <me-identity v-if="LeftTypeTotal.bottom=='6'"></me-identity>
      <!-- 账号安全 -->
      <me-passwordUp v-if="LeftTypeTotal.bottom=='7'"></me-passwordUp>
      <!-- 登录记录 -->
      <me-record v-if="LeftTypeTotal.bottom=='8'"></me-record>
    </div>

    <!-- 企业中心 -->
    <div v-if="LeftTypeTotal.top=='2'">
      <!-- 企业中心- 企业认证    【1】 -->
      <enterprise-Certification v-if="LeftTypeTotal.bottom=='1' " ></enterprise-Certification>
      <!-- 企业中心- 会员中心    【2】 -->
      <enterprise-userMember    v-else-if="LeftTypeTotal.bottom=='2' "></enterprise-userMember>
      <!-- 企业中心 — 官网发布   【3】 -->
      <enterprise-officialWebsite v-else-if="LeftTypeTotal.bottom=='3' " ></enterprise-officialWebsite>
      <!-- 企业中心 — 信息发布   【4】 -->
      <enterprise-messageForm    v-else-if="LeftTypeTotal.bottom=='4' " ></enterprise-messageForm>
      <!-- 企业中心 — 资讯发布   【5】 -->
      <enterprise-consult      v-else-if="LeftTypeTotal.bottom=='5' " ></enterprise-consult>
      <!-- 企业中心 — 需求发布   【6】 -->
      <enterprise-needForm     v-else-if="LeftTypeTotal.bottom=='6' " ></enterprise-needForm>
      <!-- 企业中心 — 店铺发布   【7】 -->
      <enterprise-shopForm     v-else-if="LeftTypeTotal.bottom=='7' " ></enterprise-shopForm>
      <!-- 企业中心 — 加盟发布   【8】 -->
      <enterprise-joinForm     v-else-if="LeftTypeTotal.bottom=='8' " ></enterprise-joinForm>
      <!-- 企业中心 — 招聘发布   【9】 -->
      <enterprise-zhaopinForm     v-else-if="LeftTypeTotal.bottom=='9' " ></enterprise-zhaopinForm>
      <!-- 企业中心 — 软件发布   【10】 -->
      <enterprise-appForm     v-else-if="LeftTypeTotal.bottom=='10' " ></enterprise-appForm>
      <!-- 企业中心 — 我的定制   【11】 -->
      <enterprise-customizedForm     v-else-if="LeftTypeTotal.bottom=='11' " ></enterprise-customizedForm>
      <!-- 企业中心 — 访问数据   【12】 -->
      <enterprise-accessData     v-else-if="LeftTypeTotal.bottom=='12' " ></enterprise-accessData>
      <!-- 企业中心 — 生成官网   【13】 -->
      <enterprise-createWeb     v-else-if="LeftTypeTotal.bottom=='13' " ></enterprise-createWeb>
    </div>
  </div>
</template>

<script>
  import userHomePage from '@/components/meHeaders/userCenter/userHomePage.vue'
  import memberCenter from '@/components/meHeaders/userCenter/memberCenter.vue'
  import meNews from '@/components/meHeaders/userCenter/meNews.vue'
  import meCollection from '@/components/meHeaders/userCenter/meCollection.vue'
  import meUpdate from '@/components/meHeaders/userCenter/meUpdate.vue'
  import meIdentity from '@/components/meHeaders/userCenter/meIdentity.vue'
  import meRecord from '@/components/meHeaders/userCenter/meRecord.vue'
  import mePasswordUp from '@/components/meHeaders/userCenter/mePasswordUp.vue'

  import officialWebsite from '@/components/meHeaders/enterpriseCenter/officialWebsite.vue'
  import enterpriseCertification from '@/components/meHeaders/enterpriseCenter/enterpriseCertification.vue'
  import userMember from '@/components/meHeaders/enterpriseCenter/userMember.vue'
  import messageForm from '@/components/meHeaders/enterpriseCenter/messageForm.vue'
  import consult from '@/components/meHeaders/enterpriseCenter/consult.vue'
  import needForm from '@/components/meHeaders/enterpriseCenter/needForm.vue'
  import shopForm from "@/components/meHeaders/enterpriseCenter/shopForm";
  import joinForm from "@/components/meHeaders/enterpriseCenter/joinForm";
  import zhaopinForm from "@/components/meHeaders/enterpriseCenter/zhaopinForm";
  import appForm from "@/components/meHeaders/enterpriseCenter/appForm";
  import customizedForm from "@/components/meHeaders/enterpriseCenter/customizedForm";
  import accessData from "@/components/meHeaders/enterpriseCenter/accessData";
  import createWeb from "@/components/meHeaders/enterpriseCenter/createWeb";

  export default {
    components:{
      userHomePage,
      memberCenter,
      meNews,
      meCollection,
      meUpdate,
      meIdentity,
      meRecord,
      mePasswordUp,

      'enterprise-Certification':enterpriseCertification, //企业中心- 企业认证 【1】
      'enterprise-userMember':userMember, //企业中心- 会员中心 【2】
      'enterprise-officialWebsite':officialWebsite, //企业中心 — 官网发布   【3】
      'enterprise-messageForm':messageForm ,//企业中心 — 信息发布   【4】
      'enterprise-consult':consult,//企业中心 — 资讯发布   【5】
      'enterprise-needForm':needForm, //企业中心 — 需求发布   【6】
      'enterprise-shopForm':shopForm, //企业中心 — 店铺发布   【7】
      'enterprise-joinForm':joinForm, //企业中心 — 加盟发布   【8】
      'enterprise-zhaopinForm':zhaopinForm, //招聘中心 — 招聘发布   【9】
      'enterprise-appForm':appForm, //招聘中心 — 软件发布   【10】
      'enterprise-customizedForm':customizedForm, //招聘中心 — 我的定制   【11】
      'enterprise-accessData':accessData, //招聘中心 — 访问数据   【12】
      'enterprise-createWeb':createWeb, //招聘中心 — 生成官网   【13】
    },
    props:{
      LeftTypeTotal:{
        type:[Object,String]
      }
    },
    data() {
      return {

      }
    },
    created(event) {
      this.getDefaultInfo() //获取默认联系方式
      this.setCompanyInfo() //获取自动显示的所属
    },
    methods:{
        getEnterpriseCenter() { //获取企业入驻信息
          this.$axios("POST","/api/User/getUserCompany",{})
          .then(res => {
            if(res.code===1) {

            }else {

            }
          })
        },
        getDefaultInfo() { //获取默认联系方式
          this.$axios("POST","/api/Release/getUserReleaseInfo",{})
          .then(res => {
            if(res.code===1) {
              this.$store.commit('setContactDetails',res.data)
            }
          })
        },
        setCompanyInfo() { //获取所属
          this.$axios("POST","/api/Release/getBindInfo",{}).then(res => {
            console.log(res);
            if(res.code===1) {
              this.$store.commit('setCompanyInfo',res.data)
            }
          })
        }
    },

  }
</script>
