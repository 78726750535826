<template>
  <div style="margin-bottom: 80px;">
    <quill-editor
        style="height: 400px;"
        v-model="editorContent"
        ref="quillEditor"
        :options="editorOption"
        @change="change"
    ></quill-editor>
    <!--重写富文本编辑器中的上传图片功能 bg-->
    <el-upload style="display:none"
               :action="uploadImgUrl"
               name="file"
               :show-file-list="false"
               :before-upload='quillBeforeUploadFunc'
               :on-success='quillUploadSuccess'
               ref="quill-upload-img"
               :id="'quill-upload-img-' + index"
    >
    </el-upload>
    <!--重写富文本编辑器中的上传图片功能 end-->
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor' // 调用编辑器

var Quill = require('quill/dist/quill.js')
const IMAGE_TYPES = ['jpg', 'png', 'tif', 'gif', 'jpeg', 'icon']

// 定义富文本编辑器默认的工具栏列表项
/*const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // 加粗、倾斜、下划线、删除线
  [{'header': 1}, {'header': 2}], // 标题一、标题二
  [{'list': 'ordered'}, {'list': 'bullet'}], // 列表
  [{'color': []}, {'background': []}], // 字体颜色、背景颜色
  [{'align': []}, 'image']
]*/

// toolbar工具栏的工具选项（默认展示全部）
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{'header': 1}, {'header': 2}],
  [{'list': 'ordered'}, {'list': 'bullet'}],
  [{'script': 'sub'}, {'script': 'super'}],
  [{'indent': '-1'}, {'indent': '+1'}],
  [{'direction': 'rtl'}],
  [{'size': ['small', false, 'large', 'huge']}],
  [{'header': [1, 2, 3, 4, 5, 6, false]}],
  [{'color': []}, {'background': []}],
  [{'align': []}, 'image'],
];
export default {
  name: 'QuillEditor1',
  components: {
    quillEditor
  },
  //index表明上传成功之后要添加内容的编辑器索引（如果引入了多个编辑器）
  props: ['index', 'toolbarOptions', 'imgBase64'],  // 通过组件传值来由引用者定义富文本编辑器的工具条列表项和是否使用自带的上传图片功能
  data () {
    return {
      uploadImgUrl: 'https://yzb.yzbse.com/api/common/upload',	// 上传图片的url，如果重写上传图片功能的话，该属性有用
      editorContent: '',
      editorOption: {
        modules: {
          toolbar: {
            container: this.toolbarOptions || toolbarOptions, // 工具栏选项
            handlers: {
              takePhoto: this.openTakePhotoFunc // 拍照，takePhoto为工具栏中定义的名称
            } // 事件重写
          }
        }
      }
    }
  },
  methods: {
    change() { //向父组件传content内容
      this.$emit('getEditor',this.editorContent)
    },
    editQuillUploadImgEventFunc () {
      const _this = this
      let imgHandler = async function (state) {
        if (state) {
          let fileInput = document.querySelector('#quill-upload-img-' + _this.index + ' input') // 隐藏的file元素
          fileInput.click() // 触发事件
        }
      }
      this.$refs.quillEditor.quill.getModule('toolbar').addHandler('image', imgHandler)
    },
    quillBeforeUploadFunc (file) {
      const fileNameArray = file.name.split('.')
      const fileType = fileNameArray[fileNameArray.length - 1]
      if (IMAGE_TYPES.indexOf(fileType.toLowerCase()) === -1) {
        this.$message.error('请上传图片格式的文件！')
        return false
      }
    },
    quillUploadSuccess (response, file, fileList) {
      console.log('========图片上传成功：')
      console.log(response)
      // 上传成功后的操作
      // ...
      console.log(this.$refs.quillEditor);
      let addImgRange = this.$refs.quillEditor.quill.getSelection()
      this.$refs.quillEditor.quill.insertEmbed(addImgRange != null ? addImgRange.index : 0, 'image', response.data.fullurl, Quill.sources.USER)
    },
    openTakePhotoFunc () {
      if (window.javacamera) {
        try {
          let data = window.javacamera.TakePhoto()
          if (data !== '' && data != null) {
            data = 'data:image/jpeg;base64,' + data
            if (!this.imgBase64) {
              this.TakePhotoToUrlFunc(data)
            } else {
              this.editorContent = this.editorContent + '<img style="max-width: 626px" src="' + data + '"/>'
            }
          }
        } catch (e) {
          this.$message.error('不能进行拍照！')
          console.log('不能进行拍照！' + e)
        }
      } else {
        if (window.mycamera !== null) {
          try {
            window.mycamera.TakePhoto(data => {
              if (data.indexOf('data:image/jpeg;base64,') > -1 && !this.imgBase64) {
                this.TakePhotoToUrlFunc(data)
              } else {
                this.editorContent = this.editorContent + '<img style="max-width: 626px" src="' + data + '" style="max-width: 626px"/>'
              }
            })
          } catch (e) {
            this.$message.error('不能进行拍照！')
            console.log('不能进行拍照！')
          }
        } else {
          this.$message.error('不能进行拍照！')
          console.log('不能进行拍照！')
        }
      }
    },
    TakePhotoToUrlFunc (data) {
      // 通过后端将图片的base转为url
    },
    enableFunc (isEnable) {
      this.$refs.quillEditor.quill.enable(isEnable)	 // 设置富文本编辑框是否禁用
    }
  },
  mounted () {
    if (!this.imgBase64) {	// 富文本编辑器自带的上传图片为base64的
      this.editQuillUploadImgEventFunc()	// 使用自定义的上传图片功能
    }

  }
}
</script>

<style scoped>

</style>