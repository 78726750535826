<template>
  <div class="zhedie" ref="zhedie" style="width: 346px;" >
    <div style="word-break: break-all" :class="{'hide': isShowMore && !showMore}">
      {{text}}
    </div>
    <div class="difcac  fs14" style="color: #00AEFF;margin: 5px 0;"  v-if="isShowMore">
      <div class="pointer" @click="showMore=!showMore">{{showMore?'收起':'展开更多'}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "zhedie",
  props: {
    text: '',
  },
  data() {
    return {
      showMore: false,
      isShowMore: false,
      textHeight: null,
    }
  },
  watch:{
    text(newValue) {
      this.$nextTick(() => {
        // 这里具体行数可依据需求自定义
        let lineHeight = 27 * 3
        this.textHeight = `${lineHeight}px`
        if (this.$refs.zhedie.offsetHeight > lineHeight) {
          this.isShowMore = true
          this.showMore = false
        } else {
          this.isShowMore = false
          this.showMore = false
        }
      });
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
  .hide{
    height: 66px;
    overflow: hidden;
  }
  .zhedie{
    text-align: left;
    background: #F3F3F3 100%;
    padding: 2px 20px;
    box-sizing: border-box;
    min-height: 25px;
    border-radius: 8px;
    margin-left: 10px;
    width: 80%;
  }
</style>