<template>
  <div id="app">
    <div>
      <router-view/>
    </div>
    <bottom_footer></bottom_footer>
    <!--返回顶部-->
    <el-backtop :right="40" :bottom="40" style="z-index: 9999">
      <!--返回顶部图标-->
      <div class="difcac box" style="padding-top: 4px;padding-right: 2px;">
        <i class="el-icon-top" style="color: #366EFF;font-size: 30px;"></i>
      </div>
    </el-backtop>
  </div>
</template>

<script>
import Bottom_footer from "@/components/bottom_footer";
export default {
  components: {Bottom_footer},
  data() {
    return {

    }
  },
  created(event) {
    this.getBrowserInfo();//获取当前浏览器类型和版本号
    let token = localStorage.getItem('yizhibang')
    let userinfo = localStorage.getItem('userinfo')
    userinfo = JSON.parse(userinfo)
    if(token && userinfo) {
      this.$store.commit('setUserInfo',userinfo)
      this.$store.commit('set_token',token)
    }else{
      console.log('未登录')
    }
  },
  methods:{
    getBrowserInfo() {//获取当前浏览器类型和版本号
      let agent = navigator.userAgent.toLowerCase();
      this.$axios("POST","/api/Index/getConfing",{name: 'introduce'})
          .then(res => {
            if(res.code===1) {
              document.title = res.data
            }
          })
      // let agenLevel = '';
      // let regStr_ie = /msie [\d.]+;/gi ;
      // let regStr_ff = /firefox\/[\d.]+/gi
      // let regStr_chrome = /chrome\/[\d.]+/gi ;
      // let regStr_saf = /safari\/[\d.]+/gi ;
      // //IE11以下
      // if(agent.indexOf("msie") > 0){
      //   agenLevel = agent.match(regStr_ie) ;
      // }
      // //IE11版本中不包括MSIE字段
      // if(agent.indexOf("trident") > 0&&agent.indexOf("rv") > 0){
      //   agenLevel = "IE " + agent.match(/rv:(\d+\.\d+)/) [1];
      // }
      // //firefox
      // if(agent.indexOf("firefox") > 0){
      //   agenLevel = agent.match(regStr_ff) ;
      // }
      // //Chrome
      // if(agent.indexOf("chrome") > 0){
      //   agenLevel = agent.match(regStr_chrome) ;
      // }
      // //Safari
      // if(agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0){
      //   agenLevel = agent.match(regStr_saf) ;
      // }
      // console.log('获取当前浏览器类型和版本号',agenLevel)
    }
  },
  watch: {
    '$route': function (newValue) {
      console.log(newValue,'改变')
    }
  },
}
</script>


<style>
::-ms-clear,::-ms-reveal{display:none;}
body {
  overflow-y: scroll;
  /*-webkit-user-select: none;*/
  /*-moz-user-select: none;*/
  /*-ms-user-select: none;*/
  /*user-select: none;*/
}
#app {
  margin:  0px;
  padding: 0px;
  width: 100%;
  min-width: 500px;
  bottom: 1px;
  /*width: auto;*/
  height: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: expression_r(document.body.clientWidth < 1000 ? "1000px": "auto");
}
/*火狐不支持修改滚动条*/
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar{
  width: 8px;
  height: 8px;
  background-color: #F5F5F5;
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  border-radius: 10px;
  background-color: #F5F5F5;
}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb{
  border-radius: 10px;
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
  /*background: -webkit-gradient(linear, 40% 0%, 75% 84%, from(#4D9C41), to(#19911D), color-stop(.6,#54DE5D));;*/
  background: #366EFF;
  /*background-color: #555;*/
}
</style>
