<template>
  <div class="padding">
    <div class="tlt dif alc posr">
      <span class="pointer fs16 col666" @click="isShow=true;getShopList()" :class="isShow===true?'active':''"> 已经发布 </span>
      <span class="pointer fs16 col666 mrLft30" @click="isShow=false" :class="isShow===false?'active':''"> 店铺发布 </span>
     <!-- <div class="dif alc pointer mrLft30" @click="isShow3=!isShow3">
        <img src="@/assets/image/yizhibang/03no_check.png" style="width: 16px;height: 16px;" v-if="!isShow3">
        <img src="@/assets/image/yizhibang/03yes_check.png" style="width: 16px;height: 16px;" v-else>
        <p class="fs12 col666 mrLft10">是否关闭此功能<span class="col999">(在您首页官网搜索将不显示此服务)</span></p>
      </div>-->
      <!--<div class="colfff difcac del_button pointer" v-if="isShow" @click="del()">删除</div>-->
    </div>

    <!--已经发布-->
    <div v-if="isShow">
      <!--店铺列表-->
      <div style="margin-top: 14px;">
        <ul class="shop_res dif difw">
          <li v-for="(item,index) in list" class="mrBtm30" @click="goShopDetail(item.url)">
            <div class="good_img">
              <!--图片-->
              <div class="posr w100 h100">
                <img  style="width: 248px;height: 248px;" :src="getImg(item.images)">
                <div class="mb1"></div>
                <div class="mb2"></div>
              </div>
              <!--序号-->
              <div class="noindex">NO.{{index+1}}</div>
              <!--品牌-->
              <div class="pinpai">
                <marquee scrollamount=3 v-if="item.brand && item.brand.length>4">品牌：{{item.brand || '暂无'}}</marquee>
                <span v-else>品牌：{{item.brand || '暂无'}}</span>
              </div>
              <!--公司-->
              <p class="compoany one_overflow">公司：{{item.compoany}}</p>
            </div>
            <div class="good_bottom posr">
              <div class="dif alc">
                <p class="one_overflow fs12 col1C1C1C  tlt" style="width: 155px;">{{item.title}}</p>
              </div>
              <div class="fs12 col3765FF tlt mrTop10">进入店铺</div>
              <div class="news_guanli pointer" style="right: 10px;" @click.stop="delShop(item)">
                <i style="color: #fa3534" class="el-icon-delete-solid fs16"></i>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!--分页-->
      <!--<div class="difcac">
        <el-pagination
            background
            layout="jumper, prev, pager  ,next   "
            :total="1000">
        </el-pagination>
      </div>-->
      <div class="tct" style="padding-top: 200px;" v-if="list.length<=0">暂无发布</div>
    </div>
    <!--店铺发布-->
    <div v-else class="mrTop30">
      <!--分类-->
      <div class="dif alc">
        <div class="fs14 col333 dif alc">
          <p style="color: #f00">*</p>
          <p class="mrLft10">分类</p>
          <div style="width: 360px;margin-left: 15px;">
            <el-select disabled value="分类" placeholder="请选择" class="w100">
              <el-option
                  :key="'分类'"
                  :label="'分类'"
                  :value="'分类'">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!--标题-->
      <div class="dif alc mrTop20 mrBtm20">
        <div class="fs14 col333 dif alc">
          <p style="color: #f00">*</p>
          <p class="mrLft10">店铺名称</p>
          <div style="width: 768px;margin-left: 15px;">
            <el-input placeholder="请输入店铺名称" v-model="title"></el-input>
          </div>
        </div>
      </div>
      <!--需求发布介绍-->
      <div class="" style="margin-top: 20px;">
        <div class="dif alc mrBtm10">
          <p style="color: #f00">*</p>
          <p class="fs14 col333 mrLft10 tlt ">店铺发布介绍</p>
        </div>
        <div style="width: 768px;">
          <el-input placeholder="请填写店铺发布的搜索关键词，并用“/”号分开 [用于店铺搜索，请谨慎填写]" type="textarea" rows="5" v-model="keywords"></el-input>
        </div>
      </div>
      <!--公司 品牌-->
      <div class="dif alc mrTop20">
        <div class="dif alc">
          <p style="color: #f00">*</p>
          <span class="mrLft10 mrRit10">公司</span>
          <el-input v-model="compoany" style="width: 70%;"></el-input>
        </div>
        <div class="dif alc mrLft30">
          <p style="color: #f00">*</p>
          <span class="mrLft10 mrRit10">品牌</span>
          <el-input v-model="brand" style="width: 70%;"></el-input>
        </div>
      </div>
      <!--店铺类型-->
      <div class="mrTop10">
        <ul class="dif alc">
          <li class="dif alc mrRit10 pointer" v-for="(item,index) in type" @click="curId=item.id">
            <div class="dif alc mrLft10">
              <img src="@/assets/image/yizhibang/51close.png" v-if="curId===item.id" style="width: 16px;height: 16px;">
              <img src="@/assets/image/yizhibang/51noclose.png" v-else style="width: 16px;height: 16px;">
            </div>
            <span class="mrLft10">{{item.name}}</span>
          </li>
        </ul>
      </div>
      <!--选择所在平台标志-->
      <div class="mrTop20" v-if="false">
        <p class="tlt fs16">选择所在平台标志</p>
        <ul class="dif difw mrTop20">
          <!--淘宝-->
          <li class="pingtai_item difcac pointer" :class="pingtai===1?'active':''" @click="pingtai=1">
            <img style="width: 139px;height: 57px;" class="bgccc">
          </li>
          <!--天猫-->
          <li class="pingtai_item difcac pointer" :class="pingtai===2?'active':''" @click="pingtai=2">
            <img style="width: 139px;height: 57px;" class="bgccc">
          </li>
          <!--京东-->
          <li class="pingtai_item difcac pointer" :class="pingtai===3?'active':''" @click="pingtai=3">
            <img style="width: 139px;height: 57px;" class="bgccc">
          </li>
          <!--拼多多-->
          <li class="pingtai_item difcac pointer" :class="pingtai===4?'active':''" @click="pingtai=4">
            <img style="width: 139px;height: 57px;" class="bgccc">
          </li>
          <!--苏宁易购-->
          <li class="pingtai_item difcac pointer" :class="pingtai===5?'active':''" @click="pingtai=5">
            <img style="width: 139px;height: 57px;" class="bgccc">
          </li>
          <!--当当-->
          <li class="pingtai_item difcac pointer" :class="pingtai===6?'active':''" @click="pingtai=6">
            <img style="width: 139px;height: 57px;" class="bgccc">
          </li>
          <!--1号店-->
          <li class="pingtai_item difcac pointer" :class="pingtai===7?'active':''" @click="pingtai=7">
            <img style="width: 139px;height: 57px;" class="bgccc">
          </li>
          <!--蘑菇街-->
          <li class="pingtai_item difcac pointer" :class="pingtai===8?'active':''" @click="pingtai=8">
            <img style="width: 139px;height: 57px;" class="bgccc">
          </li>
        </ul>
      </div>
      <!--添加宝贝图片-->
      <div class="mrTop10 tlt">
        <p class="tlt fs16 mrBtm20">添加宝贝图片(最多上传5张图片)</p>
        <!--照片墙-->
        <el-upload
            action="https://yzb.yzbse.com/api/common/upload"
            list-type="picture-card"
            :on-success="onsuccess"
            :on-remove="handleRemove"
            :limit="limit"
        >
          <i slot="default" class="el-icon-plus"></i>
        </el-upload>
      </div>
      <!--添加店铺链接-->
      <div class="mrTop10 dif alc">
        <p class="tlt fs16">添加店铺链接</p>
        <div class="dif alc mrLft20" style="width: 500px;">
          <el-input placeholder="请添加店铺链接" v-model="url" ></el-input>
          <div class="mrLft20">
            <el-button type="primary" @click="check()">查询</el-button>
          </div>
        </div>
        <span class="mrLft20 fs14 col999" v-if="count<=0">无重复地址</span>
        <span class="mrLft20 fs14" style="color: #fa3534" v-if="count>0">地址重复！</span>
      </div>
      <!--条件-->
      <div class="mrTop20 pdLft20">
        <ul class="tiaojian bold">
          <li>一个购物平台只可发布一家店铺</li>
          <li>只允许发布一个链接地址 不可重复发布</li>
          <li>只允许发布自己公司旗下直接相关服务的店铺</li>
          <li>如严重违反规则亿指榜将会对您进行限制功能封号等处罚</li>
        </ul>
      </div>
      <!--取消和发布-->
      <div class="difcac mrTop30">
        <div class="difcac msg_button1 colfff pointer" @click="isShow=true">取消</div>
        <div class="difcac msg_button2 colfff pointer" @click="releaseShop()">发布</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    LeftHeaderType: [String, Number],
  },
  data() {
    return {
      isdefault: false,//设为默认  设认·
      pageHeight:document.documentElement.clientHeight,//获取页面高度
      isShow: true,//已经发布 true 店铺发布 false
      isShow3: true,
      type: [ //店铺类型
        {name:'直营店',id:1},
        {name:'旗舰店',id:2},
        {name:'专卖店',id:3},
        {name:'专营店',id:5},
      ],
      pingtai: 1,//选择的平台
      curId: 1,
      title: '',//标题
      keywords: '',//关键词
      landline: '',//座机号码
      broker:'',//经纪人
      phone: '',//手机号码
      url: '',//店铺链接
      brand: '',//品牌
      compoany: '',//公司
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      limit: 5,
      swiper: [], //轮播图
      company: {},

      list: [],//已发布店铺
      selectList: [],//选择列表

      count: 0,//重复数量
    }
  },
  computed: {
    isActive() { //判断是否选中
      return function (id) {
        for(let item of this.selectList) {
          if(item===id) return true
        }
        return  false
      }
    },
    getImg() { //获取轮播图第一张
      return function (str) {
        if(str) {
          let arr = str.split(',')
          return this.$store.state.url + arr[0]
        }
      }
    },
  },
  methods: {
    async check() { //查找是否有重复链接
      let res = await this.$axios("POST","/api/Site/getStoreUrl",{
        url: this.url
      })
      if(res.code===1) {
        this.count = res.data
      }
      return res
    },
    delShop(item) { //删除店铺
      this.$confirm('确认要删除该店铺吗？',{type: 'warning'})
          .then(con => {
            this.$axios("POST","/api/member/deleteUserInfo",{
              type: 2,
              id: item.id
            }).then(res => {
              if(res.code===1) {
                this.$message.success('删除成功')
                this.getShopList()
              }else {
                this.$message.error(res.msg)
              }
            })
          })
    },
    del() { //删除店铺
      if(this.selectList.length<=0) {
        return this.$alert('请选择要删除的店铺')
      }
      this.$alert('暂未开放')
    },
    select(item) { //选择店铺
      for(let i in this.selectList) {
        if(this.selectList[i]===item.id) {
          this.selectList.splice(i,1)
          return
        }
      }
      this.selectList.push(item.id)
    },
    goShopDetail(url) { //店铺链接
      sessionStorage.goodsUrl = url
      let routeUrl = this.$router.resolve({
        path: '/search/shopdetail'
      })
      window.open(routeUrl .href, '_blank');
    },
    getShopList() { //获取已经发布的店铺
      this.$axios("POST","/api/Release/getReleaseStore",{})
      .then(res => {
        if(res.code===1) this.list = res.data
      })
    },
    async releaseShop() { //发布店铺
      let images =''
      if(this.swiper.length>0) {
        images = this.swiper.join(',')
      }
      let count = await this.check()
      if(count.data>0) {
        return this.$alert('店铺链接重复',{type: 'error'})
      }
      this.$axios("POST","/api/Release/releaseStore",{
        title: this.title,//名称
        keyword: this.keywords,//关键字
        images: images, //图片
        url: this.url, //店铺链接
        type: this.curId,//店铺类型
        brand: this.brand, //品牌
        compoany: this.compoany //公司
      }).then(res => {
        if(res.code===1) {
          this.$alert('发布成功')
        }else {
          this.$alert(res.msg)
        }
      })
    },
    handleRemove(file, fileList) { //删除文件
      console.log(file,fileList)
      let list = []
      for(let i in fileList) {
        list.push(fileList[i].response.data.url)
      }
      this.swiper = list
      console.log(this.swiper)
      this.$forceUpdate()
    },
    onsuccess(response, file, fileList) { //上传成功
      let list = []
      for (let i = 0;i<fileList.length;i++) {
        list.push(fileList[i].response.data.url);
      }
      this.swiper = list
    },
    getCompany() {
      this.$axios("POST","/api/Release/getReleaseSite",{})
      .then(res => {
        if(res.code==1 && res.data) {
          this.brand = res.data.brand_title
          this.compoany = res.data.enterprise
        }
      })
    }
  },
  created() {
    //获取自动显示的所属
    if(this.$store.state.companyInfo.id) {
      this.company = this.$store.state.companyInfo
    }
    //获取默认的联系方式
    if(this.$store.state.contactDetails.id) {
      this.landline = this.$store.state.contactDetails.landline_phone
      this.broker = this.$store.state.contactDetails.broker_name
      this.phone = this.$store.state.contactDetails.tel
      this.isdefault = true
    }
    this.getShopList() //获取已发布店铺
    this.getCompany() //获取已发布店铺
  }
};
</script>

<style scoped less>
.del_button{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 95px;
  width: 50px;
  height: 30px;
  background: #3765FF;
  border-radius: 15px;
  font-size: 14px;
}
input {
  padding: 10px;
  border: 0.5px solid #c2c2c2;
  border-radius: 0.5rem;
  width: 80%;
}
.active{
  color: #3765FF
}
/*已发布店铺*/
.news_guanli{
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.shop_res li:nth-child(4n-1) {
  margin-left: 30px;
  margin-right: 30px;
}
.shop_res li:nth-child(4n-2) {
  margin-left: 30px;
}
.good_bottom{
  padding: 10px 10px;
  width: 248px;
  height: 70px;
  background: #F7F6FA;
  box-sizing: border-box;

}
.good_img{
  background: #3765FF;
  width: 248px;
  height: 248px;
  position: relative;
}
.mb1{
  width: 100%;
  height: 129px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
}
.mb2{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 130px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.5;
}
.noindex {
  font-weight: bold;
  font-size: 16px;
  color: #F39800;
  position: absolute;
  top: 23px;
  left: 12px;
  z-index: 90;
}
.pinpai{
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  width: 100px;
  box-sizing: border-box;
  padding: 0 4px;
  font-size: 12px;
  height: 24px;
  background: #F39800;
  border-radius: 11px;
  position: absolute;
  top: 20px;
  right: 10px;
  z-index:2;
}
.compoany{
  font-size: 12px;
  color: #FFFFFF;
  position: absolute;
  bottom: 12px;
  left: 10px;
  width: 200px;
  text-align: left;
  z-index: 3;
}

/*店铺发布*/
/*选择平台图片*/
.pingtai_item{
  width: 180px;
  height: 96px;
  background: #F5F5F5;
  border-radius: 6px;
  box-sizing: border-box;
  border: 4px solid #F5F5F5;
  transition: all 0.3s;
  margin-right: 30px;
  margin-bottom: 30px;
}
.pingtai_item:last-child{
  margin-right: 0;
}
.pingtai_item.active{
  border: 4px solid #99B1FF;
}


/*条件*/
.tiaojian li{
  font-size: 14px;
  text-align: left;
  list-style: disc;
}
/*发布按钮*/
.msg_button1{
  width: 226px;
  height: 50px;
  background: #CCCCCC;
  border-radius: 6px;
}
.msg_button2{
  width: 226px;
  height: 50px;
  background: #3A54FF;
  border-radius: 6px;
  margin-left: 40px;
}
</style>