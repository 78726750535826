<template>
  <!--短信登录-->
  <div class="register difc difw">
    <div class="w60 mrBtm20">
      <el-input v-model="mobile" placeholder="输入手机号码" maxlength="11"></el-input>
    </div>
    <div class="posr w60" style="margin-bottom: 67px;">
      <el-input v-model="captcha" placeholder="输入验证码"></el-input>
      <p class="fs14 get_code pointer" v-if="time===31" @click="getCode()">获取验证码</p>
      <p class="fs14 get_code pointer" v-else>重新发送({{time}}秒)</p>
    </div>
    <div class="button w60" @click="login">登录</div>
    <div class="difcac mrTop20">
      <img src="@/assets/image/yizhibang/03yes_check.png" class="pointer" style="height: auto;width: 15px;"
           @click="isAgree=!isAgree" v-if="isAgree">
      <img src="@/assets/image/yizhibang/03no_check.png" class="pointer" style="height: auto;width: 15px;"
           @click="isAgree=!isAgree" v-else>
      <p class="col666 fs14 tct mrLft10" style="color:#B3B3B3;">我已阅读并同意
        <span class="col3A53FF pointer" @click="showXy1=true">《服务协议》 </span>
        <span class="col3A53FF pointer" @click="showXy2=true">《隐私协议》 </span>
      </p>
    </div>

    <!--服务协议-->
    <el-dialog
        title="服务协议"
        :visible.sync="showXy1"
        :modal-append-to-body="false"
        top="1vh"
        width="50%">
      <div v-html="fwxy" style="max-height: 500px;overflow-y: auto;text-align: left"></div>
    </el-dialog>
    <!--隐私协议-->
    <el-dialog
        title="隐私协议"
        :visible.sync="showXy2"
        :modal-append-to-body="false"
        top="1vh"
        width="50%">
      <div v-html="yszc" style="max-height: 500px;overflow-y: auto;text-align: left"></div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "msgLogin",
  data() {
    return {
      mobile: '',//手机号
      captcha: '',//验证码
      isAgree: false,
      timer: null,
      time: 31,

      fwxy: '',//服务协议
      yszc: '',//隐私协议
      showXy1: false,//显示协议
      showXy2: false,//显示协议
    }
  },
  created() {
    this.getConfig()
  },
  methods: {
    getConfig() { //获取协议内容
      this.$axios("POST","/api/index/getConfing",{})
          .then(res => {
            if(res.code==1) {
              this.yszc = res.data.yszc
              this.fwxy = res.data.fwxy
            }
          })
    },
    getCode() { //获取验证码
      clearInterval(this.timer)
      this.$message('发送中')
      this.$axios("POST","/api/Index/sendSms",{
        mobile: this.mobile,
        event: 'mobilelogin'
      }).then(res => {
        if(res.code===1) {
          this.$message.success('发送成功,验证码有效时间120秒')
          clearInterval(this.timer)
          this.timer = setInterval(()=>{
            this.time--
            if(this.time<=0) {
              this.time = 31
              clearInterval(this.timer)
            }
          },1000)
        }else {
          this.$message.error(res.msg)

        }
      })
    },
    login() {
      if(!this.isAgree) {
        this.$alert('请阅读并同意协议内容',{type: 'error'})
        return;
      }
      //开启页面滚动
      let m =function(e){e.preventDefault();};
      document.body.style.overflow='';//出现滚动条
      document.removeEventListener("touchmove",m,{ passive:true });

      /*this.$all.isPhone()//验证手机号*/

      //发送短信登录请求
      this.$axios('POST','/api/user/mobilelogin',{
        mobile: this.mobile,
        captcha: this.captcha
      }).then(res => {
        if(res.code=='1'){
          this.token = res.data.userinfo.token //存入token
          this.userInfo = res.data.userinfo //存入信息
          this.$store.commit('set_token',this.token)
          this.$store.commit('setUserInfo',this.userInfo)
          this.$message.success('登录成功')
        }else{
          this.$alert(res.msg,{type: 'error'})
        }
      }).catch(err => {
        console.log(err);
      })
    }
  }
}
</script>

<style scoped>
.register{
  margin-top: 30px;
}
div{line-height: normal}
input{
  font-size: 16px;
  width: 100%;
  height: 50px;
  border: 1px solid #000;
  border-radius: 10px;
  box-sizing: border-box;
  padding-left: 20px;
}


.button{
  width: 360px;
  height: 56px;
  background: #3A54FF;
  border-radius: 8px;
  font-size: 24px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.get_code{
  position: absolute;
  color: #3A51FF;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}
::v-deep .el-input input.el-input__inner{
  height: 56px;
  border-radius: 10px;
}
</style>