<template>
  <div class="mecenter" style="min-height: 95vh">
    <meHeader class="meheader"></meHeader>
    <div style="height: 80px;"></div>
    <div class="difb mr0At boxshadooow" style="width: 1450px;background-color: rgb(248, 248, 248)">
      <div class="posr" style="width: 224px;min-height: 800px" >
        <div style="width: 224px;background: #F8F8F8;overflow-y: auto;overflow-x:hidden;position: absolute;top: 0;left: 0;" class="hidden_scroll">
          <leftHeader @LeftHeaderType="LeftHeaderType"></leftHeader>
        </div>
      </div>
      <div >
        <!--max-height: 95vh;min-height: 85vh;-->
        <div style="width: 1226px; overflow-y: auto;background-color: #ffffff" class="" >
          <rightHeader :LeftTypeTotal="LeftTypeTotal"></rightHeader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import meHeader from '@/components/meHeaders/meHeader.vue'
import leftHeader from '@/components/meHeaders/leftHeader.vue'
import rightHeader from '@/components/meHeaders/rightHeader.vue'

export default {
  name: 'Home',
  components: {
    meHeader,
    leftHeader,
    rightHeader
  },
  data() {
    return {
      LeftTypeTotal: {'top': '1', 'bottom': '1'},//传的页面参数
    }
  },
  methods: {
    LeftHeaderType(event) {//上个页面传过来的参数
      this.LeftTypeTotal = event;//传页面参数
    },
    getEnterpriseCenter() { //获取企业入驻信息
      this.$axios("POST", "/api/User/getUserCompany", {})
          .then(res => {
            if (res.code === 1) {

            } else {

            }
          })
    },
  },

  created() {
    let query = this.$route.query
    this.LeftTypeTotal = {'top': query.leftSelect, 'bottom': query.contentType}
    this.getEnterpriseCenter(); //获取企业入驻信息
    this.$forceUpdate()
  },
  watch: {
    //监听路由参数
    "$route": function (route) {
      let query = this.$route.query
      this.LeftTypeTotal = {'top': query.leftSelect, 'bottom': query.contentType}
    }
  }
}
</script>

<style>
.meheader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}

.boxshadooow {
  box-shadow: 0 0 18px 2px rgba(202, 202, 202, 0.3);
  border-radius: 10px;
  /*background: #FFFFFF;*/
}

/*隐藏历史滚动条*/
.hidden_scroll::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.hidden_scroll {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
