<template>
  <!--  搜索结果 -->
  <div class="search_res">
    <div class="w100 h100 dif res_box">
      <!--搜索结果左侧列表-->
      <div class="res_left f1 pdLft30">
        <div class="dif alc tlt fs12 col999 mrTop20 mrBtm20">
          <router-link to="/" class="fs12 col999">首页></router-link>
          <p v-if="type == 9">网址</p>
          <p v-else>官网搜索</p>
        </div>
        <!--排行榜分类-->
        <div class="tag mrBtm20" v-if="type != 9">
          <ul class="dif difw">
            <li
              v-for="(item, index) in tags"
              :key="index"
              @click="imformation(item.id, item)"
              class="pointer fs14 col999"
              style="margin-bottom: 5px;"
              :class="isActive == item.id ? 'active' : 'norml'"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <ul v-if="res.length !== 0">
          <li
            class="res_item mrTop20 box posr"
            v-for="(item, index) in res"
            :key="item.id"
          >
            <div class="no no1" v-if="index === 0">NO.1</div>
            <div class="no no2" v-if="index === 1">NO.2</div>
            <div class="no no3" v-if="index === 2">NO.3</div>
            <!--<router-link :to="{path:'/search/webdetail',query:{sid: item.id}}">-->
            <div @click="goDetail(item)">
              <!--公司名称-->
              <div class="left_top dif alc">
                <p
                  class="fs16 col3A53FF pointer"
                  style="text-decoration: underline"
                >
                  {{ "品牌 / " + item.brand_title }}
                  {{ "关属 /" + item.enterprise }}
                </p>
                <p
                  class="colfff fs12 mrLft10"
                  v-if="item.type == 1"
                  style="background: #4e6ef2;padding: 0 5px;"
                >
                  官网
                </p>
                <p
                  class="colfff fs12 mrLft10"
                  v-if="item.type == 2"
                  style="background: #eb6100;padding: 0 5px;"
                >
                  分部
                </p>
                <p
                  class="colfff fs12 mrLft10"
                  v-if="item.type == 3"
                  style="background: #fa3534;padding: 0 5px;"
                >
                  网店
                </p>
              </div>
              <!--详细信息-->
              <div class="left_center dif alc mrTop13">
                <!--图片-->
                <img
                  :src="
                    item.image.indexOf('http') >= 0
                      ? item.image
                      : 'https://yzb.yzbse.com' + item.image
                  "
                  style="width: 125px;height: 76px;min-width: 125px;min-height: 76px;border-radius: 10px;"
                />
                <!--标题 内容-->
                <div
                  class="dif w70 box pdLft10 pdTop10"
                  style="height: 100px;flex-direction: column"
                >
                  <h4 class="one_overflow tlt fs14 col333">
                    {{ item.sitename }}
                  </h4>
                  <p
                    class="three_overflow fs12 tlt col666"
                    style="line-height: 22px;width: 508px;"
                  >
                    {{ item.keyword }}
                  </p>
                </div>
              </div>
            </div>
            <!--</router-link>-->
            <!-- 查看选项-->
            <div class="left_bottom mrTop20" @click.stop="goDetail(item)">
              <ul class="dif alc fs14">
                <li class="mrRit30 pointer col3A53FF fs14">官网窗口</li>
                <li
                  class="mrRit30 pointer fs14 col999"
                  @click.stop="goDetail2(item, 2)"
                  v-if="item.uid"
                >
                  公司介绍
                </li>
                <!--<li class="mrRit30 pointer fs14 col999">服务窗口</li>-->
                <!--<li class="mrRit30 pointer fs14 col999">下载软件</li>-->
                <li
                  class="mrRit30 pointer fs14 col999"
                  @click.stop="setShare(item, index)"
                >
                  分享
                </li>
                <div
                  class="shareicon"
                  v-if="showShare === index"
                  style="z-index: 99;"
                  @mouseleave="showShare = -1"
                >
                  <share :config="config"></share>
                </div>
                <li
                  class="mrRit30 pointer fs14 col999"
                  v-if="item.is_Collection == 0"
                  @click.stop="setCollect(item.id)"
                >
                  收藏官网
                </li>
                <li
                  class="mrRit30 pointer fs14"
                  style="color: #3765FF"
                  v-else
                  @click.stop="
                    dialogVisible = true;
                    setId = item.id;
                  "
                >
                  已收藏
                </li>
                <li
                  v-if="item.uid"
                  class="mrRit20 pointer fs14 col999 dif alc"
                  @click.stop="goDetail2(item, 7)"
                >
                  <img
                    src="@/assets/image/yizhibang/16_message.png"
                    style="width: 15px; height: 13px;"
                  />
                  <span style="margin-left: 5px;">留言</span>
                </li>
                <li class="fs14 pointer col999 dif alc" @click.stop="zan(item)">
                  <img
                    src="@/assets/image/zan1.png"
                    v-if="item.is_dz >= 1"
                    style="width: 15px; height: 15px; margin-right: 5px;"
                  />
                  <img
                    src="@/assets/image/zan2.png"
                    v-else
                    style="width: 15px; height: 15px; margin-right: 5px;"
                  />
                  <span style="color: #F6AA00;" v-if="item.is_dz >= 1">{{
                    item.fabulous
                  }}</span>
                  <span style="color: #bfbfbf;" v-else>{{
                    item.fabulous
                  }}</span>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <div v-if="lowestLevel" style="padding-top: 50px; color: #888;">
          --------已到海底最深处--------
        </div>
        <div v-if="res.length <= 0" style="padding-top: 100px;">
          <el-result icon="info" title="未搜索到结果"></el-result>
        </div>
      </div>
      <hot class="mrTop30"></hot>
    </div>
    <!--收藏官网-->
    <el-dialog
      title="收藏官网"
      :visible.sync="dialogVisible"
      :modal="false"
      width="30%"
    >
      <el-select
        v-model="currentCate"
        placeholder="请选择分类"
        style="width: 80%;"
      >
        <el-option
          v-for="item in cates"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="collect()">确 定</el-button>
      </span>
    </el-dialog>
    <div ref="scrollIdRef" id="scrollId" style="width: 1px;height: 1px;"></div>
  </div>
</template>

<script>
import Hot from "@/components/search/hot";
export default {
  name: "searchResult",
  components: { Hot },
  data() {
    return {
      lowestLevel: false,
      page: 1,
      type: "", //分类
      keyword: "", //搜索关键字,
      res: [], //搜索结果
      config: {
        sites: ["wechat", "qzone","weibo"],
        image: "",
        title: "",
        description: "",
        url: "", // 网址，默认使用 window.location.href
        source: "", // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
        wechatQrcodeTitle: "微信扫一扫：分享", // 微信二维码提示文字
        wechatQrcodeHelper:
          "<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>",
      },
      showShare: -1, //显示分享
      cates: [], //收藏官网分类
      dialogVisible: false, //显示收藏弹窗
      currentCate: "", //选中的官网分类
      setId: "", //当前选中的官网id

      tags: [
        // { id: 1, name: '综合榜' },
        { id: 3, name: "热度榜" },
        { id: 6, name: "最新榜" },
        { id: 4, name: "广告榜" },
        { id: 2, name: "点赞榜" },
        { id: 8, name: "评价榜" },
        { id: 5, name: "分享榜" },
        { id: 7, name: "收藏榜" },
      ],
      isActive: 3,
      searchToken: "",
      searchType: "",
    };
  },
  created() {
    let token = this.$store.state.token;
    //获取从首页跳转来的搜索关键字 并 搜索
    let keyword = this.$route.query.keyword;
    let type = this.$route.query.type;
    this.keyword = keyword;
    this.type = type;
    this.search(token, keyword, type);
    (this.searchToken = token), (this.searchType = type);
    this.getCates();
  },
  mounted() {
    let taht = this;
    window.addEventListener("scroll", taht.Scrollbottom); //页面加载时监听滚动事件
  },
  watch: {
    //监听路由参数  搜索
    "$route.params": function(route) {
      this.keyword = this.$route.query.keyword || "";
      this.type = this.$route.query.type;
      // this.type = 1
      let token = this.$store.state.token;
      this.search(token, this.keyword, this.type);
    },
  },
  methods: {
    Scrollbottom() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("滚动到底部了");
        this.page++;
        this.search(this.searchToken, this.keyword, this.searchType, true);
      }
    },
    findDataList() {
      let list = this.list;
      let last = list[list.length - 1];
      return new Promise((resolve) => {
        // 模拟服务端数据
        let newList = Array.from(Array(10), (item, index) => index + last + 1);
        setTimeout(() => {
          resolve(newList);
        }, 1000);
      });
    },
    imformation(id, item) {
      //获取资讯榜单
      this.currentName = item.name;
      this.isActive = id;
      let token = this.$store.state.token;
      this.search(token, this.keyword, this.type);
    },
    setCollect(id) {
      //设置收藏
      let token = localStorage.getItem("yizhibang"); //检测 是否存在token 和 用户信息
      if (token) {
        this.dialogVisible = true;
        this.setId = id;
      } else {
        this.$alert("请登录后操作", { type: "error" }).then((res) => {
          this.$store.commit("showLogin", true);
        });
      }
    },
    zan(item) {
      //点赞
      let token = localStorage.getItem("yizhibang"); //检测 是否存在token 和 用户信息
      if (!token) {
        this.$alert("请登录后操作", { type: "error" }).then((res) => {
          this.$store.commit("showLogin", true);
        });
        return;
      }
      this.$axios("POST", "/api/member/uodateSite", {
        id: item.id,
      }).then((res) => {
        if (res.code === 1) {
          this.$message.success(res.msg);
          let token = this.$store.state.token;
          this.search(token, this.keyword, this.type);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    goDetail(item) {
      //跳转到官网详情
      console.log("跳转");
      if (this.showShare != -1) {
        return;
      }
      if (item.uid) {
        let query = { sid: item.id, type: this.type };
        if (this.type == 6) {
          query.isActive = 4;
        }
        this.$router
          .push({
            path: "/search/webdetail",
            query: query,
          })
          .catch((err) => err);
      } else {
        if (item.url1) window.open(item.url1);
      }
    },

    goDetail2(item, type) {
      //跳转到官网指定窗口
      let query = { sid: item.id, active: type };
      this.$router
        .push({
          path: "/search/webdetail",
          query: query,
        })
        .catch((err) => err);
    },
    setShare(item, index) {
      // 设置分享的配置
      console.log(item);

      // 切换分享显示状态
      if (this.showShare === index) {
        this.showShare = -1;
      } else {
        this.showShare = index;
      }

      // 构造分享链接
      let host = `${window.location.protocol}//${window.location.host}/search/webdetail?sid=${item.id}`;

      // 检查 item.url1 是否包含协议部分
      const urlPattern = /^https?:\/\//i;

      if (item.uid) {  
        this.config.url = host;
      } else {
        if(item.url1){
          console.log('test')
         return  this.config.url = item.url1;
        }
        this.config.url = item.url
      }

      // 设置分享图片、标题和描述
      this.config.image = `${this.$store.state.url}${item.image}`;
      this.config.title = item.sitename;
      this.config.description = item.keyword;

      console.log(this.config);

      // 发送请求以增加分享计数
      this.$axios
        .post("/api/Comment/addSiteNum", { id: item.id })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.error("Error adding site number:", error);
        });
    },
    search(token, keyword, type, isPush) {
      //搜索
      if (!isPush) {
        this.page = 1;
        this.lowestLevel = false;
      }
      let data = {
        keyword: keyword,
        token: token,
        type: type,
        is_type: this.isActive,
        page: this.page,
      };
      let cate_id = this.$route.query.cateid;
      if (cate_id) data.cate_id = cate_id;
      // this.$message.info('努力加载中')
      this.$axios("POST", "/api/search/search", data).then((res) => {
        if (res.code === 1) {
          if (isPush) {
            this.res = [...this.res, ...res.data];
            if (res.data.length !== 10) {
              this.lowestLevel = true;
            }
            return false;
          }
          this.res = res.data;
        } else {
          // this.res = []
        }
      });
    },
    getCates() {
      //获取收藏官网分类
      this.$axios("POST", "/api/Member/getCateList", {}).then((res) => {
        if (res.code === 1) {
          this.cates = res.data;
        }
      });
    },
    collect() {
      //收藏官网
      if (!this.currentCate) {
        return this.$alert("请选择分类");
      }
      this.$axios("POST", "/api/member/collect", {
        id: this.setId,
        type: 8,
        cate_id: this.currentCate,
      }).then((res) => {
        if (res.code === 1) {
          this.$message.success(res.msg);
          this.dialogVisible = false;
          let token = this.$store.state.token;
          this.search(token, this.keyword, this.type);
        } else {
          this.$message.error(res.msg);
          this.dialogVisible = false;
        }
      });
    },
  },
};
</script>

<style scoped>
div {
  line-height: normal;
}

.shareicon {
  position: absolute;
  left: 125px;
  background-color: #ffffff;
  top: 100px;
  z-index: 99;
}

.search_res {
  width: 100%;
  box-sizing: border-box;
  padding: 0 70px;
  padding-bottom: 100px;
}

.f1 {
  flex: 1;
}

.tag li.active {
  border: 1px solid #3a54ff;
  color: #3a54ff;
  padding: 0 11px;
  border-radius: 12px;
}

.tag li.norml {
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 0 11px;
  border-radius: 12px;
}

.no {
  width: 36px;
  height: 16px;
  background: #f3414a;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 3px;
  left: -50px;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
}

.no1 {
  background: #f3414a;
}

.no2 {
  background: #ff8547;
}

.no3 {
  background: #ffac38;
}
</style>
