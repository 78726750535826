<template>
  <!--需求详情-->
  <div class="demand_detail" style="width: 1200px;margin: 0 auto;">
    <!--导航-->
    <div class="dif alc tlt fs12 col999 mrTop20 mrBtm20">
      <router-link to="/" class="fs12 col999">首页</router-link>
      <p @click="$router.back()">>需求搜索</p>
      <p>>需求详情</p>
    </div>
    <div class="w100 h100 difb res_box">
      <!--详细需求内容-->
      <div style="width: 600px;">
        <h1 class="fs28 bold col333 tlt">{{res.title}}</h1>
        <ul class="dif difw mrTop30">
          <li class="fs14 tlt mrBtm10 mrRit30">
            <span class="col999">需求名称：</span>
            <span class="col333 tlt" style="min-width: 100px;max-width: 500px">{{res.name}}</span>
          </li>
          <li class="fs14 dif alc mrBtm10 mrRit30">
            <span class="col999">剩余天数：</span>
            <div class="col333 tlt">{{res.daynum}}天</div>
          </li>
          <li class="fs14   mrBtm10 mrRit30 tlt">
            <span class="col999">地点：</span>
            <span class="col333" style="min-width: 100px;max-width: 500px">{{res.address}}</span>
          </li>
          <li class="fs14   mrBtm10 mrRit30 tlt">
            <span class="col999">需求类型：</span>
            <span class="col333 tlt" style="min-width: 100px;max-width: 500px">{{res.type}}</span>
          </li>
          <li class="fs14 dif alc mrBtm10 mrRit10">
            <span class="col999">起止时间：</span>
            <div class="col333 tlt">
              {{getTime(res.start_time)}}至 {{getTime(res.end_time)}}
            </div>
          </li>
          <li class="fs14  tlt mrBtm10">
            <span class="col999">公司：</span>
            <span class="col333 tlt" style="min-width: 100px;max-width: 500px">{{res.unit}}</span>
          </li>
        </ul>
        <!--联系电话-->
        <div class="d_tit tlt   mrBtm30">
          <img src="@/assets/image/yizhibang/20telephone.png" style="width: 20px;height: 18px;">
          <span class="fs18 col333 bold mrLft10">联系方式</span>
          <span class="fs18 col333 bold" style="margin-left: 14px;" v-if="userInfo.is_autonym==1">{{res.user_name}} {{res.user_phone}}</span>
          <span class="fs18 col333 bold" style="margin-left: 14px;" v-else>通过实名认证即可查看联系方式</span>
        </div>
        <!--介绍-->
        <el-collapse v-model="cat" class="img_warp">
          <el-collapse-item class="tlt" name="1">
            <template slot="title">
              <div class="title posr dif alc">
                <div class="title_icon"></div>
                <h3 class="fs18 col333 tlt" style="margin-left: 5px;">批购数量</h3>
              </div>
            </template>
            <div class="fs14 col333" style="line-height: 40px;text-indent: 2em" v-html="unes(res.num)"></div>
          </el-collapse-item>

          <el-collapse-item class="tlt" name="2">
            <template slot="title">
              <div class="title posr dif alc">
                <div class="title_icon"></div>
                <h3 class="fs18 col333 tlt" style="margin-left: 5px;">详细要求</h3>
              </div>
            </template>
            <div class="fs14 col333" style="line-height: 40px;text-indent: 2em" v-html="unes(res.ask_detail)"></div>
          </el-collapse-item>

          <el-collapse-item class="tlt" name="3">
            <template slot="title">
              <div class="title posr dif alc">
                <div class="title_icon"></div>
                <h3 class="fs18 col333 tlt" style="margin-left: 5px;">交付方式</h3>
              </div>
            </template>
            <div class="fs14 col333" style="line-height: 40px;text-indent: 2em" v-html="unes(res.delivery_way)"></div>
          </el-collapse-item>

          <!--<el-collapse-item class="tlt" name="4">
            <template slot="title">
              <div class="title posr dif alc">
                <div class="title_icon"></div>
                <h3 class="fs18 col333 tlt" style="margin-left: 5px;">联系方式</h3>
              </div>
            </template>
            <div class="fs14 col333" style="line-height: 40px;text-indent: 2em" v-html="unes(res.contact_way)"></div>
          </el-collapse-item>-->

          <el-collapse-item class="tlt" name="4">
            <template slot="title">
              <div class="title posr dif alc">
                <div class="title_icon"></div>
                <h3 class="fs18 col333 tlt" style="margin-left: 5px;">其他</h3>
              </div>
            </template>
            <div class="fs14 col333" style="line-height: 40px;text-indent: 2em" v-html="unes(res.other)"></div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <!--右方资讯推荐-->
      <hot></hot>
    </div>
  </div>
</template>

<script>
import Hot from "@/components/search/hot";
export default {
  name: "demandDetail",
  components: {Hot},
  data() {
    return {
      userInfo: {
        is_pay: 0,
        is_autonym: 0
      },
      id: 0,
      res: [],//需求结果
      cat: ['1','2','3','4'],//默认介绍展开
    }
  },
  methods: {
    getUserInfo() { //获取用户信息
      this.$axios("POST","/api/user/getUser",{})
          .then(res => {
            this.userInfo = res.data
          })
    },
    getDemandDetail(id) { //获取资讯详情
      this.$axios("POST","/api/demand/demandDetail",{
        id
      }).then(res => {
        if(res.code===1) {
          this.res = res.data
        }else {
          alert('加载失败,请刷新重试')
        }
      })
    }
  },
  computed: {
    getTime() {
      return function (time) {
        var date = new Date(time*1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        return Y + M + D;
      }
    },
    unes() { //对富文本进行解码
      return function (str) {
        return unescape(str)
      }
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getUserInfo()
    this.getDemandDetail(this.id)
  }
}
</script>

<style>
.info li{
  width: 200px;
  margin: 4px 0;
  text-align: left;
}

.title_icon{
  width: 10px;
  height: 10px;
  background: #3765FF;
}

.img_warp img{
  max-width: 626px;
  height: auto;
}
</style>