<template>
    <!--修改个人资料-->
    <div class="mrAt box userInfoUP" style="padding: 26px 20px;" v-loading="isLoading">
      <!--:style="{'height':pageHeight-140+'px'}"-->
        <div  class="w100 bgff container fs15 col888">
            <div class="tlt pdBtm30" style="border-bottom:1px dashed #E6E6E6;">
                <div class="ava posr dif alc" style="margin-left: 26px;">
                  <!--用户头像-->
                  <div class="posr">
                    <el-upload
                        class="avatar-uploader"
                        action="https://yzb.yzbse.com/api/common/upload"
                        :show-file-list="false"
                        :on-error="onerror"
                        :on-success="onsuccess"
                        accept="image/png, image/jpeg"
                    >
                      <i class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <img :src="avatar" class="user_img" style="width: 81px;height: 81px;">
                  </div>
                  <div class="dif alc mrLft20 mrTop10">
                    <!--用户名-->
                    <div>
                      <el-input v-model="userName" placeholder="请输入您的职务" :disabled="!isUpUserName"></el-input>
                    </div>
                    <p class="pointer fs14 col3765FF mrLft10" @click="isUpUserName=!isUpUserName" v-if="isUpUserName">取消</p>
                    <p class="pointer fs14 col3765FF mrLft10" @click="isUpUserName=!isUpUserName" v-else>修改</p>
                  </div>
                </div>
            </div>
            <div class="dif alc box bde6" style="padding: 30px 40px;">
              <!--性别-->
              <div class="rowsc">
                <div class="rows">
                  <div class="fs14 col333 difcac">性别：</div>
                  <div class="dif alc" @click="gender=1">
                    <div class="gender_select">
                      <div v-if="gender===1"></div>
                    </div>
                    <div class="fs14 col999 mrLft10">男</div>
                  </div>
                  <div class="dif alc mrLft30" @click="gender=0">
                    <div class="gender_select">
                      <div v-if="gender===0"></div>
                    </div>
                    <div class="fs14 col999 mrLft10">女</div>
                  </div>
                </div>
                <div class="rows dif alc" style="margin-left: 69px;">
                  <div class="fs14 col333">生日星座：</div>
                  <!--<div><input @input="dateSelect($event)" type="date" class="dirthip_datea" value="1617148800000"></div>-->
                  <!--生日-->
                  <div class="mrLft10 mrRit10 birthday">
                    <el-date-picker
                        v-model="birthday"
                        type="date"
                        value-format="yyyy-MM-dd"
                        @change="dateSelect"
                        placeholder="选择日期">
                    </el-date-picker>
                  </div>
                  <!--星座-->
                  <div class="dirthip_dateb">
                    <input type="text" :value="constellation"  disabled class="w100 tct fs14 colfff" style="background: none">
                  </div>
                </div>
              </div>
            </div>
            <div>
                <div class="dif alc bde6" style="padding: 30px 40px;">
                    <div class="dif alc mrRit30">
                      <div class="fs14 col333" >所属职务：</div>
                      <div>
                        <el-input v-model="duty" placeholder="请输入您的职务" :disabled="isUpDuty"></el-input>
                      </div>
                      <p class="pointer fs14 col3765FF mrLft10" v-if="isUpDuty" @click="isUpDuty=!isUpDuty">修改</p>
                      <p class="pointer fs14 col3765FF mrLft10" v-else @click="isUpDuty=!isUpDuty">取消</p>
                    </div>
                    <div class="dif alc">
                      <div class="fs14 col333">个人签名：</div>
                      <div>
                        <el-input v-model="bio" placeholder="您的个人签名" :disabled="isUpBio"></el-input>
                      </div>
                      <p class="pointer fs14 col3765FF mrLft10" v-if="isUpBio" @click="isUpBio=!isUpBio">修改</p>
                      <p class="pointer fs14 col3765FF mrLft10" v-else @click="isUpBio=!isUpBio">取消</p>
                    </div>
                </div>
                <div class="dif alc bde6" style="padding: 30px 40px;">
                    <div class="dif alc mrRit30">
                      <div class="fs14 col333" >所属行业：</div>
                      <div>
                        <el-input v-model="industry" placeholder="请输入所属行业" :disabled="isUpIndustry"></el-input>
                      </div>
                      <p class="pointer fs14 col3765FF mrLft10" v-if="isUpIndustry" @click="isUpIndustry=!isUpIndustry">修改</p>
                      <p class="pointer fs14 col3765FF mrLft10" v-else @click="isUpIndustry=!isUpIndustry">取消</p>
                    </div>
                  <div class="dif alc mrRit30 posr">
                    <div class="fs14 col333" >毕业院校：</div>
                    <div>
                      <el-input v-model="academy" placeholder="请输入毕业院校" :disabled="isUpAcademy"></el-input>
                    </div>
                    <p class="pointer fs14 col3765FF mrLft10" v-if="isUpAcademy" @click="isUpAcademy=!isUpAcademy">修改</p>
                    <p class="pointer fs14 col3765FF mrLft10" v-else @click="isUpAcademy=!isUpAcademy">取消</p>
                  </div>
                </div>
                <div class="dif alc bde6" style="padding: 30px 40px;">
                    <div class="dif alc mrRit30">
                      <div class="col333 fs14">所在地址：</div>
                      <div>
                        <el-cascader
                            size="large"
                            :options="options"
                            v-model="selectedOptions"
                            @change="handleChange"
                            :disabled="isUpOptions"
                        >
                        </el-cascader>
                      </div>
                      <p class="col3765FF fs14 pointer mrLft10" @click="isUpOptions=!isUpOptions" v-if="isUpOptions">修改</p>
                      <p class="col3765FF fs14 pointer mrLft10" @click="isUpOptions=!isUpOptions" v-else>取消</p>
                    </div>
                    <div class="dif alc">
                      <div class="col333 fs14">所属公司/组织：</div>
                      <div>
                        <el-input v-model="company" placeholder="请输入所属公司/组织" :disabled="isUpCompany"></el-input>
                        <!--<el-select v-model="company" placeholder="请选择公司组织" :disabled="isUpCompany">-->
                        <!--  <el-option-->
                        <!--      v-for="item in natureList"-->
                        <!--      :key="item.id"-->
                        <!--      :label="item.title"-->
                        <!--      :value="item.title">-->
                        <!--  </el-option>-->
                        <!--</el-select>-->
                      </div>
                      <p class="col3765FF fs14 pointer mrLft10" @click="isUpCompany=!isUpCompany" v-if="isUpCompany">修改</p>
                      <p class="col3765FF fs14 pointer mrLft10" @click="isUpCompany=!isUpCompany" v-else>取消</p>
                    </div>
                </div>
                <div class="dif alc bde6" style="padding: 30px 40px;">
                    <div class="dif alc posr mrRit30">
                      <div class="fs14 col333">QQ 号码：</div>
                      <div>
                        <el-input v-model="qqnum" placeholder="请输入QQ号" :disabled="isUpQQnum"></el-input>
                      </div>
                      <p class="pointer fs14 col3765FF mrLft10" v-if="isUpQQnum" @click="isUpQQnum=!isUpQQnum">修改</p>
                      <p class="pointer fs14 col3765FF mrLft10" v-else @click="isUpQQnum=!isUpQQnum">取消</p>
                      <div class="isShow"></div>
                    </div>
                    <div class="dif alc posr mrRit30">
                      <div class="fs14 col333">微信号码：</div>
                      <div>
                        <el-input v-model="wechat" placeholder="请输入微信号码" :disabled="isUpWechat"></el-input>
                      </div>
                      <p class="pointer fs14 col3765FF mrLft10" v-if="isUpWechat" @click="isUpWechat=!isUpWechat">修改</p>
                      <p class="pointer fs14 col3765FF mrLft10" v-else @click="isUpWechat=!isUpWechat">取消</p>
                      <div class="isShow"></div>
                    </div>
                    <div class="dif alc posr mrRit30">
                      <div class="fs14 col333">邮箱：</div>
                      <div>
                        <el-input v-model="email" placeholder="请输入邮箱" :disabled="isUpEmail"></el-input>
                      </div>
                      <p class="pointer fs14 col3765FF mrLft10" v-if="isUpEmail" @click="isUpEmail=!isUpEmail">修改</p>
                      <p class="pointer fs14 col3765FF mrLft10" v-else @click="isUpEmail=!isUpEmail">取消</p>
                      <div class="isShow"></div>
                    </div>
                </div>
                <div class="dif alc bde6" style="padding: 30px 40px;">
                  <div class="dif alc posr mrRit30">
                    <div class="fs14 col333">座机号码：</div>
                    <div>
                      <el-input v-model="landline" placeholder="请输入座机号码" :disabled="isUpLandline"></el-input>
                    </div>
                    <p class="pointer fs14 col3765FF mrLft10" v-if="isUpLandline" @click="isUpLandline=!isUpLandline">修改</p>
                    <p class="pointer fs14 col3765FF mrLft10" v-else @click="isUpLandline=!isUpLandline">取消</p>
                    <div class="isShow"></div>
                  </div>
                  <div class="dif alc posr mrRit30">
                    <div class="fs14 col333">手机号码：</div>
                    <div>
                      <el-input v-model="mobile" placeholder="" :disabled="isUpMobile" maxlength="11"></el-input>
                    </div>
                    <!--<p class="pointer fs14 col3765FF mrLft10" v-if="isUpMobile" @click="isUpMobile=!isUpMobile">修改</p>-->
                    <!--<p class="pointer fs14 col3765FF mrLft10" v-else @click="isUpMobile=!isUpMobile">取消</p>-->
                    <div class="isShow"></div>
                  </div>
                </div>
                <div class="alc" style="padding: 20px 40px;">
                  <p class="tlt mrBtm10 col333 fs14">主营介绍</p>
                  <el-input v-model="introduce" type="textarea" style="resize: none" rows="6"></el-input>
                </div>
            </div>
            <div>
              <div @click="update" class="pointer mr0At difcac colfff fs24 up_button">保存</div>
              <!--<p class="tct fs14 col999 mrTop20">点击眼睛可以选择是否在个人主页和名片中显示</p>-->
            </div>
        </div>
    </div>
</template>

<script>
  import {provinceAndCityData,CodeToText,TextToCode } from 'element-china-area-data'
  export default {
    data() {
      return {
        natureList: [],//公司组织性质列表
        isLoading: true,
        pageHeight:document.documentElement.clientHeight,//获取页面高度
        userName:'',//用户名
        isUpUserName: false,
        userinfo: {},//用户信息
        avatar: '',//头像
        gender: 0,//性别 0女 1男
        birthday: '',//生日
        constellation: '',//星座
        duty: '',//职位
        isUpDuty: true,//修改职位
        bio: '',//个人签名
        isUpBio: true,//修改个人签名
        industry: '',//所属行业
        isUpIndustry: true,//修改所属行业
        academy: '',//毕业院校
        isUpAcademy: true,//修改毕业院校
        company: '',//公司租住
        isUpCompany: true,//修改公司
        landline: 0,//座机号
        isUpLandline: true,
        mobile: 0,//手机号
        isUpMobile: true,
        email: '',//邮箱
        isUpEmail: true,
        qqnum: 0,//QQ号
        isUpQQnum: true,
        wechat: '',//微信号
        isUpWechat: true,
        introduce: '',//主营
        options: provinceAndCityData,//二级联动 省  市
        selectedOptions: [],//选择的内容
        isUpOptions: true,//修改地址
        city_name: '',//城市名称
        province_name: '',//省市名称
        address: '',//地址全程
      }
    },
    created() {
      this.getList()//获取公司性质列表
      //获取用户个人信息
      let token = this.$store.state.token
      this.$axios("POST","/api/member/personalCenter",{
        token
      })
          .then(res => {
            this.isLoading = false
        let userinfo = res.data
        this.userName = userinfo.username
        this.birthday= userinfo.birthday
        this.avatar= userinfo.avatar

        if(userinfo.gender ==="女"){
          this.gender = 0
        }else{
          this.gender = 1
        }
        this.birthday= userinfo.birthday
        this.constellation= userinfo.constellation
        this.duty= userinfo.duty
        this.bio= userinfo.bio
        this.industry= userinfo.industry
        this.academy= userinfo.academy
        this.company= userinfo.company
        this.landline= userinfo.landline
        this.mobile= userinfo.mobile
        this.email= userinfo.email
        this.qqnum= userinfo.qqnum
        this.wechat= userinfo.wechat
        this.province_name = userinfo.province_name
        this.introduce = userinfo.introduce
        this.city_name = userinfo.city_name
        let address = ''
        address = TextToCode[userinfo.province_name][userinfo.city_name].code
        this.selectedOptions = address
            console.log(this.selectedOptions);
      })
    },
    methods:{
      getList() { //获取公司性质列表
        this.$axios("POST","/api/Company/getNatureList",{})
        .then(res => {
          if(res.code===1) this.natureList = res.data
        })
      },
      dateSelect(event) { //选择生日 获取星座
        this.birthday = event
        this.$axios("POST","/api/member/getUserConstellation",{
          date: event
        }).then(res => {
          if(res.code == '1') {
            this.constellation = res.data
          }else{
            alert(res.msg)
          }
        })
      },
      update() {
       this.$axios("POST","/api/member/userDataSave",{
         username: this.userName,//用户名
         avatar: this.avatar,//头像
         gender: this.gender,//性别 0女 1男
         birthday: this.birthday,//生日
         constellation: this.constellation,//星座
         duty: this.duty,//职位
         bio: this.bio,//个人签名
         industry: this.industry,//所属行业
         academy: this.academy,//毕业院校
         company: this.company,//公司租住
         landline: this.landline,//座机号
         mobile: this.mobile,//手机号
         email: this.email,//邮箱
         qqnum: this.qqnum,//QQ号
         wechat: this.wechat,//微信号
         city_name: this.city_name, //城市名称
         province_name: this.province_name, //省名
         introduce: this.introduce,
         address: this.address
       }).then(res => {
         if(res.code===1) { //修改成功
           this.$alert('修改成功',{type: 'success'})
           this.$router.go(0)
         }else{
           this.$alert(res.msg,{type: 'warning'})
         }
       })
      },
      handleChange() { //选择省市区之后
        this.province_name = CodeToText[this.selectedOptions[0]]; //省名
        this.city_name = CodeToText[this.selectedOptions[1]]; //市名
        this.address = this.province_name+','+this.city_name
      },
      onsuccess(file){ //上传成功
        this.avatar = file.data.fullurl
      },
      onerror(err,file,fileList) { //上传失败
        console.log(err);
        console.log(file);
        console.log(fileList);
        alert('上传失败')
      }
    }
  }
</script>

<style scoped>
    .up_button{
      width: 360px;
      height: 56px;
      background: #3A54FF;
      border-radius: 8px;
    }
    .userInfoUP .mrRit30{
      margin-right: 65px;
    }
    /*是否在主页显示按钮*/
    .isShow{
      width: 17px;
      height: 11px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -30px;
    }
    /*el输入框设置*/
    ::v-deep  .el-input__inner{
      width: 180px;
      border-radius: 50px;
      height: 35px;
      line-height: 35px;
    }
    .bde6{
      border-bottom:1px dashed #E6E6E6;
    }
    .container {
        /*overflow: hidden;*/
		    /*overflow-y: auto;*/
    }
    .user_img {
        width: 81px;
        border-radius: 50%;
    }
    /*性别选择*/
    .gender_select{
      position: relative;
      width: 20px;
      height: 20px;
      border: 1px solid #999899;
      border-radius: 50%;
    }
    .gender_select div{
      width: 10px;
      height: 10px;
      background: #3765FF;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    /*生日输入框*/
    ::v-deep .birthday .el-input__inner{
      border-radius: 50px;
      line-height: 35px;
      height: 35px;
    }
    ::v-deep .birthday .el-input__icon{
      line-height: 35px;
    }
    /*星座*/
    .dirthip_dateb{
      width: 100px;
      height: 30px;
      background: #3765FF;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    textarea{
      resize: none;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 20px;
    }

    /*头像上传*/
    .avatar-uploader .el-upload {

      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      font-size: 28px;
      color: #8c939d;
      width: 81px;
      height: 81px;
      line-height:81px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
</style>