<template>
  <!--联系合作反馈-->
  <div class="mb12 difcac">
    <div class="con_warp">
      <!--顶部选择-->
      <div class="w100 difa alc" style="height: 60px;border: 1px solid #EEEEEE;">
        <div class="dif alc pointer" @click="type=1">
          <div class="difcac " :class="type===1?'active1':'normal1'">
            <el-icon class="el-icon-check colfff" v-if="type===1"></el-icon>
          </div>
          <p class="fs18 col333 bold mrLft10">联系合作</p>
        </div>
        <div class="dif alc pointer" @click="type=2">
          <div class="difcac " :class="type===2?'active1':'normal1'">
            <el-icon class="el-icon-check colfff" v-if="type===2"></el-icon>
          </div>
          <p class="fs18 col333 bold mrLft10">建议反馈/系统找错</p>
        </div>
        <div class="dif alc pointer" @click="type=3">
          <div class="difcac " :class="type===3?'active1':'normal1'">
            <el-icon class="el-icon-check colfff" v-if="type===3"></el-icon>
          </div>
          <p class="fs18 col333 bold mrLft10">举报投诉</p>
        </div>
        <div class="dif alc pointer" @click="type=4">
          <div class="difcac " :class="type===4?'active1':'normal1'">
            <el-icon class="el-icon-check colfff" v-if="type===4"></el-icon>
          </div>
          <p class="fs18 col333 bold mrLft10">推荐网址</p>
        </div>
      </div>
      <!--详细内容-->
      <div class="box" style="padding: 0 50px;">
        <p class="fs14 col999" style="line-height: normal;padding: 14px 0">{{text}}</p>
        <div class="w100 tlt box" style="background: #f5f5f5;padding-bottom: 14px;">
          <!--标题-->
          <input type="text" v-model="title" placeholder="标题最多18个字" maxlength="18" class="fs16 bold col666 tlt box pdLft20 w100"
                 style="background: none;border-bottom: 1px solid #EBEBEB;height: 60px;">
          <!--具体描述-->
          <div style="line-height: normal">
            <textarea placeholder="请填写您的具体描述" v-model="content"
                style="resize: none;background: none;border: none;padding: 14px;padding-bottom: 0;" class="box pdLft20 w100 fs14 col666" rows="5" ></textarea>
          </div>
          <!--上传照片或者截图-->
          <div class="mrLft20">
            <el-upload
                action="https://yzb.yzbse.com/api/common/upload"
                :show-file-list="false"
                :on-success="onsucess"
                :before-upload="beforeAvatarUpload">
              <img src="@/assets/image/yizhibang/06cooperation_addimg.png" style="width: 96px;height: 96px;" v-if="imgUrl===''">
              <img :src="imgUrl" style="width: 96px;height: 96px;" v-else>
            </el-upload>
            <p class="fs14 col999" style="line-height: 10px;">添加图片或截图</p>
          </div>
        </div>
      </div>
      <!--联系方式-->
      <div class=" mr0At difcac">
        <p class="fs16 col333 bold">联系方式：</p>
        <input type="text" style="width: 508px;height: 42px;background: #F5F5F5;border-radius: 4px;" v-model="relation"
        class="box pdLft20 pdRit20" placeholder="请填写QQ号或微信号，方便沟通，我们会对您的联系方式保密，请放心填写！">
      </div>
      <!--按钮-->
      <div class="difcac">
        <div class="con_button1 colfff difcac" @click="closeCon">取消</div>
        <div class="con_button2 colfff difcac" @click="submit">提交反馈</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "contactCooperation",
  data() {
    return {
      type: 1,//类型 1联系合作 2建议反馈 3举报投诉
      imgUrl: '',//上传的图片路径
      title: '',//标题
      content: '',//内容
      relation: '',//联系方式
      text: '',
    }
  },
  created() {
    this.getConfig()
  },
  methods: {
    getConfig() {
      this.$axios("POST","/api/Index/getConfing",{name: 'lianxi'})
          .then(res => {
            if(res.code===1) this.text = res.data
          })
    },
    submit() { //提交反馈
      if(this.title==='') {
        this.$alert('请填写标题','提示')
        return
      }else if(this.content==='') {
        this.$alert('请填写详细内容','提示')
        return
      }else if(this.relation==='') {
        this.$alert('请填写联系方式','提示')
        return
      }
      this.$axios("POST","/api/advice/createAdvice",{
        type: this.type,
        title: this.title,
        content: this.content,
        relation: this.relation,
        image: this.imgUrl
      }).then(res => {
        if(res.code===1) {
          this.$message.success('提交成功')
          this.closeCon()
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    closeCon() { //关闭合作联系反馈窗口
      this.$emit('closecon')
    },
    beforeAvatarUpload(file) { //限制图片上传
      const isJPG = file.type === 'image/jpeg';
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      return isJPG;
    },
    onsucess(res,file) {
      if(res.code===1) {
        this.imgUrl = res.data.fullurl
      }else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
  .mb12{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(0,0,0,0.35);
    width: 100%;
    height: 100%;
  }
  .con_warp{
    width: 692px;
    padding-bottom: 20px;
    /*height: 600px;*/
    background: #FFFFFF;
    border-radius: 20px;
    overflow: hidden;
  }
  .active1{
    width: 22px;
    height: 22px;
    background: #3A54FF;
    border-radius: 4px;
  }
  .normal1{
    width: 22px;
    height: 22px;
    background: #DDDDDD;
    border-radius: 4px;
  }
  .con_button1{
    width: 226px;
    height: 50px;
    background: #CCCCCC;
    border-radius: 6px;
    cursor: pointer;
  }
  .con_button2{
    width: 226px;
    height: 50px;
    background: #3A54FF;
    border-radius: 6px;
    margin-left: 40px;
    cursor: pointer;
  }
</style>