<template>
  <!--软件搜索结果-->
  <div class="app_result" style="width: 1260px;margin: 0 auto;">
    <!--导航-->
    <div class="dif alc tlt fs12 col999 mrTop20 mrBtm20">
      <router-link to="/" class="fs12 col999">首页</router-link>
      <p>>软件搜索</p>
    </div>
    <div class="h100 difb res_box">
      <!--左侧列表-->
      <div style="width: 800px;">
        <!--上方分类-->
        <ul class="dif alc difw appres fs14 col999">
          <li class="mrBtm20 mrRit10">软件性质：</li>
          <li v-for="(item2,index2) in appNature"
              :class="nature===item2.id?'active3':''" @click="selectTag(item2.id,1)"
              :key="index2" class="pointer mrBtm20 mrRit10">{{item2.title}}</li>
        </ul>
        <ul class="dif alc difw appres fs14 col999">
          <li class="mrBtm20 mrRit10">软件授权：</li>
          <li v-for="(item2,index2) in appAccredit"
              :class="accredit===item2.id?'active3':''" @click="selectTag(item2.id,2)"
              :key="index2" class="pointer mrBtm20 mrRit10">{{item2.title}}</li>
        </ul>
        <ul class="dif alc difw appres fs14 col999">
          <li class="mrBtm20 mrRit10">软件语言：</li>
          <li v-for="(item2,index2) in appLanguage"
              :class="language===item2.id?'active3':''" @click="selectTag(item2.id,3)"
              :key="index2" class="pointer mrBtm20 mrRit10">{{item2.title}}</li>
        </ul>
        <ul class="dif alc difw appres fs14 col999">
          <li class="mrBtm20 mrRit10">软件：</li>
          <li v-for="(item2,index2) in class1"
              :class="classId===item2.id?'active3':''" @click="selectClass(item2.id)"
              :key="index2" class="pointer mrBtm20 mrRit10">{{item2.name}}</li>
        </ul>
        <ul class="dif alc difw appres fs14 col999">
          <li class="mrBtm20 mrRit10">游戏：</li>
          <li v-for="(item2,index2) in class2"
              :class="classId===item2.id?'active3':''" @click="selectClass(item2.id)"
              :key="index2" class="pointer mrBtm20 mrRit10">{{item2.name}}</li>
        </ul>
        <!--下方软件列表-->
        <ul v-if="res.length>0">
          <li class="appres_item dif alc" v-for="(item,index) in res" @click="goAppDetail(item.id)" :key="index">
            <img style="width: 100px;height: 100px;border-radius: 20px" class="bgccc" :src="$store.state.url+item.appimage">
            <!--软件信息-->
            <ul class="dif difw fs12 col666 mrTop10" style="width: 332px;margin-left: 17px;">
              <li style="width: 40%;margin-right: 10px;" class="one_overflow tlt mrBtm10">软件名称：{{item.title}}</li>
              <li style="width: 40%;margin-right: 10px;" class="one_overflow tlt mrBtm10">软件性质：{{item.nature}}</li>
              <li style="width: 40%;margin-right: 10px;" class="one_overflow tlt mrBtm10">软件语言：{{item.language}}</li>
              <li style="width: 40%;margin-right: 10px;" class="one_overflow tlt mrBtm10">软件授权：{{item.accredit}}</li>
              <li style="width: 40%;margin-right: 10px;" class="one_overflow tlt mrBtm10">软件大小：{{item.size}}<span v-if="item.size_type===1">KB</span><span v-if="item.size_type===2">MB</span></li>
              <li style="width: 40%;margin-right: 10px;" class="one_overflow tlt mrBtm10">软件版本：{{item.versions}}</li>
              <li class="one_overflow tlt">适用平台：{{item.platform}}</li>
            </ul>
            <!--二维码-->
            <div class="dif alc" style="margin-top: 26px;" v-if="$store.state.token">
              <div v-if="item.androidimage">
                <img style="width: 74px;height: 74px;" class="bgccc pointer" :src="$store.state.url+item.androidimage" @click.stop="$all.showImg($store.state.url+item.androidimage)">
                <p class="fs12 col333 tct" style="margin-top: 15px;">安卓版本下载</p>
              </div>
              <div v-else style="opacity: 0;">
                <img style="width: 74px;height: 74px;" class="bgccc" :src="$store.state.url+item.androidimage" >
                <p class="fs12 col333 tct" style="margin-top: 15px;">安卓版本下载</p>
              </div>
              <div class="mrLft30" v-if="item.iosimage">
                <img style="width: 74px;height: 74px;" class="bgccc pointer" :src="$store.state.url+item.iosimage" @click.stop="$all.showImg($store.state.url+item.iosimage)">
                <p class="fs12 col333 tct" style="margin-top: 15px;">苹果版本下载</p>
              </div>
              <div v-else style="opacity: 0;" class="mrLft30">
                <img style="width: 74px;height: 74px;" class="bgccc" :src="$store.state.url+item.androidimage" >
                <p class="fs12 col333 tct" style="margin-top: 15px;">安卓版本下载</p>
              </div>
            </div>
            <div v-else style="width: 178px;">
              <div class="fs14">登录后即可查看下载二维码</div>
            </div>
            <!--下载按钮-->
            <div class="difcc" style="margin-left: 24px;">
              <div class="down_button pointer">软件介绍</div>
              <div class="down_button pointer" style="margin-top: 16px;" @click.stop="goAppDetail(item.id,2)">PC端下载</div>
            </div>
          </li>
        </ul>
        <!--无搜索结果-->
        <div v-if="res.length<=0" style="padding-top: 100px;">
          <el-result icon="info" title="未搜索到结果"></el-result>
        </div>
      </div>

      <!--右侧推荐-->
      <hot></hot>
    </div>
  </div>
</template>

<script>
import Hot from "@/components/search/hot";
export default {
  name: "appResult",
  components: {Hot},
  data() {
    return {
      appNature: [],//获取软件性质
      appAccredit: [],//获取软件授权
      appLanguage: [],//获取软件语言
      class1: [],//软件分类
      class2: [],//游戏分类
      classId: '',//选中的分类
      language: '',//当前语言
      nature:'',//当前性质
      accredit:'',//当前授权
      appClass: [
        {
          name:'软件',
          list: ['购物','阅读','新闻','视频','旅游','工具','社交','音乐','美化','摄影','理财','系统','生活','出行','安全','教育','健康','娱乐','儿童','办公','通讯']
        },
        {
          name: '游戏',
          list: ['休闲益智','网络游戏','飞行射击','动作冒险','体育竞速','鸡排中心','经营策略','角色扮演']
        }
      ],
      activeClass: 0,
      activeItem: 0,
      res: [],//软件搜索结果
      keyword: '',//关键字
      type: '',//搜索type

    }
  },
  created() {
    //获取从首页跳转来的搜索关键字 并 搜索
    let keyword = this.$route.query.keyword
    this.keyword = keyword
    let type = this.$route.query.type
    this.type = type
    this.search(keyword,type)
    //获取软件性质 授权 语言 分类
    this.getAppNature()
    this.getAppAccredit()
    this.getAppLanguage()
    this.getClass()
  },
  watch:{
    //监听路由参数  搜索
    "$route.params":function (route){
      this.keyword = this.$route.query.keyword
      this.type = this.$route.query.type

      this.search(this.keyword,this.type)
    }
  },
  methods: {

    selectClass(id) { //选择分类
      if(this.classId==id) {
        this.classId = ''
      }else {
        this.classId = id
      }
      this.search(this.keyword,this.type)
    },
    getClass() { //获取软件/游戏分类
      this.$axios("POST","/api/app/getAppCate",{})
      .then(res => {
        this.class1 = res.data.class1
        this.class2 = res.data.class2
      })
    },
    selectTag(id,type) {
      if(type===1) {
        this.nature = id
      }else if(type===2) {
        this.accredit = id
      }else if(type===3) {
        this.language = id
      }
      this.search(this.keyword,this.type)
    },
    getAppNature() { //获取软件性质
      this.$axios("POST","/api/app/getAppNature",{})
          .then(res => {
            if(res.code === 1) {
              this.appNature = res.data
              this.appNature.unshift({id: '',title: '不限'})
            }else{
              alert(res.msg)
            }
          })
    },
    getAppAccredit() {//获取软件授权
      this.$axios("POST","/api/app/getAppAccredit",{})
          .then(res => {
            if(res.code === 1) {
              this.appAccredit = res.data
              this.appAccredit.unshift({id: '',title: '不限'})
            }else{
              alert(res.msg)
            }
          })
    },
    getAppLanguage() { //获取软件语言
      this.$axios("POST","/api/app/getAppLanguage",{})
          .then(res => {
            if(res.code === 1) {
              this.appLanguage = res.data
              this.appLanguage.unshift({id: '',title: '不限'})
            }else{
              alert(res.msg)
            }
          })
    },
    search(keyword,type){ //搜索
      this.$message.info('努力加载中')
      this.$axios("POST",'/api/search/search',{
        keyword,
        type,
        language: this.language,
        nature: this.nature,
        accredit: this.accredit,
        classify: this.classId
      }).then(res => {
        if(res.code == '1') {
          this.res = res.data
          console.log(res);
        }else{
          this.res = []
          console.log(res);
        }
      })
    },
    changeActive(index,index2) { //修改选中分类的颜色
      this.activeClass = index
      this.activeItem = index2
    },
    goAppDetail(id,type) { //跳转到软件详情
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      let data = {
        id: id
      }
      if(type) data.type = type
      let routeUrl = this.$router.resolve({
        path: '/search/app/detail',
        query: data
      })
      window.open(routeUrl .href, '_blank');
    },
  }
}
</script>

<style scoped>
  .appres li.active3{
    color: #3765FF;
  }
  .appres_item{
    width: 800px;
    height: 146px;
    background: #FAFAFA;
    border: 1px solid #F5F5F5;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 23px 20px;
  }
  .down_button{
    width: 120px;
    height: 40px;
    background: #3A54FF;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 16px;
  }
</style>