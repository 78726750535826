<template>
  <div class="tlt container" :style="{'height':pageHeight-110+'px'}">
    <h3 class="mrBtm20">申请新分类</h3>
    <p class="fs14 col333">您好，感谢您使用亿指榜服务，我们对您没有找到匹配自己的分类感到抱歉，欢迎您提出建议我们将努力完善~</p>
    <p class="fs14 col333 mrTop10 mrBtm10">感谢您的支持与信任，成功后您将有机会获得精美礼品！</p>
    <!--申请分类-->
    <div class="dif alc mrTop20">
      <div class="difcac box mrRit10 pointer" :class="type===1?'active':'normal'" @click="type=1;pid=''">
        <el-icon class="el-icon-check colfff fs12" v-if="type===1"></el-icon>
      </div>
      <p>申请大分类</p>
      <div class="mrLft20">
        <el-input placeholder="请输入新分类" :disabled="type!==1" v-model="title1"></el-input>
      </div>
    </div>
    <!--申请子菜单-->
    <div class="dif alc mrTop20">
      <div class="difcac box mrRit10 pointer" :class="type===2?'active':'normal'" @click="type=2;pid=''">
        <el-icon class="el-icon-check colfff fs12" v-if="type===2"></el-icon>
      </div>
      <p>申请小菜单</p>
      <!--选择分类-->
      <div class="mrLft20">
        <el-select v-model="pid" placeholder="请选择分类" style="width: 189px;" :disabled="type!==2" @change="changPid">
          <el-option
              v-for="(item,index) in classity"
              :key="index"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="mrLft20">
        <el-input v-model="title2" placeholder="请输入子分类名" :disabled="type!==2" ></el-input>
      </div>
    </div>
    <!--申请子菜单的子菜单-->
    <!--<div class="dif alc mrTop20">
      <div class="difcac box mrRit10 pointer" :class="type===3?'active':'normal'" @click="type=3;pid=''">
        <el-icon class="el-icon-check colfff fs12" v-if="type===3"></el-icon>
      </div>
      <p>申请子菜单</p>
      &lt;!&ndash;选择分类&ndash;&gt;
      <div class="mrLft20">
        <el-select v-model="pid3" placeholder="请选择分类" style="width: 189px;" :disabled="type!==3" @change="changPid3">
          <el-option
              v-for="(item,index) in classity"
              :key="index"
              :label="item.name"
              :value="index">
          </el-option>
        </el-select>
      </div>
      &lt;!&ndash;选择子分类&ndash;&gt;
      <div class="mrLft20">
        <el-select v-model="pid2" placeholder="请选择子分类" style="width: 189px;" :disabled="type!==3" @change="changPid2">
          <el-option
              v-for="(item,index) in classity[current].sonInfo"
              :key="index"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="mrLft20">
        <el-input v-model="title3" placeholder="请输入子菜单名" :disabled="type!==3" ></el-input>
      </div>
    </div>-->
    <!--上传图片或截图-->
    <el-upload
        action="https://yzb.yzbse.com/api/common/upload"
        :show-file-list="false"
        :on-success="onsuccess"
        :before-upload="beforeAvatarUpload">
      <div class="upload_new difcac mrTop10" v-if="imgUrl===''">
        <el-icon class="el-icon-plus col3765FF" style="font-size: 32px;"></el-icon>
      </div>
      <div v-else class="upload_new mrTop10">
        <img :src="this.$store.state.url+imgUrl" class="w100 h100">
      </div>
    </el-upload>
    <div class="colfff pointer fankui difcac fs24" @click="submit">提交反馈</div>
    <div class="colfff pointer fankui difcac fs24" style="background: #CCCCCC;margin-top: 20px;" @click="goBack">返回</div>
  </div>
</template>

<script>
export default {
  name: "newClass",
  data() {
    return {
      pageHeight:document.documentElement.clientHeight,//获取页面高度
      imgUrl: '',//上传图片后的地址
      classity: [],//分类列表
      type: 1,//1申请新分类 2申请菜单
      pid: '',//申请子菜单时，父级id
      pid2: '',//子菜单的父级id
      pid3: '',//没用
      current: '0',//当前选择的子菜单父级的父级
      title1:'',//申请新分类
      title2: '',//申请子菜单
      title3: '',//申请子菜单的子菜单
    }
  },
  methods: {
    goBack() {
      this.$emit("changeNew",false)
    },
    changPid(e) {
      this.pid = e
    },
    changPid3(index) {
      this.current = index
      console.log(index);
    },
    changPid2(e) {
      this.pid2 = e
    },
    submit() { //申请新分类
      let title = ''
      let pid = 0
      if(this.type===1) {
        title = this.title1
      }else if(this.type===2) {
        title = this.title2
        pid = this.pid
      }else if(this.type===3) {
        title = this.title3
        pid = this.pid2
      }
      if(title==='') {
        this.$message.error('请输入内容')
        return
      }
      if(pid==='' && this.type!=1) {
        this.$message.error('请选择分类')
        return
      }
      this.$axios("POST","/api/Release/applyClassify",{
        type: this.type,
        title: title,
        pid: pid,
        image: this.imgUrl
      }).then(res => {
        if(res.code===1) {
          this.$message.success(res.msg)
          this.$emit("changeNew",false)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    getClassity() { //获取分类
      this.$axios("POST","/api/classity/readClassity",{})
        .then(res => {
          if(res.code===1) {
            this.classity = res.data
          }else {
            this.$message.error(res.msg)
          }
        })
    },
    onsuccess(res,file) { //图片上传成功之后
      if(res.code===1) {
        this.imgUrl = res.data.fullurl
      }else {
        this.$message.error(res.msg)
      }
    },
    beforeAvatarUpload(file) { //限制图片上传
      const isJPG = file.type === 'image/jpeg';
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      return isJPG;
    },
  },
  created() {
    //获取分类列表
    this.getClassity()
  }
}
</script>

<style scoped>
  .active{
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background: #3765FF;
  }
  .normal{
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid #999899;
  }
  .container {
    overflow: hidden;
    overflow-y: auto;
  }
  .bgf5{
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
  }
  .bgf5 textarea{
    margin-top: 10px;
    width: 100%;
    resize: none;
    border: none;
    box-sizing: border-box;
    background: #F5F5F5;
  }
  .upload_new{
    width: 100px;
    height: 100px;
    background: #F0F0F0;
    border-radius: 10px;
  }
  /*提交反馈*/
  .fankui{
    width: 440px;
    height: 56px;
    background: #3765FF;
    border-radius: 6px;
    margin: 0 auto;
    margin-top: 40px;
  }
</style>