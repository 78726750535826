<template>
  <!--官网详情或公司搜索-->
  <div @click="showShare=false">
    <div style="width: 1200px;" class="mr0At mrBtm30">
      <!--上方路径-->
      <div class="path" style="margin-bottom: 45px;">
        <ul class="dif alc mrTop10 fs12 col999">
          <li><router-link to="/" class="fs12 col999">首页</router-link></li>
          <li>>官网搜索</li>
          <li>>{{info.sitename}}</li>
        </ul>
      </div>
      <!--公司信息-->
      <div class="off_info pdBtm10">
        <!--上方信息-->
        <div class="info_left dif" >
          <!--logo图片-->
          <div class="bgf5 mrRit30 bra10 one_overflow"
               style="width: 150px; height: 80px;">
            <img :src="$store.state.url+info.image" alt="" style="width: 100%;height: 100%;">
          </div>
          <!--品牌 公司名 关键词-->
          <div class="info_detail">
            <div class="dif alc">
              <!--公司名称-->
              <h2 class="fs22 col000 bold mrRit30">{{info.enterprise}}</h2>
              <!--认证-->
              <!--<ul class="renzheng difc alc h100">
                <li>官网认证</li>
                <li>品牌总部</li>
                <li>优质认证</li>
              </ul>-->
            </div>
            <div class="site_keyword">
              <p style="margin-bottom: 5px;" class="col333 bold">{{info.sitename}}</p>
              <p>{{info.keyword}}</p>
             
            </div>
          </div>
        </div>
        <div class="site_keyword" style="width: 1062px;display: flex;flex-direction: column;align-items: flex-start;margin-left: 138px;">
            <p :style="index==0?'':'margin-top: 10px;'" v-for="(item,index) in urls" :key="index">
              <span>官网地址：</span>
              <!-- :href="item" -->
              <a style="color: blue;cursor: pointer;" @click="gojump(item)">{{ item }}</a>
            </p>
        </div>
        
        <!--下方窗口选择-->
        <div class="info_right" style="padding-left: 80px;margin-top: 20px;">
          <ul class="dif col666 fs14 windows posr alc" style="z-index: 80;" ref="obtain" id="obtain">
            <li v-for="(item,index) in window" class="pointer posr fs14 col999 mrRit30" style="z-index: 80"
                :class="isActive==item.id?'active':''" @click.stop="getInfo(item.id)">
              <img src="@/assets/image/yizhibang/16_message.png" style="width: 15px;height: 13px;margin-right: 5px;" v-if="item.id===7">
              <span v-if="item.id!==8">{{item.name}}</span>
              <span v-if="item.id==8" :class="{'texta': info.is_collect!=0}">收藏官网</span>
              <!-- 当前激活窗口下方显示的箭头 -->
              <div class="bottom_arrow" v-if="isActive===2 && index===1"></div>
              <div class="bottom_arrow" v-if="isActive===4 && index===3"></div>
              <div class="bottom_arrow" v-if="isActive===7 && index===6"></div>
            </li>
            <li class="pointer posr fs14 col999" style="z-index: 80" @click="zan()">
              <img src="@/assets/image/zan1.png" v-if="info.is_dz>=1" style="width: 15px;height: 15px;margin-right: 5px;">
              <img src="@/assets/image/zan2.png" v-else style="width: 15px;height: 15px;margin-right: 5px;">
              <span style="color: #F6AA00;" v-if="info.is_dz>=1">{{info.fabulous}}</span>
              <span style="color: #bfbfbf;" v-else>{{info.fabulous}}</span>
            </li>
            <!--官网窗口选择-->
            <!-- <ul class="window_s difc alc" v-if="isActive==1">
              <li v-for="(item,index) in urls" :class="currentWindow===index?'active':''" class="pointer"
                  @click="currentWindow=index;scrollTo()">{{index+1}}</li>
            </ul> -->
            <div class="share_icon" v-if="showShare" @click.stop>
              <share :config="config"></share>
            </div>
            <!--服务窗口下方图标-->
            <ul class="service dif alc" v-if="isActive==3">
              <li v-for="(item,index) in service" @click="serviceTypeId=item.id"
                  :class="serviceTypeId===item.id?'active2':''"
                  class="pointer posr">{{item.name}}
                <!-- 当前激活窗口下方显示的箭头 -->
                <div class="bottom_arrow" v-if="serviceTypeId===item.id"></div>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </div>

    <!--窗口选择-->
    <!--官网窗口-->
    <!-- <webview v-if="isActive==1" :urls="urls" :current-window="currentWindow"></webview> -->
    <!--信息介绍-->
    <info-introduce v-if="isActive==2" :sid="sid"></info-introduce>
    <!--服务窗口-->
    <service-center v-if="isActive==3" :type-id="serviceTypeId" :sid="sid"></service-center>
    <!--下载窗口-->
    <download v-if="isActive==4" :download="download"></download>
    <!--评论-->
    <comment v-if="isActive==7" :sid="sid"></comment>
    <!--收藏官网-->
    <el-dialog
        title="收藏官网"
        :visible.sync="dialogVisible"
        :modal="false"
        width="30%">
      <el-select v-model="currentCate" placeholder="请选择分类" style="width: 80%;">
        <el-option
            v-for="item in cates"
            :key="item.id"
            :label="item.name"
            :value="item.id">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="collect()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import infoIntroduce from '@/components/search/searchCommon/infoIntroduce' //信息介绍
import download from '@/components/search/searchCommon/download' //下载窗口
import comment from "@/components/search/searchCommon/comment";//评论
import ServiceCenter from "@/components/search/searchCommon/serviceWindow/serviceCenter";
import Bottom_footer from "@/components/bottom_footer";
import Webview from "@/components/search/searchCommon/serviceWindow/webview";
export default {
  name: "webDetail",
  components: {Webview, Bottom_footer, ServiceCenter, infoIntroduce,download,comment},
  data() {
    return {
      showShare: false,//显示分享
      config:{
        sites: ['qq','wechat','qzone'],
        image: '',
        title: '',
        description: '',
        url: '', // 网址，默认使用 window.location.href
        source: '', // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
        wechatQrcodeTitle: '微信扫一扫：分享', // 微信二维码提示文字
        wechatQrcodeHelper: '<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>'
      },
      window: [
        {id:1,name: ''},
        {id:2,name: '信息介绍'},
        {id:3,name: '服务窗口'},
        {id:4,name: '下载窗口'},
        {id:5,name: '分享'},
        {id:8,name: '收藏官网'},
        {id:7,name: '留言'},
      ],
      offWindow: [{id: 11}, {id: 12}, {id: 13}, {id: 14},], //官网窗口选择
      service: [ //服务窗口
        {id:1,name: '资讯'},
        {id:2,name: '需求'},
        {id:3,name: '店铺'},
        {id:4,name: '加盟'},
        {id:5,name: '招聘'},
        // {id:6,name: '定制'},
      ],
      isActive: 2,
      serviceTypeId: 1,
      sid: 0,
      infodetail: [],//信息介绍
      download: [],//下载窗口
      info: {
        is_dz: 0,
        keyword: '',
        image: '',
        enterprise: '',
        sitename: '',
        fabulous: 0,
        is_collect: 0,
      },
      urls: [],//外部链接地址
      currentWindow: 0,//当前选中的外部链接

      cates: [],//收藏官网分类
      dialogVisible: false,//显示收藏弹窗
      currentCate: '',//选中的官网分类
      setId: '',//当前选中的官网id


    }
  },
  methods: {
    scrollTo() {
      window.scrollTo({
        //滚动到元素位置
        //top: this.$refs[元素].offsetTop,//offsetTop 相对于offsetparent 定位,当有定位时，不准确。
        // top: el.getBoundingClientRect().top ,//推荐使用，getBoundingClientRect 相对于当前视口的位置
        //top: 400//滚动到指定距离
        //top: 0,//滚动到顶部
        top: document.documentElement.scrollHeight,//滚动到底部
        behavior: "smooth" // 平滑滚动
      });
    },
    init() {
      this.sid = this.$route.query.sid
      if(this.$route.query.type) {
        this.isActive = 3
        this.serviceTypeId = Number(this.$route.query.type)
      }
      if(this.$route.query.type==2) { //信息介绍
        this.isActive = 2
      }
      if(this.$route.query.type==1) { //官网
        this.isActive = 2
        this.serviceTypeId = 1
      }
      if(this.$route.query.active && this.$route.query.active==2) { //信息介绍
        this.isActive = 2
      }
      if(this.$route.query.active && this.$route.query.active==7) { //信息介绍
        this.isActive = 7
      }
      if(this.$route.query.isActive==4) { //加盟
        this.isActive = 3
        this.serviceTypeId = 4
      }
      if(this.$route.query.isActive==1) { //咨询
        this.isActive = 3
        this.serviceTypeId = 1
      }
      this.config.url = document.location.href
      //生成访问记录
      this.createData()
      this.getDetail()
      this.getCates();
    },
    setShare(item,index) { //设置分享的配置
      this.showShare = !this.showShare
      
      this.$axios("POST","/api/Comment/addSiteNum",{id: this.sid})
          .then(res => {
            console.log(res);
          })
      return
      console.log(item)
      let host = window.location.protocol+"//"+window.location.host+ '/search/webdetail?sid='+item.id
      this.config.url = host //不能分享localhost
      this.config.image = this.$store.state.url + this.info.image
      this.config.title = this.info.sitename
      this.config.description = this.info.keyword
    },
    zan() { //点赞
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      this.$axios("POST","/api/member/uodateSite",{
        id: this.sid
      }).then(res => {
        if(res.code===1) {
          this.$message.success(res.msg)
          this.getDetail()
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    getDetail() { //获取官网详情
      this.$axios("POST","/api/Release/getReleaseSite",{id: this.sid})
      .then(res => {
        if(res.code===1) {
          this.info = res.data
          console.log(res.data)
          if(res.data.url && res.data.url.length>0) this.urls = res.data.url
        }
      })
    },
    createData() { //生成访问记录
      this.$axios("POST","/api/site/createInterviewData",{
        sid: this.sid
      }).then(res => {
        console.log(res);
      })
    },
    getInfo(id) {
      if(id===8){ //收藏官网
        let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
        if(!token) {
          this.$alert('请登录后操作',{type: 'error'})
              .then(res => {
                this.$store.commit('showLogin',true)
              })
          return
        }
        this.dialogVisible = true
        // this.$axios("POST","/api/member/collect",{
        //   id: this.sid,
        //   type: 8
        // }).then(res => {
        //   if(res.code===1) {
        //     this.$message.success(res.msg)
        //   }else {
        //     this.$message.error(res.msg)
        //   }
        // })
        return;
      }
      if(id===5) { //分享
        // this.showShare = !this.showShare
        this.setShare()
        return
      }
      this.isActive = id
      if(id === 4){ //下载窗口
        this.$axios("POST","/api/site/siteInfodetail",{
          sid: this.sid,
          type: id
        }).then(res => {
          this.download = res.data
          console.log(this.download);
        })
      }
    },
    getCates() { //获取收藏官网分类
      this.$axios("POST","/api/Member/getCateList",{})
          .then(res => {
            if(res.code===1) {
              this.cates = res.data
            }
          })
    },
    collect() { //收藏官网
      if(!this.currentCate) {
        return this.$alert('请选择分类')
      }
      this.$axios("POST","/api/member/collect",{
        id: this.sid,
        type: 8,
        cate_id: this.currentCate
      }).then(res => {
        if(res.code===1) {
          this.$message.success(res.msg)
          this.dialogVisible = false
        }else {
          this.$message.error(res.msg)
          this.dialogVisible = false
        }
        setTimeout(() => {
          this.getDetail()
        },1200)
      })
    },
    gojump(url){
      window.open(url)
    },
  },
  created() {
    this.init()
  },
  activated() {
    this.init()
  },
  watch: {
    "$route":function (newValue,oldValue){
      if(newValue.path==='/search/webdetail' && newValue.query.sid !=this.sid) {
        this.init()
      }
    }
  }
}
</script>

<style scoped>
.texta{
  color: #3A51FF;
}
  .share_icon{
    position: absolute;
    top: 19px;
    left: 298px;
    z-index: 957;
  }
  .bottom_arrow{
    width: 100px;
    height: 2px;
    background: #3A51FF;
    position: absolute;
    bottom: -41px;
    left: 50%;
    transform: translateX(-50%);
  }
  .bottom_arrow:before{
    border-bottom: 10px solid #3A51FF;
    border-left: 8px solid rgba(0,0,0,0);
    border-right: 8px solid rgba(0,0,0,0);
    content: "";
    display: block;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  .site_keyword{
    margin-top: 12px;
    font-size: 14px;
    color: #333333;
    width: 1062px;
    /*height: 70px;*/
    box-sizing: border-box;
    padding: 17px;
    background: #F5FBFF;
    text-align: left;
  }

  .renzheng li{
    width: 80px;
    height: 24px;
    background: #FDAE38;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 14px;
    margin-right: 10px;
  }
  .windows li.active{
    color: #3A54FF;
  }
  .window_s{
    position: absolute;
    width: 100px;
    bottom: -30px;
    left: -17px;
  }
  .window_s li{
    width: 18px;
    height: 18px;
    background: #EEEEEE;
    border-radius: 50%;
    margin: 0 2px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    color: #3765FF;
  }
  .window_s li.active{
    color: #FFFFFF;
    background: #3765FF;
    position: relative;
  }
  .window_s li.active:before{
    border-bottom: 10px solid #3A51FF;
    border-left: 8px solid rgba(0,0,0,0);
    border-right: 8px solid rgba(0,0,0,0);
    content: "";
    display: block;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
  }
  .service{
    position: absolute;
    bottom: -28px;
    left: 41px;
  }
  .service li{
    width: 42px;
    height: 20px;
    background: #EEEEEE;
    border-radius: 10px;
    color: #3A51FF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-right: 13px;
  }
  .service li.active2{
    color: #FFFFFF;
    background: #3765FF;
  }
</style>