<template>
  <div >
    <!--不是会员-->
    <!--<div style="padding-top: 90px;">
      <el-result icon="info" title="未开通会员" subTitle="开通会员即可无限制收藏信息">
        <template slot="extra">
          <el-button type="primary" size="medium" @click="goVip()">去开通</el-button>
        </template>
      </el-result>
    </div>-->
    <!--是会员-->
    <div class="w90 mrAt pdTop20 pdBtm20" style="min-height: 86vh">
      <div class="dif alc top_group">
        <div class="fs16 col666 pointer tlt" style="margin-right: 27px;"
             v-for="(item,index) in card" :key="index"
             :class="isActive==item.id?'active':''"
             @click="isActive=item.id;getCollect(item.id)">{{ item.name }}
        </div>
        <div class="top_search">
          <!--<img src="@/assets/image/yizhibang/50top_serch.png" class="top_search_icon">-->
          <!--<input type="text" v-model="keyword" class="top_search_input" placeholder="输入您想查找的消息内容">-->
        </div>
      </div>

      <div class="top_border">
        <div class="fs14 col333 mrLft20 pointer">全部（{{cont}}）</div>
        <!--<p class="bianji" @click="manage=!manage" v-if="manage">取消编辑</p>
        <p class="bianji" @click="manage=!manage" v-else>编辑收藏</p>-->
      </div>
      <!--收藏消息-->
      <div v-if="isActive==1">
        <!--消息列表-->
        <div style="margin-top: 14px;">
          <ul v-if="news.length>0">
            <li class="news_item" v-for="(item,index) in news" :key="index">
              <img src="@/assets/image/yizhibang/50notice.png" v-if="item[0].info_type==='系统消息'" style="width: 40px;height: 40px;">
              <img :src="item[0].uinfo.avatar" class="bra" v-else style="width: 40px;height: 40px;">
              <div style="margin-left: 14px;">
                <div class="dif alc">
                  <p class="fs14 col333 one_overflow" v-if="item[0].info_type==='系统消息'">{{item[0].title}}</p>
                  <p class="fs14 col333 one_overflow" v-if="item[0].info_type==='私信消息'">{{item[0].content}}</p>
                </div>
                <div class="dif alc mrTop10">
                  <p class="fs12 col999">{{item[0].info_type}}</p>
                  <p class="fs12 col999 mrLft30 mrRit30">{{item[0].add_time}}</p>
                  <p class="pointer fs12 mrRit20" @click.stop="cancelCollect(item[0],1)" style="color: #F6AA00">取消收藏</p>
                  <p class="pointer fs12 col3765FF" @click="setDetail(item[0],index)">查看详情>></p>
                </div>
              </div>
              <div class="news_guanli pointer" v-if="manage">
                <img src="@/assets/image/yizhibang/51noclose.png" style="width: 20px;height: 20px;">
                <!--<img src="@/assets/image/yizhibang/51close.png" style="width: 20px;height: 20px;">-->
              </div>
              <!--消息详情-->
              <div class="news_detail shadow_bottom" v-if="showDetail===index">
                <!--系统消息-->
                <div style="max-height: 220px;overflow-y: auto">
                  <h3 class="tlt" v-if="item[0].info_type==='系统消息'">{{item[0].title}}</h3>
                  <h3 class="tlt" v-if="item[0].info_type==='私信消息'">{{item[0].uinfo.uname}}：</h3>
                  <div class="fs16 col333 tlt mrTop10">{{item[0].content}}</div>
                </div>
              </div>
            </li>
          </ul>
          <div v-else class="tc" style="padding-top: 60px;">
            <div>暂无收藏</div>
          </div>
        </div>
      </div>
      <!--收藏资讯-->
      <div v-show="isActive==2">
        <!--资讯列表-->
        <div style="margin-top: 14px;">
          <ul v-if="adivce.length>0">
            <li class="zixun_item" v-for="(item,index) in adivce" :key="index" @click="goAdviceDetail(item[0])">
              <img class="bgccc" :src="item[0].images[0]"
                   style="width: 114px;height: 78px;margin-right: 21px;" v-if="item[0].images">
              <img class="bgccc" style="width: 114px;height: 78px;margin-right: 21px;" v-else>
              <div style="width: 400px;height: 76px;" class="difcb tlt">
                <h3 class="fs16 col1D1E20 two_overflow" style="font-weight:normal;">{{item[0].title}}</h3>
                <p class="fs12 col999">{{item[0].siteName}} {{item[0].createtime}}</p>
              </div>
              <!--<div class="zixun_share dif alc">
                <p>链接分享</p>
                <img src="@/assets/image/yizhibang/45qq.png" style="width: 20px;height: 20px;margin-left: 7px;"
                     class="pointer">
                <img src="@/assets/image/yizhibang/45qzone.png" style="width: 20px;height: 20px;margin: 0 7px;"
                     class="pointer">
                <img src="@/assets/image/yizhibang/45sina.png" style="width: 20px;height: 20px;" class="pointer">
              </div>-->
              <div class=" pointer">
                <!--<img src="@/assets/image/yizhibang/51noclose.png" style="width: 20px;height: 20px;">-->
                <p class="pointer zhaopin_share" @click.stop="cancelCollect(item[0],2)">取消收藏</p>
                <!--<img src="@/assets/image/yizhibang/51close.png" style="width: 20px;height: 20px;">-->
              </div>
            </li>
          </ul>
          <div v-else class="tc" style="padding-top: 60px;">
            <div>暂无收藏</div>
          </div>
        </div>
      </div>
      <!--收藏需求-->
      <div v-if="isActive==3">
        <div v-if="need.length>0">
          <!--需求标题-->
          <ul class="fs14 col333 bold dif alc" style="padding: 17px 0;">
            <li style="padding: 0 20px;">需求名称</li>
            <li style="padding: 0 30px;">需求类型</li>
            <li style="padding: 0 40px;">地点</li>
            <li style="padding: 0 36px;">工作单位</li>
            <li style="padding: 0 67px;">需求标题</li>
            <li style="padding: 0 79px;">起止时间</li>
            <li style="padding: 0 21px;">剩余天数</li>
            <li style="padding: 0 26px;">状态</li>
          </ul>
          <ul class="dif alc posr col333 fs12 xuqiu_item" v-for="(item,index) in need" :key="index" @click="goNeedDetail(item[0])">
            <li style="width: 96px;" class="one_overflow">{{item[0].name}}</li>
            <li style="width: 116px;" class="one_overflow">{{item[0].type}}</li>
            <li style="width: 108px;" class="one_overflow">{{item[0].address}}</li>
            <li style="width: 100px;" class="one_overflow">{{item[0].unit}}</li>
            <li style="width: 190px;" class="one_overflow">{{item[0].title}}</li>
            <li style="width: 214px;" class="one_overflow">
              <span style="line-height: normal;">{{item[0].start_time}}</span>至
              <span style="line-height: normal;">{{item[0].end_time}}</span>
            </li>
            <li style="width: 98px;" class="one_overflow">{{item[0].daynum}}天</li>
            <li style="width: 80px;" class="one_overflow">
              <span v-if="item[0].status===1">进行中</span>
              <span v-if="item[0].status===2">已过期</span>
            </li>
            <div class="pointer">
              <p class="pointer" @click.stop="cancelCollect(item[0],3)" style="color: #F6AA00">取消收藏</p>
              <!--<img src="@/assets/image/yizhibang/51noclose.png" style="width: 20px;height: 20px;">-->
              <!--<img src="@/assets/image/yizhibang/51close.png" style="width: 20px;height: 20px;">-->
            </div>
          </ul>
        </div>
        <div v-else class="tc" style="padding-top: 60px;">
          <div>暂无收藏</div>
        </div>
      </div>
      <!--收藏店铺-->
      <div v-if="isActive==4">
        <!--店铺列表-->
        <div style="margin-top: 14px;">
          <ul v-if="shop.length>0" class="shop_res dif difw">
            <li v-for="(item,index) in shop" class="mrBtm30" @click="goShopDetail(item)">
              <div class="good_img">
                <!--图片轮播-->
                <el-carousel indicator-position="none" height="248px" arrow="never">
                  <el-carousel-item v-for="(item2,index2) in item.images" :key="index2">
                    <div class="posr w100 h100">
                      <img  style="width: 248px;height: 248px;" :src="item2">
                      <div class="mb1"></div>
                      <div class="mb2"></div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
                <!--序号-->
                <!--<div class="noindex">NO.{{index+1}}</div>-->
                <!--品牌-->
                <div class="pinpai">
                  <marquee scrollamount=3 v-if="item.brand && item.brand.length>4">品牌：{{item.brand || '暂无'}}</marquee>
                  <span v-else>品牌：{{item.brand || '暂无'}}</span>
                </div>
                <!--公司-->
                <p class="compoany one_overflow" style="word-break: break-all">公司：{{item.compoany || '暂无'}}</p>
              </div>
              <div class="good_bottom posr">
                <div class="dif alc">
                  <p class="one_overflow fs12 col1C1C1C tlt" style="width: 155px;">{{item.title}}</p>
                </div>
                <div class="fs12 col3765FF tlt mrTop10">
                  <span class="pointer">进入店铺</span>
                  <span class="pointer mrLft30" style="color: #F6AA00;"
                        @click.stop="cancelCollect(item,4)">取消收藏</span>
                </div>
                <div class="news_guanli pointer" v-if="manage" style="right: 10px;">
                  <img src="@/assets/image/yizhibang/51noclose.png" style="width: 20px;height: 20px;">
                  <!--<img src="@/assets/image/yizhibang/51close.png" style="width: 20px;height: 20px;">-->
                </div>
              </div>
            </li>
          </ul>
          <div v-else class="tc" style="padding-top: 60px;">
            <div>暂无收藏</div>
          </div>
        </div>
      </div>
      <!--收藏加盟-->
      <div v-if="isActive==5">
        <div class="mrTop10 join_warp">
          <ul>
            <li class="join_item" v-for="(item,index) in 5" :key="index">
              <div class="no no1" v-if="index===0">NO.1</div>
              <div class="no no2" v-if="index===1">NO.2</div>
              <div class="no no3" v-if="index===2">NO.3</div>
              <router-link :to="{path:'/search/webdetail',query:{sid: item.id}}">
                <!--公司名称-->
                <div class="left_top dif alc">
                  <p class="fs16 col3A53FF" style="text-decoration: underline">品牌/买购物  公司/深圳榜中榜计算机有限公司 </p>
                </div>
                <!--详细信息-->
                <div class="left_center dif alc mrTop13">
                  <!--图片-->
                  <img  style="width: 125px;height: 76px;min-width: 125px;min-height: 76px" class="bgccc">
                  <!--标题 内容-->
                  <div class="dif w70 box pdLft10 pdTop10" style="height: 100px;flex-direction: column">
                    <h4 class="one_overflow tlt fs14 col333">这里是副标题</h4>
                    <p class="three_overflow fs12 tlt col666" style="line-height: 22px;width: 508px;">建材、知识策划、专题串联、名企排行、行业统计研究、信息数据剖析、工业
                      电脑 厂家授权经销商,工业电脑 正品产品,售后保证,工业电脑 钻石级代理商,全
                      面代理工业电脑,平板电脑,嵌入式主板,PC/104模块</p>
                  </div>
                </div>
              </router-link>
              <!-- 查看选项-->
              <div class="left_bottom mrTop20">
                <ul class="dif alc fs14">
                  <li class="mrRit30 pointer col3A53FF fs14">官网窗口</li>
                  <li class="mrRit30 pointer fs14 col999">公司介绍</li>
                  <li class="mrRit30 pointer fs14 col999">服务窗口</li>
                  <li class="mrRit30 pointer fs14 col999">下载软件</li>
                  <li class="mrRit30 pointer fs14 col999">分享</li>
                  <li class="mrRit30 pointer fs14 col999">收藏官网</li>
                  <li class="mrRit20 pointer fs14 col999 dif alc">
                    <img src="@/assets/image/yizhibang/16_message.png" style="width: 15px;height: 13px;">
                    <span style="margin-left: 5px;">留言</span>
                  </li>
                  <li class=" fs14 pointer col999 dif alc">
                    <img src="@/assets/image/yizhibang/16_give.png" style="width: 15px;height: 15px;">
                    <span style="margin-left: 5px;color:#F6AA00;">100W+</span>
                  </li>
                </ul>
              </div>
              <div class="news_guanli pointer" v-if="manage">
                <img src="@/assets/image/yizhibang/51noclose.png" style="width: 20px;height: 20px;">
                <!--<img src="@/assets/image/yizhibang/51close.png" style="width: 20px;height: 20px;">-->
              </div>
            </li>
          </ul>
        </div>
        <!--分页-->
        <div class="difcac">
          <el-pagination
              background
              layout="jumper, prev, pager  ,next   "
              :total="1000">
          </el-pagination>
        </div>
      </div>
      <!--收藏招聘-->
      <div v-if="isActive==6">
        <div class="mrBtm10">
          <ul v-if="zhaopin.length>0">
            <li class="zhaopin_item" v-for="(item,index) in zhaopin" @click="goZhaopinDetail(item)">
              <div class="dif alc">
                <p class="fs18 bold col3765FF one_overflow mrRit20 tlt" style="width: 250px;">{{item.position}}
                  <span v-if="item.work_address">【{{item.work_address}}】</span></p>
                <p class="fs16 col333 tlt one_overflow mrRit20" style="width: 250px;">{{item.company}}</p>
                <div class="dif alc">
                  <p class="fs16 col333">{{item.createtime}}</p>
                  <div class="zhaopin_type mrLft10" v-if="item.type==1">直招</div>
                  <div class="zhaopin_type mrLft10" v-if="item.type==2">代招</div>
                </div>
              </div>
              <div class="dif alc mrTop20">
                <p class="fs18" style="color:#f00;">{{formatNumber(item.min_money)}}-{{formatNumber(item.max_money)}}</p>
                <p class="fs16 col999" style="margin: 0 30px;">{{item.experience_title}}</p>
                <p class="fs16 col999">{{item.education_title}}</p>
              </div>
              <div>
                <ul class="dif alc zhaopin_fuli">
                  <p class="col333 fs14 mrRit10">职位福利</p>
                  <li v-for="(item2,index2) in item.welfare">{{item2}}</li>
                </ul>
              </div>
              <p class="pointer zhaopin_share" @click.stop="cancelCollect(item,6)">取消收藏</p>
              <div class="news_guanli pointer" v-if="manage">
                <img src="@/assets/image/yizhibang/51noclose.png" style="width: 20px;height: 20px;">
                <!--<img src="@/assets/image/yizhibang/51close.png" style="width: 20px;height: 20px;">-->
              </div>
            </li>
          </ul>
          <div v-else class="tc" style="padding-top: 60px;">
            <div>暂无收藏</div>
          </div>
        </div>
      </div>
      <!--收藏软件-->
      <div v-if="isActive==7">
        <div class="mrBtm10">
          <ul class="app_warp" v-if="app.length>0">
            <!--软件信息-->
            <li class="dif alc mrTop20 app_item posr" v-for="(item,index) in app" :key="index" @click="goAppDetail(item)">
              <!--软件图标-->
              <div class="app_img bra10" style="width: 100px;height: 100px;overflow: hidden;border-radius: 20px">
                <img  class="w100 h100 bgccc" :src="item.appimage">
              </div>
              <!--软件信息-->
              <div class="app_info" style="margin-left: 41px;">
                <ul class="fs12 col666 difb difw" style="width: 410px;">
                  <li class="one_overflow">软件名称：{{item.title}}</li>
                  <li class="one_overflow">软件性质：{{item.nature}}</li>
                  <Li class="one_overflow">软件语言：{{item.language}}</Li>
                  <Li class="one_overflow">软件授权：{{item.accredit}}</Li>
                  <li class="one_overflow">软件大小：{{item.size}}</li>
                  <li class="one_overflow">软件版本：{{item.versions}}</li>
                  <li class="one_overflow">使用平台：{{item.platform}}</li>
                </ul>
              </div>
              <!--下载地址-->
              <div class="d_code dif alc">
                <div style="margin-right: 33px;">
                  <img class="bgccc pointer" style="width: 74px;height: 74px;" :src="item.androidimage" @click.stop="$all.showImg(item.androidimage)">
                  <p class="fs12 col333" style="margin-top: 15px;">安卓版本下载</p>
                </div>
                <div>
                  <img class="bgccc pointer" style="width: 74px;height: 74px;" :src="item.iosimage" @click.stop="$all.showImg(item.iosimage)">
                  <p class="fs12 col333" style="margin-top: 15px;">苹果版本下载</p>
                </div>
              </div>
              <!--下载-->
              <div class="d_d difcb h100 pointer" style="margin-left: 100px;">
                <div style="margin-bottom: 16px;">软件介绍</div>
              </div>
              <!--操作-->
              <div class="dif alc fs14 colFDAE38 share">
                <p class="pointer" style="margin-left: 16px;"
                   @click.stop="cancelCollect(item,7)">取消收藏</p>
              </div>

              <div class="news_guanli pointer" v-if="manage">
                <img src="@/assets/image/yizhibang/51noclose.png" style="width: 20px;height: 20px;">
                <!--<img src="@/assets/image/yizhibang/51close.png" style="width: 20px;height: 20px;">-->
              </div>
            </li>
          </ul>
          <div v-else class="tc" style="padding-top: 60px;">
            <div>暂无收藏</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageHeight: document.documentElement.clientHeight,//获取页面高度
      card: [
        {id: 1, name: '收藏消息'},
        {id: 2, name: '收藏资讯'},
        {id: 3, name: '收藏需求'},
        {id: 4, name: '收藏店铺'},
        // {id: 5, name: '收藏加盟'},
        {id: 6, name: '收藏招聘'},
        {id: 7, name: '收藏程序'},
      ],
      box: [],
      cont: 0,//消息数量
      news: [],//收藏的消息
      adivce: [],//收藏的资讯
      need: [],//收藏的需求
      shop: [],//收藏的店铺
      join: [],//收藏的加盟
      zhaopin: [],//收藏的招聘
      app: [],//收藏的程序
      isActive: 1, //上分消息分类
      keyword: '',//关键字
      manage: false,//批量管理
      showDetail: -1,//当前显示的消息详情索引
      userInfo: {
        is_pay: 0,//0用户 1会员
      }
    }
  },
  created(event) {
    let type = 1
    if(localStorage.getItem('yzbCollect')) {
      type = localStorage.getItem('yzbCollect')
    }
    this.getCollect(type)
    this.getUserInfo() //获取用户信息
  },
  methods: {
    goVip() { //跳转到会员
      this.$router.push({
        path: '/meCenter',
        query:{
          leftSelect:1,
          contentType: 2,
          time: new Date().getTime()
        }
      }).catch(err => err)
    },
    getUserInfo() { //获取用户信息
      this.$axios("POST","/api/user/getUser",{})
          .then(res => {
            this.userInfo = res.data
          })
    },
    setDetail(item,index) { //查看详情
      if(this.showDetail===index) {
        this.showDetail = -1
      }else {
        this.showDetail = index
      }
    },
    getCollect(type) {//获取收藏
      this.isActive = type
      localStorage.setItem('yzbCollect',type)
      this.$axios("POST", "/api/member/personCollect", {type: type,page: 1,limit: 999})
      .then(res => {
        if(res.code===1) {
          this.cont = res.data.length
          if(type==1) { //消息
            this.news = res.data
          }else if(type==2) { //资讯
            this.adivce = res.data
          }else if(type==3) { //需求
            this.need = res.data
          }else if(type==4) { //店铺
            this.shop = res.data
            console.log(this.shop);
          }else if(type==5) { //加盟
            this.join = res.data
          }else if(type==6) { //招聘
            this.zhaopin = res.data
          }else if(type==7) { //程序
            this.app = res.data
          }
        }else {
          this.cont = 0
        }
      })
    },
    goShopDetail(item) { //跳转到店铺详情
      sessionStorage.goodsUrl = item.url
      let routeUrl = this.$router.resolve({
        path: '/search/shopdetail'
      })
      window.open(routeUrl .href, '_blank');
    },
    goAdviceDetail(item) { //跳转到资讯详情
      // let routeUrl = this.$router.resolve({
      //   path: '/search/advisory/detail',
      //   query: {id: item.id}
      // })
      // window.open(routeUrl .href, '_blank');
      this.$router.push({
        path: '/search/advisory/detail',
        query: {id: item.id}
      })
    },
    goNeedDetail(item) { //跳转到资讯详情
      let routeUrl = this.$router.resolve({
        path: '/search/demand/detail',
        query: {id: item.id}
      })
      window.open(routeUrl .href, '_blank');
    },
    goZhaopinDetail(item) { //招聘详情
      let routeUrl = this.$router.resolve({
        path: '/search/zhaopin/detail?id='+item.id
      })
      window.open(routeUrl .href, '_blank');
    },
    goAppDetail(item) { //软件详情
      let routeUrl = this.$router.resolve({
        path: '/search/app/detail',
        query: {
          id: item.id
        }
      })
      window.open(routeUrl .href, '_blank');
    },
    cancelCollect(item,type) { //取消收藏
      this.$axios("POST","/api/member/collect",{
        id: item.id,
        type: type
      }).then(res=> {
        if(res.code===1) {
          this.$message.success('操作成功')
          this.getCollect(type)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
  },
  computed: {
    getTime() { //获取招工起止时间
      return function (time) {
        var date = new Date(time*1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
        var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        return Y + M + D;
      }
    },
    formatNumber() { //数字格式化
      return function (num) {
        return num >= 1e3 && num < 1e4 ? (num / 1e3).toFixed(1) + 'k' : num >= 1e4 ? (num / 1e4).toFixed(1) + 'w' : num
      }
    },
  }
}
</script>

<style scoped>
.news_detail{
  position: absolute;
  width: 100%;
  max-height: 320px;
  left: 0;
  background: #fff;
  top: 80px;
  z-index: 999;
  border-radius: 0 0 30px 30px;
  box-sizing: border-box;
  padding: 20px 30px;
}
.container {
  overflow: hidden;
  overflow-y: auto;
}

/*头部分类和搜锁*/
.top_group .active {
  color: #366EFF;
}

.top_search {
  margin-left: 25px;
  position: relative;
  display: flex;
  align-items: center;
  width: 380px;
  height: 40px;
  /*background: #F3F3F3;*/
  border-radius: 20px;
  box-sizing: border-box;
}

.top_search_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 28px;
  width: 17px;
  height: 18px;
}

.top_search_input {
  font-size: 14px;
  color: #999999;
  background: none;
  padding-left: 59px;
  width: 340px;
}

.top_border {
  width: 100%;
  height: 40px;
  background: #FAFAFA;
  margin-top: 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.top_border .active {
  color: #333333;
}

.bianji {
  position: absolute;
  top: 50%;
  font-size: 14px;
  color: #F29424;
  right: 20px;
  cursor: pointer;
  transform: translateY(-50%);
}

/*收藏消息*/
.news_item {
  width: 100%;
  position: relative;
  height: 80px;
  box-sizing: border-box;
  padding: 0 20px;
  border: 1px solid #EFEFEF;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.news_radio {
  width: 6px;
  height: 6px;
  background: #F04243;
  border-radius: 50%;;
}

.news_guanli {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

/*收藏资讯*/
.zixun_item {
  margin-bottom: 10px;
  position: relative;
  width: 1090px;
  height: 120px;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  padding: 21px;
  display: flex;
  align-items: center;
}

.zixun_share {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 90px;
}

/*收藏需求*/
.xuqiu_item {
  width: 1090px;
  height: 60px;
  border: 1px solid #EFEFEF;
  margin-bottom: 15px;
}
/*收藏店铺*/
.shop_res{
  /*max-height: 830px;  */
  /*overflow-y: auto;*/
}
.shop_res li:nth-child(4n-1) {
  margin-left: 30px;
  margin-right: 30px;
}
.shop_res li:nth-child(4n-2) {
  margin-left: 30px;
}
.good_bottom{
  padding: 10px 10px;
  width: 248px;
  height: 70px;
  background: #F7F6FA;
  box-sizing: border-box;

}
.good_img{
  background: #3765FF;
  width: 248px;
  height: 248px;
  position: relative;
}
.mb1{
  width: 100%;
  height: 129px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
}
.mb2{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 130px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.5;
}
.noindex {
  font-weight: bold;
  font-size: 16px;
  color: #F39800;
  position: absolute;
  top: 23px;
  left: 12px;
  z-index: 90;
}
.pinpai{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  width: 100px;
  font-size: 12px;
  height: 24px;
  background: #F39800;
  border-radius: 11px;
  position: absolute;
  top: 20px;
  right: 10px;
  z-index:2;
}
.compoany{
  font-size: 12px;
  color: #FFFFFF;
  position: absolute;
  bottom: 12px;
  max-width: 80%;
  left: 10px;
  z-index: 3;
}
/*收藏加盟*/
.join_item{
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  height: 210px;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  padding: 31px 0;
  padding-left: 71px;
}
.join_warp{
  margin-bottom: 10px;
  padding-right: 10px;
  max-height: 830px;
  overflow-y: auto;
}
.no{
  width: 36px;
  height: 16px;
  background: #F3414A;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 35px;
  left: 25px;
  font-weight: bold;
  font-size: 12px;
  color: #fff;
}
.no1{
  background: #F3414A;
}
.no2{
  background: #FF8547;
}
.no3{
  background: #FFAC38;
}
/*收藏招聘*/
.zhaopin_item{
  position: relative;
  width: 1090px;
  height: 136px;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  margin-top: 12px;
  padding: 22px 32px;
}
.zhaopin_type{
  line-height: 14px;
  padding: 4px 7px;
  font-size: 14px;
  color: #FFFFFF;
  background: #3765FF;
  border-radius: 10px;
}
.zhaopin_fuli li{
  font-size: 14px;
  color: #999999;
  padding: 8px 11px;
  border: 1px solid #EEEEEE;
  margin-right: 10px;
}
.zhaopin_share{
  font-size: 14px;
  color: #F6AA00;
  position: absolute;
  bottom: 16px;
  right: 30px;
}
/*收藏软件*/
.app_warp{
  max-height: 830px;
  overflow-y: auto;
  padding-right: 10px;
}
.share{
  position: absolute;
  bottom: 16px;
  right: 22px;
}
.app_info li{
  text-align: left;
  width: 50%;
  margin-bottom: 10px;
}
.app_item{
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #EFEFEF;
  padding: 23px 36px;
  position: relative;
}
.d_d div{
  width: 120px;
  height: 40px;
  background: #3A54FF;
  border-radius: 20px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
</style>