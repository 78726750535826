<template>
  <!--招聘详情-->
  <div style="width: 1200px;margin: 0 auto;">
    <!--导航-->
    <div class="dif alc tlt fs12 col999 mrTop20 mrBtm20">
      <router-link to="/" class="fs12 col999">首页</router-link>
      <p>>招聘搜索</p>
      <p>>招聘详情</p>
    </div>
    <!--详细内容-->
    <div class="zhaopin_info">
      <!--基本信息-->
      <div class="dif alc mrBtm30">
        <h1 class="fs30 col333 bold mrRit30 one_overflow" style="word-break: break-all;max-width: 500px;">{{info.position}}</h1>
        <h2 class="fs30 col3A53FF bold">{{formatNumber(info.min_money)}}-{{formatNumber(info.max_money)}}</h2>
        <p class="fs14 col999" style="margin-left: 80px;">发布时间：{{info.createtime}}</p>
      </div>
      <!--工作信息-->
      <div class="dif alc difw mrBtm20 one_overflow" style="word-break: break-all">
        <p class="fs14 col333 mrBtm20 mrRit30">{{info.type}} · {{info.education_title}}</p>
        <p class="fs14 col333 mrBtm20 mrRit30">人数：招{{info.num}}人</p>
        <p class="fs14 col333 mrBtm20 mrRit30">工作年限：{{info.experience_title}}</p>
        <p class="fs14 col333 mrBtm20 mrRit30 one_overflow">工作单位：{{info.company}}</p>
      </div>
      <!--福利条件-->
      <ul class="dif alc difw">
        <li class="fuli_item" v-for="(item,index) in info.welfare" :key="index">{{item}}</li>
      </ul>
    </div>
    <div class="d_tit dif alc mrBtm30 mrTop30">
      <img src="@/assets/image/yizhibang/20telephone.png" style="width: 20px;height: 18px;">
      <h3 class="fs18 col333 bold mrLft10">联系方式</h3>
      <h3 class="fs18 col333 bold one_overflow" style="margin-left: 14px;max-width: 900px" v-if="userInfo.is_autonym==1">{{info.user_name}} {{info.user_phone}}</h3>
      <h3 class="fs18 col333 bold" style="margin-left: 14px;" v-else>通过实名认证即可查看联系方式</h3>
    </div>
    <!--介绍-->
    <div style="margin-top: 46px;">
      <el-collapse v-model="cat">
        <el-collapse-item class="tlt" name="1">
          <template slot="title">
            <div class="title posr dif alc">
              <div class="title_icon"></div>
              <h3 class="fs18 col333 tlt" style="margin-left: 5px;">工作地点</h3>
            </div>
          </template>
          <div class="fs14 col333" style="line-height: 40px;text-indent: 2em;word-break: break-all">
            {{info.work_address}}
          </div>
        </el-collapse-item>

        <el-collapse-item class="tlt" name="2">
          <template slot="title">
            <div class="title posr dif alc">
              <div class="title_icon"></div>
              <h3 class="fs18 col333 tlt" style="margin-left: 5px;">详细内容</h3>
            </div>
          </template>
          <div class="fs14 col333" style="line-height: 40px;text-indent: 2em;word-break: break-all" v-html="info.content">
            <!--{{info.content}}-->
          </div>
        </el-collapse-item>

        <!--<el-collapse-item class="tlt" name="3">
          <template slot="title">
            <div class="title posr dif alc">
              <div class="title_icon"></div>
              <h3 class="fs18 col333 tlt" style="margin-left: 5px;">其他联系方式</h3>
            </div>
          </template>
          <div class="fs14 col333" style="line-height: 40px;text-indent: 2em">
            {{info.contact_way}}
          </div>
        </el-collapse-item>-->

        <!--<el-collapse-item class="tlt" name="4">
          <template slot="title">
            <div class="title posr dif alc">
              <div class="title_icon"></div>
              <h3 class="fs18 col333 tlt" style="margin-left: 5px;">职位介绍</h3>
            </div>
          </template>
          <div class="fs14 col333" style="line-height: 40px;text-indent: 2em">
            {{info.position_introduce}}
          </div>
        </el-collapse-item>-->
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "zhaopinDetail",
  data() {
    return {
      cat: ['1','2','3','4'],//默认介绍展开
      info: {},//招聘详情
      userInfo: {
        is_pay: 0,
        is_autonym: 0
      },
    }
  },
  computed: {
    formatNumber() { //数字格式化
      return function (num) {
        return num >= 1e3 && num < 1e4 ? (num / 1e3).toFixed(1) + 'k' : num >= 1e4 ? (num / 1e4).toFixed(1) + 'w' : num
      }
    },
  },
  methods: {
    getUserInfo() { //获取用户信息
      this.$axios("POST","/api/user/getUser",{})
          .then(res => {
            this.userInfo = res.data
          })
    },
  
    getDetail(id) { //获取招聘详情
      this.$axios("POST","/api/Recruitment/recruitmentDateil",{
        id
      }).then(res => {
        if(res.code===1) {
          this.info = res.data[0]
          this.info.content = unescape(this.info.content)
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    }
  },
  created() {
    let id = this.$route.query.id
    this.getDetail(id) //获取招聘详情
    this.getUserInfo()
  },
}
</script>

<style scoped>
  .zhaopin_info{
    width: 1200px;
    background: #F5FBFF;
    box-sizing: border-box;
    padding: 27px 20px;
  }
  .fuli_item{
    width: 70px;
    height: 24px;
    border: 1px solid #3765FF;
    border-radius: 12px;
    color: #3765FF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-right: 10px;
  }

  .title_icon{
    width: 10px;
    height: 10px;
    background: #3765FF;
  }
</style>