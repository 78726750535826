<template>
  <div>
    <div class="header_total rows bgff w100 shadow_bottom">
      <div class="w100 difb alc mr0At" style="width: 1450px;">
        <!--logo-->
        <div>
          <img @click="backIndex()" src="@/assets/image/yizhibang/16left_logo.png" class="pointer" style="width: 89px;height: 35px;">
        </div>
        <div>
          <div class="dif alc">
            <p class="fs14 col3765FF pointer mrRit30" @click="backIndex()">返回首页</p>
            <p class="pointer fs14 col3765FF mrRit30" @click="showCon=true">联系合作</p>
            <p class="pointer fs14 col3765FF mrRit30" @click="showCon=true">反馈</p>
            <div class="user dif alc" v-if="$store.state.isLogin">
              <div class="username fs14 col3765FF">{{$store.state.userInfo.username}}</div>
              <div class="posr">
                <div class="noread" v-if="noread>0"></div>
                <img class="userIcon" :src="$store.state.userInfo.avatar"/>
              </div>
              <!--点击头像之后的选项-->
              <div class="header_info tct fs12">
                <div class="pointer posr info_item" v-for="(item,index) in userList" @click="goPage(item)">
                  {{item.name}}
                  <div class="noread2" v-if="noread>0 && item.cid==3"></div>
                </div>
                <div class="pointer posr info_item" @click="logout">退出账号</div>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="col-1 fs15 col666 header_line hov333 pointer" @click="backIndex">返回首页</div>
        <div class="rows col-3">
          <div class="fs15 col666 header_line hov333 pointer" style="padding-left:10px">
            <router-link :to="{path:'/contactCooperation',query:{type:'1'}}" tag="div" class="pointer">合作联系</router-link>
          </div>
          <div class="fs15 col666 header_line hov333 pointer" style="padding-left:10px">
            <router-link :to="{path:'/contactCooperation',query:{type:'2'}}" tag="div" class="pointer">反馈</router-link>
          </div>
          <div class="fs15 col666 header_line header_img pointer" style="padding-left:10px">
            <img :src="$store.state.userInfo.avatar" style="width:35px;height:35px;" class="bra mrTop20">
            <div class="header_info absolute bgf1 tct fs12">
              <div class="hov333 pointer" v-for="(item,index) in userList" :key="index" @click="goPage(item)">{{item.name}}</div>
              <div class="hov333 pointer" @click="logout">退出账号</div>
            </div>
          </div>
        </div>-->
      </div>
    </div>
    <contact-cooperation v-if="showCon" @closecon="closecon"></contact-cooperation>
  </div>
</template>

<script>

import ContactCooperation from "@/components/home/head/contactCooperation";
export default {
  components: {ContactCooperation},
  data() {
    return {
      noread: 0,
      timer: null,
      showCon: false,//显示联系合作反馈
      userList: [ //点击头像之后的选项
        {id: 1,cid:2,name: '会员中心'},
        {id: 1,cid:1,name: '个人中心'},
        {id: 2,cid:1,name: '企业中心'},
        {id: 1,cid:4,name: '我的收藏'},
        {id: 1,cid:3,name: '我的消息'}
      ]
    }
  },
  methods: {
    getNoRead() { //获取未读数
      this.$axios("POST","/api/comment/getUnRead",{})
          .then(res => {
            this.noread = res.data
          })
      this.timer = setInterval(() => {
        this.$axios("POST","/api/comment/getUnRead",{})
            .then(res => {
              this.noread = res.data
            })
      },10000)
    },
    getUserInfo() { //获取用户信息
      this.$axios("POST","/api/user/getUser",{})
          .then(res => {
            this.$store.commit('setUserInfo',res.data)
          })
    },
    closecon() { //关闭联系合作反馈
      this.showCon = false
    },
    backIndex() { //返回首页
      this.$router.push('/')
    },
    logout() { //退出登录
      this.$store.commit('del_token')
      this.$router.push('/')
    },
    goPage(event) { //跳转到个人中心 企业中心相关页面
      this.$router.push({
        path: '/meCenter',
        query:{
          leftSelect: event.id,
          contentType: event.cid
        }
      }).catch(err => err)
    },
  },
  created() {
    this.getUserInfo() //获取用户信息
    this.getNoRead() //获取未读数
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
}
</script>

<style scoped>
  .noread2{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right:10px;
    border-radius: 50%;
    background-color: #fa3534;
    width: 7px;
    height: 7px;
  }
  .noread{
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    background-color: #fa3534;
    width: 10px;
    height: 10px;
  }
  .header_total {
    padding: 0 100px;
    height: 70px;
  }

  .user:hover>.header_info {
    display: block;
  }
  .header_info{
    position: absolute;
    top: 40px;left: 50px;
    width: 100px;height: 190px;
    padding-top: 5px;
    line-height: 30px;
    z-index: 2;
    display: none;
    background: #F4F4F4;
    border-radius: 6px;
  }
  .header_info::before{
    content: '';
    display: block;
    border-bottom: 21px solid #F4F4F4 !important;
    border-left: 14px solid rgba(0,0,0,0);
    border-right: 14px solid rgba(0,0,0,0);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -5px;
  }
  .info_item{
    color: #999999;
    font-size: 14px;

  }
  .info_item:hover{
    color: #366EFF;
  }
</style>