<template>
  <!--分类需求-->
  <router-view></router-view>
</template>

<script>

import Hot from "@/components/search/hot";
export default {
  name: "demandCenter",
  components: {Hot},
  data() {
    return {

    }
  }
}
</script>

<style scoped>

.d_info li {
  line-height: 32px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.gongneng{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -50px;
}

.demand_titl li,.d_info li{
  width: 70px;
}
</style>