<template>
  <div class="footer_warp2 difcac">
    <div class="mr0At fs14 colfff">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color: #FFFFFF">{{beian}}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottom_footer",
  data() {
    return {
      beian: '',
    }
  },
  methods: {
    getConfig() { //获取协议内容
      this.$axios("POST","/api/index/getConfing",{})
        .then(res => {
          if(res.code==1) {
            this.beian = res.data.beian
          }
        })
    },
  },
  created() {
    this.getConfig()
  }
}
</script>

<style scoped>
  .footer_warp2{
    width: 100%;
    height: 55px;
    background: #333333;
  }
</style>