<template>
  <div class="padding" v-loading="isLoading">
    <div class="tlt dif alc posr">
      <span class="pointer fs16 col666" @click="isShow=true" :class="isShow===true?'active':''"> 已经发布 </span>
      <span class="pointer fs16 col666 mrLft30" @click="isShow=false" :class="isShow===false?'active':''"> 招聘发布 </span>
      <!--<div class="dif alc pointer mrLft30" @click="isShow3=!isShow3">
        <img src="@/assets/image/yizhibang/03no_check.png" style="width: 16px;height: 16px;" v-if="!isShow3">
        <img src="@/assets/image/yizhibang/03yes_check.png" style="width: 16px;height: 16px;" v-else>
        <p class="fs12 col666 mrLft10">是否关闭此功能<span class="col999">(在您首页官网搜索将不显示此服务)</span></p>
      </div>-->
      <!--<div class="colfff difcac del_button" v-if="isShow===true && isShow2">撤职草稿箱</div>-->
      <!--<div class="colfff difcac del_button" style="width: 50px;" v-if="isShow && !isShow2">删除</div>-->
    </div>
    <!--已发布招聘-->
    <div v-if="isShow" class="mrTop20">
      <!--<div class="tlt dif alc pdLft20 posr" style="border-bottom: 1px solid #eeeeee;height: 40px;background: #FAFAFA">
        <span @click="isShow2=true" :class="isShow2===true?'active':''" class="pointer"> 正在招聘 </span>
        <span @click="isShow2=false" :class="isShow2===false?'active':''" class="pointer mrLft30"> 招聘稿箱 </span>
        <div class="news_guanli pointer" style="right: 108px;">
          <img src="@/assets/image/yizhibang/51noclose.png" style="width: 20px;height: 20px;">
        </div>
      </div>-->
      <!--正在招聘-->
      <div v-if="isShow2">
        <div class="mrBtm10">
          <ul v-if="list.length>0">
            <li class="zhaopin_item" v-for="(item,index) in list" :key="item.id"
             @click="goDetail(item)">
              <div class="dif alc">
                <p class="fs18 bold col3765FF one_overflow mrRit20 tlt" style="width: 250px;">
                  {{item.position}}
                  <span v-if="item.work_address">【{{item.work_address}}】</span>
                </p>
                <p class="fs16 col333 tlt one_overflow mrRit20" style="width: 250px;">{{item.company}}</p>
                <div class="dif alc">
                  <p class="fs16 col333">{{item.createtime}}</p>
                  <div class="zhaopin_type mrLft10">{{item.type}}</div>
                </div>
              </div>
              <div class="dif alc mrTop20">
                <p class="fs18" style="color:#f00;">{{formatNumber(item.min_money)}}-{{formatNumber(item.max_money)}}</p>
                <p class="fs16 col999" style="margin: 0 30px;">{{item.experience_title}}</p>
                <p class="fs16 col999">{{item.education_title}}</p>
              </div>
              <div v-if="item.welfare">
                <ul class="dif difw alc zhaopin_fuli">
                  <p class="col333 fs14 mrRit10 pdTop10">职位福利</p>
                  <li v-for="(item3,index2) in item.welfare" :key="index2" >{{item3}}</li>
                  <!--<li v-for="(item3,index2) in item.welfare" :key="index2" >{{item3}}</li>-->
                </ul>
              </div>
              <!--<p class="zhaopin_share dif alc">
                <span class="pointer">收藏</span>
                <span class="pointer mrLft10">分享</span>
              </p>-->
              <div class="news_guanli pointer" @click.stop="delZhaopin(item)">
                <!--<img src="@/assets/image/yizhibang/51noclose.png" style="width: 20px;height: 20px;">-->
                <i style="color: #fa3534" class="el-icon-delete-solid fs16 pointer"></i>
                <!--<img src="@/assets/image/yizhibang/51close.png" style="width: 20px;height: 20px;">-->
              </div>
            </li>
          </ul>
          <div v-else style="padding: 100px 0; ">
            <el-result icon="info" title="暂无发布"></el-result>
          </div>
        </div>
      </div>
      <!--草稿箱-->
      <div v-else>
        <div class="mrBtm10">
          <ul>
            <li class="zhaopin_item" v-for="(item,index) in 3">
              <div class="dif alc">
                <p class="fs18 bold col3765FF one_overflow mrRit20 tlt" style="width: 250px;">测试工程师【济南/历下区】</p>
                <p class="fs16 col333 tlt one_overflow mrRit20" style="width: 250px;">济南慧族网络科技有限公司</p>
                <div class="dif alc">
                  <p class="fs16 col333">2020-05-30</p>
                  <div class="zhaopin_type mrLft10">直招</div>
                </div>
              </div>
              <div class="dif alc mrTop20">
                <p class="fs18" style="color:#f00;">6-9K</p>
                <p class="fs16 col999" style="margin: 0 30px;">3-4年</p>
                <p class="fs16 col999">大专</p>
              </div>
              <div>
                <ul class="dif alc zhaopin_fuli">
                  <p class="col333 fs14 mrRit10">职位福利</p>
                  <li>五险一金</li>
                  <li>五险一金</li>
                  <li>五险一金</li>
                </ul>
              </div>
              <p class="zhaopin_share dif alc">
                <span class="pointer">收藏</span>
                <span class="pointer mrLft10">分享</span>
              </p>
              <div class="news_guanli pointer">
                <img src="@/assets/image/yizhibang/51noclose.png" style="width: 20px;height: 20px;">
                <!--<img src="@/assets/image/yizhibang/51close.png" style="width: 20px;height: 20px;">-->
              </div>
            </li>
          </ul>
        </div>
        <!--分页-->
        <div class="difcac">
          <el-pagination
              background
              layout="jumper, prev, pager  ,next   "
              :total="1000">
          </el-pagination>
        </div>
      </div>
    </div>
    <!--发布招聘-->
    <div v-else class="mrTop20">
      <!--分类-->
      <div class="dif alc">
        <div class="fs14 col333 dif alc">
          <p style="color: #f00">*</p>
          <p class="mrLft10">分类</p>
          <div style="width: 360px;margin-left: 15px;">
            <el-select  placeholder="招聘" disabled class="w100">
              <el-option
                  v-for="(item,index) in 3"
                  :label="item"
                  :value="index"
                  :key="index">
              </el-option>
            </el-select>
          </div>
        </div>
        <!--招聘类型-->
        <div class="alc dif mrLft20">
          <p class="mrRit10 fs14 col333">招聘方式</p>
          <el-radio-group v-model="type">
            <el-radio label="1">直招</el-radio>
            <el-radio label="2">代招</el-radio>
          </el-radio-group>
        </div>
      </div>
      <!--标题-->
      <div class="dif alc mrTop20 mrBtm20">
        <div class="fs14 col333 dif alc">
          <p style="color: #f00">*</p>
          <p class="mrLft10">标题</p>
          <div style="width: 768px;margin-left: 15px;">
            <el-input placeholder="请输入标题" v-model="position"></el-input>
          </div>
        </div>
      </div>
      <!--招聘发布介绍-->
      <div class="" style="margin-top: 20px;">
        <p class="fs14 col333 mrRit10 tlt mrBtm10"><span style="color: #f00">*</span>招聘发布介绍</p>
        <div style="width: 768px;">
          <el-input placeholder="请填写招聘发布的搜索关键词，并用“/”号分开 [用于发布官网的首页搜索，请谨慎填写]" type="textarea" rows="5" v-model="keywords"></el-input>
        </div>
      </div>
      <!--每月薪资 和招聘人数-->
      <div class="dif alc w100">
        <!--每月薪资-->
        <div class="dif alc mrTop10">
          <span class="text-red">*</span>
          <span class="mrRit20">每月薪资</span>
          <div style="width: 180px;">
            <el-input placeholder="最低薪资  请填写整数"  v-model="min_money"></el-input>
          </div>
          <span class="mrLft20 mrRit20">至</span>
          <div style="width: 180px;">
            <el-input placeholder="最高薪资  请填写整数" v-model="max_money"></el-input>
          </div>
          <span class="mrLft20">元/月</span>
        </div>
        <!--招聘人数-->
        <div class="dif alc tlt mrTop10 mrLft30">
          <span class="mrRit20"><span style="color: #f00">*</span>招聘人数</span>
          <div style="width: 110px;">
            <el-input placeholder="请输入人数"  v-model="num"></el-input>
          </div>
          <span class="mrLft20">人</span>
        </div>
      </div>

      <!--职位福利-->
      <div class="dif difw mrTop10">
        <span class="mrRit20 pdTop10"><span style="color: #f00">*</span>职位福利</span>
        <div style="width: 900px;" class="tlt">
          <el-checkbox-group v-model="fuli" @change="changeFuli">
            <el-checkbox-button v-for="(item,index) in fuliCont" :label="item.id"
                                :key="item.id">{{item.title}}</el-checkbox-button>
          </el-checkbox-group>
        </div>
      </div>
      <!--学历要求-->
      <div class="dif alc mrTop10">
        <span class="mrRit20"><span style="color: #f00">*</span>学历要求</span>
        <el-radio-group v-model="education">
          <el-radio-button :label="item.id" v-for="(item,index) in inviteEducation" :key="index">{{item.title}}</el-radio-button>
        </el-radio-group>
      </div>
      <!--工作年限-->
      <div class="dif alc mrTop10">
        <span class="mrRit20"><span style="color: #f00">*</span>工作年限</span>
        <el-radio-group v-model="yearNum">
          <el-radio-button :label="item.id" v-for="(item,index) in inviteExperience" :key="index">{{item.title}}</el-radio-button>
        </el-radio-group>
      </div>

      <!--工作单位描述-->
      <div class="dif alc difw mrTop10 w100">
        <div class="dif alc">
          <div style="width: 75px;"><span style="color: #f00">*</span>工作单位</div>
          <div style="width: 330px;">
            <el-input placeholder="请输入工作单位" class="mrLft30"  v-model="company"></el-input>
          </div>
        </div>
        <div class="dif alc" style="margin-left: 50px;">
          <div style="width: 75px;"><span style="color: #f00">*</span>工作地点</div>
          <div style="width: 330px;">
            <el-input placeholder="请输入工作地点" class="mrLft30"  v-model="work_address"></el-input>
          </div>
        </div>
      </div>
      <!--联系方式-->
      <div class="dif alc mrTop10">
        <div class="dif alc">
          <span class="mrRit10"><span style="color: #f00">*</span>联系人</span>
          <el-input v-model="user_name" style="width: 60%;"></el-input>
        </div>
        <div class="dif alc mrLft30">
          <span class="mrRit10"><span style="color: #f00">*</span>联系电话</span>
          <el-input v-model="user_phone" style="width: 50%;"></el-input>
        </div>
       <!-- <div class="dif alc">
          <p class="mrRit10">手机</p>
          <el-input v-model="phone" style="width: 70%;"></el-input>
        </div>-->
        <!--<div class="dif alc pointer" @click="isdefault=!isdefault">-->
        <!--  <span class="mrRit10">设为默认</span>-->
        <!--  <img src="@/assets/image/yizhibang/03no_check.png" style="width: 16px;height: 16px;" v-if="!isdefault">-->
        <!--  <img src="@/assets/image/yizhibang/03yes_check.png" style="width: 16px;height: 16px;" v-else>-->
        <!--</div>-->
      </div>
      <!--发布时间-->
      <!--<div class="dif alc mrTop10">
        <span class="mrRit30">发布时间</span>
        <el-date-picker
            v-model="createtime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
        </el-date-picker>
      </div>-->
      <!--详细需求-->
      <div class="mrTop20 mrBtm20">
        <h4 class="mrBtm20 tlt"><span style="color: #f00">*</span>详细招聘编辑(工作内容 职位要求工作时间等)</h4>
        <!--富文本-->
        <div style="margin-bottom: 80px;">
          <quill-editor1
              @getEditor="getEditor"
              ref="quillEditor"
              :index="0"
          ></quill-editor1>
        </div>
      </div>
      <!--取消和发布-->
      <div class="difcac">
        <div class="difcac msg_button1 colfff pointer" @click="isShow=true">取消</div>
        <div class="difcac msg_button2 colfff pointer" @click="submit">发布</div>
      </div>
    </div>
  </div>
</template>

<script>
import quillEditor1 from "@/components/quillEditor1";
export default {
  props: {
    LeftHeaderType: [String, Number],
  },
  components: {
    quillEditor1
  },
  data() {
    return {
      user_name: '',//联系人
      user_phone: '',//联系电话
      isLoading: true,
      pageHeight:document.documentElement.clientHeight,//获取页面高度
      isShow: true,//已发布招聘ture  发布招聘false
      isShow2: true,//正在招聘 true 草稿箱 false
      isShow3: false,//是否显示此功能
      fuli: [],//当前选中职位福利
      fuliCont: [],//职位福利内容
      inviteEducation: [],//学历要求
      education: 0,//当前选中学历要求
      inviteExperience: [],//工作年限
      yearNum: 0,//当前选中工作年限
      type: '1', //招聘方式
      position:'',//职位
      keywords: '',//搜索关键字
      createtime: '',//发布时间
      content: '',//内容
      min_money: '',//最低薪资
      max_money: '',//最高薪资
      num: '',//人数
      company: '',//公司
      work_address: '',//工作地点
      landline: "",//座机
      broker: "",//经纪人
      phone: "",//电话
      draft: [],//草稿箱
      normal: [],//正在招聘
      list: [],//列表
    }
  },
  methods: {
    goDetail(item) {
      let routeUrl = this.$router.resolve({
        path: '/search/zhaopin/detail?id='+item.id
      })
      window.open(routeUrl .href, '_blank');
    },
    changeFuli(e)  {
      console.log(e);
      console.log(this.fuliCont);
    },
    submit(){ //发布招聘
      let welfare =this.fuli.join(',')
      let content = escape(this.content)
      if(!this.position) {
        return this.$alert('请输入标题',{type: 'error'})
      }else if(!this.keywords) {
        return this.$alert('请输入招聘发布介绍',{type: 'error'})
      }else if(this.min_money==='') {
        return this.$alert('请输入最低薪资',{type: 'error'})
      }else if(!this.max_money) {
        return this.$alert('请输入最高薪资',{type: 'error'})
      }else if(!this.num) {
        return this.$alert('请输入人数',{type: 'error'})
      }else if(this.fuli.length<=0) {
        return this.$alert('请选择职位福利',{type: 'error'})
      }else if(!this.company) {
        return this.$alert('请输入工作单位',{type: 'error'})
      }else if(!this.work_address) {
        return this.$alert('请输入工作地点',{type: 'error'})
      }else if(!this.user_name) {
        return this.$alert('请输入联系人',{type: 'error'})
      }else if(!this.user_phone) {
        return this.$alert('请输入联系电话',{type: 'error'})
      }else if(!content) {
        return this.$alert('请输入详细招聘编辑内容',{type: 'error'})
      }
      this.$axios("POST","/api/Release/releaseInvite",{
        position: this.position,
        keyword: this.keywords,
        min_money: this.min_money,
        max_money: this.max_money,
        num: this.num,
        welfare: this.fuli.join(','),
        education: this.education,
        yearNum: this.yearNum,
        type: this.type,
        company: this.company,
        work_address: this.work_address,
        // createtime: this.createtime,
        user_name: this.user_name,
        user_phone: this.user_phone,
        content: content //招聘内容
      }).then(res => {
        if(res.code===1) {
          if(this.isdefault) {
            this.releaseInfo()
          }
          this.$alert('发布成功',{
            type: "success",
            callback: () => {
              this.isShow = true
              this.getRecruitments(1)
            }
          })
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    getFuli() { //获取福利
      this.$axios("POST","/api/Recruitment/welfare",{})
          .then(res => {
            this.fuliCont = res.data
          })
    },
    getInviteEducation() { //获取学历选项
      this.$axios("POST","/api/Recruitment/inviteEducation",{})
      .then(res => {
        this.inviteEducation = res.data
        this.education = res.data[0].id
      })
    },
    getInviteExperience() { //获取工作年限选项
      this.$axios("POST","/api/Recruitment/inviteExperience",{})
      .then(res => {
        this.inviteExperience = res.data
        this.yearNum = res.data[0].id
      })
    },
    getEditor(cont) { //获取富文本内容
      this.content = cont
    },
    getRecruitments(type) { //获取已经发布的招聘信息 1草稿箱  2正常
      this.$axios("POST","/api/Recruitment/getRecruitments",{}).then(res => {
        if(res.code===1) {
          this.list = res.data
         /* if(type===1) { //草稿箱
            this.draft = res.data
          }else { //正常
            this.normal = res.data
          }*/
        }else {
          // this.$message.error(res.msg)
        }
      })
    },
    releaseInfo() { //设置默认联系方式
      if(this.landline==='' || this.broker==='' || this.phone==='') {
        this.$alert('请输入完整',{type:"error"})
        this.isdefault = false
        return
      }
      this.$axios("POST","/api/Release/createUserReleaseInfo",{
        landline_phone: this.landline_phone,
        broker_name: this.broker_name,
        tel: this.tel,
        is_default: '1'
      }).then(res => {
        if(res.code===1) {
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    delZhaopin(item) { //删除招聘
      this.$confirm('确认要删除该招聘信息吗？',{type: 'warning'})
          .then(con => {
            this.$axios("POST","/api/member/deleteUserInfo",{
              type: 3,
              id: item.id
            }).then(res => {
              if(res.code===1) {
                this.$message.success('删除成功')
                this.getRecruitments(1)
              }else {
                this.$message.error(res.msg)
              }
            })
          })
    },
  },
  async created() {
    this.getFuli() //获取福利
    this.getInviteEducation() //获取学历
    this.getInviteExperience() //获取工作年限
    // await this.getRecruitments(1)
    await this.getRecruitments(1)
    this.isLoading = false
    //获取默认联系方式
    if(this.$store.state.contactDetails.id) {
      this.landline = this.$store.state.contactDetails.landline_phone
      this.broker = this.$store.state.contactDetails.broker_name
      this.phone = this.$store.state.contactDetails.tel
      this.isdefault = true
    }
  },
  computed: {
    formatNumber() { //数字格式化
      return function (num) {
        return num >= 1e3 && num < 1e4 ? (num / 1e3).toFixed(1) + 'k' : num >= 1e4 ? (num / 1e4).toFixed(1) + 'w' : num
      }
    },
  }
};
</script>

<style scoped>
.news_guanli {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.del_button{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 95px;
  width: 90px;
  height: 30px;
  background: #3765FF;
  border-radius: 15px;
  font-size: 14px;
  cursor: pointer;
}



input {
  padding: 10px;
  border: 0.5px solid #c2c2c2;
  border-radius: 0.5rem;
  width: 80%;
}

/*选中状态*/
.active{
  color: #3765FF
}
/*招聘列表*/
.zhaopin_item{
  position: relative;
  width: 1090px;
  min-height: 136px;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  margin-top: 12px;
  padding: 22px 32px;
  background: #FAFAFA;
}
.zhaopin_type{
  line-height: 14px;
  padding: 4px 7px;
  font-size: 14px;
  color: #FFFFFF;
  background: #3765FF;
  border-radius: 10px;
}
.zhaopin_fuli li{
  font-size: 14px;
  color: #999999;
  padding: 8px 11px;
  border: 1px solid #EEEEEE;
  margin-right: 10px;
  margin-top: 10px;
}
.zhaopin_share{
  font-size: 14px;
  color: #F6AA00;
  position: absolute;
  bottom: 16px;
  right: 30px;
}

.msg_button1{
  width: 226px;
  height: 50px;
  background: #CCCCCC;
  border-radius: 6px;
}
.msg_button2{
  width: 226px;
  height: 50px;
  background: #3A54FF;
  border-radius: 6px;
  margin-left: 40px;
}
</style>