<template>
  <!-- 官网收藏 -->
  <div>
    <div class="web_box">
      <!--顶部操作-->
      <div class="top posr">
        <ul class="dif alc tab" style="height: 60px;">
          <li class="dif alc pointer">
            <img src="@/assets/image/yizhibang/05newfolder.png" style="width: 18px;height: 16px;margin-right: 10px;">
            <div>
              <p class="fs12 col999 one_overflow hover" style="margin-bottom: 3px;line-height: normal" @click="dialogVisible=true">新建文件夹</p>
              <p class="fs12 col999 one_overflow tct hover" @click="showDel=true" style="line-height: normal">删除文件夹</p>
            </div>
          </li>
          <li class="dif alc pointer" v-for="(item,index) in tabs" :key="index"
              :class="tabsActive==index?'active':''" @click="tabsActive=index">
            <img src="@/assets/image/yizhibang/05file.png" style="width: 18px;height: 16px;margin-right: 6px;">
            <p class="fs14 col333 one_overflow" style="max-width: 80px;">{{item.name}}</p></li>
        </ul>
      </div>
      <!-- 内容 -->
      <div class="center dif posr">
        <div class="c_item_warp">
          <ul class="c_item" style="overflow: hidden">
            <!--11 个-->
            <li v-for="(item,index) in webData" class="mrTop10" v-if="index<11">
              <div class="dif c_top">
                <!--网站图标-->
                <div class="fs12 col666 pointer a" @click="goDetail(item)">{{item.sitename}}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="c_item_warp">
          <ul class="c_item" style="overflow: hidden">
            <!--11 个-->
            <li v-for="(item,index) in webData" class="mrTop10" v-if="index>=11 && index<22">
              <div class="dif c_top">
                <!--网站图标-->
                <div class="fs12 col666 pointer a" @click="goDetail(item)">{{item.sitename}}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="c_item_warp">
          <ul class="c_item" style="overflow: hidden">
            <!--11 个-->
            <li v-for="(item,index) in webData" class="mrTop10" v-if="index<33 && index>=22">
              <div class="dif c_top">
                <!--网站图标-->
                <div class="fs12 col666 pointer a" @click="goDetail(item)">{{item.sitename}}</div>
              </div>
            </li>
          </ul>
        </div>

        <div class="center_cont">
          <div class="dif difw" v-if="tabs.length>0">
            <div class="list_item  difb alc" v-for="(item,index) in tabs[tabsActive].site" @click="goDetail(item)">
              <div class="one_overflow" style="width: 170px;">{{item.sitename}}/公司:{{item.enterprise}}/品牌:{{item.brand_title}}</div>
              <div class="col333 fs14 mrRit10" @click.stop="cancelSite(item)">
                <i class="el-icon-delete-solid text-red fs18"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-dialog
          title="新建文件夹"
          :visible.sync="dialogVisible"
          :modal="false"
          width="30%" style="text-align: center">
        <el-input v-model="name" placeholder="请输入文件夹名"></el-input>
        <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCates()">确 定</el-button>
      </span>
      </el-dialog>
      <el-dialog
          title="删除文件夹"
          :visible.sync="showDel"
          :modal="false"
          width="30%" style="text-align: center">
        <el-select v-model="delCates" multiple placeholder="请选择要删除的分类" style="width: 90%;">
          <el-option
              v-for="item in tabs"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
        <el-button @click="showDel = false;delCates=[]">取 消</el-button>
        <el-button type="danger" @click="deleteCate()">确 定</el-button>
      </span>
      </el-dialog>
    </div>
    <div class="end_warp" @click="close()"></div>
  </div>
</template>

<script>
export default {
  name: "passBox",
  data() {
    return {
      name: '',
      isAdd: false,
      tabs: [],
      tabsActive: 0,
      webData: [],
      pageCount: 10,
      curPage: 1,
      dialogVisible: false,//显示添加提示框
      showDel: false,//显示删除分类
      delCates: [],//选中的被删除的分类
    }
  },
  methods: {
    //关闭
    close() {
      this.$emit('closeWeb',{})
    },
    //取消收藏官网
    cancelSite(item) {
      this.$confirm('确认要取消该官网收藏吗？',{type: 'warning'})
      .then(con => {
        this.$axios("POST","/api/Member/deleteCollectSite",{id: item.lid})
        .then(res => {
          if(res.code===1) {
            this.$message.success(res.msg)
            this.getCates()
          }else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    //删除分类
    deleteCate() {
      if(this.delCates.length<=0) {
        return this.$alert('请选择要删除的分类')
      }
      this.$confirm('确认要删除选中的分类吗？',{type: 'error'})
      .then(con => {
        let ids = this.delCates.join(',')
        this.$axios("POST","/api/Member/deleteCate",{id: ids})
        .then(res => {
          if(res.code===1) {
            this.$alert('删除成功',{type: 'success'})
            this.delCates = []
            this.showDel = false
            this.getCates()
          }else {
            this.$alert(res.msg,{type: 'error'})
          }
        })
      })
    },
    //获取官网分类列表
    getCates() {
      this.$axios("POST","/api/Member/getCate",{})
      .then(res => {
        if(res.code===1) {
          this.tabs = res.data
        }
      })
    },
    //新建文件夹
    addCates() {
      if(this.name==='') {
        return this.$alert('请输入文件夹名',{type: 'error'})
      }
      this.$axios("POST","/api/Member/addCate",{name: this.name})
      .then(res => {
        if(res.code===1) {
          this.name = ''
          this.dialogVisible = false
          this.$alert('添加成功',{type: 'success'})
          this.getCates();
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    goDetail(item) { //跳转到官网详情
      console.log(item)
      if(item.uid==0){
        return window.open(item.url1)
      }
      let path=this.$router.resolve({
        path:'/search/webdetail',
        query:{sid: item.id}
      })
      window.open(path.href)
    },
    curChange(c){ //切换页数显示
      this.curPage = c
    },
  },
  created() {
    this.getCates();
    /*let token = localStorage.getItem('yizhibang')
    //获取用户官网收藏数据
    this.$axios("POST","/api/member/personCollect",{
      token: token,
      type: 8
    }).then(res => {
      if(res.code===1) {
        this.webData = res.data
      }
    })*/
  }
}
</script>

<style scoped>
.end_warp{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.list_item{
  width: 33.3%;
  font-size: 14px;
  /*color: #333333;*/
  color: #3765FF;
  cursor: pointer;
  transition: all 0.2s;
  box-sizing: border-box;
  padding-left: 10px;
  text-align: left;
  margin-bottom: 10px;
}
.list_item:hover{
  text-decoration: underline;
  color: #3765FF;
}
.center_cont{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.a:hover{
  text-decoration: underline;
}
.c_item_warp{
  margin-top: -1px;
  border-right: 1px solid #EEEEEE;
}
.top{
  border-radius: 6px 6px 0 0;
  overflow: hidden;
}
.web_box{
  box-sizing: border-box;
  width: 700px;
  background: #FFFFFF;
  box-shadow: 0 0 28px 2px rgba(54, 110, 255, 0.5);
  position: absolute;
  border-radius: 6px;
  top: 50px;
  left: -375px;
  z-index: 999;
}
.web_box::before{
  content: "";
  display: block;
  border-bottom: 14px solid #FFFFFF;
  border-left: 11px solid rgba(0,0,0,0);
  border-right: 11px solid rgba(0,0,0,0);
  position: absolute;
  left: 392px;
  top: -10px;
}
.top .img{
  width: 30px;
  height: 30px;
  background: #666666;
}
.top ul{

}
.top ul li{
  box-sizing: border-box;
  margin-right: 20px;
  height: 60px;
}
.tab{
  overflow-x: auto;
  overflow-y: hidden;
}
.tab li {
  border: 1px solid rgba(0,0,0,0);
  padding: 0 20px;
}
.tab .active{
  background-color: #f1f1f1;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #EEEEEE;
  border-left: 1px solid #EEEEEE;
}

.center{
  box-sizing: border-box;
}
.center>div{
  padding: 10px;
  flex: 1;
  height: 370px;
  box-sizing: border-box;
  overflow-y: auto;
}

.bottom_page{
  position: absolute;
  bottom: 10px;
  right: 21px;
}
.c_item li{
  margin-bottom: 20px;
}
.c_item div{
  line-height: normal;
  font-size: 12px;
}
.c_top{
  font-size: 12px;
  margin-bottom: 10px;
}

.center .img{
  height: 20px;
  width: 50px;
  background: #666666;
}

</style>