<template>
  <!--信息介绍-->
  <div style="width: 1200px;border-top: 1px solid #F0F0F0;padding-top: 26px;padding-bottom: 200px;" class="mr0At">
    <!--  详细信息-->
    <div class="detail_info w100">
      <ul class="difb difw w100">
        <li style="width: 50%;" class="difc alc mrBtm10" v-for="(item,index) in main" v-if="item.status==1" :key="index">
          <p class="fs14 col333 bold dif alc">{{item.name}}</p>
          <!--<div style="width: 346px;word-break: break-all" class="div">{{item.cont}}</div>-->
          <div>
            <zhedie :text="item.cont"></zhedie>
          </div>
        </li>

      </ul>
      <div class="flex mrBtm30" style="padding-left: 87px;"
           :style="[{ 'margin-top': '30px' }]" v-if="business_scope.status==1">
        <p class="fs14 col333 bold dif alc">经营范围</p>
        <div
            class="mrRit20 bgf3f3f3 box tlt"
            rows="8"
            placeholder="代用文字"
            style="border: none;padding: 20px;"
            :style="[
          { width: '825px' },
          { 'margin-left': '10px' },
        ]"
        >{{business_scope.cont}}</div>
      </div>
    </div>
    <!--查看更多信息-->
    <!--<div class="mr0At mrTop20 mrBtm20" style="width: 1200px;height: 140px;">
      <img src="@/assets/image/info.png" class="w100 h100">
    </div>-->
    <!--目录-->
    <!--<div class="list dif alc" >
      <div class="difcac fs16 col333 list_1">目录</div>
      &lt;!&ndash;目录列表&ndash;&gt;
      <div class="list2">
        <div class="dif alc" style="height: 238px;">
          <div class="list2_item" >
            <p class="fs14 col3A53FF mrTop10">1.历史沿革</p>
            <ul class="fs12 col333 mrTop10">
              <li class="mrBtm10">.2010年</li>
              <li class="mrBtm10">.2011年</li>
              <li class="mrBtm10">.2012年</li>
              <li class="mrBtm10">.2013年</li>
            </ul>
          </div>
        </div>
      </div>
    </div>-->
    <!--介绍-->
    <el-collapse v-model="cat">
      <el-collapse-item class="tlt" name="1" v-if="history">
        <template slot="title">
          <div class="title posr dif alc">
            <div class="title_icon"></div>
            <h3 class="fs18 col333 tlt one_overflow" style="margin-left: 5px;width: 1000px">{{label1}}</h3>
          </div>
        </template>
        <div class="fs14 col333" style="line-height: 40px;text-indent: 2em" v-html="history"></div>
      </el-collapse-item>

      <el-collapse-item class="tlt" name="2" v-if="main_function">
        <template slot="title">
          <div class="title posr dif alc">
            <div class="title_icon"></div>
            <h3 class="fs18 col333 tlt one_overflow" style="margin-left: 5px;width: 1000px">{{label2}}</h3>
          </div>
        </template>
        <div class="fs14 col333" style="line-height: 40px;text-indent: 2em" v-html="main_function"></div>
      </el-collapse-item>

      <el-collapse-item class="tlt" name="3" v-if="other">
        <template slot="title">
          <div class="title posr dif alc">
            <div class="title_icon"></div>
            <h3 class="fs18 col333 tlt" style="margin-left: 5px;">其他</h3>
          </div>
        </template>
        <div class="fs14 col333" style="line-height: 40px;text-indent: 2em" v-html="other"></div>
      </el-collapse-item>
    </el-collapse>

    <!--地址-->
    <div class="address pdBtm30">
      <h3  class="mrTop30 mrBtm30 fs22 col3A53FF">位置</h3>
      <!--<div class="map"><img src="@/assets/image/address.png" alt=""></div>-->
      <div v-if="lng">
        <map-view :lng="lng" :lat="lat" :defaultValue="address_name"></map-view>
      </div>
    </div>
  </div>
</template>

<script>

import MapView from "@/components/Amap/mapView";
import Zhedie from "@/components/common/zhedie.vue";
export default {
  name: "jianjie",
  components: {
    Zhedie,
    MapView
  },
  data() {
    return {
      sid: '',
      cat: ['1','2','3'],//目录
      infodetail: [],//获取的数据
      main: {
        cname: {name: '中文名称',status: 1,cont: ''},
        founder: {name: '创始人',status: 1,cont: ''},
        ename: {name: '外文名称',status: 1,cont: ''},
        initiator: {name: '发起人',status: 1,cont: ''},
        short_name: {name: '简称名称',status: 1,cont: ''},
        leader: {name: '主领导人',status: 1,cont: ''},
        fname: {name: '前称名称',status: 1,cont: ''},
        legal_person: {name: '法定代表',status: 1,cont: ''},
        organization: {name: '组织性质',status: 1,cont: ''},
        trustee: {name: '董事主席',status: 1,cont: ''},
        department: {name: '主管部门',status: 1,cont: ''},
        president: {name: '总裁姓名',status: 1,cont: ''},
        creatTime: {name: '创立时间',status: 1,cont: ''},
        company_type: {name: '公司类型',status: 1,cont: ''},
        registerSite: {name: '注册地点',status: 1,cont: ''},
        patent_num: {name: '专利数量',status: 1,cont: ''},
        registerNum: {name: '注册号码',status: 1,cont: ''},
        publicity: {name: '宣传口号',status: 1,cont: ''},
        registerCapital: {name: '注册资本',status: 1,cont: ''},
        brand_idea: {name: '品牌理念',status: 1,cont: ''},
        turnoverYear: {name: '年营业额',status: 1,cont: ''},
        serve_aim: {name: '服务宗旨',status: 1,cont: ''},
        personnelNum: {name: '职员人数',status: 1,cont: ''},
        vision: {name: '愿景介绍',status: 1,cont: ''},
        registrationAuthority: {name: '登记机关',status: 1,cont: ''},
        mission: {name: '使命介绍',status: 1,cont: ''},
        societyCode: {name: '社会代码',status: 1,cont: ''},
        cost_idea: {name: '价值观点',status: 1,cont: ''},
        headAddress: {name: '总部地址',status: 1,cont: ''},
      },
      business_scope: {cont: '',status: 1}, //经营范围,
      history: '',//历史沿革
      main_function: '',//主要职能
      lat: '',//经纬度
      lng: '',
      other: '',//其他
      label1: '',
      label2: '',
      address_name: '',//地址名称
    }
  },

  created() {
    this.sid = this.$route.query.sid
    this.$axios("POST","/api/site/siteInfodetail",{
      sid: this.sid,
      type: 2
    }).then(res => {
      this.infodetail = res.data
      let i  = this.infodetail[0]
      let data  = this.infodetail[0]
      this.main.ename.cont = data.ename
      this.main.cname.cont = data.cname
      this.main.cname.status = data.cname_status
      this.main.ename.status = data.ename_status
      this.main.short_name.cont = data.short_name
      this.main.short_name.status = data.shore_status
      this.main.fname.cont = data.fname
      this.main.fname.status = data.fname_status
      this.main.organization.cont = data.organization
      this.main.organization.status = data.organization_status
      this.main.department.cont = data.department
      this.main.department.status = data.department_status
      this.business_scope.cont = data.business_scope
      this.business_scope.status = data.business_status
      this.main.company_type.cont = data.company_type
      this.main.company_type.status = data.company_status
      this.main.patent_num.cont = data.patent_num
      this.main.patent_num.status = data.patent_status
      this.main.publicity.cont = data.publicity
      this.main.publicity.status = data.publicity_status
      this.main.brand_idea.cont = data.brand_idea
      this.main.brand_idea.status = data.brand_status
      this.main.serve_aim.cont = data.serve_aim
      this.main.serve_aim.status = data.serve_status
      this.main.vision.cont = data.vision
      this.main.vision.status = data.vision_status
      this.main.mission.cont = data.mission
      this.main.mission.status = data.mission_status
      this.main.cost_idea.cont = data.cost_idea
      this.main.cost_idea.status = data.cost_status
      this.main.founder.cont = data.founder
      this.main.founder.status = data.founder_status
      this.main.initiator.cont = data.initiator
      this.main.initiator.status = data.initiator_status
      this.main.leader.cont = data.leader
      this.main.leader.status = data.leader_status
      this.main.legal_person.cont  = data.legal_person
      this.main.legal_person.status = data.person_status
      this.main.trustee.cont  = data.trustee
      this.main.trustee.status  = data.trustee_status
      this.main.president.cont  = data.president
      this.main.president.status  = data.president_status
      this.main.creatTime.cont = data.creatTime
      this.main.creatTime.status = data.creatTime_status
      this.main.registerSite.cont = data.registerSite
      this.main.registerSite.status = data.site_status
      this.main.registerNum.cont = data.registerNum
      this.main.registerNum.status = data.num_status
      this.main.registerCapital.cont = data.registerCapital
      this.main.registerCapital.status = data.capital_status
      this.main.turnoverYear.cont = data.turnoverYear
      this.main.turnoverYear.status = data.turnover_status
      this.main.personnelNum.cont = data.personnelNum
      this.main.personnelNum.status = data.personnel_status
      this.main.registrationAuthority.cont = data.registrationAuthority
      this.main.registrationAuthority.status = data.registration_status
      this.main.societyCode.cont = data.societyCode
      this.main.societyCode.status = data.society_status
      this.main.headAddress.cont = data.headAddress
      this.main.headAddress.status = data.head_status
      this.history = unescape(i.history)
      this.main_function = unescape(i.main_function)
      this.lat = i.lat
      this.lng = i.lng
      this.other = unescape(i.other)
      this.label1 = i.label1
      this.label2 = i.label2
      this.address_name = i.address_name
    })
  },
  mounted() {
    // this.$refs.mv.setLocationByLatLng(36.650173, 117.066933)
  }
}
</script>

<style scoped>
div{line-height: normal;}
.detail_info li p{
  text-align: left;
  width: 70px;
}
.detail_info li .div{
  text-align: left;
  background: #F3F3F3 100%;
  padding: 2px 20px;
  box-sizing: border-box;
  min-height: 25px;
  border-radius: 8px;
  margin-left: 10px;
  width: 80%;
  /*overflow:hidden;text-overflow:ellipsis;white-space:nowrap;*/
}
.list{
  width: 1200px;
  border: 1px solid #EBF1F5;
  height: 238px;
  margin-bottom: 30px;
}
.list_1{
  width: 100px;
  border: 1px solid #EBF1F5;
  height: 238px;
  background: #F5FBFF;
}
.list2{
  width: 1100px;
  height: 238px;
  overflow-x: auto;
}
.list2_item{
  box-sizing: border-box;
  flex: none;
  width: 160px;
  height: 238px;
  border: 1px solid #EBF1F5;
  overflow: hidden;
}

.title_icon{
  width: 10px;
  height: 10px;
  background: #3765FF;
}
</style>