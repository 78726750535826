<template>
  <div class="padding">
    <div class="tlt dif alc posr">
      <span class="pointer fs16 col666" @click="isShow=true" :class="isShow===true?'active':''"> 已发布软件 </span>
      <span class="pointer fs16 col666 mrLft30" @click="isShow=false" :class="isShow===false?'active':''"> 发布软件 </span>
      <!--<div class="dif alc pointer mrLft30" @click="isShow3=!isShow3">
        <img src="@/assets/image/yizhibang/03no_check.png" style="width: 16px;height: 16px;" v-if="!isShow3">
        <img src="@/assets/image/yizhibang/03yes_check.png" style="width: 16px;height: 16px;" v-else>
        <p class="fs12 col666 mrLft10">是否关闭此功能<span class="col999">(在您首页官网搜索将不显示此服务)</span></p>
      </div>-->
    </div>
    <!--已经发布-->
    <div v-if="isShow" v-loading="getLoading">
      <div class="mrBtm10">
        <ul class="app_warp" v-if="release.length>0">
          <!--软件信息-->
          <li class="dif alc mrTop20 app_item posr" v-for="(item,index) in release" :key="index">
            <!--软件图标-->
            <div class="app_img bra10" style="width: 100px;height: 100px;overflow: hidden;border-radius: 20px">
              <img  class="w100 h100 bgccc" :src="item.appimage">
            </div>
            <!--软件信息-->
            <div class="app_info" style="margin-left: 41px;">
              <ul class="fs12 col666 difb difw" style="width: 410px;">
                <li class="one_overflow">软件名称：{{item.title}}</li>
                <li class="one_overflow">软件性质：{{item.nature}}</li>
                <Li class="one_overflow">软件语言：{{item.language}}</Li>
                <Li class="one_overflow">软件授权：{{item.accredit}}</Li>
                <li class="one_overflow" v-if="item.size!==''">软件大小：{{item.size}}{{item.size_type===1?'KB':'MB'}}</li>
                <li class="one_overflow" v-if="item.versions!==''">软件版本：{{item.versions}}</li>
                <li class="one_overflow" v-if="item.platform!==''">使用平台：{{item.platform}}</li>
              </ul>
            </div>
            <!--下载地址-->
            <div class="d_code dif alc" style="width: 181px;">
              <div style="margin-right: 33px;" v-if="item.androidimage!==''">
                <img class="bgccc" style="width: 74px;height: 74px;" :src="item.androidimage">
                <p class="fs12 col333" style="margin-top: 15px;">安卓版本下载</p>
              </div>
              <div v-if="item.iosimage!==''">
                <img class="bgccc" style="width: 74px;height: 74px;" :src="item.iosimage">
                <p class="fs12 col333" style="margin-top: 15px;">苹果版本下载</p>
              </div>
            </div>
            <!--下载-->
            <div class="d_d difcb h100" style="margin-left: 100px;">
              <div style="margin-bottom: 16px;" class="pointer" @click="goDetail(item)">软件介绍</div>
              <!--<div>pc端下载</div>-->
            </div>
            <!--操作-->
            <div class=" fs14 colFDAE38 share pointer"  style="margin-left: 16px;" @click="delApp(item)">
              <!--<p class="pointer mrBtm10">分享</p>-->
              <!--<p class="pointer mrBtm10">评论</p>-->
              <!--<p class="pointer mrBtm10">收藏</p>-->
              <!--<p class="pointer">修改</p>-->
              <i style="color: #fa3534" class="el-icon-delete-solid fs16 pointer"></i>
            </div>
          </li>
        </ul>
        <div v-else style="padding: 100px 0; ">
          <el-result icon="info" title="暂未发布"></el-result>
        </div>
      </div>
      <!--分页-->
     <!-- <div class="difcac">
        <el-pagination
            :current-page="pages"
            @current-change="curChange"
            background
            layout="jumper, prev, pager  ,next   "
            :total="totla">
        </el-pagination>
      </div>-->
    </div>
    <!--软件发布-->
    <div v-else class="mrTop20">
      <!--分类-->
      <div class="dif alc">
        <div class="fs14 col333 dif alc">
          <p style="color: #f00">*</p>
          <p class="mrLft10">分类</p>
          <div style="width: 360px;margin-left: 15px;">
            <el-select disabled placeholder="软件" class="w100"></el-select>
          </div>
        </div>
      </div>
      <!--上传-->
      <div class="dif" style="margin-top: 50px;">
        <div class="posr">
          <el-upload
              class="avatar-uploader"
              action="https://yzb.yzbse.com/api/common/upload"
              :show-file-list="false"
              :on-success="onsuccess"
              :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl" :src="$store.state.url+imageUrl" style="width: 186px;height: 126px;border-radius: 15px">
            <div class="posr" v-else>
              <img src="@/assets/image/yizhibang/69upload_logo.png" style="width: 186px;height: 126px;">
              <p class="fs12 posa" style="color: #B3B3B3;left: 0;right: 0;top: 82px;">请上传软件图标</p>
            </div>
          </el-upload>

        </div>
        <div class="mrLft30">
          <!--品牌名称-->
          <div class="dif alc">
            <p class="mrRit10"><span class="text_red mrRit10">*</span>软件名称</p>
            <div style="width: 360px;">
              <el-input placeholder="请输入软件名称" v-model="title"></el-input>
            </div>
          </div>
          <!--软件发布介绍-->
          <div class="dif mrTop10">
            <p class="mrRit10"><span class="text_red mrRit10">*</span>软件发布关键词</p>
            <div style="width: 360px;">
              <el-input v-model="keywords" type="textarea" placeholder="请填写软件发布的搜索关键词，并用“/”号分开 [用于发布官网的首页搜索，请谨慎填写]"></el-input>
            </div>
          </div>

        </div>
      </div>
      <!--软件性质-->
      <div class="dif alc tlt mrTop20">
        <span class="mrRit30">软件性质</span>
        <el-radio-group v-model="appNature2">
          <el-radio :label="item.id" v-for="(item,index) in appNature" :key="index">{{item.title}}</el-radio>
        </el-radio-group>
      </div>
      <!--软件授权-->
      <div class="dif alc tlt mrTop20">
        <span class="mrRit30">软件授权</span>
        <el-radio-group v-model="appAccredit2">
          <el-radio :label="item.id" v-for="(item,index) in appAccredit" :key="index">{{item.title}}</el-radio>
        </el-radio-group>
      </div>
      <!--软件语言-->
      <div class="dif alc tlt mrTop20">
        <span class="mrRit30">软件语言</span>
        <el-radio-group v-model="appLanguage2">
          <el-radio :label="item.id" v-for="(item,index) in appLanguage" :key="index">{{item.title}}</el-radio>
        </el-radio-group>
      </div>
      <!--软件版本 大小-->
      <div class="dif alc tlt mrTop20">
        <div class="dif alc mrRit30">
          <span class="text_red mrRit10">*</span>
          <span class="mrRit30">软件版本</span>
          <el-input v-model="versions" style="width: auto;"></el-input>
        </div>
        <div class="dif alc">
          <span class="text_red mrRit10">*</span>
          <span class="mrRit30">软件大小</span>
          <el-input v-model="size" style="width: auto;"></el-input>
          <el-radio-group v-model="size_type" class="mrLft30">
            <el-radio :label="1">KB</el-radio>
            <el-radio :label="2">MB</el-radio>
          </el-radio-group>
        </div>
      </div>
      <!--适用系统-->
      <div class="dif alc tlt mrTop20">
        <span class="text_red mrRit10">*</span>
        <span class="mrRit30">适用系统</span>
        <el-input placeholder="例Win8/Win7/Vista/WinXP/Win10/" v-model="platform" style="width: 60%;"></el-input>
      </div>
      <!--上传类型-->
      <div class="dif alc tlt mrTop20">
        <!--上传软件-->
        <div class="dif alc mrRit30">
          <el-radio-group v-model="type" class="mrRit30">
            <el-radio :label="1">上传软件</el-radio>
          </el-radio-group>
          <el-select v-model="classify1" placeholder="请选择" class="mrRit30">
            <el-option
                v-for="(item,index) in class1"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
          <el-radio-group v-model="type" class="mrRit30">
            <el-radio :label="2">上传游戏</el-radio>
          </el-radio-group>
          <el-select v-model="classify2" placeholder="请选择">
            <el-option
                v-for="(item,index) in class2"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <!--上传软件下载二维码 地址-->
      <div class="difb alc mrTop30 w70">
        <!--安卓版本-->
        <div>
          <span>安卓版本</span>
          <div class="mrTop10">
            <el-upload
                class="avatar-uploader"
                action="https://yzb.yzbse.com/api/common/upload"
                :show-file-list="false"
                :on-success="onsuccess2"
                :before-upload="beforeAvatarUpload">
              <img v-if="androidimage" :src="$store.state.url+androidimage" class="avatar2">
              <div v-else class="posr">
                <img src="@/assets/image/yizhibang/69upload_logo.png" style="width: 160px;height: 160px;">
                <p class="fs12 colb3b3b3 posa" style="bottom: 20px;right: 0;left: 0;">请上传安卓版本<br>
                  下载二维码</p>
              </div>

            </el-upload>
          </div>
        </div>
        <!--苹果版本-->
        <div>
          <span>苹果版本</span>
          <div class="mrTop10">
            <el-upload
                class="avatar-uploader"
                action="https://yzb.yzbse.com/api/common/upload"
                :show-file-list="false"
                :on-success="onsuccess3"
                :before-upload="beforeAvatarUpload">
              <img v-if="iosimage" :src="$store.state.url+iosimage" class="avatar2">
              <div v-else class="posr">
                <img src="@/assets/image/yizhibang/69upload_logo.png" style="width: 160px;height: 160px;">
                <p class="fs12 colb3b3b3 posa" style="bottom: 20px;right: 0;left: 0;">请上传苹果版本<br>
                  下载二维码</p>
              </div>
            </el-upload>
          </div>
        </div>
        <!--PC版本-->
        <div>
          <span>PC版本</span>
          <div style="width: 178px;height: 178px;" class="difcc">
            <p class="mrBtm10 fs14 col333">请在下方添加下载链接</p>
           <div style="width: 320px;">
             <el-input placeholder="请填入下载地址" v-model="url"></el-input>
           </div>
          </div>
        </div>
      </div>
      <!--联系方式-->
      <div class="dif alc mrTop20">
        <div class="dif alc">
          <span class="text_red mrRit10">*</span>
          <span class="mrRit10 fs">联系电话</span>
          <el-input v-model="user_name" style="width: 70%;"></el-input>
        </div>
        <div class="dif alc mrLft30">
          <span class="text_red mrRit10">*</span>
          <span class="mrRit10">联系人</span>
          <el-input v-model="user_phone" style="width: 50%;"></el-input>
        </div>
        <!--<div class="dif alc">-->
        <!--  <span class="mrRit10">手机</span>-->
        <!--  <el-input v-model="phone" style="width: 70%;"></el-input>-->
        <!--</div>-->
        <!--<div class="dif alc pointer" @click="isdefault=!isdefault">-->
        <!--  <span class="mrRit10">设为默认</span>-->
        <!--  <img src="@/assets/image/yizhibang/03no_check.png" style="width: 16px;height: 16px;" v-if="!isdefault">-->
        <!--  <img src="@/assets/image/yizhibang/03yes_check.png" style="width: 16px;height: 16px;" v-else>-->
        <!--</div>-->
      </div>
      <!--发布时间-->
      <!--<div class="dif alc mrTop20">
        <span class="mrRit10">发布时间</span>
        <el-date-picker
            style="width: 300px;"
            v-model="createtime"
            type="date"
            value-format="timestamp"
            placeholder="选择日期">
        </el-date-picker>
      </div>-->
      <!--上传软件截图-->
      <div class="tlt mrTop20">
        <p class="tlt fs16 mrBtm20">上传软件截图(最多上传5张图片)</p>
        <!--照片墙-->
        <el-upload
            action="https://yzb.yzbse.com/api/common/upload"
            list-type="picture-card"
            :on-success="onsuccess4"
            :on-remove="handleRemove"
            :limit="limit"
        >
          <i slot="default" class="el-icon-plus"></i>
        </el-upload>
      </div>
      <!--软件介绍编辑-->
      <div class="mrTop20 tlt">
        <h4 class="mrBtm20 tlt">软件介绍编辑</h4>
        <!--富文本-->
        <div style="margin-bottom: 80px;">
          <quill-editor1 @getEditor="getEditor" :toolbarOptions="toolbarOptions" ref="quillEditor" :index="0"></quill-editor1>
        </div>
      </div>
      <!--请上传《软件著作权文件》、《ICP证》、《ICP备案证明》等相关资源-->
      <div class="tlt mrTop20 mrBtm20">
        <p class="tlt fs14 col333 mrBtm20">请上传《软件著作权文件》、《ICP证》、《ICP备案证明》等相关资源<span class="col999 fs12">（提交资质更容易通过审核）</span></p>
        <!--照片墙-->
        <el-upload
            action="https://yzb.yzbse.com/api/common/upload"
            list-type="picture-card"
            :on-success="onsuccess5"
            :on-remove="handleRemove"
            :limit="limit"
        >
          <i slot="default" class="el-icon-plus"></i>
        </el-upload>
      </div>
      <!--取消和发布-->
      <div class="difcac">
        <div class="difcac msg_button1 colfff pointer" @click="isShow=true">取消</div>
        <div class="difcac msg_button2 colfff pointer" @click="submit">发布</div>
      </div>
    </div>
  </div>
</template>

<script>
import quillEditor1 from "@/components/quillEditor1";
export default {
  props: {
    LeftHeaderType: [String, Number],
  },
  components: {
    quillEditor1
  },
  data() {
    return {
      toolbarOptions: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{'header': 1}, {'header': 2}],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'script': 'sub'}, {'script': 'super'}],
        [{'indent': '-1'}, {'indent': '+1'}],
        [{'direction': 'rtl'}],
        [{'size': ['small', false, 'large', 'huge']}],
        [{'header': [1, 2, 3, 4, 5, 6, false]}],
        [{'color': []}, {'background': []}],
        [{'align': []}],
      ],
      user_name: '',//联系人
      user_phone: '',//联系电话
      release: [],//已发布的软件列表
      totla: 20,//总条数
      pages: 1,//当前页数
      getLoading: false,
      pageHeight:document.documentElement.clientHeight,//获取页面高度
      isShow: true,//已经发布 ture  软件发布 false
      isShow3: false,//是否显示此功能
      title: '',//软件名称
      keywords: '',//关键字
      appNature: [],//软件性质
      appNature2: 0,//选中的软件性质
      appAccredit: [],//软件授权
      appAccredit2: 0,//选中的软件授权
      appLanguage: [],//软件语言
      appLanguage2: 0,//选中的软件语言
      imageUrl: '',//图标
      versions: '',//软件版本
      size: '',//软件大小
      size_type: 1,//类型 1 kb  2 mb
      platform: '',//适用系统
      type: 1,// 上传类型 1软件 2游戏
      classify1: '',//选中的上传分类 软件
      classify2: '',//选中的上传分类  游戏
      class1: [],//软件分类
      class2: [],//游戏分类
      androidimage: '',//安卓下载二维码
      iosimage: '',//苹果下载二维码
      url: '',//pc端下载链接
      createtime: '',//发布时间
      images: [],//软件截图
      images2: [],//证件截图
      limit: 5,//最大上传数量
      content: '',//软件介绍
      landline: '',
      broker: '',
      phone: '',

    }
  },
  methods: {
    getClass() { //获取软件/游戏分类
      this.$axios("POST","/api/app/getAppCate",{})
      .then(res => {
        this.class1 = res.data.class1
        this.class2 = res.data.class2
      })
    },
    goDetail(item) { //跳转到软件详情
      let data = {
        id: item.id
      }
      let routeUrl = this.$router.resolve({
        path: '/search/app/detail',
        query: data
      })
      window.open(routeUrl .href, '_blank');
    },
    delApp(item) { //删除店铺
      this.$confirm('确认要删除该软件吗？',{type: 'warning'})
          .then(con => {
            this.$axios("POST","/api/member/deleteUserInfo",{
              type: 4,
              id: item.id
            }).then(res => {
              if(res.code===1) {
                this.$message.success('删除成功')
                this.getAppList()
              }else {
                this.$message.error(res.msg)
              }
            })
          })
    },
    curChange(e) { //页数切换
      this.pages = e
      this.getAppList(this.pages)
    },
    getAppList(pages) { //获取已发布软件
      this.getLoading=true
      this.$axios("POST","/api/app/getReleaseApp",{}).then(res => {
        this.getLoading=false
        if(res.code===1) {
          this.release = res.data
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    getAppNature() { //获取软件性质
      this.$axios("POST","/api/app/getAppNature",{})
      .then(res => {
        if(res.code === 1) {
          this.appNature = res.data
          this.appNature2 = res.data[0].id
        }else{
          alert(res.msg)
        }
      })
    },
    getAppAccredit() {//获取软件授权
      this.$axios("POST","/api/app/getAppAccredit",{})
      .then(res => {
        if(res.code === 1) {
          this.appAccredit = res.data
          this.appAccredit2 = res.data[0].id
        }else{
          alert(res.msg)
        }
      })
    },
    getAppLanguage() { //获取软件语言
      this.$axios("POST","/api/app/getAppLanguage",{})
      .then(res => {
        if(res.code === 1) {
          this.appLanguage = res.data
          this.appLanguage2 = res.data[0].id
        }else{
          alert(res.msg)
        }
      })
    },
    onsuccess(file){ //上传图标成功
      this.imageUrl = file.data.url
    },
    onsuccess2(file){ //上传安卓二维码
      this.androidimage = file.data.url
    },
    onsuccess3(file){ //上传苹果二维码
      this.iosimage = file.data.url
    },
    handleRemove(file, fileList) { //删除截图
      let list = []
      for (let i = 0;i<fileList.length;i++) {
        list.push(fileList[i].response.data.fullurl);
      }
      this.fileList = list
    },
    onsuccess4(response, file, fileList) { //上传截图
      let list = []
      for (let i = 0;i<fileList.length;i++) {
        list.push(fileList[i].response.data.fullurl);
      }
      this.images = list
    },
    onsuccess5(response, file, fileList) { //上传截图
      let list = []
      for (let i = 0;i<fileList.length;i++) {
        list.push(fileList[i].response.data.fullurl);
      }
      this.images2 = list
    },
    beforeAvatarUpload(file) {//限制图片大小和格式
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传封面图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传封面图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    getEditor(cont) { //获取富文本编辑器内容
      this.content = escape(cont)
    },
    submit() { //发布软件
      if(this.appimage==='') {
        return this.$alert('请上传软件图标',{type: 'error'})
      }else if(this.title==='') {
        return this.$alert('请输入软件名称',{type: "error"})
      }else if(this.title==='') {
        return this.$alert('请输入软件关键词',{type: "error"})
      }else if(this.content==='') {
        return this.$alert('请输入软件介绍',{type: "error"})
      }else if(this.versions==='') {
        return this.$alert('请输入软件版本',{type: "error"})
      }else if(this.platform==='') {
        return this.$alert('请输入适用系统',{type: "error"})
      }else if(this.images==='') {
        return this.$alert('请上传软件截图',{type: "error"})
      }else if(this.size==='') {
        return this.$alert('请输入软件大小',{type: "error"})
      }else if(this.androidimage==='' && this.iosimage==='' && this.url==='') {
        return this.$alert('最少上传一个软件下载方式',{type: "error"})
      }

      this.$axios("POST","/api/Release/releaseApp",{
        title: this.title,
        appimage: this.imageUrl,
        keyword: this.keywords,
        nature: this.appNature2,
        accredit: this.appAccredit2,
        language: this.appLanguage2,
        versions: this.versions,
        size: this.size,
        size_type: this.size_type,
        platform: this.platform,
        type: this.type,
        classify: this.type==1?this.classify1:this.classify2,
        url: this.url,
        androidimage: this.androidimage,
        iosimage: this.iosimage,
        images: this.images,
        // createtime: this.createtime,
        content: this.content,
        user_name: this.user_name,
        user_phone: this.user_phone,
      }).then(res => {
        if(res.code===1) {
          this.isShow = false
          this.$alert('发布成功',{type: 'success'})
          this.getAppList(1)
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    }
  },
  created() {
    //获取软件性质 授权 语言 分类
    this.getAppNature()
    this.getAppAccredit()
    this.getAppLanguage()
    this.getClass()
    //获取已发布列表
    this.getAppList(1)
    //获取默认联系方式
    if(this.$store.state.contactDetails.id) {
      this.landline = this.$store.state.contactDetails.landline_phone
      this.broker = this.$store.state.contactDetails.broker_name
      this.phone = this.$store.state.contactDetails.tel
      this.isdefault = true
    }
  }
};
</script>

<style scoped>
/*选中状态*/
.active{
  color: #3765FF
}
input {
  padding: 10px;
  border: 0.5px solid #c2c2c2;
  border-radius: 0.5rem;
  width: 80%;
}

/*上传图片*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon,.avatar-uploader-icon2{
  position: relative;
  border: 1px solid #7b7b7b;
  border-radius: 20px;
  font-size: 28px;
  color: #8c939d;
  line-height: 178px;
  text-align: center;
}
.avatar-uploader-icon{width: 250px;height: 178px;}
.avatar-uploader-icon2{width: 178px;height: 178px;}
.txt{
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translate(-50%);
}
.avatar {
  width: 250px;
  height: 178px;
  border-radius: 20px;
  display: block;
}
.avatar2 {
  width: 160px;
  border-radius: 20px;
  height: 160px;
  display: block;
}
/*软件发布介绍 关键词*/
.keywords{
  margin-top: 10px;
  resize: none;
  width: 100%;
  border-radius: 15px;
  padding: 20px;
}




/*软件列表*/
.app_warp{
  padding-right: 10px;
}
.share{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;
}
.app_info li{
  text-align: left;
  width: 50%;
  margin-bottom: 10px;
}
.app_item{
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #EFEFEF;
  padding: 23px 36px;
  position: relative;
}
.d_d div{
  width: 120px;
  height: 40px;
  background: #3A54FF;
  border-radius: 20px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.msg_button1{
  width: 226px;
  height: 50px;
  background: #CCCCCC;
  border-radius: 6px;
}
.msg_button2{
  width: 226px;
  height: 50px;
  background: #3A54FF;
  border-radius: 6px;
  margin-left: 40px;
}
</style>