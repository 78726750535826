<template>
  <!--店铺-->
  <div class="shop_class">
    <div class="w100 h100 difb res_box">
      <!--店铺搜索左侧列表-->
      <div class="res_left f2">
        <!--联系电话-->
        <!-- <div class="d_tit dif alc mrBtm30">
           <img src="@/assets/image/yizhibang/20telephone.png" style="width: 20px;height: 18px;">
           <h3 class="fs18 col333 bold mrLft10">联系店铺方 0538-000000 </h3>
           <h3 class="fs18 col333 bold" style="margin-left: 44px;">王经理 154-00000000 </h3>
         </div>-->
        <!--搜索结果-->
        <ul class="shop_res dif difw" v-if="res.length>0">
          <li v-for="(item,index) in res" class="mrBtm20" @click="goDetail(item)">
            <div class="good_img">
              <!--图片轮播-->
              <el-carousel indicator-position="none" height="248px" arrow="never">
                <el-carousel-item v-for="(item2,index2) in getImg(item.images)" :key="index2">
                  <div class="posr w100 h100">
                    <img :src="$store.state.url+item2" style="width: 248px;height: 248px;">
                    <div class="mb1"></div>
                    <div class="mb2"></div>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <!--序号-->
              <div class="noindex">NO.{{index+1}}</div>
              <!--品牌-->
              <div class="pinpai">
                <marquee scrollamount=3 v-if="item.brand && item.brand.length>4">品牌：{{item.brand || '暂无'}}</marquee>
                <span v-else>品牌：{{item.brand || '暂无'}}</span>
              </div>
              <!--公司-->
              <!--<p class="compoany">公司：{{item.compoany}}</p>-->
            </div>
            <div class="good_bottom">
              <!--店铺类型-->
              <div class="difb alc">
                <div class="dif alc">
                  <p class="bold fs12 one_overflow" style="color:#000033;max-width: 150px;">{{item.title}}</p>
                </div>
                <div class="difb alc box">
                  进入店铺
                </div>
              </div>
              <!--操作-->
              <div class="difb alc" style="margin-top: 15px;">
                <div class="dif alc fs12 colF39800">
                  <p class="pointer hover" @click.stop="collect(item)" v-if="item.is_collect==1">已收藏</p>
                  <p class="pointer hover" @click.stop="collect(item)" v-else>收藏</p>
                </div>
                <p class="fs12 one_overflow" style="color:#1C1C1C;max-width: 150px;">
                  <span v-if="item.type==1">直营店</span>
                  <span v-if="item.type==2">旗舰店</span>
                  <span v-if="item.type==3">专卖店</span>
                  <span v-if="item.type==4">专营店</span>
                </p>
              </div>
            </div>
          </li>
          <!--<li v-for="(item,index) in res" class="posr mrTop20 mrBtm20 mrRit10">
            <div class="no">{{index+1}}<span>.NO</span></div>
            &lt;!&ndash;店铺图片&ndash;&gt;
            <div class="shop_pic w100 bgf5">
              <el-carousel height="130px" indicator-position="none">
                <el-carousel-item v-for="(item2,index2) in item.images" :key="index2" >
                  <img :src="item2">
                </el-carousel-item>
              </el-carousel>
            </div>
            &lt;!&ndash;店铺信息&ndash;&gt;
            <div class="shop_info tlt fs13">
              <div style="padding: 8px;">
                <p class="colfff">品牌/{{item.brand}}</p>
                <p class="colfff">公司/{{item.compoany}}</p>
              </div>
              <p class="col004F8A bgff tct bold" style="line-height: 20px;">{{item.title}}</p>
              <div class="difb alc" style="height: 40px;padding: 0 8px;">
                <div class="colfff fs14 dif alc">
                  <img style="height: 20px;width: 50px;" class="bgf5">
                  直营店
                </div>
                <div class="difb alc box">
                  <router-link to="/search/shopdetail" class="colfff fs18 bold">进入店铺</router-link>
                </div>
              </div>
            </div>
            &lt;!&ndash;分享 收藏&ndash;&gt;
            <div class="caozuo difc alc fs12 col004F8A">
              <div>分享</div>
              <div class="mrLft20">收藏</div>
            </div>
          </li>-->
        </ul>
        <div v-if="res.length<=0" style="padding: 100px 0;">
          <el-result icon="info" title="暂无内容"></el-result>
        </div>
      </div>
      <!--右侧相关推荐-->
      <!--<div class="res_right">-->
      <!--  &lt;!&ndash;标题&ndash;&gt;-->
      <!--  <div class="posr mrBtm20" >-->
      <!--    <h2 class="bold col333 fs20 tlt">相关资讯</h2>-->
      <!--    <div class="title_bold"></div>-->
      <!--  </div>-->
      <!--  <div class="box dif alc" style="padding: 16px 0;width: 368px;border-bottom: 1px solid #E6E6E6;"-->
      <!--       v-for="(item,index) in hot" :key="index">-->
      <!--    <img class="bgcb" style="width: 114px;height: 78px;min-width: 114px;min-height: 78px" :src="'https://yzb.yzbse.com'+item.image">-->
      <!--    <div class="mrLft20 difcb tlt" style="height: 74px;">-->
      <!--      <div class="fs16 two_overflow" style="color:#1D1E20;">{{item.title}}</div>-->
      <!--      <div class="col999" style="font-size: 12px;">凤凰网  06-01 11:03</div>-->
      <!--    </div>-->
      <!--  </div>-->
      <!--</div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "shop",
  data() {
    return {
      res: [],//店铺结果
      hot: [],//相关推荐
      sid: '',
    }
  },
  computed: {
    getImg() { //获取轮播图第一张
      return function (str) {
        if(str) {
          let arr = str.split(',')
          return arr
        }
      }
    },
  },
  methods: {
    //跳转到详情
    goDetail(item) {
      sessionStorage.goodsUrl = item.url
      let routeUrl = this.$router.resolve({
        path: '/search/shopdetail'
      })
      window.open(routeUrl .href, '_blank');
    },
    //收藏店铺
    collect(item) {
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      this.$axios("POST","/api/member/collect",{
        id: item.id,
        type: 4
      }).then(res => {
        if(res.code===1) {
          this.$message.success('操作成功')
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    getShop(sid) {//获取官网详情---店铺
      this.$axios("POST","/api/Release/getReleaseStore",{
        sid,
      }).then(res => {
        if(res.code==1) {
          this.res = res.data || []
        }
      })
    },
    getHot() { // 获取相关推荐
      this.$axios("POST","/api/site_title/imformation",{
        type: 1
      }).then(res => {
        this.hot = res.data.slice(0,5)
      })
    }
  },
  created() {
    this.sid = this.$route.query.sid
    this.getShop(this.sid) //获取商铺列表
    // this.getHot() //获取相关推荐
  }
}
</script>

<style scoped>
.shop_class{
  width: 1200px;
  margin: 30px auto;
  box-sizing: border-box;
}
.f2{flex:2}

.title_bold{
  width: 256px;
  height: 10px;
  background: #F6F6F6;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}


.shop_res li{
  margin-left: 30px;
  /*margin-right: 30px;*/
}

.good_bottom{
  padding: 10px 10px;
  width: 248px;
  height: 70px;
  background: #F7F6FA;
  box-sizing: border-box;

}
.good_img{
  background: #3765FF;
  width: 248px;
  height: 248px;
  position: relative;
}
.mb1{
  width: 100%;
  height: 129px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
}
.mb2{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 130px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.5;
}
.noindex {
  font-weight: bold;
  font-size: 16px;
  color: #F39800;
  position: absolute;
  top: 23px;
  left: 12px;
  z-index: 90;
}
.pinpai{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  width: 100px;
  font-size: 12px;
  height: 24px;
  background: #F39800;
  border-radius: 11px;
  position: absolute;
  top: 20px;
  right: 10px;
  z-index:2;
}
.compoany{
  font-size: 12px;
  color: #FFFFFF;
  position: absolute;
  bottom: 12px;
  left: 10px;
  z-index: 3;
}

.no span{
  font-weight: normal;
  font-size: 10px;
}

</style>