<template>
  <!--服务窗口 加盟-->
  <div style="width: 1200px;margin: 40px auto;">
    <div class="join" v-if="res">
      <!--联系电话-->
      <div class="d_tit dif alc mrBtm30">
        <img src="@/assets/image/yizhibang/20telephone.png" style="width: 20px;height: 18px;">
        <h3 class="fs18 col333 bold mrLft10">联系加盟方</h3>
        <h3 class="fs18 col333 bold" style="margin-left: 14px;" v-if="userInfo.is_autonym==1">{{res.user_name}} {{res.user_phone}}</h3>
        <h3 class="fs18 col333 bold" style="margin-left: 14px;" v-else>通过实名认证即可查看联系方式</h3>
      </div>
      <!--内容-->
      <div class="j_top dif">
        <!--轮播图-->
        <div class="swiper_warp">
          <el-carousel height="320px">
            <el-carousel-item v-for="(item,index) in swiper" :key="index">
              <img :src="$store.state.url + item"  class="w100 h100">
            </el-carousel-item>
          </el-carousel>


        </div>
        <!--详细信息-->
        <div class="j_info">
          <ul class="col7b fs16 col999 tlt">
            <li>投资金额<span class="money">{{res.investmentNUm}}</span></li>
            <li>经营模式<span class="fs18 col333">{{res.manage_pattern}}</span></li>
            <li>加盟区域<span class="fs18 col333">{{res.leagueArea}}</span></li>
            <li>门店总数<span class="fs18 col333">{{res.storeNum}}家</span></li>
            <li>品牌成立<span class="fs18 col333">{{res.brand_authorizing}}</span></li>
            <li>适合人群<span class="fs18 col333">{{res.brandCrowd}}</span></li>
            <li>所属行业<span class="fs18 col333">{{res.industryInvolved}}</span></li>
          </ul>
        </div>
      </div>
      <!--目录-->
      <!--<div class="list dif alc" >
        <div class="difcac fs16 col333 list_1">目录</div>
        &lt;!&ndash;目录列表&ndash;&gt;
        <div class="list2">
          <div class="dif alc" style="height: 238px;">
            <div class="list2_item" >
              <p class="fs14 col3A53FF mrTop10">1.历史沿革</p>
              <ul class="fs12 col333 mrTop10">
                <li class="mrBtm10">.2010年</li>
                <li class="mrBtm10">.2011年</li>
                <li class="mrBtm10">.2012年</li>
                <li class="mrBtm10">.2013年</li>
              </ul>
            </div>
          </div>
        </div>
      </div>-->
      <!--介绍-->
      <el-collapse v-model="cat">
        <el-collapse-item class="tlt" name="1">
          <template slot="title">
            <div class="title posr dif alc">
              <div class="title_icon"></div>
              <h3 class="fs18 col333 tlt" style="margin-left: 5px;">{{res.label1 || '招商详情'}}</h3>
            </div>
          </template>
          <div class="fs14 col333 content_warp" style="line-height: 40px;text-indent: 2em" v-html="unse(res.attractcontent)"></div>
        </el-collapse-item>

        <el-collapse-item class="tlt" name="2">
          <template slot="title">
            <div class="title posr dif alc">
              <div class="title_icon"></div>
              <h3 class="fs18 col333 tlt" style="margin-left: 5px;">{{res.label2 || '加盟优势'}}</h3>
            </div>
          </template>
          <div class="fs14 col333 content_warp" style="line-height: 40px;text-indent: 2em" v-html="unse(res.advantagecontent)"></div>
        </el-collapse-item>

        <el-collapse-item class="tlt" name="3">
          <template slot="title">
            <div class="title posr dif alc">
              <div class="title_icon"></div>
              <h3 class="fs18 col333 tlt" style="margin-left: 5px;">{{res.label3 || '加盟条件'}}</h3>
            </div>
          </template>
          <div class="fs14 col333 content_warp" style="line-height: 40px;text-indent: 2em" v-html="unse(res.conditioncontent)"></div>
        </el-collapse-item>

        <el-collapse-item class="tlt" name="4">
          <template slot="title">
            <div class="title posr dif alc">
              <div class="title_icon"></div>
              <h3 class="fs18 col333 tlt" style="margin-left: 5px;">{{res.label4 || '加盟流程'}}</h3>
            </div>
          </template>
          <div class="fs14 col333 content_warp" style="line-height: 40px;text-indent: 2em" v-html="unse(res.processcontent)"></div>
        </el-collapse-item>

        <el-collapse-item class="tlt" name="5">
          <template slot="title">
            <div class="title posr dif alc">
              <div class="title_icon"></div>
              <h3 class="fs18 col333 tlt" style="margin-left: 5px;">其他</h3>
            </div>
          </template>
          <div class="fs14 col333 content_warp" style="line-height: 40px;text-indent: 2em" v-html="unse(res.other)"></div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div v-else style="padding: 100px 0;">
      <el-result icon="info" title="暂无内容"></el-result>
    </div>
  </div>
</template>

<script>
export default {
  name: "join",
  data() {
    return {
      sid: '',
      cat: ['1','2','3','4','5'],//默认展开的目录
      res: null,//加盟结果
      swiper: [],//轮播图
      userInfo: {
        is_pay: 0,
        is_autonym: 0,
      },
    }
  },
  created() {
    this.sid = this.$route.query.sid
    this.getJoin(this.sid)
    this.getUserInfo()
  },
  methods:{
    getUserInfo() { //获取用户信息
      this.$axios("POST","/api/user/getUser",{})
          .then(res => {
            this.userInfo = res.data
          })
    },
    getJoin(sid) {
      this.$axios("POST","/api/site/siteInfodetail",{
        sid,
        type: 4,
        infoType: 4
      }).then(res => {
        if(res.code===1) {
          this.res = res.data.pop();
          this.swiper = this.res.images.split(',')
        }else {
          this.$message.error(res.msg)
        }
      })
    }
  },
  computed: {
    unse() { //富文本转义
      return function (str) {
        return unescape(str)
      }
    }
  }
}
</script>

<style scoped>
.d_tit div{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.swiper_warp{
  width: 520px;
  height: 320px;
  background: #666666;
  overflow: hidden;
  position: relative;
}


.j_top{
  padding-bottom: 20px;
}
.j_info ul{
  margin-left: 36px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 320px;
}
.j_info li span{
  margin-left: 20px;
}
.j_info .money{
  font-weight: bold;
  color: #F6AA00;
  font-size: 26px;
  font-style: italic;

}



.title_icon{
  width: 10px;
  height: 10px;
  background: #3765FF;
}


.img_warp{
  width: 100%;
}
.img_warp img{
  width: 360px;
  height: 140px;
}

/*  目录*/
.list{
  width: 1200px;
  border: 1px solid #EBF1F5;
  height: 238px;
  margin-bottom: 30px;
}
.list_1{
  width: 100px;
  border: 1px solid #EBF1F5;
  height: 238px;
  background: #F5FBFF;
}
.list2{
  width: 1100px;
  height: 238px;
  overflow-x: auto;
}
.list2_item{
  box-sizing: border-box;
  flex: none;
  width: 160px;
  height: 238px;
  border: 1px solid #EBF1F5;
  overflow: hidden;
}
.content_warp ::v-deep img{
  display: block;
  margin: 0 auto;
  width: 70%;
  height: auto;
}
</style>