<template>
  <div>
    <!-- <object
      :data="urls[currentWindow]"
      class="iframe"
    ></object> -->
    <iframe :src="urls[currentWindow]" class="iframe" ></iframe>
  </div>
</template>

<script>
export default {
  name: "webview",
  props: {
    urls: {
      type: Array,
      default: []
    },
    currentWindow: {
      type: [Number,String],
      default: 0
    }
  },
  data() {
    return {
      // 页面高度
      screenHeight: document.body.clientHeight,
      // 计算出的高度
      PageHeight: '',
      isScroll: true,
      timer: null,
    }
  },
  methods: {
    GetHeight() {
      // 根据页面高度计算  (可以自定义逻辑)
      this.PageHeight = (this.screenHeight)
    },
    handleScrollx() { //监听滚动高度
      let height = document.body.clientHeight
      console.log(window.pageYOffset)
      if(window.pageYOffset>= height/5.2) {
        if(this.isScroll ) {
          this.scrollTo()
        }
        this.isScroll = false
      }else {
        this.isScroll = true
      }
      // console.log('滚动高度', window.pageYOffset)
      // console.log('距离顶部高度', this.$refs.obtain.getBoundingClientRect().top)
    },
    scrollTo() {
      let el = document.getElementById('obtain');
      // let el = document.getElementById('test');
      if(el){
        window.scrollTo({
          //滚动到元素位置
          //top: this.$refs[元素].offsetTop,//offsetTop 相对于offsetparent 定位,当有定位时，不准确。
          // top: el.getBoundingClientRect().top ,//推荐使用，getBoundingClientRect 相对于当前视口的位置
          //top: 400//滚动到指定距离
          //top: 0,//滚动到顶部
          top: document.documentElement.scrollHeight,//滚动到底部
          behavior: "smooth" // 平滑滚动
        });
      }
    },
  },
  created() {
    this.GetHeight()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScrollx, true)
    console.log('创建',document.body.clientHeight)
  },
  beforeDestroy() {
    console.log('销毁')
    window.removeEventListener('scroll', this.handleScrollx, true)
  }
}
</script>

<style scoped>
  .iframe{
    width: 100%;
    height: 82vh;
    border: solid #fff 1px;
    /* border-bottom: 1px solid #3A51FF; */
  }
</style>