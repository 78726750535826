<template>
    <div class=" box" style="padding: 72px 81px;">
      <div style="width: 170px;">
        <h3 class="fs16 col333 bold tlt" style="width: 170px;">为营造可靠可追溯环境将实行实名认证</h3>
        <p class="fs12 col999 tlt mrTop10">认证后解锁联系方式评论留言</p>
      </div>
      <div class=" dif alc" style="margin-top: 90px;">
        <div class="left_input" v-if="obj.status!=1">
          <div class="dif alc mrBtm30">
            <p class="fs14 col999">真实姓名</p>
            <input type="text" placeholder="请您输入姓名" v-model="obj.true_name">
          </div>
          <div class="dif alc">
            <p class="fs14 col999">身份证号</p>
            <input type="text" placeholder="请您输入证件号码" maxlength="18" v-model="obj.card_no">
          </div>
        </div>
        <div class="center_upload dif alc" style="margin-left: 60px;" v-if="obj.status!=1">
          <div style="width: 194px;height: 150px;" class="pointer posr">
            <el-upload
                class="w100 h100"
                action="https://yzb.yzbse.com/api/common/upload"
                :show-file-list="false"
                :on-success="onsuccess"
                :on-error="onerror"
                accept="image/png, image/jpeg"
            >
              <img src="@/assets/image/yizhibang/59positive.png" class="w100 h100" v-if="obj.id_front===''">
              <img :src="$store.state.url+obj.id_front" v-else style="width: 194px;height: 150px;">
            </el-upload>
          </div>
          <div style="width: 194px;height: 150px;" class="pointer posr mrLft30">
            <el-upload
                class="w100 h100"
                action="https://yzb.yzbse.com/api/common/upload"
                :show-file-list="false"
                :on-success="onsuccess2"
                accept="image/png, image/jpeg"
            >
              <img src="@/assets/image/yizhibang/59reverse.png" class="w100 h100" v-if="obj.id_side===''">
              <img :src="$store.state.url+obj.id_side" v-else style="width: 194px;height: 150px;">
            </el-upload>
          </div>
        </div>
        <ul class="fs12 col999 tlt" style="margin-left: 50px;" v-if="obj.status!=1">
          <li class="mrBtm10">· 支持jpg、jpeg、png格式</li>
          <li class="mrBtm10">· 图片大小不超过5MB</li>
          <li class="mrBtm10">· 所填信息必须与身份证一致</li>
          <li class="mrBtm10">· 上传图片须手持身份证露出四个角</li>
          <li>· 上传图片如果不够清晰会审核不通过</li>
        </ul>
      </div>
      <div  class="pointer mr0At difcac colfff fs24 up_button" v-if="obj.status==0">待审核</div>
      <div @click="update" class="pointer mr0At difcac colfff fs24 up_button" v-if="obj.status=='-1'">保存</div>
      <div class="pointer mr0At difcac colfff fs24 up_button" v-if="obj.status==1">已认证</div>
      <div @click="update" class="pointer mr0At difcac colfff fs24 up_button" v-if="obj.status==2">重新提交</div>
      <div class="tc mrTop10" v-if="obj.status==2">
        失败原因：{{obj.reason}}
      </div>
      <div style="margin-top: 100px;" v-if="false">
        <h4 class="fs16 col333 tlt mrBtm30">认证分享给朋友吧</h4>
        <div class="dif fs13 col333 alc">
          <div class="dif alc pointer" style="margin-right: 80px;">
            <p class="mrRit10">认证qq</p>
            <img src="@/assets/image/yizhibang/59qq.png" style="width: 30px;height: 30px;">
          </div>
          <div class="dif alc pointer" style="margin-right: 80px;">
            <p class="mrRit10">认证空间</p>
            <img src="@/assets/image/yizhibang/59qzone.png" style="width: 30px;height: 30px;">
          </div>
          <div class="dif alc pointer" style="margin-right: 80px;">
            <p class="mrRit10">认证微信</p>
            <img src="@/assets/image/yizhibang/59wechat.png" style="width: 30px;height: 30px;">
          </div>
          <div class="dif alc pointer" style="margin-right: 80px;">
            <p class="mrRit10">认证微圈</p>
            <img src="@/assets/image/yizhibang/59wechat_moments.png" style="width: 30px;height: 30px;">
          </div>
          <div class="dif alc pointer">
            <p class="mrRit10">认证微博</p>
            <img src="@/assets/image/yizhibang/59weibo.png" style="width: 30px;height: 30px;">
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        idefentStatus:true,//身份认证框状态
        pageHeight:document.documentElement.clientHeight,//获取页面高度
        obj: {
          id_front: '',//身份正面
          true_name: '',//真实姓名
          card_no: '',//身份证
          id_side: '',//身份反面
          status: '-1'
        }
      }
    },
    created(event) {
        this.getInfo()
    },
    methods:{
      getInfo() { //获取实名认证信息
        this.$axios("POST","/api/member/getMyAuthentication",{})
        .then(res => {
          if(res.code===1 && res.data) {
            this.obj = res.data
            this.$forceUpdate()
          }
        })
      },
      update() {
        this.$axios("POST","/api/member/userAuthentication",this.obj)
        .then(res => {
          if(res.code===1) {
            this.$alert('提交成功')
            // this.$router.go(0)
            this.getInfo()
          }else {
            this.$alert(res.msg)
          }
        })
      },
      onerror(err) {
        console.log(err);
      },
      onsuccess(file) {
        this.obj.id_front = file.data.url
      },
      onsuccess2(file) {
        this.obj.id_side = file.data.url
      },
    }
  }
</script>


<style scoped>
  .up_button{
    width: 360px;
    height: 46px;
    margin-top: 80px;
    background: #3A54FF;
    border-radius: 8px;
  }
  .userInfoUP .mrRit30{
    margin-right: 65px;
  }
  .left_input input{
    width: 180px;
    height: 30px;
    border: 1px solid #CCCCCC;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 0 20px;
    margin-left: 10px;
    font-size: 12px;
    color: #333;
  }
</style>