<template>
  <div>
    <!--未提交-->
    <div class="container" v-if="obj.type==0">
      <h1 class="fs16 col333 bold tlt">企业认证</h1>
      <div class="difcac mrTop20">
        <!--第一步-->
        <div class="posr pointer difcac jindu" @click="jindu=1">
          <img src="@/assets/image/yizhibang/63blue.png" class="jindu_bg">
          <p class="posr fs32 colfff bold" style="font-style: italic;">1</p>
          <p class="posr fs18 colfff bold mrLft30">确认个人资料身份</p>
        </div>
        <!--第二步-->
        <div class="posr pointer difcac jindu mrLft20 mrRit20" @click="next1"> 
          <!-- jindu=2 -->
          <img src="@/assets/image/yizhibang/63blue.png" class="jindu_bg" v-if="jindu>=2">
          <img src="@/assets/image/yizhibang/63gray.png" class="jindu_bg" v-else>
          <p class="posr fs32 colfff bold" style="font-style: italic;" :class="jindu>=2?'colfff':'col3765FF'">2</p>
          <p class="posr fs18 colfff bold mrLft30" :class="jindu>=2?'colfff':'col3765FF'">组织企业身份认证</p>
        </div>
        <!--第三步-->
        <div class="posr pointer difcac jindu" @click="jindu=3">
          <img src="@/assets/image/yizhibang/63blue.png" class="jindu_bg" v-if="jindu>=3">
          <img src="@/assets/image/yizhibang/63gray.png" class="jindu_bg" v-else>
          <p class="posr fs32 colfff bold" style="font-style: italic;" :class="jindu>=3?'colfff':'col3765FF'">3</p>
          <p class="posr fs18 colfff bold mrLft30" :class="jindu>=3?'colfff':'col3765FF'">组织企业资质认证</p>
        </div>
      </div>
      <!--第一步-->
      <div v-if="jindu===1">
        <div class="difcac" style="margin-top: 60px;">
          <div class="jindu_icon"></div>
          <p class="fs16 col333 bold">个人中心中“我的资料”是否完善且真实</p>
          <div class="jindu_icon2 active difb alc mrLft30" v-if="isUser==1">
            <p class="fs12 colfff">完成</p>
            <div class="difcac" style="width: 22px;">
              <img src="@/assets/image/yizhibang/63yes.png" style="width: 12px;height: 8px;">
            </div>
          </div>
          <div class="jindu_icon2  difb alc mrLft30" v-else style="padding: 0;border: none" >
            <p class="fs12 colfff" style="width: 100%;margin: 0;padding: 0;">未完成</p>
          </div>
        </div>
        <div class="difcac" style="margin-top: 20px;">
          <div class="jindu_icon"></div>
          <p class="fs16 col333 bold">个人中心中“身份认证”是否认证且无误</p>
          <div class="jindu_icon2 active difb alc mrLft30" v-if="auth==1">
            <p class="fs12 colfff">完成</p>
            <div class="difcac" style="width: 22px;">
              <img src="@/assets/image/yizhibang/63yes.png" style="width: 12px;height: 8px;">
            </div>
          </div>
          <div class="jindu_icon2  difb alc mrLft30" v-else style="padding: 0;border: none" >
            <p class="fs12 colfff" style="width: 100%;margin: 0;padding: 0;">未完成</p>
          </div>
        </div>
        <div class="difcac" style="margin-top: 40px;">
          <p class="fs14 col999">所填信息必须与组织企业信息前后一致，否则将不会审核通过。</p>
        </div>
        <div class="difc alc mrTop30">
          <div class="pointer">
            <el-upload
                action="https://yzb.yzbse.com/api/common/upload"
                :show-file-list="false"
                :on-success="uploadCertificate"
                :before-upload="beforeAvatarUpload">
              <img src="@/assets/image/yizhibang/63explain.png" style="width: 189px;height: 177px;" v-if="certificate===''">
              <img :src="certificate" style="width: 189px;height: 177px;" v-else>
            </el-upload>

          </div>
          <div style="margin-left: 50px;">
            <h2 class="fs26 col333 bold">正式员工说明书</h2>
            <p class="fs14 col999 mrTop20 mrBtm10">上传自己的员工说明书</p>
            <p class="fs14 col999">请确保所有信息清晰完整</p>
          </div>
        </div>
        <div class="difcac mrTop20">
          <p class="fs14 col999">注：证明书拍照上传，要求清晰可见，请参考模板</p>
          <p class="fs14 col3765FF pointer mrLft10" @click="donwload()"
             style="text-decoration: underline">下载模板</p>
        </div>
        <div class="difcac mrTop20">
          <div class="next_button pointer fs24 colfff" @click="next1">
            进入下一步
          </div>
        </div>
      </div>
      <!--第二步-->
      <div v-if="jindu===2">
        <div class="dif alc" style="margin-top: 40px;">
          <div class="jindu_icon"></div>
          <p class="fs16 col333 bold">我是品牌总舵/组织总会</p>
          <div class="jindu_icon2 pointer difb alc mrLft30" :class="{'active': type===1}" @click="type=1">
            <p class="fs12 colfff">确认</p>
            <div class="difcac" style="width: 22px;">
              <img src="@/assets/image/yizhibang/63yes.png" style="width: 12px;height: 8px;" v-if="type===1">
            </div>
          </div>
        </div>
        <div class="jindu_info mrTop20">
          <ul>
            <li class="col3765FF tlt fs16 bold">·自己独立的品牌（商标）；国外品牌在中国的唯一总代理总经销；有营业执照的生产型企业，服务型企业。</li>
            <li class="col3765FF tlt fs16 bold mrTop10">·行业总协会、机构组织总部。</li>
          </ul>
          <p class="fs14 col999 tlt mrTop20" style="line-height: 24px;">服务：1.生成官网，认证官网，发布官网  2.宣传推广建设品牌形象 3.建设立体品牌IP(发布资讯与共享知识。发布需求、展示产品店铺、发布合作加盟、发布招聘信息) 4.发布企业软
            件、APP  5.提供参与投票、评论、私信、收藏反馈售后窗口服务  6.实时访问数据参考平台  7.网站内精准用户侧栏推荐 8.展示网店或被收录网店的网站 9.后续更多服务更新</p>
        </div>

        <div class="dif alc" style="margin-top: 40px;">
          <div class="jindu_icon"></div>
          <p class="fs16 col333 bold">我是品牌分舵/组织分会</p>
          <div class="jindu_icon2 difb alc mrLft30 pointer" :class="{'active': type===2}" @click="type=2">
            <p class="fs12 colfff">确认</p>
            <div class="difcac" style="width: 22px;">
              <img src="@/assets/image/yizhibang/63yes.png" style="width: 12px;height: 8px;" v-if="type===2">
            </div>
          </div>
        </div>
        <div class="jindu_info mrTop20">
          <ul>
            <li class="col3765FF tlt fs16 bold">·品牌地区的分公司、售后点、服务点；以加盟销售为主的经销商、销售点；品牌的代理商、实体店、服务网店、专卖店、办事处；均需要有执照和品牌授权许可。</li>
            <li class="col3765FF tlt fs16 bold mrTop10">·行业分协会、机构组织分部。</li>
          </ul>
          <p class="fs14 col999 tlt mrTop20" style="line-height: 24px;">服务：1.生成分点网站，认证官网，发布官网  2.宣传推广建设品牌形象 3.建设立体品牌IP(发布资讯与共享知识。发布需求、展示产品店铺、<span class="text_red">发布合作加盟</span>、发布招聘信息) <span class="text_red">4.发布企业软
          件、APP</span>  5.提供参与投票、评论、私信、收藏反馈售后窗口服务  6.实时访问数据参考平台  7.网站内精准用户侧栏推荐 9.后续更多服务更新 <span class="text_red">(红色代表无服务)</span></p>
        </div>

        <div class="dif alc" style="margin-top: 40px;">
          <div class="jindu_icon"></div>
          <p class="fs16 col333 bold">我是网店主</p>
          <div class="jindu_icon2 difb alc mrLft30 pointer" :class="{'active': type===3}" @click="type=3">
            <p class="fs12 colfff">确认</p>
            <div class="difcac" style="width: 22px;">
              <img src="@/assets/image/yizhibang/63yes.png" style="width: 12px;height: 8px;" v-if="type===3">
            </div>
          </div>
        </div>
        <div class="jindu_info mrTop20">
          <ul>
            <li class="col3765FF tlt fs16 bold mrTop10">网上销售的网店主、代理的品牌网店、商城有销售这个品牌；均需要有执照和允许代理销售的授权。</li>
          </ul>
          <p class="fs14 col999 tlt mrTop20" style="line-height: 24px;">服务：1.生成分点网站，认证官网，发布官网  2.宣传推广建设品牌形象 3.建设立体品牌IP(发布资讯与共享知识。发布需求、展示产品店铺、<span class="text_red">发布合作加盟</span>、发布招聘信息) <span class="text_red">4.发布企业软
          件、APP</span>  5.提供参与投票、评论、私信、收藏反馈售后窗口服务  6.实时访问数据参考平台  7.网站内精准用户侧栏推荐 9.后续更多服务更新 <span class="text_red">(红色代表无服务)</span></p>
        </div>
        <div class="difcac mrTop20">
          <div class="next_button pointer fs24 colfff" @click="jindu=3">
            进入下一步
          </div>
        </div>
      </div>
      <!--第三步-->
      <div v-if="jindu===3">
        <div class="difc alc" style="margin-top: 90px;">
          <div class="left_input">
            <div class="dif alc mrBtm30">
              <p class="fs14 col999">品牌名称</p>
              <input type="text" placeholder="请您输入品牌名称" class="fs12 col666" v-model="brand_name">
            </div>
            <div class="dif alc">
              <p class="fs14 col999">企业名称</p>
              <input type="text" placeholder="请您输入企业全称" class="fs12 col666" v-model="firm_name">
            </div>
            <p class="fs14 col333 mrTop20">品牌名称企业名称不可修改请仔细检查填写</p>
          </div>
          <div class="center_upload dif alc" style="margin-left: 60px;">
            <!--组织或企业执照正面-->
            <el-upload
                action="https://yzb.yzbse.com/api/common/upload"
                :show-file-list="false"
                :on-success="uploadTradingimage"
                :before-upload="beforeAvatarUpload">
              <img src="@/assets/image/yizhibang/65business_license.png" style="width: 194px;height: 150px;" class="pointer" v-if="tradingimage===''">
              <img :src="tradingimage" style="width: 194px;height: 150px;" v-else>
            </el-upload>

            <!--法人身份证正面-->
            <!-- <el-upload
                action="https://yzb.yzbse.com/api/common/upload"
                :show-file-list="false"
                :on-success="uploadLegal"
                :before-upload="beforeAvatarUpload">
              <img src="@/assets/image/yizhibang/59positive2.png" style="width: 194px;height: 150px;margin-left: 32px;" class="pointer" v-if="legalPersonimage===''">
              <img :src="legalPersonimage" style="width: 194px;height: 150px;margin-left: 32px;" v-else>
            </el-upload> -->
          </div>
          <ul class="fs12 col999 tlt" style="margin-left: 50px;">
            <li class="mrBtm10">· 支持jpg、jpeg、png格式</li>
            <li class="mrBtm10">· 图片大小不超过5MB</li>
            <li class="mrBtm10">· 所填信息必须与身份证一致</li>
            <li class="mrBtm10">· 上传图片须露出四个角。</li>
            <li>· 上传图片如果不够清晰会审核不通过</li>
          </ul>
        </div>
        <!--<div style="width: 970px;margin: 0 auto;">
          <p class="fs14 col333 tlt mrTop30 mrBtm10">主营关键词</p>
          <div class="jindu_3 mr0At">
          <textarea rows="5" placeholder="请填写公司的主营关键词【用于发布官网的话请如实谨慎填写】"
                    v-model="keyword"
                    cols="fs14 col666"></textarea>
          </div>
        </div>-->
        <div class="difcac mrTop30">
          <div class="next_button pointer fs24 colfff" @click="authenCompany">
            提交审核
          </div>
        </div>
      </div>
    </div>
    <!--已提交-->
    <div v-else class="difcac" style="height: 700px;">
      <el-result icon="success" title="通过" subTitle="企业认证审核已通过" v-if="obj.status==1"></el-result>
      <el-result icon="error" title="审核未通过" subTitle="企业认证审核未通过" v-if="obj.status==2">
        <template slot="extra">
          <div v-if="obj.msg" class="mrBtm20">驳回原因：{{obj.msg}}</div>
          <el-button type="primary" size="medium" @click="obj.type = 0">重新提交</el-button>
        </template>
      </el-result>
      <el-result icon="info" title="未审核" subTitle="请等待审核" v-if="obj.status==0"></el-result>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    LeftHeaderType: [String, Number],
  },
  data() {
    return {
      type: 1, // 1=品牌总舵/组织总会,2=品牌总舵/组织分会,3=网店店主
      pageHeight:document.documentElement.clientHeight,//获取页面高度
      jindu: 1,
      certificate: '',//正式员工证明书
      tradingimage: '',//	组织或企业执照正面
      legalPersonimage: '', //法人身份证正面
      firm_name: '',//企业名称
      brand_name: '',//品牌名称
      keyword: '',//关键词
      donwloadUrl: '',//模板下载链接
      obj: {
        type: '0',
        status: 0,
      },
      auth: -1,//是否实名认证
      isUser: 0,
    }
  },
  methods: {
    getUserInfo() { //获取用户信息
      this.$axios("POST","/api/member/personalCenter",{})
      .then(res => {
        if(res.data.company && res.data.duty && res.data.introduce) {
          this.isUser = 1
        }
      })
    },
    getInfo() { //获取实名认证信息
      this.$axios("POST","/api/member/getMyAuthentication",{})
          .then(res => {
            if(res.code===1 && res.data) {
              this.auth = res.data.status || -1
            }
          })
    },
    getEnterpriseCenter() { //获取企业入驻信息
      this.$axios("POST","/api/User/getUserCompany",{})
          .then(res => {
            if(res.code===1) {
              this.obj = res.data
            }
          })
    },
    donwload() { //打开下载链接
      window.open(this.donwloadUrl)
    },
    getUrl() { //获取模板下载链接
      this.$axios("POST","/api/Index/getConfing",{name: 'donwload_url'})
      .then(res => {
        if(res.code===1) this.donwloadUrl = res.data
      })
    },
    beforeAvatarUpload(file) { //限制图片上传
      const isJPG = file.type === 'image/jpeg';
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      return isJPG;
    },
    uploadCertificate(res, file, list) { //上传员工证明
      if(res.code===1) {
        this.certificate = res.data.fullurl
      }else {
        this.$message.error(res.msg)
      }
    },
    uploadTradingimage(res,file,list) { //上传组织或企业执照正面
      if(res.code===1) {
        this.tradingimage = res.data.fullurl
      }else {
        this.$message.error(res.msg)
      }
    },
    uploadLegal(res, file ,list) { //上传法人身份证正面
      if(res.code===1) {
        this.legalPersonimage = res.data.fullurl
      }else {
        this.$message.error(res.msg)
      }
    },
    authenCompany() { //企业认证
      if(this.certificate==='') {
        this.$alert('请上传正式员工说明书', '提示', {
          confirmButtonText: '确定',
        });
        return
      }
      if(this.firm_name==='' || this.brand_name==='') {
        this.$alert('请填写完整资料', '提示', {
          confirmButtonText: '确定',
        });
        return
      }
      if(this.tradingimage==='' ) {
        this.$alert('请上传证件照', '提示', {
          confirmButtonText: '确定',
        });
        return
      }
      this.$axios("POST","/api/company/authenCompany",{
        firm_name: this.firm_name,
        keyword: this.keyword,
        tradingimage: this.tradingimage,
        // legalPersonimage: this.legalPersonimage,
        certificate: this.certificate,
        brand_name: this.brand_name,
        type: this.type
      }).then(res => {
        if(res.code===1) {
          console.log(res);
          this.$alert('提交成功', '提示', {
            confirmButtonText: '确定',
          }).then(res => {
            this.$router.go(0)
          }).catch(err => {
            this.$router.go(0)
          });
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    next1() { //进入下一部 1
      if(this.isUser!=1) {
        this.$alert('请完善个人资料', '提示', {
          confirmButtonText: '确定',
        });
      }else if(this.auth!=1) {
        this.$alert('请完成身份认证', '提示', {
          confirmButtonText: '确定',
        });
      }else if(this.certificate==='') {
        this.$alert('请上传正式员工证明书', '提示', {
          confirmButtonText: '确定',
        });
      }else {
        this.jindu=2
      }
    },
  },
  created() {
    this.getInfo();
    this.getUserInfo();
    this.getUrl();
    this.getEnterpriseCenter();
  }
};
</script>

<style scoped>
.container {
  /*overflow: hidden;
  overflow-y: auto;*/
  box-sizing: border-box;
  padding: 40px 30px;
}
/*上方进度条*/
.jindu{
  width: 317px;
  height: 52px;
}
.jindu_bg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.jindu_icon{
  width: 10px;
  height: 10px;
  background: #3765FF;
  margin-right: 23px;
}
.jindu_icon2{
  width: 64px;
  height: 20px;
  border: 1px solid #bbb;
  border-radius: 2px;
}
.jindu_icon2.active{
  border: 1px solid #3765FF;
}
.jindu_icon2 p{
  width: 42px;
  height: 20px;
  background: #bbbbbb;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jindu_icon2.active p{
  width: 42px;
  height: 20px;
  background: #3765FF;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.next_button{
  width: 440px;
  height: 56px;
  background: #3765FF;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.jindu_info{
  width: 1166px;
  background: #F5F5F5;
  box-sizing: border-box;
  padding: 20px 28px;
}

/*第三步输入框*/
.left_input input{
  width: 180px;
  height: 30px;
  border: 1px solid #CCCCCC;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 0 20px;
  margin-left: 10px;
  font-size: 12px;
  color: #cccccc;
}
.jindu_3{
  width: 970px;
  height: 163px;
  background: #F0F0F0;
  border-radius: 10px;
  box-sizing: border-box;
  resize: none;
  padding: 15px 20px;
}
.jindu_3 textarea{
  width: 100%;
  height: 100%;
  border: none;
  background: none;
}
</style>