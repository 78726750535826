<template>
<!--  注册盒子 -->
  <div>
    <!--登录框-->
    <div class="register_center" @click="close()" v-show="!showCode">
      <div class="warp dif" @click.stop >

        <!--右侧-->
        <div class="reg_right">
          <!--关闭按钮-->
          <div class="close" @click="close">
            <i class="el-icon-close fs29 pointer"></i>
          </div>
          <!--登录选项-->
          <ul class="r_top difa mr0At w70 mrTop30" v-if="!forgetPass">
            <li v-for="(item,index) in card" :key="index" class="pointer fs20 bold col333"
                :class="isActive==item.id?'active':''" @click="isActive=item.id">{{item.name}}</li>
          </ul>
          <!---->
          <div class="right_cont">
            <!--短信登录 0-->
            <msg-login v-if="isActive==0 && !forgetPass"></msg-login>
            <!--密码登录 1-->
            <login v-if="isActive==1 && !forgetPass" @forgetPwd="forgetPwd"></login>
            <!--注册登录 2-->
            <register v-if="isActive==2 && !forgetPass" @showCode="setCode()"
                      @changeTab="changeTab"></register>
            <!--忘记密码 -->
            <forget-pass v-if="forgetPass" @forgetPwd="forgetPwd"></forget-pass>
          </div>
        </div>
        <!--左侧-->
        <div class="reg_left difcc posr">
          <div class="right_bg">
            <img src="@/assets/image/yizhibang/11right_bgimg.png" class="w100 h100">
          </div>
          <div>
            <img src="@/assets/image/yizhibang/home_logo.png" style="width: 195px;height: auto;" class="posr">
            <!--letter-spacing: 6px;-->
            <div class="tct posr colfff fs14" style="margin-top: 5px;padding-left: 7px;">{{login_text}}</div>
          </div>
          <!--<div class="bold fs20 colfff posr" style="margin-top: 67px;margin-bottom: 25px;">手机扫码登录</div>
          <img src="@/assets/image/yizhibang/06cooperation_addimg.png" style="width: 200px;height: 200px;" class="posr">
          <p class="fs14 colfff posr mrTop30">打开亿指榜手机APP</p>
          <p class="fs14 colfff posr">使用【扫一扫】扫描二维码登录</p>-->
        </div>
      </div>
    </div>
    <!--验证码-->
    <div class="register_center" @click="close(2)" v-show="showCode">
      <div class="warp2" @click.stop >
        <div class="posr tct code_title difcac">获取验证码
          <img src="@/assets/image/yizhibang/00one_close.png"  class="code_close pointer" @click="showCode=false">
        </div>
        <div class="difcac pdTop30">
          <slide-verify ref="slideblock"
            :w="450"
            :h="255"
            :imgs="images"
            @again="onAgain"
            @fulfilled="onFulfilled"
            @success="onSuccess" @fail="onFail"
            @refresh="onRefresh" :slider-text="text"
            :accuracy="accuracy"></slide-verify>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import msgLogin from "@/components/home/register/msgLogin"; //短信登录
import login from "@/components/home/register/login"; //密码登录
import register from "@/components/home/register/register"; //注册登录
import forgetPass from "@/components/home/register/forgetPass"; //忘记密码

export default {
  name: "registerCenter",
  components: {login,register,msgLogin,forgetPass},
  methods: {
    changeTab(e) {
      this.isActive = e
    },
    //忘记密码页面显示
    forgetPwd(bool) {
      this.forgetPass = bool
    },
    close(type) {
      if(type==2) {
        this.showCode = false
      }else {
        //开启页面滚动
        let m =function(e){e.preventDefault();};
        document.body.style.overflow='';//出现滚动条
        document.removeEventListener("touchmove",m,{ passive:true });
        this.$emit('closeLogin',false)
      }
    },
    onSuccess(times) {
      console.log('验证通过，耗时 '+ times + '毫秒');
      this.msg = 'login success, 耗时${(times / 1000).toFixed(1)}s'
      this.$message.success('验证成功')
      setTimeout(() => {
        this.showCode = false
        eventBus.$emit('codeSuccess',true)
      },1200)
    },
    onFail(){
      this.$alert('验证失败，请重试',{type: 'error'})
      .then(res => {
        this.$refs.slideblock.reset();
      }).catch(err => {
        this.showCode = false
      })
      console.log('验证不通过');
      this.msg = ''
    },
    onRefresh(){
      console.log('点击了刷新小图标');
      this.msg = ''
    },
    onFulfilled() {
      console.log('刷新成功啦！');
    },
    onAgain() {
      console.log('检测到非人为操作的哦！');
      this.msg = 'try again';
      // 刷新
      this.$refs.slideblock.reset();
    },
    setCode() {
      this.showCode = true
      this.$refs.slideblock.reset();
    },
    handleClick() {
      this.$refs.slideblock.reset();
    },
    getConfig() {
      this.$axios("POST","/api/Index/getConfing",{name: 'loginText'})
          .then(res => {
            if(res.code===1) this.login_text = res.data
          })
    }
  },
  created() {
    this.getConfig()
  },
  data() {
    return {
      login_text: '',
      images: [
        require('@/assets/image/code/img1.jpg'),
        require('@/assets/image/code/img2.jpg'),
        require('@/assets/image/code/img3.jpg'),
        require('@/assets/image/code/img4.jpg'),
        require('@/assets/image/code/img5.jpg'),
      ],
      card: [ //登录方式
        {id:0,name:'短信登录'},
        {id:1,name:'密码登录'},
        {id:2,name:'注册登录'}
      ],
      isActive: 1,
      forgetPass: false,
      showCode: false,
      msg: '',
      text: '向右滑动验证',
      accuracy: 5,
    }
  }
}
</script>

<style scoped>
  .register_center{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(151,151,151,0.5);
    z-index: 999;
  }
  .register_center div{line-height: normal}
  .warp{
    width: 1200px;
    height: 628px;
    background: #FFFFFF;
    /*box-shadow: 0 5px 50px 0 rgba(186, 186, 186, 0.5);*/
    border-radius: 20px;
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .warp2{
    width: 576px;
    height: 500px;
    background: #FFFFFF;
    border-radius: 20px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .code_close{
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }
  .code_title{
    width: 100%;
    height: 70px;
    background: #3A54FF;
    font-weight: bold;
    font-size: 26px;
    color: #ffffff;
    border-radius: 20px 20px 0px 0px;
  }
  .close{
    line-height: normal;
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 99;
  }

  .reg_left{
    width: 600px;
    height: 628px;
    background: #3A54FF;
    border-radius: 0 20px 20px 0;
  }
  .right_bg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .reg_right{
    position: relative;
    width: 50%;
    padding-top: 80px;
    background: #fff;
  }
  .reg_logo{
    margin-bottom: 50px;
    background: #666666;
    width: 100px;
    height: 50px;
  }
  .r_top li{
    font-size: 18px;
    color: #000;
    padding-bottom: 5px;
  }
  .r_top li.active{
    border-bottom: 4px solid #3A54FF;
    color: #3A54FF;
  }
</style>