<template>
  <!--需求发布-->
  <div class="padding">
    <div class="tlt dif alc posr">
      <span class="pointer fs16 col666" @click="isShow=true" :class="isShow===true?'active':''"> 已经发布 </span>
      <span class="pointer fs16 col666 mrLft30" @click="isShow=false" :class="isShow===false?'active':''"> 需求发布 </span>
     <!-- <div class="dif alc pointer mrLft30" @click="isShow3=!isShow3">
        <img src="@/assets/image/yizhibang/03no_check.png" style="width: 16px;height: 16px;" v-if="!isShow3">
        <img src="@/assets/image/yizhibang/03yes_check.png" style="width: 16px;height: 16px;" v-else>
        <p class="fs12 col666 mrLft10">是否关闭此功能<span class="col999">(在您首页官网搜索将不显示此服务)</span></p>
      </div>-->
      <!--<div class="colfff difcac del_button">删除</div>-->
    </div>
    <!-- 曾经发布-->
    <div v-if="isShow" class="mrTop20">
      <!--<div class="tlt dif alc pdLft20 posr" style="border-bottom: 1px solid #eeeeee;height: 40px;background: #FAFAFA">
        <span @click="isShow2=true" :class="isShow2===true?'active':''" class="pointer"> 需求展示中 </span>
        <span @click="isShow2=false" :class="isShow2===false?'active':''" class="pointer mrLft30"> 需求已完成 </span>
        <div class="news_guanli pointer" style="right: 108px;">
          <img src="@/assets/image/yizhibang/51noclose.png" style="width: 20px;height: 20px;">
          &lt;!&ndash;<img src="@/assets/image/yizhibang/51close.png" style="width: 20px;height: 20px;">&ndash;&gt;
        </div>
      </div>-->
      <!--需求展示中-->
      <div v-if="isShow2">
        <!--需求标题-->
        <ul class="fs14 col333 bold dif alc" style="padding: 17px 0;">
          <li style="padding: 0 20px;">需求名称</li>
          <li style="padding: 0 30px;">需求类型</li>
          <li style="padding: 0 40px;">地点</li>
          <li style="padding: 0 36px;">工作单位</li>
          <li style="padding: 0 67px;">需求标题</li>
          <li style="padding: 0 79px;">起止时间</li>
          <li style="padding: 0 21px;">剩余天数</li>
          <li style="padding: 0 26px;">状态</li>
        </ul>
        <ul class="dif alc posr col333 fs12 xuqiu_item" @click="goDetail(item)"
            v-for="(item,index) in incomplete" :key="index">
          <li style="width: 96px;" class="one_overflow">{{item.name}}</li>
          <li style="width: 116px;" class="one_overflow">{{item.type}}</li>
          <li style="width: 108px;" class="one_overflow">{{item.address}}</li>
          <li style="width: 124px;" class="one_overflow">{{item.unit || ''}}</li>
          <li style="width: 190px;" class="one_overflow">{{item.title}}</li>
          <li style="width: 214px;" class="one_overflow">{{item.start_time}}至{{item.end_time}}</li>
          <li style="width: 98px;" class="one_overflow">{{item.daynum}}天</li>
          <li style="width: 80px;" class="one_overflow">
            <span v-if="item.status===1" class="col333">进行中</span>
            <span v-if="item.status===0" class="col333">已暂停</span>
            <span v-if="item.status===2" class="col333">已过期</span>
          </li>
          <div class="news_guanli pointer" @click.stop="delNeed(item)">
            <i class="el-icon-delete-solid fs16" style="color: #fa3534"></i>
            <!--<img src="@/assets/image/yizhibang/51noclose.png" style="width: 20px;height: 20px;">-->
            <!--<img src="@/assets/image/yizhibang/51close.png" style="width: 20px;height: 20px;">-->
          </div>
        </ul>
        <!--分页-->
       <!-- <div class="difcac">
          <el-pagination
              background
              layout="jumper, prev, pager  ,next   "
              :total="1000">
          </el-pagination>
        </div>-->
      </div>
      <!--需求已完成-->
      <div v-else>
        <!--需求标题-->
        <ul class="fs14 col333 bold dif alc" style="padding: 17px 0;">
          <li style="padding: 0 20px;">需求名称</li>
          <li style="padding: 0 30px;">需求类型</li>
          <li style="padding: 0 40px;">地点</li>
          <li style="padding: 0 36px;">工作单位</li>
          <li style="padding: 0 67px;">需求标题</li>
          <li style="padding: 0 79px;">起止时间</li>
          <li style="padding: 0 21px;">剩余天数</li>
          <li style="padding: 0 26px;">状态</li>
        </ul>
        <ul class="dif alc posr col333 fs12 xuqiu_item" v-for="(item,index) in 5" :key="index">
          <li style="width: 96px;" class="one_overflow">工作服装</li>
          <li style="width: 116px;" class="one_overflow">招投标采购</li>
          <li style="width: 108px;" class="one_overflow">北京</li>
          <li style="width: 100px;" class="one_overflow">中国政府网</li>
          <li style="width: 190px;" class="one_overflow">北京代用名公司需求介绍</li>
          <li style="width: 214px;" class="one_overflow">2020-01-02至2021-01-02</li>
          <li style="width: 98px;" class="one_overflow">20天</li>
          <li style="width: 80px;" class="one_overflow">已完成</li>
        </ul>
        <!--分页-->
        <div class="difcac">
          <el-pagination
              background
              layout="jumper, prev, pager  ,next   "
              :total="1000">
          </el-pagination>
        </div>
      </div>
   </div>
    <!--需求发布-->
    <div v-else class="tlt mrTop30">
      <!--分类-->
      <div class="dif alc">
        <div class="fs14 col333 dif alc">
          <p style="color: #f00">*</p>
          <p class="mrLft10">分类</p>
          <div style="width: 360px;margin-left: 15px;">
           <el-input placeholder="需求" disabled></el-input>
          </div>
        </div>
      </div>
      <!--标题-->
      <div class="dif alc mrTop20 mrBtm20">
        <div class="fs14 col333 dif alc">
          <p style="color: #f00">*</p>
          <p class="mrLft10">标题</p>
          <div style="width: 768px;margin-left: 15px;">
            <el-input placeholder="请输入标题" v-model="title"></el-input>
          </div>
        </div>
      </div>
      <!--需求发布介绍-->
      <div class="" style="margin-top: 50px;">
        <p class="fs14 col333 mrRit10 tlt mrBtm10"><span style="color: #f00">*</span>需求发布介绍</p>
        <div style="width: 768px;">
          <el-input placeholder="请填写资讯发布的搜索关键词，并用“/”号分开 [用于发布官网的首页搜索，请谨慎填写]" type="textarea" rows="5" v-model="keywords"></el-input>
        </div>
      </div>
      <!--需求名称和类型-->
      <div class="dif alc">
        <!--需求名称-->
        <div class="dif alc mrRit30">
          <p style="color: #f00">*</p>
          <p class="mrRit10">输入需求名称</p>
          <div style="width: 180px;">
            <el-input placeholder="请输入需求名称" v-model="name"></el-input>
          </div>
        </div>
        <!--需求类型-->
        <div class="dif alc mrRit30 mrTop30 mrBtm30">
          <p style="color: #f00">*</p>
          <p class="mrRit10">输入需求类型</p>
          <div style="width: 420px;">
            <el-input placeholder="请填写需求采购方式（如招投标、咨询采购、集中采购等）" v-model="type"></el-input>
          </div>
        </div>
      </div>
      <!--起止时间 剩余时间-->
      <div class="mrTop10 dif alc">
        <div class="dif alc">
          <p style="color: #f00">*</p>
          <span>起止时间</span>
          <div class="mrLft10">
            <el-date-picker
                style="width: 400px;"
                v-model="date"
                type="daterange"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                @change="getDays"
                end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
        <div class="dif alc mrLft20 tlt">
          <span class="mrRit10">剩余天数</span>
          <input type="text" :value="daynum" style="width: 60px;" class="tct" disabled>
        </div>
        <div class="dif alc mrLft20 tlt">
          <span class="mrRit10">状态</span>
          <el-select  placeholder="请选择状态"  style="width: 180px;" v-model="status">
            <el-option
                label="进行中"
                value="1">
            </el-option>
            <el-option
                label="已暂停"
                value="0">
            </el-option>
          </el-select>
        </div>
      </div>
      <!--所属-->
      <!--<div class="fs14 mrTop30 mrBtm30">
        <p>自动显示
          <span class="bold fs14 col333 mrLft30">所属品牌:{{company.brand_title}}</span>
          <span class="bold fs14 col333" style="margin: 0 78px;">公司:{{company.sitename}}</span>
          <span class="bold fs14 col333">地点: {{company.address}}</span>
        </p>
      </div>-->
      <!--地点和单位-->
      <div class="dif alc mrTop20">
        <div class="dif alc">
          <p style="color: #f00">*</p>
          <span class="mrRit10">地点</span>
          <el-input v-model="address" style="width: 70%;"></el-input>
        </div>
        <div class="dif alc mrLft30">
          <p style="color: #f00">*</p>
          <span class="mrRit10">工作单位</span>
          <el-input v-model="unit" style="width: 50%;"></el-input>
        </div>
      </div>
      <!--联系方式-->
      <div class="dif alc mrTop20 w100">
        <div class="dif alc">
          <p style="color: #f00">*</p>
          <span class="mrRit10">联系电话</span>
          <el-input v-model="user_name" style="width:65%;"></el-input>
        </div>
        <div class="dif alc mrLft30">
          <p style="color: #f00">*</p>
          <span class="mrRit10">联系人</span>
          <el-input v-model="user_phone" style="width: 50%;"></el-input>
        </div>
        <!--<div class="dif alc">-->
        <!--  <span class="mrRit10">手机</span>-->
        <!--  <el-input v-model="tel" style="width: 70%;"></el-input>-->
        <!--</div>-->
        <!--<div class="dif alc pointer" @click="isdefault=!isdefault">-->
        <!--  <span class="mrRit10">设为默认</span>-->
        <!--  <img src="@/assets/image/yizhibang/03no_check.png" style="width: 16px;height: 16px;" v-if="!isdefault">-->
        <!--  <img src="@/assets/image/yizhibang/03yes_check.png" style="width: 16px;height: 16px;" v-else>-->
        <!--</div>-->
      </div>
      <!--批购数量-->
      <div class="mrTop20 mrBtm20">
        <div class="dif alc mrBtm20">
          <div class="message_icon mrRit10"></div>
          <p class="fs14 col333 bold">批购数量</p>
        </div>
        <!--富文本-->
        <div style="margin-bottom: 80px;">
          <quill-editor1
              @getEditor="getEditor1"
              ref="quillEditor"
              :index="0"
          ></quill-editor1>
        </div>
      </div>
      <!--详细要求-->
      <div class="mrBtm20">
        <div class="dif alc mrBtm20">
          <div class="message_icon mrRit10"></div>
          <p class="fs14 col333 bold">详细要求</p>
        </div>
        <!--富文本-->
        <div style="margin-bottom: 80px;">
          <quill-editor1
              @getEditor="getEditor2"
              ref="quillEditor"
              :index="0"
          ></quill-editor1>
        </div>
      </div>
      <!--交付方式-->
      <div class="mrBtm20">
        <div class="dif alc mrBtm20">
          <div class="message_icon mrRit10"></div>
          <p class="fs14 col333 bold">交付方式</p>
        </div>
        <!--富文本-->
        <div style="margin-bottom: 80px;">
          <quill-editor1
              @getEditor="getEditor3"
              ref="quillEditor"
              :index="0"
          ></quill-editor1>
        </div>
      </div>
      <!--联系方式-->
      <!--<div class="mrBtm20">
        <div class="dif alc mrBtm20">
          <div class="message_icon mrRit10"></div>
          <p class="fs14 col333 bold">联系方式</p>
        </div>
        &lt;!&ndash;富文本&ndash;&gt;
        <div style="margin-bottom: 80px;">
          <quill-editor1
              @getEditor="getEditor4"
              ref="quillEditor"
              :index="0"
          ></quill-editor1>
        </div>
      </div>-->
      <!--其他-->
      <div class="mrBtm20">
        <div class="dif alc mrBtm20">
          <div class="message_icon mrRit10"></div>
          <p class="fs14 col333 bold">其他</p>
        </div>
        <!--富文本-->
        <div style="margin-bottom: 80px;">
          <quill-editor1
              @getEditor="getEditor5"
              ref="quillEditor"
              :index="0"
          ></quill-editor1>
        </div>
      </div>
      <!--取消和发布-->
      <div class="difcac">
        <div class="difcac msg_button1 colfff pointer" @click="isShow=true">取消</div>
        <div class="difcac msg_button2 colfff pointer" @click="submit">发布</div>
      </div>
    </div>
  </div>
</template>

<script>
//富文本
import quillEditor1 from "@/components/quillEditor1";
export default {
  props: {
    LeftHeaderType: [String, Number],
  },
  components: {
    quillEditor1
  },
  data() {
    return {
      editorOption: {
        placeholder: '编辑文章内容'
      },
      pageHeight:document.documentElement.clientHeight,//获取页面高度
      user_name: '',//联系人
      user_phone: '',//联系电话
      title: '', //需求标题
      isShow: true,//曾经发布 true  需求发布 false
      isShow2: true,//需求展示中 true  需求以完成 false
      isShow3: false,//是否不显示此功能
      keywords: '',//关键词
      name: '',//需求名称
      type: '',//需求类型
      date: [],//起止时间
      daynum: 0,//剩余天数
      landline_phone: '',//联系座机
      broker_name: '',//经纪人
      tel: '',//手机
      content: '详细内容',//内容
      isdefault: false, //设为默认
      status: '1',//状态
      completed: [],//已完成列表
      incomplete: [],//未完成
      company: {},//自动显示的所属
      num: '',//批购数量
      ask_detail: '',//详细要求
      delivery_way: '',//交付方式
      contact_way: '',//联系方式
      other: '',//其他
      address: '',//工作地点
      unit: '',//工作单位
    }
  },
  watch: {
    isdefault(newValue) {
      if(newValue) {
        this.releaseInfo()
      }
    }
  },
  methods: {
    goDetail(item) {
      let routeUrl = this.$router.resolve({
        path: '/search/demand/detail',
        query:{
          id: item.id
        }
      })
      window.open(routeUrl .href, '_blank');
    },
    delNeed(item) { //删除需求
      this.$confirm('确认要删除该需求吗？',{type: 'warning'})
      .then(con=>{
        this.$axios("POST","/api/member/deleteUserInfo",{
          type: 5,
          id: item.id
        }).then(res => {
          if(res.code===1) {
            this.$message.success('删除成功')
            this.getDemand(1)
          }else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    getDays() {
      let daynum = this.date[1] - this.date[0]
      daynum = daynum / (1000*3600*24)
      if(daynum>=365) {
        this.date = []
        this.daynum = 0
        return this.$alert('起止时间最多选择一年')
      }
      this.daynum = daynum
    },
    submit() { //发布需求
      let that = this
      let data = {
        name: this.name,
        keyword: this.keywords,
        title: this.title,
        type: this.type,
        start_time: this.date[0] / 1000,
        end_time: this.date[1] / 1000,
        status: this.status*1,
        daynum: this.daynum,
        content: this.content,
        num: this.num,
        ask_detail: this.ask_detail,
        delivery_way: this.delivery_way,
        contact_way: this.contact_way,
        address: this.address,
        unit: this.unit,
        other: this.other,
        user_name: this.user_name,
        user_phone: this.user_phone,
      }
      if(!this.name) {
        return this.$alert('请输入需求名称',{type: 'error'})
      }else if(!this.keywords) {
        return this.$alert('请输入需求发布介绍',{type: 'error'})
      }else if(!this.title) {
        return this.$alert('请输入需求标题',{type: 'error'})
      }else if(!this.type) {
        return this.$alert('请输入需求类型',{type: 'error'})
      }else if(this.date.length<=0) {
        return this.$alert('请选择起止时间',{type: 'error'})
      }else if(!this.daynum) {
        return this.$alert('请输入正确剩余天数',{type: 'error'})
      }else if(!this.content) {
        return this.$alert('请输入详细内容',{type: 'error'})
      }else if(!this.content) {
        return this.$alert('请输入详细内容',{type: 'error'})
      }else if(this.user_name==='') {
        return this.$alert('请输入联系电话',{type: 'error'})
      }else if(this.user_phone==='') {
        return this.$alert('请输入联系人',{type: 'error'})
      }else if(this.num==='') {
        return this.$alert('请输入批购数量',{type: 'error'})
      }else if(this.ask_detail==='') {
        return this.$alert('请输入详细要求',{type: 'error'})
      }else if(this.delivery_way==='') {
        return this.$alert('请输入交付方式',{type: 'error'})
      }else if(this.address==='') {
        return this.$alert('请输入工作地点',{type: 'error'})
      }else if(this.unit==='') {
        return this.$alert('请输入工作单位',{type: 'error'})
      }

      this.$axios("POST","/api/Release/releaseDemand",data).then(res => {
         if(res.code===1) {
           if(this.isdefault) {
             this.releaseInfo()
           }
           this.$alert('发布成功',{
             type: "success",
             callback: () => {
               this.isShow=true
               this.getDemand(1)
             }
           })
         }else {
           this.$alert(res.msg,{type: 'error'})
         }
      })
    },
    getEditor1(cont) { //批购数量
      this.num = escape(cont)
    },
    getEditor2(cont) { //详细要求
      this.ask_detail = escape(cont)
    },
    getEditor3(cont) { //交付方式
      this.delivery_way = escape(cont)
    },
    getEditor4(cont) { //联系方式
      this.contact_way = escape(cont)
    },
    getEditor5(cont) { //其他
      this.other = escape(cont)
    },
    getDemand(type) { //获取已发布需求 1未完成 2已完成
      this.$axios("POST","/api/Demand/getDemand",{
        type,
      }).then(res => {
        if(res.code===1) {
          if(type===1) { //未完成
            this.incomplete = res.data
          }else if(type===2) { //未完成
            this.completed = res.data
          }
        }else {
          // this.$message.error(res.msg)
        }
      })
    },
    releaseInfo() { //设置默认联系方式
      if(this.landline_phone==='' || this.broker_name==='' || this.tel==='') {
        this.$alert('请输入完整',{type:"error"})
        this.isdefault = false
        return
      }
      this.$axios("POST","/api/Release/createUserReleaseInfo",{
        landline_phone: this.landline_phone,
        broker_name: this.broker_name,
        tel: this.tel,
        is_default: '1'
      }).then(res => {
        if(res.code===1) {
        }else {
          this.$message.error(res.msg)
        }
      })
    },
  },
  created() {
    //获取已发布需求
    this.getDemand(1)
    this.getDemand(2)
    //获取默认联系方式
    if(this.$store.state.contactDetails.id) {
      this.landline_phone = this.$store.state.contactDetails.landline_phone
      this.tel = this.$store.state.contactDetails.tel
      this.broker_name = this.$store.state.contactDetails.broker_name
      this.isdefault = true
    }
    //获取自动显示的所属
    if(this.$store.state.companyInfo.id) {
      this.company = this.$store.state.companyInfo
    }
  }
};
</script>

<style scoped>
.del_button{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 95px;
  width: 50px;
  height: 30px;
  background: #3765FF;
  border-radius: 15px;
  font-size: 14px;
}
.news_guanli {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.xuqiu_item {
  width: 1090px;
  height: 60px;
  border: 1px solid #EFEFEF;
  margin-bottom: 15px;
}

input {
  padding: 10px;
  border: 0.5px solid #c2c2c2;
  border-radius: 0.5rem;
  width: 80%;
}
/*选中状态*/
.active{
  color: #3765FF
}

/* 列表 */
.d_info li {
  line-height: 32px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.gongneng{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.msg_button1{
  width: 226px;
  height: 50px;
  background: #CCCCCC;
  border-radius: 6px;
}
.msg_button2{
  width: 226px;
  height: 50px;
  background: #3A54FF;
  border-radius: 6px;
  margin-left: 40px;
}
.message_icon{
  width: 10px;
  height: 10px;
  background: #345FFD;
}
</style>
