<template>
  <!--服务窗口 需求-->
  <div style="width: 1200px;margin: 40px auto;">
    <div class="demand" style="padding-bottom: 100px;" v-if="res.length>0">
      <!--联系电话-->
      <!-- <div class="d_tit dif alc mrBtm30">
         <img src="@/assets/image/yizhibang/20telephone.png" style="width: 20px;height: 18px;">
         <h3 class="fs18 col333 bold mrLft10">联系需求方 0538-000000 </h3>
         <h3 class="fs18 col333 bold" style="margin-left: 44px;">王经理 154-00000000 </h3>
       </div>-->
      <!--需求内容-->
      <div class="demand_titl bg_a" style="padding: 12px 0;">
        <ul class="fs14 bold col333 dif">
          <li style="padding: 0 20px;">需求名称</li>
          <li style="padding: 0 50px;">需求类型</li>
          <li style="padding: 0 20px;">地点</li>
          <li style="padding: 0 50px;">工作单位</li>
          <li style="padding: 0 40px;" class="tlt">需求标题</li>
          <li style="padding: 0 90px;margin-left: 30px;">起止时间</li>
          <li style="margin-right: 70px;">剩余天数</li>
          <li style="margin-right: 80px;width: 48px;">状态</li>
          <li style="width: 58px;" class="tct">操作</li>
        </ul>
      </div>
      <!--详细信息-->
      <div>
        <ul class="fs12 col333 dif d_info posr"
            style="padding: 12px 0;" @click="goDemandDetail(item.id)"
            :class="index%2===0?'bg_b':'bg_a'"
            v-for="(item,index) in res" :key="index">
          <li style="width: 96px;" class="one_overflow tct">{{item.name}}</li>
          <li style="width: 156px;" class="one_overflow tct">{{item.type}}</li>
          <li style="width: 68px;" class="one_overflow tct">{{item.address}}</li>
          <li style="width: 128px;" class="one_overflow tct">{{item.unit}}</li>
          <li style="width: 196px;" class="tct one_overflow">{{item.title}}</li>
          <li style="width: 236px;" class="one_overflow tct">
            <span style="line-height: normal;">{{item.start_time}}</span>至
            <span style="line-height: normal;">{{item.end_time}}</span>
          </li>
          <li style="margin-right: 70px;width: 56px;" class="one_overflow tct">{{item.daynum}}天</li>
          <li style="margin-right: 80px;width: 48px;" class="one_overflow tct">
            <span v-if="item.status===1">进行中</span>
            <span v-if="item.status===2">已过期</span>
          </li>
          <li class="dif alc col3A53FF">
            <!--<p class="pointer">分享</p>-->
            <p class="pointer" style="margin-left: 15px;" @click.stop="collect(item)" v-if="item.is_collect==1">已收藏</p>
            <p class="pointer" style="margin-left: 15px;" @click.stop="collect(item)" v-else>收藏</p>
          </li>

          <!--<li style="width: 100px;">{{item.name}}</li>
          <li style="width: 100px;">{{item.type}}</li>
          <li style="width: 50px;">{{item.address}}</li>
          <li style="width: 100px;">{{item.unit}}</li>
          <li style="width: 400px;letter-spacing: 5px" class="tlt bold col004F8A">{{item.title}}</li>
          <li style="width: 100px;">
            <div class="fs12 difcc">
              <span style="line-height: normal;">{{item.start_time}}</span>至
              <span style="line-height: normal;">{{item.start_time}}</span>
            </div>
          </li>
          <li style="width: 100px;">20天</li>
          <li style="width: 100px;">
            <span v-if="item.status===1">进行中</span>
            <span v-if="item.status===2">已过期</span>
          </li>
          <div class="col7b fs16 gongneng dif">
            <p>分享</p>
            <p class="col004F8A mrLft20">收藏</p>
          </div>-->
        </ul>
      </div>
    </div>
    <div v-if="res.length<=0" style="padding: 100px 0;">
      <el-result icon="info" title="暂无内容"></el-result>
    </div>
  </div>
</template>

<script>
export default {
  name: "demand",
  data() {
    return {
      res: [],//需求结果
      sid: '',
    }
  },
  methods: {
    goDemandDetail(id) { //跳转到需求详情
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      let routeUrl = this.$router.resolve({
        path: '/search/demand/detail',
        query:{
          id
        }
      })
      window.open(routeUrl .href, '_blank');
    },
    collect(item) { //收藏
      this.$axios("POST","/api/member/collect",{
        id: item.id,
        type: 3
      }).then(res => {
        if(res.code===1) {
          this.$message.success('操作成功')
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    getDemand(sid) {//获取需求
      this.$axios("POST","/api/site/siteInfodetail",{
        sid,
        type: 4,
        infoType: 2
      }).then(res => {
        console.log(res);
        this.res = res.data || []
      })
    }
  },
  created() {
    this.sid = this.$route.query.sid
    this.getDemand(this.sid)
  }
}
</script>

<style scoped>
.bg_a{
  background: #F3F3F3;
}
.bg_b{
  background: #FFFFFF;
}
.d_info li {
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.gongneng{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
</style>