<template>
  <div class="padding container website" v-loading.fullscreen.lock="isLoading">
    <div v-if="!isNew">
      <h1 class="fs16 col333 bold tlt mrBtm30">官网发布</h1>
      <!--分类-->
      <div class="dif alc">
        <div class="fs14 col333 dif alc">
          <p style="color: #f00">*</p>
          <p class="mrLft10">分类</p>
          <div style="width: 360px;margin-left: 15px;">
            <el-input value="官网" disabled></el-input>
         
          </div>
        </div>
      </div>
      <!--上传商标选择地-->
      <div class="dif alc" style="margin-top: 50px;">
        <el-upload
          action="https://yzb.yzbse.com/api/common/upload"
          :show-file-list="false"
          :on-success="onsuccess"
          :before-upload="beforeAvatarUpload"
        >
          <div class="posr" v-if="imgUrl === ''">
            <img
              src="@/assets/image/yizhibang/69upload_logo.png"
              style="width: 186px;height: 126px;"
            />
            <p
              class="fs12 posa"
              style="color: #B3B3B3;left: 0;right: 0;top: 82px;"
            >
              请上传商标图片
            </p>
          </div>
          <div v-else>
            <img
              :src="$store.state.url + imgUrl"
              style="width: 186px;height: 126px;"
            />
          </div>
        </el-upload>

        <div class="mrLft30 difcb" style="height: 126px;">
          <!--品牌名称-->
          <div class="dif alc">
            <p class="mrRit10">品牌名称</p>
            <div style="width: 360px;">
              <el-input
                placeholder="请输入品牌名称"
                v-model="brand_title"
              ></el-input>
            </div>
          </div>
          <!--企业名称-->
          <div class="dif alc">
            <p class="mrRit10">企业名称</p>
            <div style="width: 360px;">
              <el-input
                placeholder="请输入企业名称"
                v-model="enterprise"
              ></el-input>
            </div>
          </div>
          <!--所在地区-->
          <div class="dif alc">
            <p class="mrRit10">所在地区</p>
            <div style="width: 360px;">
              <el-cascader
                style="width: 360px;"
                :options="options"
                v-model="selectedOptions"
                @change="handleChange"
              >
              </el-cascader>
            </div>
          </div>
        </div>
      </div>
      <!--选择分类-->
      <div class="dif alc" style="margin-top: 20px;">
        <div class="fs14 col333 dif alc">
          <p>请选择分类</p>
          <div style="width: 360px;margin-left: 15px;">
            <el-cascader 
             v-if="currentClass.length >= 2"
              style="width: 360px;"
              :props="props"
              v-model="currentClass"
              :options="classity"
              @change="changeClass"
            ></el-cascader>
          </div>
        </div>
        <!--申请新分类-->
        <p class="pointer fs14 text-red mrLft30" @click="isNew = true">
          申请新分类
        </p>
      </div>
      <!--选择分类-->
      <!--  <div class="dif alc" style="margin-top: 50px;">
        &lt;!&ndash;选择行业分类&ndash;&gt;
        <div class="dif alc mrRit30">
          <p class="mrRit10">选择行业分类</p>
          <div>
            <el-select v-model="value" placeholder="请选择" class="w100">
              <el-option
                  v-for="item in 3"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </div>
        </div>
        &lt;!&ndash;选择分类菜单&ndash;&gt;
        <div class="dif alc mrRit30">
          <p class="mrRit10">选择分类菜单</p>
          <div>
            <el-select v-model="value" placeholder="请选择" class="w100">
              <el-option
                  v-for="item in 3"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </div>
        </div>
        &lt;!&ndash;选择子菜单&ndash;&gt;
        <div class="dif alc mrRit30">
          <p class="mrRit10">选择子菜单</p>
          <div>
            <el-select v-model="value" placeholder="请选择" class="w100">
              <el-option
                  v-for="item in 3"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </div>
        </div>

      </div>-->
      <!--标题-->
      <div class="dif alc" style="margin-top: 20px;">
        <p class="fs14 col333 mrRit10">官网标题</p>
        <div style="width: 400px;">
          <el-input
            placeholder="广告语或一句话介绍自己"
            v-model="sitename"
          ></el-input>
        </div>
        <!-- <p class="fs14 col333 mrLft30 mrRit20">标题属性</p>
        <div class="dif alc">
          &lt;!&ndash;默认&ndash;&gt;
          <div class="dif alc pointer mrRit20" @click="titleType=1">
            <img src="@/assets/image/yizhibang/51close.png" style="width: 16px;height: 16px;" v-if="titleType===1">
            <img src="@/assets/image/yizhibang/51noclose.png" style="width: 16px;height: 16px;" v-else>
            <p class="mrLft10 fs14 col999">默认</p>
          </div>
          &lt;!&ndash;加粗&ndash;&gt;
          <div class="dif alc pointer mrRit20" @click="titleType=2">
            <img src="@/assets/image/yizhibang/51close.png" style="width: 16px;height: 16px;" v-if="titleType===2">
            <img src="@/assets/image/yizhibang/51noclose.png" style="width: 16px;height: 16px;" v-else>
            <p class="mrLft10 fs14 col999">加粗</p>
          </div>
          &lt;!&ndash;斜体&ndash;&gt;
          <div class="dif alc pointer" @click="titleType=3">
            <img src="@/assets/image/yizhibang/51close.png" style="width: 16px;height: 16px;" v-if="titleType===3">
            <img src="@/assets/image/yizhibang/51noclose.png" style="width: 16px;height: 16px;" v-else>
            <p class="mrLft10 fs14 col999">斜体</p>
          </div>
        </div>-->
      </div>
      <!--官方主营关键词-->
      <div class="" style="margin-top: 50px;">
        <p class="fs14 col333 mrRit10 tlt mrBtm10">官网主营关键词</p>
        <div style="width: 768px;">
          <el-input
            v-model="keyword"
            v-angle="keyword"
            placeholder="请填写公司的官网主营关健词，并用“/”号分开 [用于发布官网的首页搜索,请如实谨慎填写]"
            type="textarea"
            rows="5"
          ></el-input>
        </div>
      </div>
      <!--外部链接-->
      <div style="margin-top: 40px;">
        <p class="col333 fs14 mrRit10 pdTop10 tlt">外部链接：</p>
        <div class="dif alc mrTop10" v-for="(item, index) in urls" :key="index">
          <div style="width: 300px;" class="mrRit20">
            <el-input
              placeholder="请输入外部链接"
              @input="inputUrl(item.url, index)"
              v-model="item.url"
            ></el-input>
          </div>
          <i
            class="el-icon-circle-plus-outline fs28 pointer"
            style="color: #3A51FF"
            v-if="index === 0"
            @click="addUrls()"
          ></i>
          <i
            class="el-icon-remove-outline fs28 pointer"
            style="color: #3A51FF"
            v-else
            @click="delUrls(index)"
          ></i>
          <span class="mrLft20 fs14 col999" v-if="item.repeat <= 0"
            >无重复地址</span
          >
          <span
            class="mrLft20 fs14"
            style="color: #fa3534"
            v-if="item.repeat > 0"
            >地址重复！</span
          >
        </div>
      </div>
      <!--按钮-->
      <div class="difcac" style="margin-top: 50px;">
        <div class="website_button1 difcac pointer fs20">取消</div>
        <div class="website_button2 difcac pointer fs20" @click="submit()">
          提交
        </div>
      </div>
    </div>
    <new-class v-else @changeNew="changeNew"></new-class>
  </div>
</template>

<script>
import NewClass from "@/components/meHeaders/enterpriseCenter/newClass";
import { CodeToText, TextToCode, regionData } from "element-china-area-data";
export default {
  components: { NewClass },
  props: {
    LeftHeaderType: [String, Number],
  },
  data() {
    return {
      urls: [{ url: "", repeat: 0 }], //外部链接
      isLoading: true,
      pageHeight: document.documentElement.clientHeight, //获取页面高度
      isNew: false, //显示申请新分类
      brand_title: "", //品牌名称
      enterprise: "", //企业全称
      sitename: "", //网站名称
      keyword: "", //网站关键词
      address: "", //选择的地址
      options: regionData, //二级联动 省  市
      selectedOptions: [], //选择的内容
      imgUrl: "", //商标图片
      titleType: 1, //标题属性 1默认 2加粗 3斜体
      currentClass: [], //当前选择的官网分类列表
      classity: [], //全部官网分类列表
      classityId1: "", //选择的分类一级
      classityId2: "", //选择的分类二级
      classityId3: "", //选择的分类三级
      props: {
        children: "sonInfo",
        value: "id",
        label: "name",
      },
      timer: null,
    };
  },
  methods: {
    inputUrl(url, index) {
      //检测是否有重复
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        this.urls.forEach((item, index2) => {
          if (item.url === url && index2 !== index && url !== "") {
            item.repeat = 1;
          } else {
            item.repeat = 0;
          }
        });
        const res = await this.$axios("POST", "/api/index/getAddress", {
          url: url,
        });
        if (res.code == 1) {
          this.urls[index].repeat = 0;
        } else {
          this.urls[index].repeat = 1;
        }

        this.$forceUpdate();
      }, 500);
    },
    delUrls(index) {
      //删除链接
      this.urls.splice(index, 1);
    },
    addUrls() {
      //添加链接
      if (this.urls.length >= 5) {
        return this.$alert("最多添加5个外部链接", { type: "warning" });
      }
      this.urls.push({ url: "" });
    },
    changeClass(e) {
      //获取选择的分类id
      // this.$nextTick(() => {
        this.classityId1 = e[0];
        if (e[1]) {
          this.classityId2 = e[1];
        } else {
          this.classityId2 = "";
        }
        // if (e[2]) {
        //   this.classityId3 = e[2];
        // } else {
        //   this.classityId3 = "";
        // }
        this.currentClass = e;
      // });
    },
    getClassity() {
      //获取分类列表
      this.$axios("POST", "/api/classity/readClassity", {}).then((res) => {
        if (res.code === 1) {
          this.classity = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changeNew(bool) {
      this.isNew = bool;
    },
    handleChange() {
      //选择省市区之后
      let province_name = CodeToText[this.selectedOptions[0]]; //省名
      let city_name = CodeToText[this.selectedOptions[1]]; //市名
      let area_name = CodeToText[this.selectedOptions[2]]; //区名
      this.address = province_name + "," + city_name + "," + area_name;
      console.log(this.address);
    },
    onsuccess(res, file) {
      //图片上传成功之后
      console.log(res.data.url);
      if (res.code === 1) {
        this.imgUrl = res.data.url;
        this.imgUrl2 = res.data.url;
      } else {
        this.$message.error(res.msg);
      }
    },
    beforeAvatarUpload(file) {
      //限制图片上传
      const isJPG = file.type === "image/jpeg";
      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      return isJPG;
    },
    submit() {
      //发布官网请求
      let urls = [];
      for (let item of this.urls) {
        if (item.url) {
          if (item.repeat == 0) {
            urls.push(item.url);
          } else {
            return this.$alert("外部链接地址重复，请检查", { type: "error" });
          }
        }
      }
      if (this.sitename === "") {
        this.$alert("请输入网站标题", "提示");
        return;
      } else if (this.brand_title === "") {
        this.$alert("请输入品牌名称", "提示");
        return;
      } else if (this.enterprise === "") {
        this.$alert("请输入企业名称", "提示");
        return;
      } else if (this.imgUrl === "") {
        this.$alert("请上传商标图片", "提示");
        return;
      } else if (this.address === "") {
        this.$alert("请选择所在地区", "提示");
        return;
      } else if (this.classityId === "") {
        this.$alert("请选择所属分类", "提示");
        return;
      } else if (this.keyword === "") {
        this.$alert("请输入关键词", "提示");
        return;
      } else if (urls.length <= 0) {
        this.$alert("请添加外部链接", "提示");
        return;
      }

      this.$axios("POST", "/api/Release/releaseSite", {
        sitename: this.sitename,
        brand_title: this.brand_title,
        enterprise: this.enterprise,
        image: this.imgUrl,
        address: this.address,
        classity: this.classityId1,
        classitys: this.classityId2,
        classityss: this.classityId3,
        keyword: this.keyword,
        title_style: this.titleType,
        url: urls.join(","),
      }).then((res) => {
        if (res.code === 1) {
          this.$alert("提交成功", "提示");
        } else {
          this.$alert(res.msg, "提示");
        }
      });
    },
    getDetail() {
      //获取我的官网发布
      this.$axios("POST", "/api/Release/getReleaseSite", {}).then((res) => {
        this.isLoading = false;
        if (res.code === 1) {
          if (res.data.address) {
            this.address = res.data.address;
            let addressArr = res.data.address.split(",");
            this.selectedOptions = [
              TextToCode[addressArr[0]].code,
              TextToCode[addressArr[0]][addressArr[1]].code,
              TextToCode[addressArr[0]][addressArr[1]][addressArr[2]].code,
            ];
          }
          this.brand_title = res.data.brand_title;
            this.classityId1 = res.data.classity
            this.classityId2 = res.data.classitys==0?'':res.data.classitys
            // this.classityId3 = res.data.classityss==0?'':res.data.classityss
            if (res.data.classity != 0) {
              this.currentClass.push(res.data.classity);
            }
            if (res.data.classitys != 0) {
              this.currentClass.push(res.data.classitys);
            }
            // if (res.data.classityss != 0) {
            //   this.currentClass.push(res.data.classityss);
            // }
             if(this.currentClass.length==0){
              this.classityId1 = 95
              this.classityId2 = 96
              this.currentClass=[95,96]
            }
          this.enterprise = res.data.enterprise;
          this.imgUrl = res.data.image;
          this.keyword = res.data.keyword;
          this.sitename = res.data.sitename;
          this.titleType = res.data.title_style;
          if (res.data.url && res.data.url.length > 0) {
            this.urls = [];
            for (let item of res.data.url)
              this.urls.push({ url: item, repeat: 0 });
          }
        } else {
          if(this.currentClass.length==0){
            this.classityId1 = 95
              this.classityId2 = 96
              this.currentClass=[95,96]
            }
          this.$message.error(res.msg);
        }
      });
    },
  },
  async created() {
    //获取分类列表
    await this.getClassity();
    //获取我的官网发布
    await this.getDetail();
  },
  //强制输入框全角转半角
  directives: {
    angle: {
      inserted: function(el, binding, vnode) {
        el.children[0].value = binding.value;
        let angleValue = el.children[0];
        // console.log(el)
        // console.log(binding)
        // console.log(vnode)
        // // vnode.data.model.callback(angleValue.value)
        // console.log(angleValue.value)
        el.addEventListener("keyup", function(e) {
          e = e || window.event;
          // console.log(e)
          // // console.log(e.key)
          let str = angleValue.value;
          let tmp = "";
          str = str.replace(/：/g, ":");
          str = str.replace(/。/g, ".");
          str = str.replace(/“/g, '"');
          str = str.replace(/”/g, '"');
          str = str.replace(/【/g, "[");
          str = str.replace(/】/g, "]");
          str = str.replace(/《/g, "<");
          str = str.replace(/》/g, ">");
          str = str.replace(/，/g, ",");
          str = str.replace(/？/g, "?");
          str = str.replace(/、/g, ",");
          str = str.replace(/；/g, ";");
          str = str.replace(/（/g, "(");
          str = str.replace(/）/g, ")");
          str = str.replace(/‘/g, "'");
          str = str.replace(/’/g, "'");
          str = str.replace(/『/g, "[");
          str = str.replace(/』/g, "]");
          str = str.replace(/「/g, "[");
          str = str.replace(/」/g, "]");
          str = str.replace(/﹃/g, "[");
          str = str.replace(/﹄/g, "]");
          str = str.replace(/〔/g, "{");
          str = str.replace(/〕/g, "}");
          str = str.replace(/—/g, "-");
          str = str.replace(/·/g, ".");
          /* 正则转换全角为半角 */
          for (var i = 0; i < str.length; i++) {
            if (str.charCodeAt(i) === 12288) {
              tmp += String.fromCharCode(str.charCodeAt(i) - 12256);
              continue;
            }
            if (str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375) {
              tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
            } else {
              tmp += String.fromCharCode(str.charCodeAt(i));
            }
          }
          // console.log(angleValue.value)
          // console.log(binding)
          // console.log(vnode)
          vnode.data.model.callback(tmp);
        });
      },
    },
  },
};
</script>

<style scoped>
.website ::v-deep .el-input__inner {
  height: 40px;
  border-radius: 10px;
}
.website_button1 {
  width: 226px;
  height: 50px;
  background: #cccccc;
  border-radius: 6px;
  color: #ffffff;
}
.website_button2 {
  margin-left: 40px;
  width: 226px;
  height: 50px;
  background: #3a54ff;
  border-radius: 6px;
  color: #ffffff;
}
</style>
