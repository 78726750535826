<template>
  <!--获取用户登录记录-->
    <div class="w90 mrAt pdTop10 box" style="padding: 0 34px;">
        <h3 class="fs16 col333 tlt mrBtm10">查看一年内登录记录</h3>
        <div class="header_top w100 fs14 col333 bold rows alc">
            <div class="col-2 tct">用户名</div>
            <div class="col-6 tct one_overflow">浏览器版本</div>
            <div class="col-2 tct">时间</div>
            <div class="col-2 tct">IP</div>
        </div>
        <div :style="{'height':pageHeight-160+'px'}" class="w100 bgff container fs12 col333">
            <div v-for="(item,index) in res" :key="index" class="w100 jilu_item" :class="index%2===0?'':'bgf3'">
                <div class="col-2 tct one_overflow">{{item.username}}</div>
                <div class="col-6 one_overflow pdLft20">{{item.versions}}</div>
                <div class="col-2 tct">{{item.logintime}}</div>
                <div class="col-2 tct">{{item.ip}}</div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
          pageHeight:document.documentElement.clientHeight,//获取页面高度
          res: []
      }
    },
    created(event) {
        this.$axios("POST","/api/member/getUserLoginLog",{})
          .then(res => {
            this.res = res.data
          })
    },
    methods:{
        pageDetail(eventa,eventb) {
            if(eventa == '1') {//
                
            }
        }
    }
  }
</script>


<style scoped>
    .container {
        overflow: hidden;
		    overflow-y: auto;
    }
    .header_top {
      height: 36px;
      background: #F3F3F3;
    }
    .bgf3{
      background: #F3F3F3;
    }
    .jilu_item{
      height: 36px;
      display: flex;
      align-items: center;
    }
</style>