<template>
  <!--访问数据-->
  <div class="padding container">
    <h3 class="tlt">访问数据</h3>
    <!--分类-->
   <!-- <ul class="col7b dif mrTop20 pdBtm20" style="border-bottom: 1px dashed #666666;">
      <li class="mrRit20" v-for="(item,index) in classData"><h3>{{item.name}}</h3></li>
    </ul>-->
    <!--访问列表-->
    <ul class="posr mrTop30 pdBtm20">
      <li class="mrBtm20" v-for="(item,index) in list">
        <!--<h4 class="tlt">2020年1月22日</h4>-->
        <div class="dif alc">
          <div style="width: 50px;height: 50px;">
            <img :src="item.avatar" class="w100 h100 bra" style="background-color: #eee">
          </div>
          <p class="col7b fs16 mrLft20 mrRit20">{{item.username || '暂无用户名'}}</p>
          <!--<p class="fs28">V1</p>-->
          <div class="dif alc  col7b">
            <div class="user_vip mrRit10  " v-if="item.is_pay==1 ">
              <img src="@/assets/image/yizhibang/45vip_bg.png" style="width: 33px;height: 15px;">
              <span class="fs12 colfff posr" style="font-style: italic;">VIP</span>
            </div>
            <!--企业会员-->
            <div class="difa alc mrRit20 user_vip" style="width: 40px;" v-if="item.vip_type==1">
              <img src="@/assets/image/yizhibang/45card_bg.png" style="width: 100%;height: 15px;">
              <img src="@/assets/image/yizhibang/45authentication.png" style="width: 15px;height: 15px;margin-left: 3px;">
              <div style="color: #FFFFFF;margin-left: 12px;position:relative;font-size: 12px;">企</div>
            </div>
            <p class="mrRit20" v-if="item.duty">{{item.duty}}</p>
            <p class="mrRit20" v-if="item.company">{{item.company}}</p>
            <p class="mrRit20" v-if="item.add_time">{{item.add_time}}</p>
          </div>
        </div>
      </li>
      <!--<div class="title" style="color:#034F89;">
        <div class="fs16">查看全部访问</div>
      </div>-->
    </ul>
    <!--访问次数-->
    <div class="mrTop30 posr" style="border-bottom: 1px dashed #666666;">
      <div class="dif bold col333">
        <p class="mrRit20">今日访问次数：{{charts2.today}}次</p>
        <p>总访问次数：{{charts2.count}}次</p>
      </div>
      <div
          id="count"
          style="width: 100%;height: 400px;"
      ></div>
    </div>
    <!--访问人数-->
    <div class="mrTop30 posr" style="border-bottom: 1px dashed #666666;">
      <div class="dif bold col333">
        <p class="mrRit20">今日访问人数：{{charts1.today}}人</p>
        <p>总访问人数：{{charts1.count}}人</p>
      </div>
      <div
          id="user"
          style="width: 100%;height: 400px;"
      ></div>
    </div>

    <!--比例-->
     <div class="mrTop30 dif alc" style="border-bottom: 1px dashed #666666;">
      <div style="width: 50%;">
        <h3 class="tlt">性别比例</h3>
        <div id="gender" style="width: 100%;height: 200px;"></div>
      </div>
       <div style="width: 50%;">
         <h3 class="tlt">地域分布</h3>
         <div id="address" style="width: 100%;height: 200px;"></div>
       </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "accessData",
  data() {
    return {
      pageHeight:document.documentElement.clientHeight,//获取页面高度
      classData: [
        {id:0,name:'访问用户'},
        {id:1,name:'访问次数'},
        {id:2,name:'访问人数'},
        {id:3,name:'性别分布'},
        {id:4,name:'地域分布'},
      ],
      list: [],//访问人数
      charts1: {
        name: "访问人数",
        count: 0,
        today: 0,
        xData: [], //x坐标轴
        data: [] //数据
      },//访问人数
      charts2: {
        name: "访问次数",
        count: 0,
        today: 0,
        xData: [], //x坐标轴
        data: [] //数据
      },//访问次数
    }
  },
  methods: {
    getData() { //获取访问人数
      this.$axios("POST","/api/Release/getStatistics",{}).then(res => {
        if(res.code===1) {
          this.list = res.data
        }
      })
    },
    initEchartsLine(data,id) { //初始化折线图
      const option = {
        tooltip: {
          show: true,
          trigger: 'axis'
        },
        grid: {
          left: '5%',
          right: '5%',
        },
        xAxis: {
          type: 'category',
          data: data.xData,
        },
        yAxis: {type: 'value'},
        series: [
          {
            name: data.name,
            type: "line",
            data: data.data,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0, color: 'rgba(0,186,255,0.3)' // 0% 处的颜色
                }, {
                  offset: 1, color: 'rgba(0,132,255,0.1)' // 100% 处的颜色
                }],
              },
            }
          }
        ],
        color: '#0084FF',
      };
      const myChart = echarts.init(document.getElementById(id));// 图标初始化
      myChart.setOption(option);// 渲染页面
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    initEchartsPie(data,id,color) { //初始化饼状图
      const option = {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            data: data,
            label: {
              show: true,
              formatter: '{b}: {d}%'
            },
          },
        ]
      };
      if(color) option.color = color
      const myChart = echarts.init(document.getElementById(id));// 图标初始化
      myChart.setOption(option);// 渲染页面
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
    getUserCount() { //获取访问人数
      this.$axios("POST","/api/Index/getUserCount",{})
      .then(res => {
        if(res.code===1) {
          this.charts1.count = res.data.website
          this.charts1.today = res.data.website_count
          for(let key in res.data.data) {
            this.charts1.xData.push(key)
            this.charts1.data.push(res.data.data[key])
          }
          this.initEchartsLine(this.charts1,'user')
        }
      })
    },
    getCount() { //获取访问次数
      this.$axios("POST","/api/Index/getCount",{})
          .then(res => {
            if(res.code===1) {
              this.charts2.count = res.data.website
              this.charts2.today = res.data.website_count
              for(let key in res.data.data) {
                this.charts2.xData.push(key)
                this.charts2.data.push(res.data.data[key])
              }
              this.initEchartsLine(this.charts2,'count')
            }
          })
    },
    getGender() { //获取男女占比
      this.$axios("POST","/api//Index/getUserCounts",{})
      .then(res => {
        if(res.code===1) {
          let data = [
            {
              value: res.data.nan,name: '男生占比',
              tooltip: {formatter: '男生人数：{c}人'}
            },
            {
              value: res.data.nv,name: '女生占比',
              tooltip: {formatter: '女生人数：{c}人'}
            },
          ]
          let color = ['#4089FF','#FECD5D']
          this.initEchartsPie(data,'gender',color)
        }
      })
    },
    getAddress() { //获取地域分布
      this.$axios("POST","/api//Index/getUserAddress",{})
          .then(res => {
            if(res.code===1) {
              let data = res.data.data.map(item => {
                return {
                  value: item.num,
                  name: item.province_name+'占比',
                  tooltip: {formatter: item.province_name+'：{c}人'}
                }
              })
              this.initEchartsPie(data,'address')
            }
          })
    },
  },
  created() {
    this.getData()
  },
  mounted() {
    this.getUserCount()
    this.getCount()
    this.getGender()
    this.getAddress()
  }
}
</script>

<style scoped>
  .user_vip{
    width: 33px;
    height: 15px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7px;
  }
  .user_vip img{
    position: absolute;
    left: 0;
    top: 0;
  }
  .container {
    overflow: hidden;
    overflow-y: auto;
  }
  .padding {
    padding: 30px;
  }

  .title{
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
  }
  .title div{
    position: absolute;
    color: #034F89;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }
  .title div::before{
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -28px;
    display: block;
    width: 0;
    border-top: 12px solid #034F89;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
  }
</style>