<template>
  <!--生成的官网首页-->
  <div class="mrTop30">
    <!--公司简介-->
    <div class="tlt mr0At posr" style="width: 1200px;">
      <h3  class="fs18 col333 title">公司简介</h3>
      <!--详细信息-->
      <div class="dif mrTop20">
        <!--左侧-->
        <div class="detail_info w100" style="flex: 1">
          <ul class="difb difw w100">
            <li class="difc alc w100" v-for="index in 12">
              <p class="fs14 col333 bold">中文名称</p>
              <div class="one_overflow fs14 col333">吧啦吧啦吧啦吧</div>
            </li>
          </ul>
        </div>
        <!--右侧-->
        <div class="w100 right pdLft20" style="flex: 1">
          <div class="mrBtm20">
            <h3 class="fs16 col333">历史沿革</h3>
            <p class="text">中国工会在中国工人运动发展的基础上诞生.1921年8月中国共产党成立后不久，就成立了中国劳动组合书记部,作为全国工会的通信联络机关，张
              国焘、邓中夏先后任主任。1922年5月 中国劳动组合书记部在广州召开了第一次全国劳动大会， 确定筹备全国性工会组织。1925年5月1日在广州召开了第
              二次全国劳动大会.正式成立了中华全国总工会。抗日战争时期暫停使用中华全国总工会的名称，成立陕甘宁边区总工会和各抗日根据地总工会。解放战
              争时期边区总工会和各根据地总工会联合为解放区总工会。1948年8月1日由解放区总工会和国民党统治区工会在哈尔滨联合召开了第六次全国劳动大会,
              决定恢复中华全国总工会。</p>
          </div>
          <div class="mrBtm20">
            <h3 class="fs16 col333">历史沿革</h3>
            <p class="text">中国工会在中国工人运动发展的基础上诞生.1921年8月中国共产党成立后不久，就成立了中国劳动组合书记部,作为全国工会的通信联络机关，张
              国焘、邓中夏先后任主任。1922年5月 中国劳动组合书记部在广州召开了第一次全国劳动大会， 确定筹备全国性工会组织。1925年5月1日在广州召开了第
              二次全国劳动大会.正式成立了中华全国总工会。抗日战争时期暫停使用中华全国总工会的名称，成立陕甘宁边区总工会和各抗日根据地总工会。解放战
              争时期边区总工会和各根据地总工会联合为解放区总工会。1948年8月1日由解放区总工会和国民党统治区工会在哈尔滨联合召开了第六次全国劳动大会,
              决定恢复中华全国总工会。</p>
          </div>
        </div>
      </div>
    </div>
    <!--实时资讯-->
    <div class="tlt mr0At posr mrTop30" style="width: 1200px;">
      <h3  class="title fs18 col333">实时资讯</h3>
      <div class="pdLft30 mrTop30">
        <ul class="difb w100 difw">
            <!--左侧新闻列表-->
            <li v-for="(item,index) in 2" :key="index" style="margin-bottom: 37px;">
              <!--标题-->
              <div class="fs20 col333 tlt" style="margin-bottom: 14px;">微博的绿洲，真的比撒哈拉还荒凉</div>
              <!--标题-->
              <div class="dif alc news_img tlt" style="margin-bottom: 13px;">
                <img class="bgcb">
                <img class="bgcb">
                <img class="bgcb">
              </div>
              <!--来源 时间-->
              <div class="dif alc">
                <div style="font-size: 12px;color:#999;">人民网</div>
                <div style="font-size: 12px;color:#999;">06-01 11:03</div>
              </div>
            </li>
        </ul>
      </div>
      <!--查看更多-->
      <router-link class="fs12 col999 more1" to="/">更多资讯</router-link>
    </div>
    <!--品牌加盟-->
    <div class="tlt mr0At posr mrTop30" style="width: 1200px;">
      <h3  class="title fs18 col333">品牌加盟</h3>
      <div class="j_top dif mrTop30">
        <!--轮播图-->
        <div class="swiper_warp">
          <el-carousel height="320px">
            <el-carousel-item v-for="item in 4" :key="item">
              <img src="" alt="" class="w100 h100">
            </el-carousel-item>
          </el-carousel>


        </div>
        <!--详细信息-->
        <div class="j_info">
          <ul class="col7b fs16 col999 tlt">
            <li>投资金额<span class="money">20-50万</span></li>
            <li>经营模式<span class="fs18 col333">蚌埠住了</span></li>
            <li>加盟区域<span class="fs18 col333">本想</span></li>
            <li>门店总数<span class="fs18 col333">12家</span></li>
            <li>品牌成立<span class="fs18 col333">1910-11-11</span></li>
            <li>适合人群<span class="fs18 col333">老鼠人</span></li>
            <li>所属行业<span class="fs18 col333">4000+</span></li>
          </ul>
        </div>
      </div>
      <!--查看更多-->
      <router-link class="fs12 col999 more1" to="/">详细介绍</router-link>
    </div>
    <!--产品店铺-->
    <div class="tlt mr0At posr mrTop30" style="width: 1200px;">
      <h3  class="title fs18 col333">产品店铺</h3>
      <!--店铺列表-->
      <ul class="shop_res dif difw">
        <li v-for="(item,index) in 4">
          <div class="good_img">
            <!--图片轮播-->
            <div class="posr w100 h100">
              <img  style="width: 248px;height: 248px;">
              <div class="mb1"></div>
              <div class="mb2"></div>
            </div>
            <!--序号-->
            <div class="noindex">NO.{{index+1}}</div>
            <!--品牌-->
            <div class="pinpai">品牌：买购网</div>
            <!--公司-->
            <p class="compoany">公司：深圳市</p>
          </div>
          <div class="good_bottom">
            <!--店铺类型-->
            <div class="difb alc">
              <div class="dif alc">
                <img src=""  class="bgccc" style="width: 52px;height: 24px;">
                <p class="bold fs12" style="color:#000033;margin-left: 3px;">直营店</p>
              </div>
              <div class="difb alc box">
                <router-link to="/search/shopdetail" target="_blank" class="col3765FF fs12">进入店铺</router-link>
              </div>
            </div>
            <!--操作-->
            <div class="difb alc" style="margin-top: 15px;">
              <div class="dif alc fs12 colF39800">
                <p>分享</p>
                <p style="margin-left: 13px;">收藏</p>
              </div>
              <p class="fs12" style="color:#1C1C1C;">淘宝官方淘宝旗舰店</p>
            </div>
          </div>
        </li>
      </ul>
      <!--查看更多-->
      <router-link class="more1 fs12 col999" to="/">查看更多</router-link>
    </div>
    <!--内部需求-->
    <div class="tlt mr0At posr " style="width: 1200px;margin-top: 60px;">
      <h3  class="fs18 col333 title">内部需求</h3>
      <!--需求内容-->
      <div class="mrTop30 demand_titl bg_a" style="padding: 12px 0;">
        <ul class="fs14 bold col333 dif">
          <li style="padding: 0 20px;">需求名称</li>
          <li style="padding: 0 50px;">需求类型</li>
          <li style="padding: 0 20px;">地点</li>
          <li style="padding: 0 50px;">工作单位</li>
          <li style="padding: 0 70px;" class="tlt">需求标题</li>
          <li style="padding: 0 90px;">起止时间</li>
          <li style="margin-right: 70px;">剩余天数</li>
          <li style="margin-right: 80px;width: 48px;" class="tct">状态</li>
          <li style="width: 58px;" class="tct">操作</li>
        </ul>
      </div>
      <!--详细信息-->
      <div>
        <ul class="fs12 col333 dif d_info posr"
            style="padding: 12px 0;"
            :class="index%2===0?'bg_b':'bg_a'"
            v-for="(item,index) in 2" :key="index">
          <li style="width: 96px;" class="one_overflow tct">招投标</li>
          <li style="width: 156px;" class="one_overflow tct">直招</li>
          <li style="width: 68px;" class="one_overflow tct">北京</li>
          <li style="width: 128px;" class="one_overflow tct">买购网</li>
          <li style="width: 196px;" class="tct one_overflow">老鼠人4000+</li>
          <li style="width: 236px;" class="one_overflow tct">
            <span style="line-height: normal;">2021-01-02</span>至
            <span style="line-height: normal;">2021-01-03</span>
          </li>
          <li style="margin-right: 70px;width: 56px;" class="one_overflow tct">20天</li>
          <li style="margin-right: 80px;width: 48px;" class="one_overflow tct">
            <!--<span v-if="item.status===1">进行中</span>
            <span v-if="item.status===2">已过期</span>-->
            <span>进行中</span>
          </li>
          <li class="dif alc col3A53FF">
            <p class="pointer">分享</p>
            <p class="mrLft10 pointer">收藏</p>
          </li>
        </ul>
      </div>
      <router-link class="more1 fs12 col999" to="/">查看更多</router-link>
    </div>
    <!--公司招聘-->
    <div class="tlt mr0At posr mrTop30" style="width: 1200px;">
      <h3 class="title fs18 col333 mrBtm30">公司招聘</h3>
      <!--招聘内容-->
      <div class="z_main">
        <ul>
          <li class="zhaopin_item posr" v-for="(item,index) in res" :key="index">
            <div class="dif alc">
              <p class="fs18 bold col3765FF one_overflow tlt" style="margin-right: 16px;width: 320px;">{{item.position}}【{{item.city_name}}/{{item.area_name}}】</p>
              <p class="fs16 col333 tlt one_overflow" style="width: 250px;">{{item.company}}</p>
              <div class="dif alc mrLft20">
                <p>{{item.createtime}}</p>
                <div class="zhaopin_type">{{item.type}}</div>
              </div>
            </div>
            <div class="dif alc mrTop20">
              <p class="fs18" style="color:#ff0000;">{{item.money_title}}</p>
              <p class="fs16 col999 mrLft30 mrRit30">{{item.experience_title}}</p>
              <p class="fs16 col999">{{item.education_title}}</p>
            </div>
            <!--职工福利-->
            <div class="dif alc mrTop20">
              <p class="fs14 col333" style="margin-right: 13px;">职位福利</p>
              <ul class="dif alc fuli">
                <li v-for="(item2,index2) in item.welfare">{{item2}}</li>
              </ul>
            </div>
            <div class="caozuo dif alc">
              <p class="pointer fs14 colFDAE38 mrRit20">收藏</p>
              <p class="pointer fs14 colFDAE38">分享</p>
            </div>
          </li>
          <!--<li class="dif alc mrTop30 mrBtm30 posr" v-for="(item,index) in res">
            <div class="col004F8A fs16 difcb bold">
              <p>{{item.position}}</p>
              <p>{{item.money_title}}</p>
            </div>
            <div class="mrLft30">
              &lt;!&ndash;招聘条件&ndash;&gt;
              <ul class="difb fs14 col7b bold alc mrBtm10 tiaojian" style="width: 710px;">
                <li>招{{item.num}}人</li>
                <li>{{item.education_title}}</li>
                <li>{{item.experience_title}}</li>
                <li>{{item.city_name}}/{{item.area_name}}</li>
                <li class="one_overflow">{{item.company}}</li>
                <li>{{item.createtime}}</li>
                <li class="z_type">{{item.type}}</li>
              </ul>
              &lt;!&ndash;职工福利&ndash;&gt;
              <ul class="welfare dif alc fs12">
                <p class="mrRit20">职工福利</p>
                <li v-for="(item,index) in item.welfare" :key="index">{{item}}</li>
              </ul>
            </div>
            <div class="caozuo col004F8A dif ">
              <p>收藏</p>
              <p class="mrLft20">分享</p>
            </div>
          </li>-->
        </ul>
      </div>
      <!--查看更多-->
      <router-link class="more1 fs12 col999" to="/">查看更多</router-link>
    </div>
    <!--软件下载-->
    <div class="tlt mr0At posr mrTop30 mrBtm30" style="width: 1200px;">
      <h3 class="title col333 fs18">公司招聘</h3>
      <!--下载内容-->
      <div class="mrTop30">
        <ul class="difb difw">
          <li v-for="index in 7">
            <div style="width: 100px;height: 100px;">
              <img src="@/assets/image/app.png" class="w100 h100">
            </div>
            <p class="tct mrTop20 fs14 col333">钉钉软件</p>
          </li>
        </ul>
      </div>
      <!--查看更多-->
      <router-link class="more1 fs12 col999" to="/">查看更多</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    sid: [String,Number]
  },
  data() {
    return {
      res: [],//招聘结果
    }
  },
  methods: {
    getZhaopin(sid) {
      this.$axios("POST","/api/site/siteInfodetail",{
        sid,
        type: 4,
        infoType: 5
      }).then(res => {
        console.log(res);
        this.res = res.data
      })
    }
  },
  created() {
    this.getZhaopin(1)
  }
}
</script>

<style scoped>
  /*标题*/
  .title{
    position: relative;
    padding-left: 20px;
  }
  .title::before{
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #3765FF;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  /*查看更多*/
  .more1{
    position: absolute;
    top: 0;
    right: 0;
  }

  /*公司简介*/
  /*左侧信息*/
  .detail_info li{
    margin-bottom: 16px;
  }
  .detail_info li div{
    text-align: left;
    padding: 2px 20px;
    width: 346px;
    height: 26px;
    background: #F3F3F3;
    border-radius: 2px;
    margin-left: 10px;
  }
  /*右侧文本*/
    .right{
      border-left: 1px solid #7b7b7b;
    }
    .text{
      text-indent: 2em;
      box-sizing: border-box;
      text-align: left;
      font-size: 14px;
      color: #333333;
      line-height: 36px;
    }

  /*实时资讯*/
  .news {
    width: 1000px;
    border-top: 1px solid #F4F4F4;
    margin: 0 auto;
    padding-top: 50px;
    display: flex;
    justify-content: center;
  }
  .news_img img{
    width: 184px;
    height: 122px;
    background: #cbcbcb;
    margin-right: 4px;
  }
  .news_img img:first-child{
    border-radius: 10px 0 0 10px;
  }
  .news_img img:last-child{
    border-radius: 0 10px 10px 0;
    margin-right: 0;
  }

  /*加盟列表*/
  .swiper_warp{
    width: 520px;
    height: 320px;
    background: #666666;
    overflow: hidden;
    position: relative;
  }
  .j_top{
    padding-bottom: 20px;
  }
  .j_info ul{
    margin-left: 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 320px;
  }
  .j_info li span{
    margin-left: 20px;
  }
  .j_info .money{
    font-weight: bold;
    color: #F6AA00;
    font-size: 26px;
    font-style: italic;

  }

  /*商铺列表*/
  .shop_class{
    width: 100%;
    box-sizing: border-box;
  }
  .f2{flex:2}

  .title_bold{
    width: 256px;
    height: 10px;
    background: #F6F6F6;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  .shop_res{
    margin-top: 30px;
    margin-left: 30px;
  }
  .shop_res li {
    margin-right: 50px;
  }
  .shop_res li:nth-child(4n){
    margin-right: 0;
  }
  .good_bottom{
    padding: 10px 10px;
    width: 248px;
    height: 70px;
    background: #F7F6FA;
    box-sizing: border-box;

  }
  .good_img{
    background: #3765FF;
    width: 248px;
    height: 248px;
    position: relative;
  }
  .mb1{
    width: 100%;
    height: 129px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
  }
  .mb2{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 130px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.5;
  }
  .noindex {
    font-weight: bold;
    font-size: 16px;
    color: #F39800;
    position: absolute;
    top: 23px;
    left: 12px;
    z-index: 90;
  }
  .pinpai{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    width: 100px;
    font-size: 12px;
    height: 24px;
    background: #F39800;
    border-radius: 11px;
    position: absolute;
    top: 20px;
    right: 10px;
    z-index:2;
  }
  .compoany{
    font-size: 12px;
    color: #FFFFFF;
    position: absolute;
    bottom: 12px;
    left: 10px;
    z-index: 3;
  }

  .no span{
    font-weight: normal;
    font-size: 10px;
  }


  /*内部需求*/
  .bg_a{
    background: #F3F3F3;
  }
  .bg_b{
    background: #FFFFFF;
  }
  .d_info li {
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }

  /*公司招聘*/
  .zhaopin{
    padding-bottom: 200px;
  }
  .zhaopin_item{
    width: 1200px;
    background: #FAFAFA;
    border: 1px solid #F5F5F5;
    box-sizing: border-box;
    margin-bottom: 20px;
    padding: 22px 32px;
  }
  .zhaopin_type{
    width: 42px;
    height: 20px;
    background: #3765FF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #FFFFFF;
    margin-left: 8px;
  }
  .fuli li{
    font-size: 14px;
    color: #999999;
    border: 1px solid #EEEEEE;
    padding: 8px 11px;
    margin-right: 10px;
  }
  .caozuo{
    position: absolute;
    bottom: 16px;
    right: 22px;
  }
</style>