<template>
  <div v-loading="isLoading">
    <!--不是会员-->
    <div class="w90 mrAt" style="margin-bottom: 40px;" v-if="userInfo.vip_type==0">
      <!--第一步-->
      <div v-if="pageType==='1'">
        <h1 class="tc bold fs38 mrTop30" style="margin-bottom: 31px;"> 仅限试运营期间 超级特惠！感恩送永久优惠！</h1>
        <div class="difcac">
          <div class="warp1">
            <!--<h1 class="tc fs30" style="padding-bottom: 50px;border-bottom: 1px solid rgba(0,0,0,0);">免费获得会员</h1>-->
            <h1 class="tc fs30" style="padding-bottom: 50px;border-bottom: 1px solid rgba(0,0,0,0);">{{list.enter_price1}}永久会员</h1>
            <div class="col333 tlt box " style="height: 225px;border-bottom: 1px solid #333333;">
              <!--<div class="button difcac mr0At" @click="pageType='2'">分享并开通</div>-->
              <!--<div class="button difcac mr0At" @click="pageType='3';current=5;getPayInfo(0)">分享并开通</div>-->
              <div class="button difcac mr0At" @click="pageType='3';current=5;getPayInfo(0)">支付并开通</div>
             <!-- <div class="difcb mr0At" style="height: 135px;margin-top: 24px;">
                <p style="font-size: 15px;">仅需三步即可获得一周会员！</p>
                <p style="font-size: 15px;">1.添加服务号、企业微信。</p>
                <p style="font-size: 15px;">2.获取文字+链接+图片</p>
                <p style="font-size: 15px;">分享至朋友圈。</p>
                <p style="font-size: 15px;">3.至24小时后截图回传审核。</p>
              </div>-->
              <div v-html="vipText[3].brief_content"></div>
            </div>
            <div style="margin-top: 43px;" class="fs16 tlt col333">
              <!--<div class="difb alc " style="margin-bottom: 15px;">
                <p>企业尊贵会员标识！</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>正式运营后永久感恩优惠！</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>发布已有官网！</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>企业/组织信息宣传功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>咨询宣传功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>需求发布功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>网店宣传功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>加盟宣传功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>招聘信息发布平台</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>软件app发布功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>访问动态数据展示</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>-->
              <div v-html="vipText[3].content"></div>
            </div>
          </div>
          <div class="warp1">
            <h1 class="tc fs30" style="padding-bottom: 50px;border-bottom: 1px solid rgba(0,0,0,0);">{{list.one_price}}获得会员</h1>
            <div class="col333 tlt box " style="height: 225px;border-bottom: 1px solid #333333;">
              <div class="button difcac mr0At" @click="pageType='3';current=2;getPayInfo(0)">支付并开通</div>
              <!--<div class="difcc tlt mr0At" style="height: 135px;margin-top: 24px;">
                <p style="font-size: 15px;width: 60%;">每天约0.8元</p>
                <p style="font-size: 15px;margin-top: 20px;width: 60%;">即可获得1年会员！</p>
              </div>-->
              <div v-html="vipText[4].brief_content"></div>
            </div>
            <div style="margin-top: 43px;" class="fs16 tlt col333">
              <!--<div class="difb alc " style="margin-bottom: 15px;">
                <p>企业尊贵会员标识！</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>正式运营后永久感恩优惠！</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>发布已有官网！</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>企业/组织信息宣传功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>咨询宣传功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>需求发布功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>网店宣传功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>加盟宣传功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>招聘信息发布平台</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>软件app发布功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>访问动态数据展示</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>-->
              <div v-html="vipText[4].content"></div>
            </div>
          </div>
          <div class="warp1">
            <h1 class="tc fs30" style="padding-bottom: 50px;border-bottom: 1px solid rgba(0,0,0,0);">{{list.three_price}}获得会员</h1>
            <div class="col333 tlt box " style="height: 225px;border-bottom: 1px solid #333333;">
              <div class="button difcac mr0At" @click="pageType='3';current=3;getPayInfo(0)">支付并开通</div>
              <!--<div class="difcc tlt mr0At" style="height: 135px;margin-top: 24px;">
                <p style="font-size: 15px;width: 60%;">每年约233元</p>
                <p style="font-size: 15px;margin-top: 20px;width: 60%;">即可获得3年会员！</p>
              </div>-->
              <div v-html="vipText[5].brief_content"></div>
            </div>
            <div style="margin-top: 43px;" class="fs16 tlt col333">
              <!--<div class="difb alc " style="margin-bottom: 15px;">
                <p>企业尊贵会员标识！</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>正式运营后永久感恩优惠！</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>发布已有官网！</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>企业/组织信息宣传功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>咨询宣传功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>需求发布功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>网店宣传功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>加盟宣传功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>招聘信息发布平台</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>软件app发布功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>访问动态数据展示</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>-->
              <div v-html="vipText[5].content"></div>
            </div>
          </div>
          <div class="warp1">
            <h1 class="tc fs30" style="padding-bottom: 50px;border-bottom: 1px solid rgba(0,0,0,0);">{{list.permanent_price}}获得会员</h1>
            <div class="col333 tlt box " style="height: 225px;border-bottom: 1px solid #333333;">
              <div class="button difcac mr0At" @click="pageType='3';current=4;getPayInfo(0)">支付并开通</div>
             <!-- <div class="difcac tct mr0At" style="height: 135px;margin-top: 24px;">
                <p style="font-size: 15px;">即将获得永久会员！</p>
              </div>-->
              <div v-html="vipText[6].brief_content"></div>
            </div>
            <div style="margin-top: 43px;" class="fs16 tlt col333">
              <!--<div class="difb alc " style="margin-bottom: 15px;">
                <p>企业尊贵会员标识！</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>正式运营后永久感恩优惠！</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>发布已有官网！</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>企业/组织信息宣传功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>咨询宣传功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>需求发布功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>网店宣传功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>加盟宣传功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>招聘信息发布平台</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>软件app发布功能</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>
              <div class="difb alc " style="margin-bottom: 15px;">
                <p>访问动态数据展示</p>
                <i class="el-icon-check fs16 col333"></i>
              </div>-->
              <div v-html="vipText[6].content"></div>
            </div>
          </div>
        </div>
      </div>
      <!--第二部-->
      <div v-if="pageType==='2'">
        <div class="pointer col666 mrTop30 tlt" @click="pageType='1'">
          <i class="el-icon-arrow-left"></i>
          <span>返回</span>
        </div>
        <div class="difcc" style="margin-top: 40px;">
          <img :src="codeImg" style="width: 258px;height: 258px;">
          <div class="share_button difcac pointer" style="margin-top: 64px;">微信关注服务号获取分享</div>
        </div>
      </div>
      <!--第三步-->
      <div v-if="pageType==='3'">
        <div class="pointer col666 mrTop30 tlt" @click="pageType='1'">
          <i class="el-icon-arrow-left"></i>
          <span>返回</span>
        </div>
        <div class="difcac  alc" style="margin-top: 120px;" >
          <div class="difcc pointer" @click="showWeChatCode=true">
            <div class="pay_icon">
              <img :src="imgUrl" class="w100 h100 bgccc">
            </div>
            <div class="pay_title pointer" style="background: #1EC624;" @click="getPayInfo(0)">微信支付</div>
            <p class="fs14 col000 mrTop10">使用微信扫码支付</p>
          </div>
          <div class="difcc pointer" style="margin-left: 100px;" @click="getPayInfo(1)">
            <div class="pay_icon pay_ali_icon difcc" >
              <img src="@/assets/image/yizhibang/zfb.png" style="width: 60px;height: 60px;" alt="">
              <p class="bold fs24 col000 tct">支付宝</p>
              <p class=" fs14 col000 tct" style="letter-spacing: 6px">ALIPAY</p>
            </div>
            <div class="pay_title pointer" style="background: #1CABEC" >支付宝支付</div>
            <p class="fs14 col000 mrTop10">跳转支付宝网页版支付</p>
          </div>
        </div>
        <div class="difcac alc" style="margin-top: 60px;">
          <!--<div class="zidongxufei difcac" @click="autoXufei=!autoXufei">
            <img src="@/assets/image/yizhibang/63yes.png" alt="" v-if="autoXufei">
          </div>-->
          <p class="fs24 col000 bold mrLft10" v-if="current==2">需支付{{list.one_price}}元</p>
          <p class="fs24 col000 bold mrLft10" v-if="current==3">需支付{{list.three_price}}元</p>
          <p class="fs24 col000 bold mrLft10" v-if="current==4">需支付{{list.permanent_price}}元</p>
        </div>
        <div class="difcc fs16 col999 mrTop10">
          <p class="">本次活动仅在试运营期间有效，成功充值开通后非亿指榜原因概不退款。</p>
          <p class="">本次活动在法律允许范围内，最终解释权归亿指榜所有。</p>
          <p>对本次活动有疑问的，可以咨询亿指榜客服。</p>
        </div>
      </div>
    </div>
    <!--是会员-->
    <div v-else class="difcac" style="height: 700px;">
      <el-result icon="success" title="已开通会员" :subTitle="'到期时间：'+userInfo.vip_time"></el-result>
    </div>
    <!--支付宝支付-->
    <el-dialog :visible.sync="showAlipay" :modal="true" width="350px" :center="true" top="40vh" @close="close2()">
      <div class="difcc">
        <h2 class="tct mrBtm20">支付宝支付</h2>
        <div class="mrTop20 difcac">
          <div>
            <el-button type="success" plain round @click="paySuccess()">支付完成</el-button>
          </div>
          <div class="mrLft30">
            <el-button type="info" plain round @click="close2()">重新支付</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--微信-->
    <el-dialog :visible.sync="showWeChatCode" :modal="true" width="350px" :center="true" top="30vh" @close="close()">
      <div v-if="imgUrl" class="difcc">
        <h2 class="tct mrBtm20">微信支付</h2>
        <img :src="imgUrl" style="width: 200px;height: auto">
        <div class="mrTop20 difcac">
          <div>
            <el-button type="success" plain round @click="paySuccess()">支付完成</el-button>
          </div>
          <div class="mrLft30">
            <el-button type="info" plain round @click="close()">重新支付</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAlipay: false,
      showWeChatCode: false,
      imgUrl: '',//微信二维码
      list: {
        "user_price": "0.01",
        "one_price": "0.01",
        "three_price": "699",
        "permanent_price": "1699"
      },
      isLoading: false,
      current: 2,
      pageType:'1',//显示几级页面
      pageHeight:document.documentElement.clientHeight,//获取页面高度
      autoXufei: false,//自动续费
      price: '',//vip价格
      codeImg: '',//扫码图片
      info: '',//描述内容
      userInfo: {
        is_pay: 0,
        end_time: '',//到期时间
        vip_type: 0,
        vip_time: '',
      },
      vipText: [
        {id: 1,type: 1,content: '',brief_content: ''},
        {id: 2,type: 2,content: '',brief_content: ''},
        {id: 3,type: 3,content: '',brief_content: ''},
        {id: 4,type: 4,content: '',brief_content: ''},
        {id: 5,type: 5,content: '',brief_content: ''},
        {id: 6,type: 6,content: '',brief_content: ''},
        {id: 7,type: 7,content: '',brief_content: ''},
      ]
    }
  },
  created(event) {
    this.getVipConfig()
    this.getConfig()
    this.getUserInfo()
  },
  methods:{
    getVipConfig() { //获取会员描述
      this.$axios("POST","/api/search/getVipConfig",{})
      .then(res => {
        if(res.code==1) {
          this.vipText = res.data
        }
      })
    },
    getUserInfo() { //获取用户信息
      this.$axios("POST","/api/user/getUser",{})
          .then(res => {
            this.userInfo = res.data
          })
    },
    copy() { //复制
      let msg = '服务推荐榜，就用亿指榜！不同纬度的推荐榜单帮你推荐生活！\n' +
          '只要你家里所需拿不准的，这里的推荐榜单都给你！\n' +
          '只要你家公司/组织要入驻的，这里生成官网功能、发布官网功能、信息资讯宣传功能、需求发布功能、网店宣传功能、加盟宣传功能、招聘发布功能、软件app发布功能、实时访问动态功能全部都给你！\n' +
          '点击进入亿指榜官网：www.yzbse.com'
      this.$copyText(msg).then(res => {
        this.$message.success('已复制到剪贴板')
      }).catch(err => {
        this.$message.error('复制到剪贴板失败，请重试')
      })
    },
    getPayInfo(type) { //获取支付参数 0微信 1支付宝
      this.isLoading = true
      this.$axios("POST","/api/site/addOrder",{
        type,
        types: this.current
      })
          .then(res => {
            this.isLoading = false
            if(res.code===1) {
              if(type===0) { //微信支付
                this.wxPay(res.data)
              }else { //支付宝支付
                this.aliPay(res.data)
              }
            }else{
              this.$message.error(res.msg)
            }
          })
    },
    wxPay(data) { //微信支付
      // this.showWeChatCode = true
      this.imgUrl = data
    },
    aliPay(data) { //支付宝支付
      this.showAlipay = true
      // 打开支付宝form表单
      const div = document.createElement('div')
      div.innerHTML = data
      document.body.appendChild(div)
      document.forms[0].acceptCharset = "UTF-8"
      document.forms[0].target = "_blank"
      if(navigator.userAgent.indexOf('Firefox') >= 0) {
        this.$alert('打开支付宝支付','支付',{type: 'info'})
            .then(res => {
              document.forms[0].submit()
            })
      }else {
        document.forms[0].submit()
      }

    },
    close() { //微信支付关闭弹窗
      // this.imgUrl = ''
      this.showWeChatCode = false
    },
    close2() { //支付宝支付关闭弹窗
      this.showAlipay = false
      this.$router.replace({
        path: '/meCenter',
        query:{
          leftSelect:2,
          contentType: 2,
          time: new Date().getTime()
        }
      }).catch(err => err)
      setTimeout(() => {
        this.$router.go(0)
      },500)
    },
    paySuccess() { //点击支付成功
      this.$router.replace({
        path: '/meCenter',
        query:{
          leftSelect:2,
          contentType: 2,
          time: new Date().getTime()
        }
      }).catch(err => err)
      setTimeout(() => {
        this.$router.go(0)
      },500)
    },
    getConfig() {
      this.$axios("POST","/api/site/getConfings",{})
          .then(res => {
            if(res.code===1) this.list = res.data
          })
      this.$axios("POST","/api/Index/getConfing",{name: 'user_price'})
          .then(res => {
            if(res.code===1) this.price = res.data
          })
      this.$axios("POST","/api/Index/getConfing",{name: 'account_image'})
          .then(res => {
            if(res.code===1) this.codeImg = res.data
          })
    },
    pageDetail(eventa,eventb) {
      if(eventa == '1') {//切换页面
        this.pageType = eventb
      }
    }
  }
}
</script>

<style scoped>
.member_button1{
  width: 360px;
  height: 56px;
  background: #3A54FF;
  border-radius: 8px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #FFFFFF;
  margin-top: 100px;
  cursor: pointer;
}
.member_button2{
  width: 360px;
  height: 56px;
  background: #E6E6E6;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #3A54FF;
  margin: 0 auto;
}
.pay_icon{
  width: 160px;
  height: 160px;
  box-sizing: border-box;
  border: 4px solid #1EC624;
  border-radius: 2px;
}
.pay_ali_icon{
  border: 4px solid #1CABEC;
}
.pay_title{
  margin-top: 10px;
  width: 160px;
  height: 34px;
  border-radius: 2px;
  font-size: 18px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}
.zidongxufei{
  cursor: pointer;
  width: 22px;
  height: 22px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #D6D6D6;
  border-radius: 4px;
}

.warp1{
  width: 260px;
  height: 822px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
  margin-right: 1px;
  box-sizing: border-box;
  padding: 62px 22px;
  padding-bottom: 0;
}
.button{
  width: 205px;
  height: 42px;
  background: #3A54FF;
  border-radius: 5px;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
}
.share_button{
  width: 360px;
  height: 56px;
  background: #3A54FF;
  border-radius: 8px;
  color: #ffffff;
  font-size: 24px;
}
</style>
