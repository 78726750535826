<template>
    <!--我的消息-->
    <div class="w90 mrAt pdTop20  pdBtm20" style="min-height: 86vh">
      <div class="dif alc top_group">
        <!--<div class="fs16 col666 pointer tlt" style="margin-right: 47px;" :class="isActive===1?'active':''" @click="isActive=1">全部消息</div>-->
        <div class="fs16 col666 pointer tlt" style="margin-right: 47px;" :class="isActive===2?'active':''" @click="getInfo(1)">系统消息</div>
        <div class="fs16 col666 pointer tlt" style="margin-right: 47px;" :class="isActive===3?'active':''" @click="getInfo(2)">私信消息</div>
        <div class="fs16 col666 pointer tlt" style="margin-right: 47px;" :class="isActive===4?'active':''" @click="getInfo(3)">我的留言</div>
        <div class="top_search">
          <img src="@/assets/image/yizhibang/50top_serch.png" class="top_search_icon">
          <input type="text" v-model="keyword" class="top_search_input" placeholder="输入您想查找的消息内容">
        </div>
        <!--操作-->
       <!-- <div class="dif alc" style="margin-left: 65px;">
          <div class="top_caozuo pointer">全部已读</div>
          <div class="top_caozuo pointer mrLft20" @click="manage=!manage">批量管理</div>
        </div>-->
      </div>
      <!--  <div class="top_border">
        <div class="fs14 col999 mrLft20 pointer" :class="isActive2===1?'active':''" @click="isActive2=1">全部（{{list.length}}）</div>
        <div class="fs14 col999 mrLft10 pointer" :class="isActive2===2?'active':''" @click="isActive2=2">未读（0）</div>
      </div>-->
       <!--消息列表-->
      <div style="margin-top: 14px;">
        <!--系统消息-->
        <div v-if="isActive==2">
          <div class="tlt mrBtm10">
            <el-button type="danger" icon="el-icon-delete" circle @click="deleteSysTem()"></el-button>
          </div>
          <ul v-if="mgs.length>0">
            <el-checkbox-group v-model="checkSystem">
              <li class="news_item" v-for="(item,index) in mgs" :key="index">
                <el-checkbox :label="item.id">{{''}}</el-checkbox>
                <img src="@/assets/image/yizhibang/50notice.png" style="width: 40px;height: 40px;">
                <div style="margin-left: 14px;">
                  <div class="dif alc">
                    <div class="news_radio" v-if="item.noread==1"></div>
                    <p class="fs14 col333  one_overflow">{{item.title}}</p>
                  </div>
                  <div class="dif alc mrTop10">
                    <p class="fs12 col999">系统消息</p>
                    <p class="fs12 col999 mrLft30 mrRit30">{{item.add_time}}</p>
                    <p class="pointer fs12 col3765FF" @click="collect(item,1)" v-if="item.is_collect==1">取消收藏</p>
                    <p class="pointer fs12 col3765FF" @click="collect(item,1)" v-else>收藏</p>
                    <p class="pointer fs12 col3765FF mrLft30" @click="setDetail(item,index)">查看详情>></p>
                  </div>
                </div>
                <div class="news_guanli pointer" v-if="manage">
                  <img src="@/assets/image/yizhibang/51noclose.png" style="width: 20px;height: 20px;">
                  <!--<img src="@/assets/image/yizhibang/51close.png" style="width: 20px;height: 20px;">-->
                </div>
                <!--消息详情-->
                <div class="news_detail shadow_bottom" v-if="showDetail===index">
                  <!--系统消息-->
                  <div style="max-height: 220px;overflow-y: auto">
                    <h2 class="tlt">{{item.title}}</h2>
                    <div class="fs16 col333 tlt mrTop10">{{item.content}}</div>
                  </div>
                </div>
              </li>
            </el-checkbox-group>
          </ul>
          <div v-else>暂无消息</div>
        </div>
        <!--私信消息-->
        <div v-if="isActive==3">
          <div class="tlt mrBtm10">
            <el-button type="danger" icon="el-icon-delete" circle @click="deleteChat()"></el-button>
          </div>
          <ul v-if="chat.length>0">
            <el-checkbox-group v-model="checkChat">
              <li class="news_item" v-for="(item,index) in chat" :key="index">
                <el-checkbox :label="item.opposite_user_id">{{''}}</el-checkbox>
                <img :src="item.uinfo.avatar" class="bra" style="width: 40px;height: 40px;">
                <div style="margin-left: 14px;">
                  <div class="dif alc">
                    <div class="news_radio" v-if="item.noread>0"></div>
                    <!--<p class="fs14 col333  one_overflow">{{item.uinfo.uname}}：{{item.content}}</p>-->
                    <p class="fs14 col333  one_overflow">{{item.uinfo.uname}}</p>
                  </div>
                  <div class="dif alc mrTop10">
                    <p class="fs12 col999">私信消息</p>
                    <p class="fs12 col999 mrLft30 mrRit30">{{item.add_time}}</p>
                    <p class="pointer fs12 col3765FF" @click="collect(item,2)" v-if="item.is_collect==1">取消收藏</p>
                    <p class="pointer fs12 col3765FF" @click="collect(item,2)" v-else>收藏</p>
                    <p class="pointer fs12 col3765FF mrLft30" @click="setChat(item,index)">查看详情>></p>
                  </div>
                </div>
                <div class="news_guanli pointer" v-if="manage">
                  <img src="@/assets/image/yizhibang/51noclose.png" style="width: 20px;height: 20px;">
                  <!--<img src="@/assets/image/yizhibang/51close.png" style="width: 20px;height: 20px;">-->
                </div>
                <!--消息详情-->
                <div class="news_detail shadow_bottom" v-if="showChat===index" >
                  <div class="chat_list col333 w100" id="chat"
                       style="max-height: 250px;overflow-y: auto" >
                    <div class="mrBtm10" v-for="(item2,index2) in history.list" >
                      <!--我的消息-->
                      <div class="dife fs16 " v-if="item2.type==1">
                        <div style="max-width: 80%" class="mrRit20 pdTop10">
                          <div class="trt fs16">{{history.uInfo.username}}</div>
                          <div class="tlt me_chat mrTop10" style="max-width: 100%;word-break: break-all">
                            {{item2.content}}
                          </div>
                        </div>
                        <img :src="history.uInfo.avatar" class="bra" style="width: 50px;height: 50px;background-color: #eee">
                      </div>
                      <!--对方消息-->
                      <div class="dif fs16 " v-else>
                        <img :src="history.herInfo.avatar" class="bra" style="width: 50px;height: 50px;background-color: #eee">
                        <div style="max-width: 80%" class="mrLft20 pdTop10">
                          <div class="tlt fs16">{{history.herInfo.username}}</div>
                          <div class="tlt her_chat mrTop10" style="max-width: 100%;word-break: break-all">
                            {{item2.content}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <el-input type="textarea" v-model="msg" placeholder="请输入内容。。。" rows="5"></el-input>
                  <div class="dife alc mrTop10">
                    <div style="width: 150px;"><el-button type="primary" class="w100" @click="sendMsg()">点击回复</el-button></div>
                  </div>
                </div>
              </li>
            </el-checkbox-group>
          </ul>
          <div v-else>暂无消息</div>
        </div>
        <!--我的留言-->
        <div v-if="isActive==4">
          <div class="tlt mrBtm10">
            <el-button type="danger" icon="el-icon-delete" circle @click="deleteComment()"></el-button>
          </div>
          <ul v-if="comment.length>0">
            <el-checkbox-group v-model="checkComment">
              <li class="news_item" v-for="(item,index) in comment" :key="index">
                <el-checkbox :label="item.id">{{''}}</el-checkbox>
                <img src="@/assets/image/yizhibang/50notice.png" style="width: 40px;height: 40px;">
                <div style="margin-left: 14px;">
                  <div class="dif alc">
                    <!--<div class="news_radio"></div>-->
                    <p class="fs14 col333  one_overflow">{{item.content}}</p>
                  </div>
                  <div class="dif alc mrTop10">
                    <p class="fs12 col999">我的留言</p>
                    <p class="fs12 col999 mrLft30 mrRit30">{{item.add_time}}</p>
                    <!--<p class="pointer fs12 col3765FF" @click="collect(item,3)">收藏</p>-->
                    <p class="pointer fs12 col3765FF mrLft30" @click="goMyComment(item,index)">查看详情>></p>
                  </div>
                </div>
                <div class="news_guanli pointer" v-if="manage">
                  <img src="@/assets/image/yizhibang/51noclose.png" style="width: 20px;height: 20px;">
                  <!--<img src="@/assets/image/yizhibang/51close.png" style="width: 20px;height: 20px;">-->
                </div>
                <!--消息详情-->
                <div class="news_detail shadow_bottom" v-if="showDetail===index">
                  <!--系统消息-->
                  <div style="max-height: 220px;overflow-y: auto">
                    <div class="fs16 col333 tlt mrTop10">{{item.content}}</div>
                  </div>
                </div>
              </li>
            </el-checkbox-group>
          </ul>
          <div v-else>暂无消息</div>
        </div>
      </div>
      <!--分页-->
      <div class="difcac" v-if="false">
        <el-pagination
            background
            layout="jumper, prev, pager  ,next   "
            :total="1000">
        </el-pagination>
      </div>
       <!-- <div :style="{'height':pageHeight-110+'px'}" class="w100 bgff container fs15 col888" v-if="news.length!==0">
            <div v-for="(item,index) in news" :key="index" class="rows w100">
                <div class="col-1"><img src="@/assets/image/waste/dizhi_wxz.png" style="width:30px;" alt=""></div>
                <div class="col-1 one_overflow">{{item.type}}</div>
                <div class="col-1 one_overflow">{{item.uname}}</div>
                <div class="col-7 one_overflow w80 pdLft10"><item class="content"></item></div>
                <div class="col-1">{{item.createtime}}</div>
                <div class="col-1">收藏</div>
            </div>
        </div>-->
    </div>
</template>

<script>
  export default {
    data() {
      return {
        pageHeight:document.documentElement.clientHeight,//获取页面高度
        news:[],//消息数据
        isActive: 2, //上分消息分类
        keyword: '',//关键字
        isActive2: 1,//全部消息1  未读消息2
        manage: false,//批量管理

        showDetail: -1,
        showChat: -1,
        list: [],//全部消息列表
        mgs: [],//系统消息列表
        comment: [],//我的留言
        chat: [],//私信消息
        msg: '',//回复的消息
        history: {
          list: [],
          uInfo: null,
          herInfo: null,
        },//私信聊天记录
        checkChat: [],//选中的私信消息
        checkComment: [],//选中的留言
        checkSystem: [],//选中的系统消息
      }
    },
    created(event) {
      let type = 1
      if(localStorage.getItem('yzbNews')) {
        type = Number(localStorage.getItem('yzbNews'))
      }
      this.getInfo(type)
    },
    methods:{
      goMyComment(item,index) {
        let query ={sid: item.sid,active: 7}
        this.$router.push({
          path:'/search/webdetail',
          query: query
        }).catch(err => err)
      },
      deleteChat() { //删除选中的私信消息
        if(this.checkChat.length<=0) {
          return this.$message.error('请选择要删除的私信消息')
        }
        this.$confirm('确认要删除选中的消息吗？',{type: 'warning'})
        .then(con => {
          this.$axios("POST","/api/User/delChat",{id:this.checkChat.join(',')})
          .then(res => {
            if(res.code==1) {
              this.$message.success(res.msg)
              this.getInfo(2)
              this.checkChat = []
            }else {
              this.$message.success(res.msg)
              this.getInfo(2)
            }
          })
        })
      },
      deleteComment() {
        if(this.checkComment.length<=0) {
          return this.$message.error('请选择要删除的留言')
        }
        this.$confirm('确认要删除选中的消息吗？',{type: 'warning'})
            .then(con => {
              this.$axios("POST","/api/User/delComment",{id:this.checkComment.join(',')})
                  .then(res => {
                    if(res.code==1) {
                      this.$message.success(res.msg)
                      this.getInfo(3)
                      this.checkComment = []
                    }else {
                      this.$message.success(res.msg)
                      this.getInfo(3)
                    }
                  })
            })
      },
      deleteSysTem() {
        if(this.checkSystem.length<=0) {
          return this.$message.error('请选择要删除的系统消息')
        }
        this.$confirm('确认要删除选中的系统吗？',{type: 'warning'})
            .then(con => {
              this.$axios("POST","/api/User/delSystem",{id:this.checkSystem.join(',')})
                  .then(res => {
                    if(res.code==1) {
                      this.$message.success(res.msg)
                      this.getInfo(1)
                      this.checkComment = []
                    }else {
                      this.$message.success(res.msg)
                      this.getInfo(1)
                    }
                  })
            })
      },
      collect(item,type) { //收藏消息
        this.$axios("POST","/api/member/collect",{
          type: 1,
          id: item.id,
          info_type: type,
        })
        .then(res => {
          if(res.code===1) {
            this.$message.success('操作成功')
            this.getInfo(type)
          }else {
            this.$message.error(res.msg)
          }
        })
      },
      setDetail(item,index) { //查看详情 系统消息
        if(this.showDetail===index) {
          this.showDetail = -1
        }else {
          this.showDetail = index
          this.$axios("POST","/api/comment/readSystemInfo",{id: item.id})
          .then(res => {
            item.noread = 0
          })
        }
      },
      setChat(item,index) { //查看详情
        console.log(item);
        if(this.showChat===index) {
          this.showChat = -1
        }else {
          this.showChat = index
          this.getChatHistory(item.uinfo.id)
        }
      },
      getInfo(type) { //获取消息列表
        this.isActive = type+1
        localStorage.setItem('yzbNews',type)
        console.log(this.isActive);
        this.$axios("POST","/api/user/getDriverInfo",{type})
        .then(res => {
          if(res.code===1) {
            if(type==1) {
              this.mgs = res.data
            }else if(type==2) {
              this.chat = res.data
            }else {
              this.comment = res.data
            }
          }
        })
      },
      scrollBottom() { //滚动聊天框
        let div = document.getElementById("chat");
        div.scrollTop = 99999;
      },
      getChatHistory(id) { //获取聊天记录
        this.$axios("POST","/api/Comment/getHistory",{id})
        .then(res => {
          this.getInfo(2)
          if(res.code===1) {
            this.history = res.data
            this.$forceUpdate()
            setTimeout(() => {
              this.scrollBottom()
            },100)
          }else {
            this.$message.error(res.msg)
          }
        })
      },
      sendMsg() { //发送消息
        if(!this.msg.trim()) return this.$message.error('请输入私信内容')
        let data = {
          pid: this.history.herInfo.id,
          comment: this.msg
        }
        this.$axios("POST","/api/Comment/chat",data)
        .then(res => {
          if(res.code===1) {
            this.$message.success(res.msg)
            this.getChatHistory(this.history.herInfo.id)
            this.msg = ''
          }else {
            this.$message.error(res.msg)
          }
        })
      },
    }
  }
</script>

<style scoped>
    .me_chat {
      background-color: #3765FF;
      color: #ffffff;
      border-radius: 20px;
      padding: 10px;
    }
    .her_chat {
      background-color: #f1f1f1;
      color: #333;
      border-radius: 20px;
      padding: 10px;
    }
    .news_detail{
      position: absolute;
      width: 100%;
      max-height: 620px;
      left: 0;
      background: #fff;
      top: 80px;
      z-index: 23;
      border-radius: 0 0 30px 30px;
      box-sizing: border-box;
      padding: 20px 30px;
    }
    .container {
      overflow: hidden;
		  overflow-y: auto;
    }
    .top_group .active{
      color: #366EFF;
    }
    .top_search{
      margin-left: 25px;
      position: relative;
      display: flex;
      align-items: center;
      width: 380px;
      height: 40px;
      background: #F3F3F3;
      border-radius: 20px;
      box-sizing: border-box;
    }
    .top_search_icon{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 28px;
      width: 17px;
      height: 18px;
    }
    .top_search_input{
      font-size: 14px;
      color: #999999;
      background: none;
      padding-left: 59px;
      width: 340px;
    }
    .top_caozuo{
      width: 84px;
      height: 26px;
      background: #F3F3F3;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;color: #666666;
    }
    .top_border{
      width: 100%;
      height: 40px;
      background: #FAFAFA;
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
    .top_border .active{
      color: #333333;
    }
    .news_item{
      width: 100%;
      position: relative;
      height: 80px;
      box-sizing: border-box;
      padding: 0 20px;
      border: 1px solid #EFEFEF;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
    .news_radio{
      width: 6px;
      height: 6px;
      background: #F04243;
      border-radius: 50%;;
    }
    .news_guanli{
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
    }
</style>