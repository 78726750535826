<template>
  <!--招聘搜索结果-->
  <div class="zhaopin_result" style="width: 1200px;margin: 0 auto;" @click="showShare=false">
    <!--导航-->
    <div class="dif alc tlt fs12 col999 mrTop20 mrBtm20">
      <router-link to="/" class="fs12 col999">首页</router-link>
      <p>>招聘搜索</p>
    </div>
    <div class="w100 h100 dif res_box">
      <div style="width: 800px;">
        <!--招聘分类-->
        <ul class="dif difw zhaopin_class" >
          <li>福利:</li>
          <li v-for="(item,index) in zhaopinClass" :key="index" @click="changeFuli(item.id)" class="pointer"
              :class="{'active': isActiveCheck(item.id)}">{{item.title}}</li>
        </ul>
        <!--招聘筛选-->
        <ul class="dif difw alc zhaopin_class mrBtm10" style="margin-top: 15px;">
          <li class="dif alc">
            <div>时间：</div>
            <el-select  @change="search(keyword,type)"
                v-model="inviteTime2" placeholder="发布时间" size="mini" style="width: 120px;">
              <el-option
                  v-for="(item,index) in inviteTime"
                  :key="index"
                  :label="item.title"
                  :value="item.id">
              </el-option>
            </el-select>
          </li>
          <li class="dif alc">
            <div>薪资：</div>
            <el-select v-model="inviteMoney2"   @change="search(keyword,type)"
                       placeholder="薪资范围" size="mini" style="width: 120px;">
              <el-option
                  v-for="(item,index) in inviteMoney"
                  :key="index"
                  :label="item.title"
                  :value="item.id">
              </el-option>
            </el-select>
          </li>
          <li class="dif alc">
            <div>学历：</div>
            <el-select v-model="inviteEducation2"  @change="search(keyword,type)"
                       placeholder="学历要求" size="mini" style="width: 120px;">
              <el-option
                  v-for="(item,index) in inviteEducation"
                  :key="index"
                  :label="item.title"
                  :value="item.id">
              </el-option>
            </el-select>
          </li>
          <li class="dif alc">
            <div>经验：</div>
            <el-select v-model="inviteExperience2"  @change="search(keyword,type)"
                       placeholder="工作经验" size="mini" style="width: 120px;">
              <el-option
                  v-for="(item,index) in inviteExperience"
                  :key="index"
                  :label="item.title"
                  :value="item.id">
              </el-option>
            </el-select>
          </li>
        </ul>
        <!--搜索结果-->
        <ul class="res_list">
          <!--无搜索结果-->
          <div v-if="list.length<=0" style="padding-top: 100px;">
            <el-result icon="info" title="未搜索到结果"></el-result>
          </div>
          <!--有搜索结果-->
          <li class="zhaopin_item posr" v-for="(item,index) in list" :key="index" @click="goZhaopinDetail(item.id)">
            <div class="dif alc">
              <p class="fs18 bold col3765FF one_overflow tlt" style="margin-right: 16px;width: 270px;">{{item.position}}
                <span v-if="item.work_address">【{{item.work_address}}】</span>
              </p>
              <p class="fs16 col333 tlt one_overflow" style="width: 200px;">{{item.company}}</p>
              <div class="dif alc mrLft20">
                <p>{{item.createtime}}</p>
                <div class="zhaopin_type">{{item.type}}</div>
              </div>
            </div>
            <div class="dif alc mrTop20">
              <p class="fs18" style="color:#ff0000;">{{formatNumber(item.min_money)}}-{{formatNumber(item.max_money)}}</p>
              <p class="fs16 col999 mrLft30 mrRit30">{{item.experience_title}}</p>
              <p class="fs16 col999">{{item.education_title}}</p>
            </div>
            <!--职工福利-->
            <div class="dif alc mrTop20">
              <p class="fs14 col333 pdTop10" style="margin-right: 13px;">职位福利</p>
              <ul class="dif alc fuli difw" style="max-width: 600px;">
                <li v-for="(item2,index2) in item.welfare">{{item2}}</li>
              </ul>
            </div>
            <div class="caozuo dif alc">
              <p class="fs14 colFDAE38 mrRit20 pointer" @click.stop="collect(item)" v-if="item.is_collect==0 || !item.is_collect">收藏</p>
              <p class="fs14 colFDAE38 mrRit20 pointer" @click.stop="collect(item)" v-else>已收藏</p>
              <p class="fs14 colFDAE38 pointer" @click.stop="setShare(item,index)">分享</p>
            </div>
          </li>
        </ul>
      </div>

      <hot></hot>
      <!--分享-->
      <div class="share_icon shadow_bottom difcc" v-if="showShare" @click.stop>
        <i class="el-icon-close close pointer fs24" @click.stop="showShare=false"></i>
        <h3>点击分享</h3>
        <share :config="config"></share>
      </div>
    </div>
  </div>
</template>

<script>
import Hot from "@/components/search/hot";
export default {
  name: "zhaopinResult",
  components: {Hot},
  data() {
    return {
      zhaopinClass: [],
      isActive: 1,
      selectActive: [],//选中的福利
      type: '',
      keyword: '',
      inviteTime: [],//招聘发布时间
      inviteTime2: '',//当前选中招聘发布时间
      inviteMoney: [],//招聘薪资范围
      inviteMoney2: '',//当前选中招聘薪资范围
      inviteEducation: [],//学历要求
      inviteEducation2: '',//当前选中学历要求
      inviteExperience: [],//经验要求
      inviteExperience2: '',//当前选中经验要求
      list: [],//搜索结果
      config:{
        sites: ['qq','wechat','qzone'],
        image: '',
        title: '',
        description: '',
        url: '', // 网址，默认使用 window.location.href
        source: '', // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
        wechatQrcodeTitle: '微信扫一扫：分享', // 微信二维码提示文字
        wechatQrcodeHelper: '<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>'
      },
      showShare: false,
    }
  },
  methods: {
    //收藏
    collect(item) {
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      this.$axios("POST","/api/member/collect",{
        id: item.id,
        type: 6
      }).then(res => {
        if(res.code===1) {
          this.$message.success('操作成功')
          setTimeout(() => {
            this.search(this.keyword,this.type)
          },1500)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    changeFuli(id) {
      let index = this.selectActive.findIndex(item => {
        return item===id
      })
      if(index>=0) {
        this.selectActive.splice(index,1)
      }else {
        this.selectActive.push(id)
      }
      this.search(this.keyword,this.type)
    },
    setShare(item,index) {
      this.showShare = true
      this.config.title = item.position
      let host = window.location.protocol+"//"+window.location.host+ '/search/zhaopin/detail?id='+item.id
      this.config.url = host
      this.config.description = item.keyword
    },
    goZhaopinDetail(id) { //跳转到招聘详情页
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      let routeUrl = this.$router.resolve({
        path: '/search/zhaopin/detail?id='+id
      })
      window.open(routeUrl .href, '_blank');
    },
    search(keyword,type){ //搜索
      let data = {
        keyword: this.keyword,
        type: this.type,
        invite_money:this.inviteMoney2,
        invite_education: this.inviteEducation2,
        invite_time: this.inviteTime2,
        invite_experience: this.inviteExperience2,
        welfare: this.selectActive.join(',')
      }
      // this.$message.info('努力加载中')
      this.$axios("POST",'/api/search/search',data).then(res => {
        if(res.code === 1) {
          this.list = res.data
          this.$forceUpdate()
        }else{
          this.list = []
        }
      })
    },
    getInviteTime() {
      this.$axios("POST","/api/Recruitment/inviteTime",{})
      .then(res => {
        this.inviteTime = res.data
        this.inviteTime2 = res.data[0].id
      })
    },
    getInviteMoney() {
      this.$axios("POST","/api/Recruitment/inviteMoney",{})
      .then(res => {
        this.inviteMoney = res.data
        this.inviteMoney2 = res.data[0].id
      })
    },
    getInviteEducation() {
      this.$axios("POST","/api/Recruitment/inviteEducation",{})
      .then(res => {
        this.inviteEducation = res.data
        this.inviteEducation2 = res.data[0].id
      })
    },
    getInviteExperience() {
      this.$axios("POST","/api/Recruitment/inviteExperience",{})
      .then(res => {
        this.inviteExperience = res.data
        this.inviteExperience2 = res.data[0].id
      })
    },
    getFulis() { //获取福利列表
      this.$axios("POST","/api/Recruitment/welfare",{})
      .then(res => {
        if(res.code===1) {
          this.zhaopinClass = res.data
        }
      })
    }
  },
  watch:{
    //监听路由参数  搜索
    "$route.params":function (route){
      this.keyword = this.$route.query.keyword
      this.type = this.$route.query.type
      this.search(this.keyword,this.type)
    }
  },
  created() {
    //获取从首页跳转来的搜索关键字 并 搜索
    let keyword = this.$route.query.keyword
    let type = this.$route.query.type
    this.keyword = keyword
    this.type = type
    this.search(keyword,type)
    this.getInviteTime()
    this.getInviteMoney()
    this.getInviteEducation()
    this.getInviteExperience()
    this.getFulis()
  },
  computed: {
    isActiveCheck() { //判断是否选中
      return function (id) {
        if(this.selectActive.length<=0) return  false
        let index = this.selectActive.findIndex(item => {
          return item===id
        })
        if(index>=0) {
          return true
        }else {
          return  false
        }
      }
    },
    formatNumber() { //数字格式化
      return function (num) {
        return num >= 1e3 && num < 1e4 ? (num / 1e3).toFixed(1) + 'k' : num >= 1e4 ? (num / 1e4).toFixed(1) + 'w' : num
      }
    },
    getTime() { //时间戳转换
      return function (time) {
        var date = new Date(time*1000);
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
        return Y + M + D;
      }
    }
  }
}
</script>

<style scoped>
.zhaopin_class li{
  color: #999;
  font-size: 14px;
  margin-right: 26px;
  margin-bottom: 20px;
}
.zhaopin_class .active{
  color: #3765FF;
}
.zhaopin_item{
  width: 728px;
  background: #FAFAFA;
  border: 1px solid #F5F5F5;
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 22px 22px;
}
.zhaopin_type{
  width: 42px;
  height: 20px;
  background: #3765FF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #FFFFFF;
  margin-left: 8px;
}
.fuli li{
  font-size: 14px;
  color: #999999;
  border: 1px solid #EEEEEE;
  padding: 8px 11px;
  margin-right: 10px;
  margin-top: 10px;
}
.caozuo{
  position: absolute;
  bottom: 16px;
  right: 22px;
}
.share_icon{
  width: 200px;
  height: 100px;
  border-radius: 20px;
  background: #FFFFFF;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 99;
}
.close{
  position: fixed;
  top: 10px;
  right: 10px;
}
</style>