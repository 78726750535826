<template>
  <!-- 密码箱 -->
  <div>
    <div class="pass_box">
      <!--顶部操作-->
      <div class="top">
      
        <ul class="dife alc" style="height: 60px;border-bottom: 1px solid #EEEEEE;">
          <li style="width: 460px;text-align: left;">目前密码箱仅做提示作用，输入密码内容请务必隐晦！</li>
          <li @click="isAdd=!isAdd" class="add_button pointer">添加密码</li>
          <!--<li class="fs12 dif alc">-->
          <!--  <img src="@/assets/image/yizhibang/03yes_check.png" style="width: 16px;height: 16px;" v-if="rememberPass" @click="rememberPass=false">-->
          <!--  <img src="@/assets/image/yizhibang/03no_check.png" style="width: 16px;height: 16px;" v-else @click="rememberPass=true">-->
          <!--  <div class="mrLft10">自动记住网页密码</div>-->
          <!--</li>-->
          <!--<li class="fs12 dif alc">-->
          <!--  <img src="@/assets/image/yizhibang/03yes_check.png" style="width: 16px;height: 16px;" v-if="autoPass" @click="autoPass=false">-->
          <!--  <img src="@/assets/image/yizhibang/03no_check.png" style="width: 16px;height: 16px;" v-else @click="autoPass=true">-->
          <!--  <div class="mrLft10">网址自动填写密码</div>-->
          <!--</li>-->
          <li class="fs12 dif alc">
            <img src="@/assets/image/yizhibang/03yes_check.png" style="width: 16px;height: 16px;" v-if="hidePass" @click="hidePass=false">
            <img src="@/assets/image/yizhibang/03no_check.png" style="width: 16px;height: 16px;" v-else @click="hidePass=true">
            <div class="mrLft10">密码隐藏</div>
          </li>
        </ul>
      </div>
      <!-- 内容 -->
      <div class="center">
        <ul class="difb alc w100" style="line-height: normal;">
          <li class="fs12 bold col333 difcac" style="width: 150px;">网站代用名</li>
          <li class="fs12 bold col333 difcac" style="width: 150px;">登录网址</li>
          <li class="fs12 bold col333 difcac" style="width: 150px;">用户名</li>
          <li class="fs12 bold col333 difcac" style="width: 150px;">密码</li>
          <li class="fs12 bold col333 difcac" style="width: 150px;">操作</li>
        </ul>
        <ul class="difb alc w100" style="line-height: normal;" v-for="(item,index) in boxData[curPage-1]">
          <li class="fs12 col666 tct" style="width: 150px;">{{item.site_name}}</li>
          <li class="fs12 col666 tct" style="width: 150px;">{{item.url}}</li>
          <li class="fs12 col666 tct" style="width: 150px;">{{item.username}}</li>
          <li class="fs12 col666 tct" style="width: 150px;" @mouseenter="showPass=index" @mouseleave="showPass=-1">
            <div v-if="hidePass && showPass!==index">
              <span v-for="(item2,index2) in item.pwd.length" :key="index2">●</span>
            </div>
            <div v-if="!hidePass || showPass===index">{{item.pwd}}</div>
          </li>
          <li class="fs12 difcac tct" style="width: 150px;">
            <div style="color:#3A53FF;" class="pointer" @click="setUpdate(item)">修改</div>
            <div style="color:#FF0000;" class="mrLft10 mrRit10 pointer" @click="delPass(item.id)">删除</div>
            <!--<div style="color:#02CEAF;" class="pointer">填入</div>-->
          </li>
        </ul>
        <!--<ul class="c_item difb difw" style="overflow: hidden">
          <li v-for="(item,index) in boxData[curPage-1]" style="margin-top: 6px;" class="borBtmdd">
            <div class="dif c_top">
              <div class="mrRit10">{{item.site_name}}</div>
              <div>登录网址:{{item.url}}</div>
            </div>
            <div class="difb c_bottom">
              <div class="dif alc">
                <div>{{item.username}}</div>
                <div v-if="showPwd===index" class="mrLft10"><input type="text" :value="item.pwd" disabled></div>
                <div v-else class="mrLft10"><input type="password" :value="item.pwd" disabled></div>
              </div>
              <div class="dif">
                <img src="@/assets/image/enterprise/icon_show.png"
                     style="width: 20px;height: 20px;"
                     v-if="showPwd===index" @click="showPwd=-1">
                <img src="@/assets/image/enterprise/icon_hid.png"
                     style="width: 20px;height: 20px;"
                     v-else @click="showPwd=index">
                <div>修改</div><div class="mrRit10 mrLft10">移除</div><div>填入</div>
              </div>
            </div>
          </li>
        </ul>-->
      </div>
      <!--底部页数-->
     <div>
      
     </div>
      <div class="bottom difcac pdTop30">
        <el-pagination
            layout="prev, pager, next"
            :total="pageCount * 10"
            :current-page="curPage"
            @current-change="curChange"
        >
        </el-pagination>
      </div>
      <!--点击添加密码-->
      <div class="add_warp" v-if="isAdd">
        <div class="add_pass" >
          <div class="add_title">
            <div>添加密码</div>
            <img src="@/assets/image/yizhibang/00one_close.png" class="close" @click="isAdd=!isAdd">
          </div>
          <div class="add_cont">
            <div class="cont_warp difb alc" style="margin-top: 18px;">
              <div class="dif alc w50 box">
                <p class="fs16 col333">网站名称：</p>
                <input type="text" v-model="site_name">
              </div>
              <div class="dif alc w50 box">
                <p class="fs16 col333">登录网址：</p>
                <input type="text" v-model="url">
              </div>
            </div>

            <div class="cont_warp difb alc" style="margin-top: 23px;">
              <div class="dif alc w50 box">
                <p class="fs16 col333">用户名称：</p>
                <input type="text" v-model="username">
              </div>
              <div class="dif alc w50 box">
                <p class="fs16 col333">网站密码：</p>
                <input type="password" v-model="pwd">
              </div>
            </div>
          </div>
          <div class="add_bottom difcac" style="margin-top: 30px;">
            <p class="bold pointer button1" @click="setShow()">取消</p>
            <p class="bold pointer button2" @click="addPwd" v-if="upDateId===''">确认添加</p>
            <p class="bold pointer button2" @click="update" v-else>确认修改</p>
          </div>
        </div>
      </div>
    </div>
    <div class="end_warp" @click="close()"></div>
  </div>
</template>

<script>
export default {
  name: "passBox",
  data() {
    return {
      isAdd: false,//显示添加密码页面
      boxData: [],
      site_name: '',//网站代用名
      url: '',//网站url
      username: '',//用户名
      pwd: '',//密码
      showPwd: false, //显示密码
      pageCount: 0,
      curPage: 1,
      rememberPass: false,//自动记住网页密码
      autoPass: false,//自动填写密码
      hidePass: true,//隐藏密码
      showPass: -1,//触摸显示密码的索引

      upDateId:  '',//要修改的id
    }
  },
  created() {
    //获取用户密码箱数据
    this.getPwdBox()
  },
  methods: {
    close() {
      this.$emit('closeWarp',{})
    },
    setShow() { //显示添加修改弹窗
      this.isAdd=!this.isAdd
      this.upDateId =''
    },
    update() { //修改密码箱
      if(!this.site_name || !this.url || !this.username || !this.pwd){
        alert('请输入完整')
        return
      }
      this.$axios("POST","/api/site/createCodeBox",{
        site_name: this.site_name,
        url: this.url,
        username: this.username,
        pwd: this.pwd,
        id: this.upDateId
      }).then(res => {
        if(res.code == '1') {
          alert(res.msg)
          this.isAdd = false
          this.upDateId=''
          this.getPwdBox()
        }else{
          alert(res.msg)
        }
      })
    },
    setUpdate(item) { //设置要修改的密码箱
      console.log(item);
      this.site_name = item.site_name
      this.url = item.url
      this.username = item.username
      this.pwd = item.pwd
      this.upDateId = item.id
      this.isAdd=true
    },
    addPwd() { //添加密码箱
      if(!this.site_name || !this.url || !this.username || !this.pwd){
        alert('请输入完整')
        return
      }
      this.$axios("POST","/api/site/createCodeBox",{
        site_name: this.site_name,
        url: this.url,
        username: this.username,
        pwd: this.pwd
      }).then(res => {
        if(res.code == '1') {
          alert(res.msg)
          this.isAdd = false
          this.getPwdBox()
        }else{
          alert(res.msg)
        }
      })
    },
    curChange(c){ //切换页数显示
      this.curPage = c
    },
    getPwdBox(){
      this.$axios("POST","/api/site/getUserCodeBox",{

      }).then(res => {
        if(res.code == '1'){
          let boxData = res.data
          let pwdBox = []
          let pageCount = Math.ceil(boxData.length / 5)
          for(let i = 0;i<pageCount;i++){
            let item = boxData.slice(i*5,(i+1)*5)
            pwdBox.push(item)
          }
          this.pageCount = pageCount
          this.boxData = pwdBox
        }else {
          this.boxData = []
        }
      })
    },
    delPass(id) { //删除密码
      this.$confirm('确认要删除改密码吗', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认删除',
        cancelButtonText: '取消'
      }).then(() => {
        this.$axios("POST","/api/site/delUserCodeBox",{
          id
        }).then(res => {
          if(res.code===1) {
            //获取用户密码箱数据
            this.getPwdBox()
          }
        })
      })
    },
  }
}
</script>

<style scoped>
  
  .end_warp{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .add_button{
    width: 70px;
    height: 28px;
    background: #3A53FF;
    font-size: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
  }
  .pass_box{
    box-sizing: border-box;
    width: 700px;
    height: 430px;
    background: #fff;
    position: absolute;
    top: 50px;
    left: -350px;
    z-index: 999;
    box-shadow: 0 0 28px 2px rgba(54, 110, 255, 0.5);
    border-radius: 6px;
  }
  .pass_box::before{
    content: "";
    display: block;
    border-bottom: 14px solid #FFFFFF;
    border-left: 11px solid rgba(0,0,0,0);
    border-right: 11px solid rgba(0,0,0,0);
    position: absolute;
    left: 369px;
    top: -10px;
  }
  .top ul li{
    margin-right: 20px;
  }

  .center{
    box-sizing: border-box;
    position: relative;
  }
  .center>div.warp{
    padding: 10px;
    flex: 1;
    border: 1px solid #DCDCDC;
    height: 390px;
    box-sizing: border-box;
  }
  .c_item{
    padding: 0 20px;
    z-index: 999;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .bottom{
    height: 40px;
    position: absolute;
    bottom: 17px;
    right: 0;
  }
  .bottom div{
    margin: 0 20px;
  }

  .c_item li{
    margin-bottom: 10px;
  }
  .c_item div{
    line-height: normal;
    font-size: 12px;
  }
  .c_top{
    font-size: 12px;
    margin-bottom: 10px;
  }
  .c_bottom input{
    width: 50px;
  }

  .add_warp{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
  }
  .add_title{
    width: 692px;
    height: 70px;
    background: #3A54FF;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 26px;
    color: #FFFFFF;
    font-weight: bold;
  }
  .close{
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
  }
  .add_pass{
    width: 692px;
    height: 360px;
    background: #FFFFFF;
    border-radius: 20px;
    box-sizing: border-box;
    position: absolute;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .add_cont{
    padding: 30px;
  }
  .button1{
    width: 226px;
    height: 50px;
    background: #CCCCCC;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #FFFFFF;
  }
  .button2{
    width: 226px;
    height: 50px;
    background: #3A54FF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #FFFFFF;
    margin-left: 40px;
  }
  .cont_warp div{
    line-height: 30px;
  }
  .cont_warp input{
    box-sizing: border-box;
    padding: 0 14px;
    width: 220px;
    height: 38px;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
  }
  .center ul li{
    padding: 17px 0;
  }
</style>