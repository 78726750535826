<template>
  <!--联系我们-->
  <div style="width: 800px;margin: 30px auto;">
    <ul class="col333 fs18 tlt us_list" style="width: 400px;">
      <li v-if="info.is_landline==1">座机：{{info.landline}}</li>
      <li v-if="info.is_tel==1">手机：{{info.tel}}</li>
      <li v-if="info.is_qq==1">Q Q：{{info.qq}}</li>
      <li v-if="info.is_wx==1">微信：{{info.wx}}</li>
      <li v-if="info.is_mail==1">邮箱：{{info.mail}}</li>
      <li>品牌：{{info.brand_title}}</li>
      <li>全称：{{info.enterprise}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "contactUs",
  data() {
    return {
      info: {
        is_app: 0,
        is_demand: 0,
        is_info: 0,
        is_landline: 0,
        is_league: 0,
        is_mail: 0,
        is_message: 0,
        is_qq: 0,
        is_recruit: 0,
        is_store: 0,
        is_tel: 0,
        is_wx: 0,
        mail: '',
        tel: '',
        qq: '',
        wx: '',
        landline: '',
        brand_title: '',
        enterprise: '',
      },
    }
  },
  methods: {
    getWebDetail() { //获取官网信息
      this.id = this.$route.query.id
      this.sid = this.$route.query.sid
      this.$axios("POST","/api/Release/getBindInfo",{id: this.sid})
          .then(res => {
            if(res.code===1) {
              this.info = res.data
            }
          })
    },
  },
  created() {
    this.getWebDetail()
  }
}
</script>

<style scoped>
 .us_list li{
   margin: 20px 0;
 }
</style>