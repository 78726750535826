import { render, staticRenderFns } from "./officialWebsite.vue?vue&type=template&id=587ad228&scoped=true&"
import script from "./officialWebsite.vue?vue&type=script&lang=js&"
export * from "./officialWebsite.vue?vue&type=script&lang=js&"
import style0 from "./officialWebsite.vue?vue&type=style&index=0&id=587ad228&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "587ad228",
  null
  
)

export default component.exports