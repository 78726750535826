<template>
  <!--资讯详情-->
  <div class="advisory mr0At pdBtm30" style="width: 1200px;">
    <!--导航-->
    <div class="dif alc tlt fs12 col999 mrTop20 mrBtm20">
      <router-link to="/" class="fs12 col999">首页</router-link>
      <p @click="$router.back()" class="pointer" v-if="routerType==1">>资讯搜索</p>
      <p>>资讯详情</p>
    </div>
    <div class="w100 h100 difb res_box">
      <!--搜索结果左侧列表-->
      <div class="res_left" style="width: 626px;">
        <!--文章标题-->
        <h1 class="two_overflow tlt mrTop20 mrBtm20 fs28 col333">{{res.title}}</h1>
        <!--发表时间-->
        <div class="posr fs12 dif alc col7b">
          <p @click="goWebSite()" class="fs14 col3A53FF pointer">查看他家更多资讯</p>
          <p class="mrRit20 fs14 col999" style="margin-left: 54px;">{{res.createtime}}</p>
        </div>
        <!--资讯内容-->
        <div class="cont mrTop20 mrBtm20">
          <div>
            <!--  正文介绍-->
            <div>
              <div style="text-indent: 2em;line-height: 2em;word-break: break-all"
                   class="w100 tlt fs18 col333 image_warp" v-html="content">{{content}}</div>
            </div>
            <!-- <div class="mrTop20">
              <img :src="item" class="w100" style="height: auto" v-for="(item,index) in images" :key="index">
            </div> -->
          </div>
        </div>
        <!--点赞 评论-->
        <div class="fs14 dife alc caozuo2 mrTop20 posr">
          <div class="difcac bottom_button pointer mrLft10">
            <div class="difcac" v-if="res.is_praise==1" @click="like">
              <img src="@/assets/image/yizhibang/16_give.png" style="width: 18px;height: 18px;">
              <p class="fs12 col999 mrLft10">取消</p>
            </div>
            <div class="difcac" v-else @click="like">
              <img src="@/assets/image/yizhibang/29thumbs.png" style="width: 18px;height: 18px;">
              <p class="fs12 col999 mrLft10">点赞</p>
            </div>
          </div>
          <div class="difcac bottom_button pointer mrLft10" @click="setShare()">
            <img src="@/assets/image/yizhibang/29share.png" style="width: 18px;height: 18px;">
            <p class="fs12 col999 mrLft10">分享</p>
          </div>
          <div class="difcac bottom_button pointer mrLft10" @click="collect()" v-if="res.is_Collection==1">
            <!--<img src="@/assets/image/yizhibang/29collection.png" style="width: 18px;height: 18px;">-->
            <i class="el-icon-star-on fs20" style="color: rgb(246,170,0)"></i>
            <p class="fs12 col999 mrLft10">已收藏</p>
          </div>
          <div class="difcac bottom_button pointer mrLft10" @click="collect()" v-else>
            <!--<img src="@/assets/image/yizhibang/29collection.png" style="width: 18px;height: 18px;">-->
            <i class="el-icon-star-off fs20 col999"></i>
            <p class="fs12 col999 mrLft10">收藏</p>
          </div>
          <div class="share_icon2" v-if="showShare" @mouseleave="showShare = false">
            <share :config="config"></share>
          </div>
        </div>
      </div>
      <hot></hot>
    </div>
    <!--评论区-->
    <div class="comment_area" style="margin-top: 50px;">
      <!--评论输入框-->
      <div class="comment_input">
        <textarea rows="5" v-model="comment" placeholder="请输入评论内容"></textarea>
      </div>
      <!--发布评论-->
      <div class="dife alc mrTop20">
        <div class="submit pointer" @click="postComment(sid,1)">发布评论</div>
      </div>
      <ul>
        <!--一级评论-->
        <li v-for="(item,index) in comments" class="mrTop10 pdBtm10 pdTop20" style="border-bottom: 1px solid #EEEEEE;">
          <div class="area_top posr dif">
            <!--头像-->
            <div class="ava mrRit10 bra bgccc" style="width: 60px;height: 60px;overflow:hidden;">
              <img :src="item.userinfo.avatar" class="w100 h100 bra">
            </div>
            <div>
              <!--发布时间-->
              <div class="dif alc">
                <p class="area_name fs18 bold col333 mrRit30">{{item.userinfo.username}}</p>
                <p class="area_time fs12 col999 mrRit30" style="color:#7b7b7b;">发表于 {{item.createtime}}</p>
                <!--<div class="fs12 col3A53FF">私信 举报</div>-->
              </div>

              <div class="comment_main box tlt  posr mrTop20" style="width: 1079px;">
                <!--一级评论内容-->
                <p class="fs14 col999 posr mrBtm20">
                  {{item.content}}
                  <img src="@/assets/image/yizhibang/25comment.png" style="width: 17px;height: 16px;margin-right: 40px;"  class="reply pointer" @click="showComment(item.id,2)">
                  <!--<img src="@/assets/image/yizhibang/25thumbs.png" style="width: 17px;height: 16px;"  class="reply pointer" @click="showComment(item.id,2)">-->
                  <!--<span class="reply fs14 pointer" style="color:#7b7b7b;" >回复</span>-->
                </p>
                <!--二级评论-->
                <ul class="two_comment" v-if="item.sonInfo && item.sonInfo.length>0">
                  <li class="pdTop20 mrBtm20 w100" v-for="(item2,index2) in item.sonInfo" v-if="index2<2">
                    <div class="area_top posr dif w100">
                      <!--头像-->
                      <div class="ava mrRit10 bra bgccc" style="width: 60px;height: 60px; overflow: hidden">
                        <img :src="item2.userinfo.avatar" class="w100 h100 bra">
                      </div>
                      <div>
                        <div class="dif alc">
                          <p class="area_name fs18 col333 bold mrRit30">{{item2.userinfo.username}}</p>
                          <p class="area_time fs12 col999 mrRit30">发表于 {{item2.createtime}}</p>
                          <!--<div class="fs12 col3A53FF">私信 举报</div>-->
                        </div>
                        <div class="comment_main box tlt  posr mrTop10 mrBtm10 w100">
                          <p class="fs14 col999 posr" style="width: 970px;">
                            <span class="area_time fs14 col999 mrRit10">回复</span>
                            <span class="area_name fs14 col333">{{item.userinfo.username}}：</span>
                            {{item2.content}}
                            <img src="@/assets/image/yizhibang/25comment.png" style="width: 17px;height: 16px;margin-right: 40px;"  class="reply pointer" @click="showComment(item.id,2,item2.userinfo.username)">
                            <!--<img src="@/assets/image/yizhibang/25thumbs.png" style="width: 17px;height: 16px;"  class="reply pointer">-->
                          </p>
                        </div>
                      </div>

                    </div>

                  </li>
                  <li class="pdTop20 mrBtm20" v-for="(item2,index2) in item.sonInfo"  v-if="index2>=2 && index===i1 && index===i0">
                    <div class="area_top posr dif alc">
                      <!--头像-->
                      <div class="ava mrRit10 bra bgccc" style="width: 60px;height: 60px;overflow: hidden">
                        <img :src="item2.userinfo.avatar" class="w100 h100 bra">
                      </div>
                      <div>
                        <div class="dif alc">
                          <p class="area_name fs18 col333 bold mrRit30">{{item2.userinfo.username}}</p>
                          <p class="area_time fs12 col999 mrRit30">发表于 {{item2.createtime}}</p>
                          <!--<div class="fs12 col3A53FF">私信 举报</div>-->
                        </div>
                        <div class="comment_main box tlt  posr mrTop10 mrBtm10 w100">
                          <p class="fs14 col999 posr" style="width: 970px;">
                            <span class="area_time fs14 col999 mrRit10">回复</span>
                            <span class="area_name fs14 col333">{{item.userinfo.username}}：</span>
                            {{item2.content}}
                            <img src="@/assets/image/yizhibang/25comment.png" style="width: 17px;height: 16px;margin-right: 40px;"  class="reply pointer" @click="showComment(item.id,2)">
                            <!--<img src="@/assets/image/yizhibang/25thumbs.png" style="width: 17px;height: 16px;"  class="reply pointer" @click="showComment(item.id,2)">-->
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                  <span class="pointer fs12 col3A53FF" @click="i1=index;i0=index" v-if="index!==i1 && item.sonInfo.length>2">还有{{item.sonInfo.length - 2}}条评论,点击查看更多</span>
                  <span class="pointer fs12 col3A53FF" @click="i1=index;i0=index" v-else-if="index===i1 && item.sonInfo.length>2 && i0!==index">还有{{item.sonInfo.length - 2}}条评论,点击查看更多</span>
                  <p @click="i1=-1;i0=-1" v-else-if="index===i1 && item.sonInfo.length>2" class="pointer fs12 col3A53FF mrTop10">收起</p>
                </ul>
              </div>
            </div>
            <p class="fs12 fastest" style="color:#F6AA00;" v-if="index===0">沙发</p>
            <p class="fs12 fastest" style="color:#F6AA00;" v-if="index===1">板凳</p>
            <p class="fs12 fastest" style="color:#F6AA00;" v-if="index===2">地板</p>
            <p class="fs12 fastest" style="color:#666666;" v-if="index>2">{{index+1}}F</p>
          </div>
        </li>
      </ul>
    </div>
    <!--评论2-->
    <div class="comment2" v-if="isShow">
      <div class="comment2_cont">
        <span @click="isShow=false" class="fs14 col999">关闭</span>
        <!--输入框-->
        <div class="comment_input2">
          <textarea rows="5" v-model="comment" placeholder="请输入评论内容"></textarea>
        </div>
        <!--发布评论-->
        <div class="dife alc mrTop20">
          <div class="submit pointer" @click="postComment()">回复</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Hot from "@/components/search/hot";
export default {
  name: "advisoryDetail",
  components: {Hot},
  data() {
    return {
      routerType: '1',//1搜索 2首页跳转
      content: '',//资讯内容
      images: [],//资讯图片
      sid: '',
      res: {},
      isLike: false,
      comments: [], //获取评论
      comment: '', //输入评论内容
      isShow: false,
      type: 2,
      pid: 0,
      busername: '123',
      //显示更多
      i0: -1,
      i1: -1,
      i2: -1,
      //分享配置
      config:{
        sites: ['qq','wechat','qzone'],
        image: '',
        title: '',
        description: '',
        url: '', // 网址，默认使用 window.location.href
        source: '', // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
        wechatQrcodeTitle: '微信扫一扫：分享', // 微信二维码提示文字
        wechatQrcodeHelper: '<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>'
      },
      showShare: false,
    }
  },
  watch:{
    "$route.params":function (route) {
      console.log(route);
      this.$router.go(0)
    }
  },
  methods: {
    collect() {
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      this.$axios("POST","/api/member/collect",{
        id: this.sid,
        type: 2
      }).then(res => {
        if(res.code===1) {
          this.$message.success('操作成功')
          this.getDetail(this.sid)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    goWebSite() { //跳转到官网更多资讯
      this.$router.push({
        path: '/search/webdetail',
        query: {
          sid: this.res.sid,
          isActive: 1,//咨询
        }
      })
    },
    setShare() {
      this.showShare=!this.showShare
      if(this.showShare) {
        this.$axios("POST","/api/site_title/addNum",{id: this.sid})
      }
    },
    postComment(pid,type,busername) { //发布评论
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      if(!pid && !type && !busername){ //回复窗口点击
        pid = this.pid
        type = this.type
        busername = this.busername
      }
      if(this.comment === ''){
        this.$alert('评论不允许为空')
        return
      }
      this.$axios("POST","/api/Information/infoComment",{
        pid: pid,
        type: type,
        busername: busername,
        comment: this.comment
      }).then(res => {
        this.$alert(res.msg)
        this.isShow = false
        this.comment = '' //评论完清空内容
        //获取资讯评论
        this.getComment(this.sid)
      })
    },
    like() { //点赞
      let token = localStorage.getItem('yizhibang') //检测 是否存在token 和 用户信息
      if(!token) {
        this.$alert('请登录后操作',{type: 'error'})
            .then(res => {
              this.$store.commit('showLogin',true)
            })
        return
      }
      this.$axios("POST","/api/site_title/praiseImformation",{
        id:this.sid
      }).then(res => {
        if(res.code == '1') {
          this.getDetail(this.sid)
        }else{
          this.$alert(res.msg)
        }
      })
    },
    getComment(id) {
      //获取资讯评论
      this.$axios("POST","/api/Information/getComment",{
        id
      }).then(res => {
        this.comments = res.data
      })
    },
    showComment(pid,type,busername){ //显示回复时输入框
      this.pid = pid
      this.type = type
      this.busername = busername
      this.isShow = true
    },
    getDetail(id) {
      this.$axios("POST","/api/Information/info",{
        id
      }).then(res => {
        if(res.code===1) {
          this.res = res.data
          this.isLike = this.res.is_praise
          this.content = unescape(res.data.content)
          this.images = res.data.images
          //配置分享
          this.config.title = res.data.title

          if(res.data.images && res.data.images.length>0) {
            this.config.image = '<img src="'+res.data.images[0]+'" style="width: 200px;height: 200px;">'
            console.log(this.config.image)
          }
          this.config.url = document.location.href
        }
      })
    },
  },
  created() {
    //获取资讯详情
    let id = this.$route.query.id
    this.routerType = this.$route.query.type
    this.sid = id
    this.getDetail(id)
    //获取资讯评论
    this.getComment(id)
  },
}
</script>

<style>
.share_icon2{
  position: absolute;
  top: 39px;
  left: 403px;
}
.image_warp img{
  max-width: 626px;
  height: auto;
}
.hot li img{
  width: 230px;
  height: 120px;
  background: #666;
}
.caozuo2 span{
  margin: 0 10px;
}
.bottom_button{
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  padding: 8px 19px;
}
/*评论*/
/*评论2*/
.comment2{
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
}
.comment2 .comment2_cont{
  box-sizing: border-box;
  padding: 20px 20px;
  border-radius: 20px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.comment2_cont span{
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;
}
.comment_input2 textarea{
  box-sizing: border-box;
  resize: none;
  margin-top: 20px;
  width: 500px;
  height: 150px;
  background: #F4F4F4;
  border: 1px solid #DEDEDE;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
  border: none;
  font-size: 16px;
}
.comment_input textarea{
  box-sizing: border-box;
  resize: none;
  width: 1200px;
  height: 120px;
  background: #F4F4F4;
  border: 1px solid #DEDEDE;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
  font-size: 16px;
}
.submit{
  width: 130px;
  height: 46px;
  background: #3A54FF;
  border-radius: 6px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
.fastest,.reply{
  position: absolute;
  top: 20px;
  right: 0;
}
.reply{
  top: 10px;
  right: 50px;
}

/*评论2*/
.two_comment{
  width: 1100px;
  background: #F4F4F4;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
}
.two_comment::before{
  content: "";
  display: block;
  border-bottom: 14px solid #F4F4F4;
  border-left: 13px solid rgba(0,0,0,0);
  border-right: 13px solid rgba(0,0,0,0);
  position: absolute;
  top: -10px;
  left: 27px;
}
.comment2{
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  left: 0;
}
.comment2 textarea{
  background: none;
}
.comment2_cont span{
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;
}
</style>