<template>
  <!--服务窗口-->
  <div class="service_window" style="width: 1200px;margin: 0 auto;">
    <div class="bolder_service"></div>
    <!--资讯-->
    <advisory v-if="typeId==1" :sid="sid"></advisory>
    <!--需求-->
    <demand v-if="typeId==2" :sid="sid"></demand>
    <!--店铺-->
    <shop v-if="typeId==3" :sid="sid"></shop>
    <!--加盟-->
    <join v-if="typeId==4" :sid="sid"></join>
    <!--招聘-->
    <zhaopin v-if="typeId==5" :sid="sid"></zhaopin>
  </div>
</template>

<script>
import Advisory from "@/components/search/searchCommon/serviceWindow/advisory"; //服务窗口 资讯
import demand from "@/components/search/searchCommon/serviceWindow/demand"; //服务窗口 需求
import join from "@/components/search/searchCommon/serviceWindow/join"; //服务窗口 加盟
import shop from "@/components/search/searchCommon/serviceWindow/shop"; //服务窗口 店铺
import zhaopin from "@/components/search/searchCommon/serviceWindow/zhaopin"; //服务窗口 招聘
export default {
  props: {
    typeId: {
      default: 1
    },
    sid: [String,Number]
  },
  name: "serviceCenter",
  components: {Advisory,demand,join,shop,zhaopin}
}
</script>

<style scoped>
.bolder_service{
  border-top: 1px solid #F0F0F0;
  padding-top: 26px;
}
.service_window{
  padding-top: 30px;
}
</style>