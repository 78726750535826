<template>
<!--  进入店铺-->
  <div class="shop_detail">
    <iframe :src="url" id="obtain"></iframe>
  </div>
</template>

<script>
export default {
  name: "shopDetail",
  data() {
    return {
      url: '',//店铺链接
      // 页面高度
      screenHeight: document.body.clientHeight,
      // 计算出的高度
      PageHeight: '',
      isScroll: true,
      timer: null,
    }
  },
  created() {
    if(this.$route.query.id) {
      this.getUrl(this.$route.query.id)
    }else {
      this.url = sessionStorage.getItem('goodsUrl')
    }
    this.GetHeight()
    console.log(this.url);
  },
  methods: {
    getUrl(id) { //获取店铺id
      this.$axios("POST","/api/search/getStoreUrl",{id})
      .then(res => {
        if(res.code===1) {
          this.url = res.data
        }
      })
    },
    GetHeight() {
      // 根据页面高度计算  (可以自定义逻辑)
      this.PageHeight = (this.screenHeight)
      this.$nextTick(() => {
        this.scrollTo()
      })

    },
    handleScrollx() { //监听滚动高度
      let height = document.body.clientHeight
      if(window.pageYOffset>= height/13) {
        if(this.isScroll) {
          this.scrollTo()
        }
        this.isScroll = false
      }else {
        this.isScroll = true
      }
      // console.log('滚动高度', window.pageYOffset)
      // console.log('距离顶部高度', this.$refs.obtain.getBoundingClientRect().top)
    },
    scrollTo() {
      let el = document.getElementById('obtain');
      // let el = document.getElementById('test');
      console.log(el)
      if(el){
        window.scrollTo({
          //滚动到元素位置
          //top: this.$refs[元素].offsetTop,//offsetTop 相对于offsetparent 定位,当有定位时，不准确。
          top: el.getBoundingClientRect().top+90 ,//推荐使用，getBoundingClientRect 相对于当前视口的位置
          //top: 400//滚动到指定距离
          //top: 0,//滚动到顶部
          // top: document.documentElement.scrollHeight-400,//滚动到底部
          behavior: "smooth" // 平滑滚动
        });
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScrollx, true)
  }
}
</script>

<style scoped>
  iframe{
    border: none;
    width: 100%;
    height: 100vh;
  }
</style>